import { NgModule } from '@angular/core';
import { SwitchToggleComponent } from './switch-toggle.component';
import { CommonModule } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
@NgModule({
    declarations: [SwitchToggleComponent],
    imports: [CommonModule, MatSlideToggleModule],
    exports: [SwitchToggleComponent],
})
export class SwitchToggleModule {}

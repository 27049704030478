import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';

@Component({
  selector: 'app-vehicles-tools-panel',
  templateUrl: './vehicles-tools-panel.component.html',
})
export class VehiclesToolsPanelComponent implements OnInit{
  @Input() lastUpdated: Date;
  @Input() isAvailableToExport: boolean;
  @Input() progressValueExport: number;
  @Input() isOpenFilters: boolean;
  @Input() isOpenColumns: boolean;
  @Input() isSelectedVehicle: boolean;

  @Output() refreshData = new EventEmitter();
  @Output() newVehicle = new EventEmitter();
  @Output() editVehicle = new EventEmitter();
  @Output() deleteVehicle = new EventEmitter();
  @Output() exportToExcel = new EventEmitter();
  @Output() resetAll = new EventEmitter();
  @Output() clearSorting = new EventEmitter();
  @Output() clearFiltering = new EventEmitter();
  @Output() clearRowGrouping = new EventEmitter();
  @Output() resetColumnOrder = new EventEmitter();
  @Output() resetColumnVisibility = new EventEmitter();
  @Output() openFilters = new EventEmitter();
  @Output() openColumns = new EventEmitter();

  refreshDataLabel = 'Refresh Data';
  newLabel = 'New';
  editLabel = 'Edit';
  deleteLabel = 'Delete';
  hasPermissionManageVehicles = false;

  constructor(private permissionService: AppPermissionService){}

  ngOnInit(): void {
    this.permissionService.user$.subscribe((user) => {
      if(user?.permissions.includes(AppPermissions.ManageVehicles)){
        this.hasPermissionManageVehicles = true;
      }
    });
  }
}

import { Component, Inject, inject } from "@angular/core";
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { NotificationType } from "@app/services/notification/notification.service";

@Component({
    selector: 'app-snackbar',
    templateUrl: './snackbar.component.html',
    styleUrls: ['./snackbar.component.scss']
  })
  export class SnackBarComponent {
    constructor(@Inject(MAT_SNACK_BAR_DATA) public data: {message: string, notificationType: NotificationType}){}
    snackBarRef = inject(MatSnackBarRef);
  }
export enum UsersDataEnum {
    radioButton = 'radioButton',
    firstName = 'firstName',
    lastName = 'lastName',
    email = 'email',
    companyContext = 'companyContext',
    assignedRole = 'assignedRole',
    dashboardPermission = 'dashboardPermission',
    heartbeatsPermission = 'heartbeatsPermission',
    eventHistoryPermission = 'eventHistoryPermission',
    mapPermission = 'mapPermission',
    devicePositionPermission = 'devicePositionPermission',
    positionHistoryPermission = 'positionHistoryPermission',
    vehiclesPermission = 'vehiclesPermission',
    commandsPermission = 'commandsPermission',
    usersPermission = 'usersPermission',
}

export type UsersData = {
    [key in UsersDataEnum]: any;
};

export enum IconTypeEnum {
    Dashboard = 'column-dashboard',
    Heartbeats = 'column-heartbeats',
    EventsHistory = 'column-events-history',
    Map = 'column-map',
    Device = 'column-device',
    PositionHistory = 'column-position-history',
    Vehicles = 'column-vehicles',
    Commands = 'column-commands',
    Users = 'column-users',
}

export type IconType =
    | IconTypeEnum.Dashboard
    | IconTypeEnum.Heartbeats
    | IconTypeEnum.EventsHistory
    | IconTypeEnum.Map
    | IconTypeEnum.Device
    | IconTypeEnum.PositionHistory
    | IconTypeEnum.Vehicles
    | IconTypeEnum.Commands
    | IconTypeEnum.Users;

export type UsersSettings = {
    [key in UsersDataEnum]: {
        dataFieldName: string;
        label: string;
        cellRendererComponent?: any;
        iconType?: IconType;
    };
};

export type UsersColumnSettings = {
    key: UsersDataEnum;
    visible: boolean;
};

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-devices-tool-panel',
    templateUrl: './devices-tool-panel.component.html',
    styleUrls: ['./devices-tool-panel.component.scss'],
})
export class DevicesToolPanelComponent {
    @Input() isAvailableToExport: boolean;
    @Input() progressValueExport: number;
    @Input() isOpenFilters: boolean;
    @Input() isOpenColumns: boolean;

    @Output() exportToExcel = new EventEmitter();
    @Output() resetAll = new EventEmitter();
    @Output() clearSorting = new EventEmitter();
    @Output() clearFiltering = new EventEmitter();
    @Output() clearRowGrouping = new EventEmitter();
    @Output() resetColumnOrder = new EventEmitter();
    @Output() resetColumnVisibility = new EventEmitter();
    @Output() openFilters = new EventEmitter();
    @Output() openColumns = new EventEmitter();
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeartbeatsPanelComponent } from './heartbeats-panel.component';
import { AngularMaterialModule } from '@app/angular-material/angular-material.module';
import { DefaultTableToolsPanelModule } from '@app/shared/tools-panel/default-table-tools-panel';

@NgModule({
  declarations: [
    HeartbeatsPanelComponent
  ],
  exports: [
    HeartbeatsPanelComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    DefaultTableToolsPanelModule,
  ]
})
export class HeartbeatsPanelModule { }

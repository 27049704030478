<div class="dialog-container mat-elevation-z4">
    <div
        *ngIf="!dialogConfig.panelClass.includes('side-slider')"
        class="dialog-header"
        [ngStyle]="{ 'min-width': [dialogConfig.dialogSize] }"
    >
        <h3>{{ dialogConfig.title }}</h3>
        <h1 *ngIf="dialogConfig.subTitle">{{ dialogConfig.subTitle }}</h1>
    </div>

    <div mat-dialog-content class="dialog-content">
        <h3
            class="slider-header"
            *ngIf="dialogConfig.panelClass.includes('side-slider')"
        >
            {{ dialogConfig.title }}
        </h3>
        <h4
            class="slider-sub-header"
            *ngIf="dialogConfig.panelClass.includes('side-slider') && dialogConfig.subTitle">
            {{ dialogConfig.subTitle }}
        </h4>
        <p *ngIf="dialogConfig.dialogMessage">
            {{ dialogConfig.dialogMessage }}
        </p>
        <div *ngIf="!!dialogConfig.component">
            <ng-container
                *ngComponentOutlet="dialogConfig.component"
            ></ng-container>
        </div>
    </div>
    <div
        mat-dialog-actions
        class="dialog-button-group"
        *ngIf="dialogConfig.showDialogButtons"
    >
        <button mat-stroked-button color="primary" [mat-dialog-close]="false">
            {{ dialogConfig.cancelText }}
        </button>
        <button mat-flat-button color="primary" [mat-dialog-close]="true">
            {{ dialogConfig.okText }}
        </button>
    </div>
</div>

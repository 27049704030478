import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { from, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EnvironmentService } from 'environments/environment.service';

@Injectable()
export class Auth0Interceptor implements HttpInterceptor {
    constructor(private auth: AuthService, private environment: EnvironmentService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return from(this.auth.getAccessTokenSilently()).pipe(
            switchMap((token) => {
                if(this.environment.isDevelopment){
                    console.log(token);
                }
                if(request.url.includes("blob.core.windows.net/fileupload")){
                    return next.handle(request);
                }
                else{
                    const headers = request.headers.set(
                        'Authorization',
                        `Bearer ${token}`
                    );
                    const authorizedRequest = request.clone({ headers });
                    return next.handle(authorizedRequest);
                }

            })
        );
    }
}

export enum CommandsDataEnum {
    commandName = 'commandName',
    applicationTypes = 'applicationTypes',
    deviceCount = 'deviceCount',
    description = 'description',
    commandStatus = 'commandStatus',
    pendingCount = 'pendingCount',
    runningCount = 'runningCount',
    succeededCount = 'succeededCount',
    receivedCount = 'receivedCount',
    notReceivedCount = 'notReceivedCount',
    failedCount = 'failedCount',
    commandType = 'commandType',
    expiresOn = 'expiresOn',
    jobIds = 'jobIds',
    start = 'start',
    end = 'end',
    id = 'id',
}

export type CommandsData = {
    [key in CommandsDataEnum]: any;
};

export type CommandsSettings = {
    [key in CommandsDataEnum]: {
        dataFieldName: string;
        label: string;
        cellRendererComponent?: any;
    };
};

export type CommandsColumnSettings = {
    key: CommandsDataEnum;
    visible: boolean;
};

export interface ICommandData {
    commandName: string;
    applicationTypes: Array<string>;
    deviceCount: number;
    description: string;
    commandStatus: string;
    pendingCount: number;
    runningCount: number;
    succeededCount: number;
    receivedCount: number;
    notReceivedCount: number;
    failedCount: number;
    commandType: string;
    expiresOn: string;
    jobIds: Array<string>;
    start: string;
    end: string;
    payloads: Array<string>;
    expires: boolean;
    devices?: Array<any>;
    id?: string;
}
export enum CommandTypes {
    SELECT = 'SelectCommand',//None
    REBOOT = 'ValReboot',//41
    RESTART_SERVICE = 'ValRestartValidatorService',//45
    RETRIEVE_LOG_FILE = 'ValUploadLogFile',//45
    RETRIEVE_CONFIG_FILE = 'ValUploadConfigFile',// 45
    OVERWRITE_CONFIG_FILE = 'ValOverwriteConfigFile',//45
    SET_SOUND = 'ValSetSound',//45
    SET_EVENT_LOG_LEVEL = 'ValSetEventLogLevel',//
    SET_VOLUME = 'ValSetVolume',
    SET_TRANSACTION_DATA_RETENTION_PERIOD = 'ValSetTransactionDataRetentionTime',
    SET_MAX_TRANSACTION_DATA_SEND_TIME = 'ValSetMaxTransactionDataSendTime',
    SET_MAX_NUMBER_TRANSACTION_DATA_RECORDS = 'ValSetMaxNumberTransactionDataRecords',
    SET_NEW_SW_CHECK_TIME = 'ValSetNewSWCheckTime',
    SET_NEW_EOD_CHECK_TIME = 'ValSetNewEODCheckTime',
    SET_NEW_EMV_BLACKLIST_CHECK_TIME = 'ValSetNewEMVBlacklistCheckTime',
    SET_NEW_OV_CHIPCARD_BLACKLIST_CHECK_TIME = 'ValSetNewOVChipcardBlacklistCheckTime',
    SET_DISPLAY_BRIGHTNESS = 'ValSetDisplayBrightness',
    SET_EEPROM_VALUE = 'ValSetEEPROMValue',
    SET_FARE_MEDIA_USAGE_ALLOWED = 'ValSetFareMediaUsageAllowed',
    SET_DEGRADED_MODE = 'ValSetDegradedMode',
    TRIGGER_FILE_INTEGRITY_CHECK = 'ValFileIntegrityMonitorTrigger'
}

export interface CommandTypeValue {
    type: CommandTypes;
    value: string;
    note: string;
}

export const CommandTypeValues: CommandTypeValue[] = [
    {
        type: CommandTypes.SELECT,
        value: 'Select Command',
        note: 'Select a new command.',
    },
    {
        type: CommandTypes.REBOOT,
        value: 'Reboot',
        note: 'Reboot the selected devices.',
    },
    {
        type: CommandTypes.RESTART_SERVICE,
        value: 'Restart Service',
        note: 'Restart the selected application services on the selected devices.',
    },
    {
        type: CommandTypes.RETRIEVE_LOG_FILE,
        value: 'Retrieve log file',
        note: 'Retrieve log files going back to the specified date from the selected services and devices.',
    },
    {
        type: CommandTypes.RETRIEVE_CONFIG_FILE,
        value: 'Retrieve Config File',
        note: 'Retrieve config files for the selected services from the selected devices',
    },
    {
        type: CommandTypes.OVERWRITE_CONFIG_FILE,
        value: 'Overwrite Config File',
        note: 'Overwrite the complete configuration file of an application service on the selected devices. <br><br> WARNING: uploading an incorrect configuration file may cause the validator to stop functioning or lead to unexpected behavior. <br><br>(Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_SOUND,
        value: 'Set Sound',
        note: 'Choose a sound file, which will be played on a validator on check-in, check-out or error for OV-Chipcards, EMV bank cards and barcode tickets.',
    },
    {
        type: CommandTypes.SET_EVENT_LOG_LEVEL,
        value: 'Set Event Log Level',
        note: 'Set the event log level of a service on the validator. (Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_VOLUME,
        value: 'Set Volume',
        note: 'Set the volume of the validator. <br><br> (Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_TRANSACTION_DATA_RETENTION_PERIOD,
        value: 'Set Transaction Data Retention Period',
        note: 'Adjust the number of days that transaction data (OV-Chipcard and barcode) must be stored on a validator after the data has been sent to the back-office. <br><br>(Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_MAX_TRANSACTION_DATA_SEND_TIME,
        value: 'Set Max Transaction Data Send Time',
        note: 'Set the maximum time in minutes between two periodic uploads of OV-Chipcard and barcode transaction data records and audit register files. <br><br>(Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_MAX_NUMBER_TRANSACTION_DATA_RECORDS,
        value: 'Set Max Number Transaction Data Records',
        note: 'Set the maximum number of OV-Chipcard and barcode transaction data records a file can contain before it is sent to the back-office. <br><br>(Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_NEW_SW_CHECK_TIME,
        value: 'Set New SW Check Time',
        note: 'Set the number of minutes between two periodic checks for a new SW. <br><br> (Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_NEW_EOD_CHECK_TIME,
        value: 'Set New EOD Check Time',
        note: 'Set the number of minutes between two periodic checks for a new EOD. <br><br> (Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_NEW_EMV_BLACKLIST_CHECK_TIME,
        value: 'Set New EMV Blacklist Check Time',
        note: 'Set the number of minutes between two periodic checks for a new EMV blacklist delta file. <br><br> (Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_NEW_OV_CHIPCARD_BLACKLIST_CHECK_TIME,
        value: 'Set New OV Chipcard Blacklist Check Time',
        note: 'Set the number of minutes between two periodic checks for a new OV-Chipcard blacklist. <br><br> (Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_DISPLAY_BRIGHTNESS,
        value: 'Set Display Brightness',
        note: 'Set the brightness of the validator display. <br>‘Dynamic’ makes use of the validator sensor and adjusts the brightness automatically. <br>‘Static’ will disable usage of the sensor and will set the brightness to a fixed value. <br><br>(Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.SET_EEPROM_VALUE,
        value: 'Set EEPROM Value',
        note: 'Set the value of the EEPROM <br><br>(Requires a full reboot)',
    },
    {
        type: CommandTypes.SET_FARE_MEDIA_USAGE_ALLOWED,
        value: 'Set Fare Media Usage Allowed',
        note: 'Enable or disable whether a validator is allowed to use OV-Chipcards, barcode tickets and EMV bank cards as a valid fare media. To change the fare media of a vehicle the command must be sent to all validators in a vehicle. The change becomes active after a full PON/OFF cycle.',
    },
    {
        type: CommandTypes.SET_DEGRADED_MODE,
        value: 'Set Degraded Mode',
        note: 'Enable or disable Degraded Mode on a validator. To set all validators in a vehicle to degraded mode, the command needs to be sent to all validators in a vehicle. <br><br>(Requires a reboot or restart of the specific service)',
    },
    {
        type: CommandTypes.TRIGGER_FILE_INTEGRITY_CHECK,
        value: 'Trigger File Integrity Check',
        note: 'Manually check the file integrity of selected devices.',
    },
];

<app-default-table-tools-panel
    class="no-margin-panel"
    [isAvailableToExport]="isAvailableToExport"
    [progressValueExport]="progressValueExport"
    [isOpenFilters]="isOpenFilters"
    [isOpenColumns]="isOpenColumns"
    (exportToExcel)="exportToExcel.emit()"
    (resetAll)="resetAll.emit()"
    (clearSorting)="clearSorting.emit()"
    (clearFiltering)="clearFiltering.emit()"
    (clearRowGrouping)="clearRowGrouping.emit()"
    (resetColumnOrder)="resetColumnOrder.emit()"
    (resetColumnVisibility)="resetColumnVisibility.emit()"
    (openFilters)="openFilters.emit()"
    (openColumns)="openColumns.emit()"
>
    <ng-content left></ng-content>
</app-default-table-tools-panel>

import { Injectable } from '@angular/core';
import { AppRestService, KeySettings, SettingsCacheService } from '@app/shared';
import { BehaviorSubject, Observable, of, switchMap, take } from 'rxjs';
import { Account, UserPermission, UserRole } from './user-role.interface';
import { CompanyContextService } from '../company-context/company-context.service';

@Injectable({
    providedIn: 'root',
})
export class UserRoleService {
    private userAction$: BehaviorSubject<string> =
    new BehaviorSubject<string>("none");    
    constructor(
        private appRestService: AppRestService,
        private companyContextService: CompanyContextService,
        private settingsCacheService: SettingsCacheService
    ) {}

    getAccounts(ctx: string): Observable<Account[]> {
        return this.appRestService.get(`account/${ctx}/users`);
    }

    createAccount(ctx: string, body: Account): Observable<any> {
        return this.appRestService.post(`account/${ctx}/users`, body);
    }

    updateAccount(ctx: string, body: Account): Observable<Account> {
        return this.appRestService.put(`account/${ctx}/users`, body);
    }

    getRolesAndPermissions(): Observable<UserRole[]> {
        const allRoles = this.settingsCacheService.getDataFromLocalStorage(KeySettings.AllRoles) as UserRole[];
        if(allRoles && allRoles.length > 0) {
            return of(allRoles);
        }
        else{
            return this.appRestService
            .get('/account/roles-permissions-mapping');
        }
    }

    createRole(ctx: string, role: UserRole) {
        return this.appRestService.post(`account/${ctx}/custom-roles`, role);
    }

    updateRole(role: any): Observable<any> {
        return this.companyContextService.getCompanyContext().pipe(
            take(1),
            switchMap((ctx: string) => {
                return this.getRolesAndPermissions().pipe(
                    take(1),
                    switchMap(
                        (userRoles: UserRole[]) => {
                            const permissions: UserPermission[] = [];
                            userRoles.forEach((role: UserRole) => {
                                role.permissions.forEach((permission: UserPermission) => {
                                    permissions.push({
                                        permissionName: permission.permissionName,
                                        permissionId: permission.permissionId,
                                    });
                                });
                            });
                            role.permissions = role.permissions.map(
                                (selectedPermission: string) => {
                                    return permissions.find(
                                        (permission: UserPermission) =>
                                            permission.permissionName ===
                                            selectedPermission
                                    );
                                }
                            );
                            return this.appRestService.put(
                                `account/${ctx}/custom-roles`,
                                role
                            );
                        }
                    )
                );
            })
        );
    }

    deleteRole(roleId: string): Observable<any> {
        return this.companyContextService.getCompanyContext().pipe(
            take(1),
            switchMap((ctx: string) => {
                return this.appRestService.delete(
                    `account/${ctx}/custom-roles/${roleId}`
                );
            })
        );
    }

    getCreatedRoles(ctx: string): Observable<UserRole[]> {
        return this.appRestService.get(`account/${ctx}/custom-roles`);
    }

    getUserAction(): Observable<string> {
        return this.userAction$;
    }

    setUserAction(action: string) {
        this.userAction$.next(action);
    }
}

import { Component} from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { ProgressSpinnerMode } from "@angular/material/progress-spinner";

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss']
})
export class AppLoadingComponent{
    color: ThemePalette = 'warn';
    mode: ProgressSpinnerMode = 'indeterminate';
    diameter = 48;
}
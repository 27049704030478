<ng-container *ngIf="dataLoaded$ | async; else notLoaded">
    <app-header></app-header>
    <app-sidenav></app-sidenav>
</ng-container>

<ng-template #notLoaded>
    <app-loading></app-loading>
</ng-template>



import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';

import { HeartbeatsRoutingModule } from './heartbeats-routing.module';
import { HeartbeatsComponent } from './heartbeats.component';
import { 
  ConnectionStateCellRendererModule, 
  LocationCellRendererModule,
  CustomDateCellRendererModule 
} from '@app/shared/grid/cell-renderer';
import { RowsPerPageModule } from '@app/shared/grid/rows-per-page';
import { HeartbeatsPanelModule } from '@app/pages/heartbeats/heartbeats-panel';
import { AppDialogModule } from '@app/shared/app-dialog';

@NgModule({
  declarations: [
    HeartbeatsComponent
  ],
  imports: [
    CommonModule,
    HeartbeatsRoutingModule,
    AgGridModule,
    HeartbeatsPanelModule,
    ConnectionStateCellRendererModule,
    CustomDateCellRendererModule,
    LocationCellRendererModule,
    RowsPerPageModule,
    AppDialogModule
  ]
})
export class HeartbeatsModule { }

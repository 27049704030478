import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, filter } from 'rxjs/operators';
import { AppPermissions } from './app-permissions';
import { AppRestService } from '../app-rest';

@Injectable({
    providedIn: 'root',
})
export class AppPermissionService {
    private userSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    user$: Observable<any> = this.userSubject
        .asObservable()
        .pipe(filter((user) => !!user));

    permissions: string[] = [];

    constructor(
        private authService: AuthService,
        public appRestService: AppRestService
    ) {
        this.authService.user$
            .pipe(switchMap((user) => this.fetchUserPermissions(user)))
            .subscribe((userWithPermissions) => {
                this.userSubject.next(userWithPermissions);
            });
    }

    public hasPermission(data: AppPermissions) {
        return this.permissions.includes(data);
    }

    private fetchUserPermissions(user: any): Observable<any> {
        // Fetch user's permissions from Auth0's user object.
        if (user) {
            const permissions = user['https://www.api360.com/permissions'];

            // Return an observable with the user object enriched with permissions.
            return of({ ...user, permissions });
        } else {
            return of(null);
        }
    }
}

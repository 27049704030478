<div class="permission">
    <div class="permission__item" *ngFor="let config of permissionConfig">
        <span class="permission__name"
            >{{ config.permissionDisplayValue }}:</span
        >
        <span class="permission__selected-value">{{
            getSelectedPermissionsValue(
                config.permissionName,
                config.permissionOptions
            )
        }}</span>
        <div class="permission__switchers">
            <div
                class="permission__switcher"
                (click)="
                    toggleRestrictedPermission(
                        config.permissionName,
                        config.permissionOptions
                    )
                "
            >
                <mat-icon
                    svgIcon="restricted"
                    [ngClass]="{
                        active: isRestricted(
                            config.permissionName,
                            config.permissionOptions
                        )
                    }"
                ></mat-icon>
            </div>
            <div
                class="permission__switcher"
                *ngIf="isViewPermissionOption(config.permissionOptions)"
                (click)="toggleViewPermission(config.permissionName)"
            >
                <mat-icon
                    svgIcon="view"
                    [ngClass]="{ active: isView(config.permissionName) }"
                ></mat-icon>
            </div>
            <div
                class="permission__switcher"
                *ngIf="isEditPermissionOption(config.permissionOptions)"
                (click)="toggleEditPermission(config.permissionName)"
            >
                <mat-icon
                    svgIcon="edit"
                    [ngClass]="{ active: isEdit(config.permissionName) }"
                ></mat-icon>
            </div>
        </div>
    </div>
</div>

import {
    Component, Inject,
    OnInit,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICompany } from '@app/services/company/company.service';
import {
    EEPROMPTO,
    IVehicleModel,
    VehiclePayload,
} from '../vehicles.interface';
import { VehiclesService } from '../vehicles.service';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { take } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppDialogConfig } from "@app/shared/app-dialog/app-dialog.config";
import { AppDialogService } from "@app/shared/app-dialog/app-dialog.service";

@Component({
    selector: 'app-create-vehicle',
    templateUrl: './create-vehicle.component.html',
    styleUrls: ['./create-vehicle.component.scss'],
})
export class CreateVehicleComponent implements OnInit {
    vehicleToEdit: IVehicleModel | null = null;
    vehicleForm: FormGroup;
    vehicleModels: any[] = [];
    companies: ICompany[] = [];
    eeproms: EEPROMPTO[] = [];

    constructor(
        public vehicleService: VehiclesService,
        private companyContext: CompanyContextService,
        public dialogRef: MatDialogRef<CreateVehicleComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        public appDialogService: AppDialogService,
    ) {}

    ngOnInit(): void {
        this.vehicleToEdit = this.dialogConfig.data.vehicleToEdit;
        this.vehicleForm = new FormGroup({
            company: new FormControl(null, {
                validators: Validators.required,
            }),
            eepromPTO: new FormControl(null, {
                validators: Validators.required,
            }),
            vehicleModel: new FormControl(null, {
                validators: Validators.required,
            }),
            name: new FormControl('', {
                nonNullable: true,
                validators: Validators.required,
            }),
            pilot: new FormControl(false),
        });

        this.companyContext.getCompanyContext().subscribe((ctx: string) => {
            this.companyContext.getAllCompanies(ctx).subscribe((res) => {
                this.companies = res.companies;
            });
        });

        setTimeout(() => {
            if(this.vehicleToEdit) {
                this.initEntry();
            }
        }, 0);
    }

    initEntry() {
        if (this.vehicleToEdit !== null) {
            this.vehicleService
                .getVehicleDetailsById(
                    `${this.vehicleToEdit.fullVehicleId}`
                )
                .subscribe((res) => {
                    if (res.eepromPTO !== null) {
                        if (this.vehicleToEdit !== null) {
                            this.vehicleToEdit.eepromPTO = res.eepromPTO;
                        }
                    }
                    this.companyContextSelected(res.companyContext);
                });

            this.vehicleForm
                .get('company')
                ?.setValue(this.vehicleToEdit.companyContext);

            this.vehicleForm.get('name')?.setValue(this.vehicleToEdit.name);

            this.vehicleForm
                .get('pilot')
                ?.setValue(this.vehicleToEdit.pilot === 'yes');
        } else if (this.vehicleToEdit === null && this.vehicleForm) {
            this.vehicleForm.reset();
            this.eeproms = [];
            this.vehicleModels = [];
        }
    }

    companyContextSelected(companyContext: string): void {
        this.vehicleForm.get('eepromPTO')?.reset();
        this.vehicleForm.get('vehicleModel')?.reset();

        this.vehicleService
            .getVehicleModels(companyContext)
            .pipe(take(1))
            .subscribe((res) => {
                this.vehicleModels = res.result;
                if (this.vehicleToEdit) {
                    this.vehicleForm
                        .get('vehicleModel')
                        ?.setValue(
                            this.vehicleToEdit.vehicleModelData.vehicleModelId
                        );
                }
            });

        this.vehicleService
            .getEEPROMPTOValues(companyContext)
            .pipe(take(1))
            .subscribe((res) => {
                this.eeproms = res;
                if (this.vehicleToEdit) {
                    this.vehicleForm
                        .get('eepromPTO')
                        ?.setValue(this.vehicleToEdit.eepromPTO);
                }
            });
    }

    cancel() {
        if (!this.vehicleToEdit) {
            this.vehicleForm.reset();
            this.eeproms = [];
            this.vehicleModels = [];
        } else {
            this.initEntry();
        }
        this.appDialogService.closeSliderDialog(this.dialogRef);
    }

    submit(value: any) {
        const vehicle = this.vehicleModels.find(
            (vehicleModel: any) =>
                vehicleModel.vehicleModelId === value.vehicleModel
        );

        const company: ICompany = this.companies.find(
            (company: ICompany) => company.companyContext === value.company
        ) as ICompany;

        const payload: VehiclePayload = {
            company,
            companyContext: company.companyContext,
            companyName: company.companyName,
            eeprom: {
                eepromPTO: value.eepromPTO,
            },
            eepromPTO: value.eepromPTO,
            hasTobsu: false,
            hasTobsuDisplay: '',
            isPilot: !!value.pilot,
            isPilotDisplay: '',
            name: value.name,
            validatorCount: 0,
            vehicleId: this.vehicleToEdit
                ? this.vehicleToEdit.fullVehicleId
                : '',
            vehicleModel: vehicle,
        };

        this.appDialogService.closeSliderDialog(this.dialogRef, payload);
    }
}

import {
    DateTimeOperators,
    NumberOperators,
    Operator,
    Operators,
    StringOperators,
    StringOperatorsV2,
    TableFieldTypes,
} from '@app/shared/operators';

export interface TableField {
    dataFieldGroupName: string;
    dataFieldName: string;
    displayName: string;
    dataType: TableFieldTypes;
}

export interface TableFieldV2 {
    dataFieldGroupName: string;
    dataFieldName: string;
    displayName: string;
    dataType: TableFieldTypes;
    possibleValues?: string[];
    groupOrderNumber: number;
}

export const StringOperatorsMap: Map<Operators, Operator> =
    StringOperators.reduce(
        (acc: Map<Operators, Operator>, item: Operator) =>
            acc.set(item.type, item),
        new Map<Operators, Operator>()
    );

export const StringOperatorsMapV2: Map<Operators, Operator> =
    StringOperatorsV2.reduce(
        (acc: Map<Operators, Operator>, item: Operator) =>
            acc.set(item.type, item),
        new Map<Operators, Operator>()
    );

export const NumberOperatorsMap: Map<Operators, Operator> =
    NumberOperators.reduce(
        (acc: Map<Operators, Operator>, item: Operator) =>
            acc.set(item.type, item),
        new Map<Operators, Operator>()
    );

export const DateTimeOperatorsMap: Map<Operators, Operator> =
    DateTimeOperators.reduce(
        (acc: Map<Operators, Operator>, item: Operator) =>
            acc.set(item.type, item),
        new Map<Operators, Operator>()
    );

export const AGGridToODataOperatorsMapper: { [key: string]: Operators } = {
    equals: Operators.EQ,
    notEqual: Operators.NE,
    contains: Operators.CONTAINS,
    notContains: Operators.NOT_CONTAINS,
    startsWith: Operators.STARTS_WITH,
    endsWith: Operators.ENDS_WITH,
    lessThan: Operators.LESS,
    lessThanOrEqual: Operators.LESS_OR_EQUAL,
    greaterThan: Operators.GREATER,
    greaterThanOrEqual: Operators.GREATER_OR_EQUAL,
    blank : Operators.BLANK,
    notBlank : Operators.NOT_BLANK
};

export const AGGridToODataOperatorsNameMapper: { [key: string]: string } = {
    equals: 'equals',
    notEqual: 'does not equal',
    contains: 'contains',
    notContains: 'does not contain',
    startsWith: 'starts with',
    endsWith: 'ends with',
    lessThan: 'is less than',
    lessThanOrEqual: 'is less than or equal to',
    greaterThan: 'is greater than',
    greaterThanOrEqual: 'is greater than or equal to',
    blank: 'blank',
    notBlank : 'not blank'
};

export const filterOptions = Object.keys(AGGridToODataOperatorsMapper);

import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ExportService,
    GridComponent,
    GridService,
    KeySettings,
    PERCENTAGE,
    PageSettingsCache,
    SettingsCacheService,
    TableField,
} from '@app/shared';
import {
    UsersColumnSettings,
    UsersData,
    UsersDataEnum,
} from './users.interface';
import { UsersService } from './users.service';
import { HeartbeatsService } from '../heartbeats/heartbeats.service';
import { ColDef, ColumnState, ColumnVO, GridReadyEvent } from '@ag-grid-community/core';
import { Observable, Subject, filter, switchMap, take, takeUntil } from 'rxjs';
import { usersSettings } from './users.settings';
import { TableFieldTypes } from '@app/shared/operators';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import {
    CreateAccountComponent,
    SaveActions,
} from './create-account/create-account.component';
import { DialogSizes } from '@app/shared/app-dialog/app-dialog.config';
import { UserRoleService } from '@app/services/account/user-role.service';
import { Account, RolePermissionMapping, UserPermission, UserRole } from '@app/services/account/user-role.interface';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import {
    AppNotificationService,
    NotificationType,
} from '@app/services/notification/notification.service';
import { NavigationStart, Router } from '@angular/router';
import { PreferencesService } from '@app/shared/preferences/preferences.service';
import { MatRadioButton } from '@angular/material/radio';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { EnvironmentService } from 'environments/environment.service';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['../../shared/grid/grid.component.scss'],
})
export class UsersComponent extends GridComponent implements OnInit, OnDestroy {
    private isGridDataLoaded = false;
    public rowData!: Array<UsersData>;
    roles: RolePermissionMapping[] = [];
    selectedAccount: Account | undefined;
    usersSettings = usersSettings;
    hasPermissionManageUsers = false;
    destroy$: Subject<void> = new Subject();
    currentRadioButtonRef: MatRadioButton;
    lastCreateUserData: Account | null = null;
    constructor(
        private heartbeatsService: HeartbeatsService,
        private usersService: UsersService,
        private exportService: ExportService,
        public override settingsCacheService: SettingsCacheService,
        public _companyContextService: CompanyContextService,
        public appDialogService: AppDialogService,
        public userRoleService: UserRoleService,
        private permissionService: AppPermissionService,
        private notificationService: AppNotificationService,
        private router: Router,
        private prefService: PreferencesService,
        private gridService: GridService,
        private env: EnvironmentService
    ) {
        super();
        LicenseManager.setLicenseKey(this.env.agGridLicenseKey);
    }

    public ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            if (user?.permissions.includes(AppPermissions.ManageUsers)) {
                this.hasPermissionManageUsers = true;
            }
        });
        this.prefService.enableSavingGridPreferences(KeySettings.UsersGrid);
        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter((event) => event instanceof NavigationStart)
            )
            .subscribe(() => {
                const colState = this._grid.columnApi?.getColumnState();
                this.settingsCacheService.saveDataToLocalStorage(
                    KeySettings.UsersGrid,
                    colState
                );
            });
        this.userRoleService
            .getRolesAndPermissions()
            .pipe(take(1))
            .subscribe({
                next: (res: UserRole[]) => {
                    res.forEach((role: UserRole) => {
                        role.permissions.forEach(
                            (permission: UserPermission) => {
                                if(permission.permissionName.includes('View') && !role.roleName.includes('Viewer')){
                                    return;
                                }
                                this.roles.push({
                                    permissionName: permission.permissionName,
                                    permissionId: permission.permissionId,
                                    roleId: role.roleId,
                                    roleName: role.roleName,
                                });
                            }
                        );
                        this.settingsCacheService.saveDataToLocalStorage(KeySettings.AllRoles, res);
                    });
                },
                complete: () => {
                    this.settingsCacheService.saveDataToLocalStorage(
                        KeySettings.RolePermissionMapping,
                        this.roles
                    );
                },
                error: (err) => {
                    console.log('Failed loading User roles!', err);
                    this.notificationService.showSnackBar(
                        'Failed loading User roles!',
                        NotificationType.Error,
                        2000
                    );
                }
            });

        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next: (tableFieldsMap: Map<string, TableField>) => {
                    const settings = this.settingsCacheService.users;

                    for (const columnSettings of settings.columnSettingsList) {
                        const rowGroupIndex =
                            settings.serverSideGetRowsRequest.rowGroupCols.findIndex(
                                (column: ColumnVO) =>
                                    column.id === columnSettings.key
                            );
                        const colSettings =
                            usersSettings[
                                UsersDataEnum[
                                    columnSettings.key as keyof typeof UsersDataEnum
                                ]
                            ];
                        const dataFieldName = colSettings.dataFieldName;

                        this.columnDefs.push({
                            iconType: colSettings.iconType,
                            field: columnSettings.key,
                            filter: this._createFilter(
                                tableFieldsMap,
                                columnSettings.key
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get(colSettings.dataFieldName)
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            rowGroup: rowGroupIndex > -1,
                            rowGroupIndex:
                                rowGroupIndex > -1 ? rowGroupIndex : null,
                            sort:
                                settings.serverSideGetRowsRequest.sortModel[0]
                                    ?.colId === columnSettings.key
                                    ? settings.serverSideGetRowsRequest
                                          .sortModel[0]?.sort
                                    : null,
                            hide: !columnSettings.visible,
                            headerName:
                                tableFieldsMap.get(dataFieldName)
                                    ?.displayName ?? colSettings.label,
                            cellRenderer: colSettings.cellRendererComponent,
                        } as ColDef);
                    }
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: 'Group rows by column:',
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer',
                        } as ColDef,
                        ...this.columnDefs,
                    ];
                },
            });
    }

    public get isSelectedAccount(): boolean {
        return this.selectedAccount !== undefined;
    }

    public ngOnDestroy(): void {
        this.companyContextSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public _createFilter(
        tableFieldsMap: Map<string, TableField>,
        field: string
    ): 'agTextColumnFilter' | 'agNumberColumnFilter' | 'agDateColumnFilter' {
        switch (
            tableFieldsMap.get(
                usersSettings[
                    UsersDataEnum[field as keyof typeof UsersDataEnum]
                ].dataFieldName
            )?.dataType ??
            TableFieldTypes.STRING
        ) {
            case TableFieldTypes.NUMBER:
                return 'agNumberColumnFilter';
            case TableFieldTypes.DATE_TIME:
                return 'agDateColumnFilter';
        }

        return 'agTextColumnFilter';
    }

    public refreshDataByContext(): void {
        this.isGridDataLoaded = false;
        this._grid.api.showLoadingOverlay();
        if (this.currentRadioButtonRef) {
            this.currentRadioButtonRef.checked = false;
            this.selectedAccount = undefined;
        }
        this.usersService
            .users()
            .pipe(take(1))
            .subscribe((data: any) => {
                this.rowData = data.rowData;
                this.refreshData(false);
                this._grid.api.hideOverlay();
                this.isGridDataLoaded = true;
            });
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;

        this.companyContextSubscription = this._companyContextService
            .getCompanyContext()
            .subscribe(() => this.refreshDataByContext());

        this._grid.api.addEventListener('columnVisible', (event: any) => {
            this.settingsCacheService.users = {
                ...this.settingsCacheService.users,
                columnSettingsList:
                    this.settingsCacheService.users.columnSettingsList.map(
                        (column: UsersColumnSettings): UsersColumnSettings => {
                            if (
                                event.column === null ||
                                column.key === event.column.colId
                            ) {
                                return {
                                    ...column,
                                    visible: event.visible,
                                };
                            }

                            return column;
                        }
                    ),
            } as PageSettingsCache<UsersColumnSettings>;
        });
        // subscribe to user action and refresh grid if role is deleted
        this.userRoleService.getUserAction().subscribe((action: string) => {
            if(action === "DELETE_ROLE") {
                    this.refreshDataByContext();
            }
        });

        const filterModel =
            this.settingsCacheService.positionHistory.serverSideGetRowsRequest
                .filterModel;

        if (filterModel) {
            for (const [key, value] of Object.entries(filterModel)) {
                this._grid.api.getFilterInstance(key)?.setModel(value);
            }

            this.refreshData();
        }

        this._initRowsPerPage();
        this._initColumnRowGroup(true);
        this._initDomLayout();

        const keySetting = KeySettings.UsersGrid;
        const gridPreferencesLocal =
            this.settingsCacheService.getDataFromLocalStorage(keySetting);
        const gridPreferencesDB =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.Preferences
            )['preferences'][keySetting];

        const gridPreferencesDefault =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.DefaultPreferences
            )['preferences'][keySetting];

        if (gridPreferencesLocal) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesLocal,
                applyOrder: true,
            });
        } else if (gridPreferencesDB) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesDB,
                applyOrder: true,
            });
        } else if (gridPreferencesDefault) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesDefault,
                applyOrder: true,
            });
        }

        const pageSize = this.settingsCacheService.getGridPageSizes?.find((pageSize) => pageSize.gridName === KeySettings.UsersGrid);
        if(pageSize){
            this.paginationPageSize = pageSize.pageSize;
            this.cacheBlockSize = pageSize.pageSize;
            this._grid.api.paginationSetPageSize(pageSize.pageSize);
        }
    }

    public getData(): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this.usersService.users().pipe(take(1));
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        const chunkSize = 1000000;
        const endRow = chunkSize;
        const rowData: Array<UsersData> = [];

        this.getData().subscribe(
            (data: { rowCount: number; rowData: Array<UsersData> }) =>
                this.updateProgressBar(
                    endRow + chunkSize,
                    rowData,
                    chunkSize,
                    data
                )
        );
    }

    public updateProgressBar(
        endRow: number,
        rowData: Array<UsersData>,
        chunkSize: number,
        data: { rowCount: number; rowData: Array<any> }
    ): void {
        rowData = [...rowData, ...data.rowData];

        this.progressValueExport = Math.round(
            PERCENTAGE(rowData.length, data.rowCount)
        );

        if (rowData.length < data.rowCount) {
            this.getData().subscribe(
                (data: { rowCount: number; rowData: Array<UsersData> }) =>
                    this.updateProgressBar(
                        endRow + chunkSize,
                        rowData,
                        chunkSize,
                        data
                    )
            );
        } else {
            if(this.isGridDataLoaded) {
                const gridRowsData: UsersData[] = this._getGridRowsData();
                rowData = gridRowsData
                    .map((item) => {
                        return  rowData.find((row) => {
                            return Object.keys(UsersDataEnum)
                                .every(key =>
                                    item[key as keyof UsersData] === row[key as keyof UsersData]);
                        });
                    })
                    .filter(row => !!row) as UsersData[];
            }
            const columnsOrder = this._getColumnsOrderForExport(usersSettings, UsersDataEnum);
            const result = this._prepareDataForExport(rowData, usersSettings, UsersDataEnum);
            this.exportService.exportToExcel(
                result,
                chunkSize,
                'exported_data.xlsx',
                'Chunk_',
                columnsOrder
            );

            this.progressValueExport = 0;
            this.isAvailableToExport = true;
        }
    }

    public editUser(): void {
        this.appDialogService
            .openDialog({
                title: 'Edit Account',
                showDialogButtons: false,
                component: CreateAccountComponent,
                dialogSize: DialogSizes.Small,
                panelClass: ['side-slider'],
                data: {
                    userToEdit: this.selectedAccount,
                    allRoles: this.roles,
                },
            })
            .subscribe((data: any) => {
                if (data?.action === SaveActions.EDIT_ACCOUNT) {
                    this._companyContextService
                        .getCompanyContext()
                        .pipe(
                            take(1),
                            switchMap((ctx: string) => {
                                return this.userRoleService.updateAccount(
                                    ctx,
                                    this.generateAccountPayload(data)
                                );
                            })
                        )
                        .subscribe(() => this.refreshDataByContext());
                }
                if (data?.action === "DELETE_ACCOUNT") {
                    this.deleteUser();
                }
            });
    }

    public createNewUser(): void {
        this.appDialogService
            .openDialog({
                title: 'Create Account',
                showDialogButtons: false,
                component: CreateAccountComponent,
                dialogSize: DialogSizes.Small,
                panelClass: ['side-slider'],
                data: {
                    allEmails: () => (this.rowData ?? []).map(d => d.email),
                    lastCreateUserData: this.lastCreateUserData,
                    allRoles: this.roles
                }
            })
            .subscribe((data) => {
                switch (data?.action) {
                    case SaveActions.CREATE_ROLE:
                        this.lastCreateUserData = null;
                        this._companyContextService
                            .getCompanyContext()
                            .pipe(
                                take(1),
                                switchMap((ctx: string) =>
                                    this.userRoleService.createRole(ctx, {
                                        roleName: data.payload.roleName,
                                        permissions:
                                            data.payload.newRoleState.map(
                                                (permissionName: string) => {
                                                    const rolePermissionMap: RolePermissionMapping =
                                                        this.roles.find(
                                                            (
                                                                role: RolePermissionMapping
                                                            ) =>
                                                                role.permissionName ===
                                                                permissionName
                                                        ) as RolePermissionMapping;

                                                    return {
                                                        permissionId:
                                                            rolePermissionMap.permissionId,
                                                        permissionName,
                                                    };
                                                }
                                            ),
                                    })
                                )
                            )
                            .subscribe();

                        break;
                    case SaveActions.CREATE_ACCOUNT:
                        this.lastCreateUserData = this.generateAccountPayload(data);
                        this._companyContextService
                            .getCompanyContext()
                            .pipe(
                                take(1),
                                switchMap((ctx: string) => {
                                    return this.userRoleService.createAccount(
                                        ctx,
                                        this.generateAccountPayload(data)
                                    );
                                })
                            )
                            .subscribe({
                                next: () => {
                                    this.lastCreateUserData = null;
                                    this.refreshDataByContext();
                                },
                                error: () => {
                                    this.notificationService.showSnackBar(
                                        'Email belongs to an existing user!',
                                        NotificationType.Error,
                                        2000
                                    );
                                },
                            });
                        break;
                    default:
                        this.lastCreateUserData = null;
                }
            });
    }

    generateAccountPayload(data: any): Account {
        const accountPayload: Account = {
            companyContext: data.payload.ctx.companyContext,
            companyName: data.payload.ctx.companyName,
            firstName: data.payload.firstName,
            lastName: data.payload.lastName,
            email: data.payload.email,
        };

        if (data.payload.id) {
            accountPayload['id'] = data.payload.id;
        }
        if (data.payload.customRole) {
            accountPayload['customRole'] = data.payload.customRole;
        }

        if (data.payload.applicationRoles) {
            accountPayload['applicationRoles'] =
                data.payload.applicationRoles.map((permissionName: string) => {
                    return {
                        roleId: this.roles.find(
                            (role: RolePermissionMapping) =>
                                role.permissionName === permissionName
                        )?.roleId,
                        roleName: permissionName,
                    };
                });
        }
        return accountPayload;
    }

    public selected(data: Account, ref: MatRadioButton) {
        this.selectedAccount = data;
        this.currentRadioButtonRef = ref;
    }

    public getDefaultPreferencesForReset(): ColumnState[] | null {
        const keySetting = KeySettings.UsersGrid;
        const gridPreferencesDefault = this.settingsCacheService.getDataFromLocalStorage(
            KeySettings.DefaultPreferences
            )['preferences'][keySetting];
        if (gridPreferencesDefault) {
            return gridPreferencesDefault as ColumnState[];
        }
        return null
    }

    public deleteUser(){
        if (this.selectedAccount) {
            this.usersService
            .deleteUser(this.selectedAccount)
            .subscribe({
                next: () => {
                    this.refreshDataByContext();
                    this.notificationService.showSnackBar(
                      'User Account Deleted!',
                      NotificationType.Info,
                      2000
                  );
                },
                error: (err: any) => {
                    console.log('Deleting user failed!', err)
                    this.selectedAccount = undefined;
                    this.currentRadioButtonRef.checked = false;
                    this.notificationService.showSnackBar(
                      'Deleting user failed!',
                      NotificationType.Error,
                      2000
                  );
                },
            });
        }
    }

    public override onPageSizeChanged(value: number): void {
        this.paginationPageSize = value;
        this.cacheBlockSize = value;
        const currentPageSizes = this.settingsCacheService.getGridPageSizes;
        if(currentPageSizes){
           //check if the gridName already exists in the array and if so update the pageSize
           const index = currentPageSizes.findIndex(x => x.gridName === KeySettings.UsersGrid);
           if(index !== -1){
               currentPageSizes[index].pageSize = value;
           } else{
               currentPageSizes.push({gridName: KeySettings.UsersGrid, pageSize: value});
           }
           this.settingsCacheService.getGridPageSizes = currentPageSizes;
        } else{
            this.settingsCacheService.getGridPageSizes = [{gridName: KeySettings.UsersGrid, pageSize: value}];
        }   
        this._grid.api.paginationSetPageSize(value);
    }
}

export enum Operators {
    IN = 'IN',
    EQ = 'EQ',
    NE = 'NE',
    STARTS_WITH = 'STARTS_WITH',
    ENDS_WITH = 'ENDS_WITH',
    CONTAINS = 'CONTAINS',
    NOT_CONTAINS = 'NOT_CONTAINS',
    IS_NULL = 'IS_NULL',
    NOT_NULL = 'NOT_NULL',
    IS_EMPTY = 'IS_EMPTY',
    NOT_EMPTY = 'NOT_EMPTY',
    BOOL = 'BOOL',
    GREATER_OR_EQUAL = 'GREATER_OR_EQUAL',
    GREATER = 'GREATER',
    LESS_OR_EQUAL = 'LESS_OR_EQUAL',
    LESS = 'LESS',
    BLANK = 'BLANK',
    NOT_BLANK = 'NOT_BLANK'
}

export const NumberOperators: Operator[] = [
    {
        type: Operators.EQ,
        operatorTemplate: 'field eq value',
        displayName: 'equals',
    },
    {
        type: Operators.NE,
        operatorTemplate: 'field ne value',
        displayName: 'does not equal',
    },
    {
        type: Operators.GREATER_OR_EQUAL,
        operatorTemplate: 'field ge value',
        displayName: 'is greater than or equal to',
    },
    {
        type: Operators.GREATER,
        operatorTemplate: 'field gt value',
        displayName: 'is greater than',
    },
    {
        type: Operators.LESS_OR_EQUAL,
        operatorTemplate: 'field le value',
        displayName: 'is less than or equal to',
    },
    {
        type: Operators.LESS,
        operatorTemplate: 'field lt value',
        displayName: 'is less than',
    },
    {
        type: Operators.BLANK,
        operatorTemplate: 'field eq null',
        displayName: 'blank'
    },
    {
        type: Operators.NOT_BLANK,
        operatorTemplate: 'field ne null',
        displayName: 'not blank'
    },
];

export const StringOperators: Operator[] = [
    {
        type: Operators.EQ,
        operatorTemplate: 'field eq value',
        displayName: 'equals',
    },
    {
        type: Operators.NE,
        operatorTemplate: 'field ne value',
        displayName: 'does not equal',
    },
    {
        type: Operators.STARTS_WITH,
        operatorTemplate: 'startswith(field, value)',
        displayName: 'starts with',
    },
    {
        type: Operators.CONTAINS,
        operatorTemplate: 'contains(tolower(field),tolower(value))',
        displayName: 'contains',
    },
    {
        type: Operators.NOT_CONTAINS,
        operatorTemplate: 'not(contains(tolower(field),tolower(value)))',
        displayName: 'does not contain',
    },
    {
        type: Operators.ENDS_WITH,
        operatorTemplate: 'endswith(field, value)',
        displayName: 'ends with',
    },
    {
        type: Operators.BLANK,
        operatorTemplate: 'field eq null',
        displayName: 'blank'
    },
    {
        type: Operators.NOT_BLANK,
        operatorTemplate: 'field ne null',
        displayName: 'not blank'
    },
];

export const StringOperatorsV2: Operator[] = [
    {
        type: Operators.EQ,
        operatorTemplate: 'field eq value',
        displayName: 'equals',
    },
    {
        type: Operators.NE,
        operatorTemplate: 'field ne value',
        displayName: 'does not equal',
    },
    {
        type: Operators.STARTS_WITH,
        operatorTemplate: 'startswith(field, value)',
        displayName: 'starts with',
    },
    {
        type: Operators.CONTAINS,
        operatorTemplate: 'contains(field,value)',
        displayName: 'contains',
    },
    {
        type: Operators.NOT_CONTAINS,
        operatorTemplate: 'not(contains(field,value))',
        displayName: 'does not contain',
    },
    {
        type: Operators.ENDS_WITH,
        operatorTemplate: 'endswith(field, value)',
        displayName: 'ends with',
    },
    {
        type: Operators.BLANK,
        operatorTemplate: 'field eq null',
        displayName: 'blank'
    },
    {
        type: Operators.NOT_BLANK,
        operatorTemplate: 'field ne null',
        displayName: 'not blank'
    },
];

export const BoolOperators: Operator[] = [
    {
        type: Operators.EQ,
        operatorTemplate: 'field eq value',
        displayName: 'equals',
    },
    {
        type: Operators.NE,
        operatorTemplate: 'field ne value',
        displayName: 'does not equal',
    },
    {
        type: Operators.BLANK,
        operatorTemplate: 'field eq null',
        displayName: 'blank'
    },
    {
        type: Operators.NOT_BLANK,
        operatorTemplate: 'field ne null',
        displayName: 'not blank'
    },
];

export const DateTimeOperators: Operator[] = [
    {
        type: Operators.EQ,
        operatorTemplate: 'field eq value',
        displayName: 'equals',
    },
    {
        type: Operators.NE,
        operatorTemplate: 'field ne value',
        displayName: 'does not equal',
    },
    {
        type: Operators.GREATER_OR_EQUAL,
        operatorTemplate: 'field ge value',
        displayName: 'is greater than or equal to',
    },
    {
        type: Operators.GREATER,
        operatorTemplate: 'field gt value',
        displayName: 'is greater than',
    },
    {
        type: Operators.LESS_OR_EQUAL,
        operatorTemplate: 'field le value',
        displayName: 'is less than or equal to',
    },
    {
        type: Operators.LESS,
        operatorTemplate: 'field lt value',
        displayName: 'is less than',
    },
    {
        type: Operators.BLANK,
        operatorTemplate: 'field eq null',
        displayName: 'blank'
    },
    {
        type: Operators.NOT_BLANK,
        operatorTemplate: 'field ne null',
        displayName: 'not blank'
    },
];

export const AllOperators: Operator[] = [
    {
        type: Operators.EQ,
        operatorTemplate: 'field eq value',
        displayName: 'equals',
    },
    {
        type: Operators.NE,
        operatorTemplate: 'field ne value',
        displayName: 'does not equal',
    },
    {
        type: Operators.CONTAINS,
        operatorTemplate: 'contains(tolower(field),tolower(value))',
        displayName: 'contains',
    },
    {
        type: Operators.NOT_CONTAINS,
        operatorTemplate: 'not(contains(tolower(field),tolower(value)))',
        displayName: 'does not contain',
    },
    {
        type: Operators.STARTS_WITH,
        operatorTemplate: 'startswith(field, value)',
        displayName: 'starts with',
    },
    {
        type: Operators.ENDS_WITH,
        operatorTemplate: 'endswith(field, value)',
        displayName: 'ends with',
    },
    {
        type: Operators.LESS,
        operatorTemplate: 'field lt value',
        displayName: 'is less than',
    },
    {
        type: Operators.LESS_OR_EQUAL,
        operatorTemplate: 'field le value',
        displayName: 'is less than or equal to',
    },
    {
        type: Operators.GREATER,
        operatorTemplate: 'field gt value',
        displayName: 'is greater than',
    },
    {
        type: Operators.GREATER_OR_EQUAL,
        operatorTemplate: 'field ge value',
        displayName: 'is greater than or equal to',
    },
    {
        type: Operators.BLANK,
        operatorTemplate: 'field eq null',
        displayName: 'blank'
    },
    {
        type: Operators.NOT_BLANK,
        operatorTemplate: 'field ne null',
        displayName: 'not blank'
    },
];

export const FilterOptionsNumber: string[] = [
    "equals",
    "notEqual",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "blank",
    "notBlank"
]

export const FilterOptionsDate: string[] = [
    "equals",
    "notEqual",
    "lessThan",
    "lessThanOrEqual",
    "greaterThan",
    "greaterThanOrEqual",
    "blank",
    "notBlank"
]

export const FilterOptionsString: string[] = [
    "equals",
    "notEqual",
    "contains",
    "notContains",
    "startsWith",
    "endsWith",
    "blank",
    "notBlank"
]

export interface Operator {
    type: Operators;
    operatorTemplate: string | null;
    displayName: string | null;
}

export enum TableFieldTypes {
    ALL = 'ALL',
    NUMBER = 'Number',
    BOOL = 'Bool',
    STRING = 'String',
    DATE_TIME = 'DateTime',
}

import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";


@Component({
    selector: 'app-custom-download-url-cell-renderer',
    templateUrl: './custom-download-url-cell-renderer.component.html',
    styleUrls: ['./custom-download-url-cell-renderer.component.scss'],
})
export class CustomDownloadUrlCellRendererComponent implements ICellRendererAngularComp {
    displayValue: string;
    downloadUrl: string;
    constructor(private http: HttpClient){}

    agInit(params: ICellRendererParams): void {
        this.downloadUrl = params.value;
        this.displayValue = params.value ? 'Yes' : 'None';
    }
    refresh(): boolean {
        return false;
    }

    openDownloadUrl(){
        window.open(this.downloadUrl);
    }

}
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    CellClickedEvent,
    ColDef,
    ColGroupDef,
    ColumnState,
    ColumnVO,
    GetRowIdFunc,
    GetRowIdParams,
    GridReadyEvent,
    IServerSideDatasource,
    IServerSideGetRowsParams,
} from '@ag-grid-community/core';
import { HeartbeatsService } from './heartbeats.service';
import {
    EventsColumnSettings,
    EventsData,
    EventsDataEnum,
} from './heartbeats.interface';
import { eventsSettings } from './heartbeats.settings';
import {
    ExportService,
    GridComponent,
    GridService,
    KeySettings,
    PERCENTAGE,
    PageSettingsCache,
    SettingsCacheService,
    TableField,
} from '@app/shared';
import { Observable, switchMap, filter, take, Subject, takeUntil } from 'rxjs';
import { TableFieldTypes } from '@app/shared/operators';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { PreferencesService } from '@app/shared/preferences/preferences.service';
import {
    AppNotificationService,
    NotificationType,
} from '@app/services/notification/notification.service';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { Modality } from '@app/models/modality.enum';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { DialogSizes } from '@app/shared/app-dialog/app-dialog.config';
import { VehicleDetailsComponent } from '../vehicles/vehicle-details/vehicle-details.component';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { EnvironmentService } from 'environments/environment.service';


@Component({
    selector: 'app-heartbeats',
    templateUrl: './heartbeats.component.html',
    styleUrls: ['../../shared/grid/grid.component.scss'],
})
export class HeartbeatsComponent
    extends GridComponent
    implements OnInit, OnDestroy
{
    public rowData!: Array<EventsData>;
    hasPermissionViewHeartbeats = false;
    hasPermissionManageDefaultPreference = false;
    public getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
        return `${params.data.vehicle}`;
    };
    eventsSettings = eventsSettings;
    destroy$: Subject<void> = new Subject();

    constructor(
        private heartbeatsService: HeartbeatsService,
        private exportService: ExportService,
        public override settingsCacheService: SettingsCacheService,
        private router: Router,
        private prefService: PreferencesService,
        public _companyContextService: CompanyContextService,
        private permissionService: AppPermissionService,
        private notificationService: AppNotificationService,
        private route: ActivatedRoute,
        private gridService: GridService,
        private appDialogService: AppDialogService,
        private env: EnvironmentService
    ) {
        super();
        LicenseManager.setLicenseKey(this.env.agGridLicenseKey);
    }

    public ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            this.setModalityAndPermissions(user?.permissions);
            if (this.hasPermissionViewHeartbeats) {
                this.prefService.enableSavingGridPreferences(
                    KeySettings.HeartbeatsGrid
                );
                this.router.events
                    .pipe(
                        takeUntil(this.destroy$),
                        filter((event) => event instanceof NavigationStart)
                    )
                    .subscribe(() => {
                        const colState = this._grid.columnApi?.getColumnState();
                        this.settingsCacheService.saveDataToLocalStorage(
                            KeySettings.HeartbeatsGrid,
                            colState
                        );
                    });
                this.continueProcessing();
            } else {
                this.notificationService.showSnackBar(
                    'You lack permission to view Heartbeats!',
                    NotificationType.Error,
                    3000
                );
            }
        });
    }

    public ngOnDestroy(): void {
        this.companyContextSubscription?.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    private continueProcessing(): void {
        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next: (tableFieldsMap: Map<string, TableField>) => {
                    const heartbeats = this.settingsCacheService.heartbeats;

                    for (const columnSettings of heartbeats.columnSettingsList) {
                        const rowGroupIndex =
                            heartbeats.serverSideGetRowsRequest.rowGroupCols.findIndex(
                                (column: ColumnVO) =>
                                    column.id === columnSettings.key
                            );
                        const colSettings =
                            eventsSettings[
                                EventsDataEnum[
                                    columnSettings.key as keyof typeof EventsDataEnum
                                ]
                            ];
                        const dataFieldName = colSettings.dataFieldName;
                        const tableField = tableFieldsMap.get(dataFieldName);

                        const newColumn = {
                            field: columnSettings.key,
                            filter: this._createFilter(
                                tableFieldsMap,
                                columnSettings.key
                            ),
                            filterParams: {
                                filterOptions:
                                    this.gridService.createFilterOptions(
                                        tableFieldsMap.get(
                                            colSettings.dataFieldName
                                        )?.dataType ?? TableFieldTypes.STRING
                                    ),
                            },
                            sortable: true,
                            enableRowGroup: true,
                            rowGroup: rowGroupIndex > -1,
                            rowGroupIndex:
                                rowGroupIndex > -1 ? rowGroupIndex : null,
                            sort:
                                heartbeats.serverSideGetRowsRequest.sortModel[0]
                                    ?.colId === columnSettings.key
                                    ? heartbeats.serverSideGetRowsRequest
                                          .sortModel[0]?.sort
                                    : null,
                            hide: !columnSettings.visible,
                            headerName:
                                tableFieldsMap.get(dataFieldName)
                                    ?.displayName ?? colSettings.label,
                            cellRenderer: colSettings.cellRendererComponent,
                        } as ColDef;

                        if (colSettings.dataFieldName === 'header.vehicleID') {
                            newColumn.onCellClicked = (
                                event: CellClickedEvent
                            ) => {
                                this.showVehicleDetails(event.data);
                            };
                        }

                        if (tableField?.dataFieldGroupName) {
                            const columnGroupIndex = this.columnDefs.findIndex(
                                (colDef: ColDef | ColGroupDef) =>
                                    colDef.headerName ===
                                    tableField?.dataFieldGroupName
                            );

                            if (columnGroupIndex === -1) {
                                this.columnDefs.push({
                                    headerName: tableField?.dataFieldGroupName,
                                    marryChildren: true,
                                    children: [newColumn],
                                });
                            } else {
                                this.columnDefs[columnGroupIndex] = {
                                    ...this.columnDefs[columnGroupIndex],
                                    children: [
                                        ...(
                                            this.columnDefs[
                                                columnGroupIndex
                                            ] as ColGroupDef
                                        ).children,
                                        newColumn,
                                    ],
                                };
                            }
                        } else {
                            this.columnDefs.push(newColumn);
                        }
                    }
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: 'Group rows by column:',
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer',
                        } as ColDef,
                        ...this.columnDefs,
                    ];
                },
            });
    }
    showVehicleDetails(data: any) {
        const vehicleId = `${data.PTO}:${data.vehicle}`;
        this.appDialogService
            .openDialog({
                title: 'Vehicle Details',
                showDialogButtons: false,
                component: VehicleDetailsComponent,
                dialogSize: DialogSizes.Small,
                panelClass: ['side-slider'],
                data: {
                    vehicleToView: { fullVehicleId: vehicleId },
                },
            })
            .subscribe();
    }

    setModalityAndPermissions(userPermissions: string[]): void {
        if (
            userPermissions.includes(
                AppPermissions.FeatureHeartbeatsPTOViewsAll
            )
        ) {
            this.modality = Modality.All;
        } else {
            this.modality = Modality.None;
        }
        if (userPermissions.includes(AppPermissions.ViewHeartbeats)) {
            this.hasPermissionViewHeartbeats = true;
        } else {
            this.hasPermissionViewHeartbeats = false;
        }
        if (userPermissions.includes(AppPermissions.ManageConfiguration)) {
            this.hasPermissionManageDefaultPreference = true;
        }
    }

    public _createFilter(
        tableFieldsMap: Map<string, TableField>,
        field: string
    ): 'agTextColumnFilter' | 'agNumberColumnFilter' | 'agDateColumnFilter' {
        switch (
            tableFieldsMap.get(
                eventsSettings[
                    EventsDataEnum[field as keyof typeof EventsDataEnum]
                ].dataFieldName
            )?.dataType ??
            TableFieldTypes.STRING
        ) {
            case TableFieldTypes.NUMBER:
                return 'agNumberColumnFilter';
            case TableFieldTypes.DATE_TIME:
                return 'agDateColumnFilter';
        }

        return 'agTextColumnFilter';
    }

    public getRows(
        serverSideGetRowsParams: IServerSideGetRowsParams<any, any>
    ): void {
        this.serverSideGetRowsRequest = serverSideGetRowsParams.request;

        this.settingsCacheService.heartbeats = {
            ...this.settingsCacheService.heartbeats,
            serverSideGetRowsRequest: this.serverSideGetRowsRequest,
        } as PageSettingsCache<EventsColumnSettings>;
        this.heartbeatsService
            .companies(
                this.serverSideGetRowsRequest,
                this.paginationPageSize,
                this.modality
            )
            .pipe(take(1))
            .subscribe({
                next: serverSideGetRowsParams.success,
            });
    }

    public columnsChanged(event: any): void {
        this.settingsCacheService.heartbeats = {
            ...this.settingsCacheService.heartbeats,
            columnSettingsList:
                this.settingsCacheService.heartbeats.columnSettingsList.map(
                    (column: EventsColumnSettings): EventsColumnSettings => {
                        if (
                            event.column === null ||
                            column.key === event.column.colId
                        ) {
                            return {
                                ...column,
                                visible: event.visible,
                            };
                        }

                        return column;
                    }
                ),
        } as PageSettingsCache<EventsColumnSettings>;
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;
        this.companyContextSubscription = this._companyContextService
            .getCompanyContext()
            .subscribe(() => {
                this._grid.api.setServerSideDatasource({
                    getRows: this.getRows.bind(this),
                } as IServerSideDatasource);
                this.refreshData(false);
            });

        this._grid.api.addEventListener(
            'displayColumnsChanged',
            this.columnsChanged.bind(this)
        );

        const filterModel =
            this.settingsCacheService.heartbeats.serverSideGetRowsRequest
                .filterModel;

        if (filterModel) {
            for (const [key, value] of Object.entries(filterModel)) {
                this._grid.api.getFilterInstance(key)?.setModel(value);
            }

            this.refreshData();
        }

        const vehicleId = this.route.snapshot.queryParamMap.get('vehicleId');

        if (vehicleId) {
            this._grid.columnApi
                ?.getColumnState()
                .forEach((item) => this._grid.api.destroyFilter(item.colId));

            this._grid.api.getFilterInstance('vehicle')?.setModel({
                type: 'eq',
                filter: vehicleId,
            });

            this.router.navigate(['/heartbeats'], {
                queryParams: { vehicleId: null },
            });

            this.refreshData();
        }

        this._initRowsPerPage();
        this._initColumnRowGroup();
        this._initDomLayout();

        const keySetting = KeySettings.HeartbeatsGrid;
        const gridPreferencesLocal =
            this.settingsCacheService.getDataFromLocalStorage(keySetting);
        const gridPreferencesDB =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.Preferences
            )['preferences'][keySetting];

        const gridPreferencesDefault =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.DefaultPreferences
            )['preferences'][keySetting];

        if (gridPreferencesLocal) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesLocal,
                applyOrder: true,
            });
        } else if (gridPreferencesDB) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesDB,
                applyOrder: true,
            });
        } else if (gridPreferencesDefault) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesDefault,
                applyOrder: true,
            });
        }
        
        const pageSize = this.settingsCacheService.getGridPageSizes?.find(x => x.gridName === KeySettings.HeartbeatsGrid)
        if(pageSize){
            this.paginationPageSize = pageSize.pageSize;
            this.cacheBlockSize = pageSize.pageSize;
            this._grid.api.paginationSetPageSize(pageSize.pageSize);
        }
    }

    public getData(
        endRow: number,
        chunkSize: number
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this._companyContextService.getCompanyContext().pipe(
            switchMap((currentCompany: string) =>
                this.heartbeatsService
                    .query(
                        {
                            ...this.serverSideGetRowsRequest,
                            rowGroupCols: [],
                            groupKeys: [],
                            endRow: endRow,
                        },
                        chunkSize,
                        currentCompany,
                        this.modality
                    )
                    .pipe(take(1))
            )
        );
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        const chunkSize = 1000000;
        const endRow = chunkSize;
        const rowData: Array<EventsData> = [];

        this.getData(endRow, chunkSize).subscribe(
            (data: { rowCount: number; rowData: Array<EventsData> }) =>
                this.updateProgressBar(
                    endRow + chunkSize,
                    rowData,
                    chunkSize,
                    data
                )
        );
    }

    public updateProgressBar(
        endRow: number,
        rowData: Array<EventsData>,
        chunkSize: number,
        data: { rowCount: number; rowData: Array<any> }
    ): void {
        rowData = [...rowData, ...data.rowData];

        this.progressValueExport = Math.round(
            PERCENTAGE(rowData.length, data.rowCount)
        );

        if (rowData.length < data.rowCount) {
            this.getData(endRow, chunkSize).subscribe(
                (data: { rowCount: number; rowData: Array<EventsData> }) =>
                    this.updateProgressBar(
                        endRow + chunkSize,
                        rowData,
                        chunkSize,
                        data
                    )
            );
        } else {
            const columnsOrder = this._getColumnsOrderForExport(
                eventsSettings,
                EventsDataEnum
            );
            const result = this._prepareDataForExport(
                rowData,
                eventsSettings,
                EventsDataEnum
            );
            this.exportService.exportToExcel(
                result,
                chunkSize,
                'exported_data.xlsx',
                'Chunk_',
                columnsOrder
            );

            this.progressValueExport = 0;
            this.isAvailableToExport = true;
        }
    }

    public saveDefaultPreferences() {
        this.prefService.updateAllPreferences('defaultPreference');
    }

    public getDefaultPreferencesForReset(): ColumnState[] | null {
        const keySetting = KeySettings.HeartbeatsGrid;
        const gridPreferencesDefault =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.DefaultPreferences
            )['preferences'][keySetting];
        if (gridPreferencesDefault) {
            return gridPreferencesDefault as ColumnState[];
        }
        return null;
    }

    public override onPageSizeChanged(value: number): void {
        this.paginationPageSize = value;
        this.cacheBlockSize = value;
        const currentPageSizes = this.settingsCacheService.getGridPageSizes;
        if(currentPageSizes){
            //check if the gridName already exists in the array and if so update the pageSize
            const index = currentPageSizes.findIndex(x => x.gridName === KeySettings.HeartbeatsGrid);
            if(index !== -1){
                currentPageSizes[index].pageSize = value;
            } else{
                currentPageSizes.push({gridName: KeySettings.HeartbeatsGrid, pageSize: value});
            }
            this.settingsCacheService.getGridPageSizes = currentPageSizes;
        } else{
            this.settingsCacheService.getGridPageSizes = [{gridName: KeySettings.HeartbeatsGrid, pageSize: value}];
        }   
        this._grid.api.paginationSetPageSize(value);
    }
}

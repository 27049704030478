import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { timeRanges } from '@app/pages/commands/new-command/new-command.interfaces';

@Component({
    selector: 'app-date-control',
    templateUrl: './date-control.component.html',
    styleUrls: ['./date-control.component.scss'],
})
export class DateControlComponent implements OnInit {
    @ViewChild('timeSelect') timeSelectv: any;
    @Output() public dateSelectionChanged: EventEmitter<string> =
        new EventEmitter<string>();

    @Input() public timeSelectionEnabled = true;
    @Input() dateControl: FormControl;

    dateForm: FormGroup;
    timeRanges: string[] = timeRanges;

    ngOnInit(): void {
        this.dateForm = new FormGroup({});

        if (this.timeSelectionEnabled) {
            this.dateForm.addControl(
                'time',
                new FormControl(timeRanges[0], {
                    nonNullable: true,
                    validators: [Validators.required],
                })
            );
        }
    }

    timeSelectChange(timeRange: string): void {
        const now = new Date();
        const nowDateTime = now.toISOString();
        const nowDate = nowDateTime.split('T')[0];
        const stringToTimeDate = new Date(nowDate + 'T' + timeRange);

        const selectedDate = new Date(this.dateControl?.value);

        selectedDate.setHours(stringToTimeDate.getHours());
        selectedDate.setMinutes(stringToTimeDate.getMinutes());

        this.dateControl?.setValue(selectedDate.toISOString());
    }

    dateChange(e: MatDatepickerInputEvent<any, any>): void {
        if (this.timeSelectionEnabled) {
            this.timeSelectChange(this.dateForm.get('time')?.value);
        }
    }
}

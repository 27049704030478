import { Injectable } from '@angular/core';
import { AppRestService } from '@app/shared';
import { Observable } from 'rxjs';
import { CompanyContextService } from '../company-context/company-context.service';
import { Modality } from '@app/models/modality.enum';

export interface DevicesResponse {
    isAisAggregated: boolean;
    lastUpdated: string;
    lastUpdatedDateTime: string;
    result: Device[];
    totalCount: number;
}

export interface Device {
    pto: string;
    concession: string;
    vehicleId: string;
    deviceSerialNo: string;
    validatorPositionNo: string;
    vehicleModel: string;
    deviceId: string;
    deviceJobStatus: string;
    deviceJobStatusReason: string;
    companyContext: string;
    companyName: string;
    refreshedOn: string;
}

@Injectable({
    providedIn: 'root',
})
export class DevicesService {
    constructor(
        private appRest: AppRestService,
        private companyContext: CompanyContextService
    ) {}

    getDevices(companyContext: string, modality: Modality = Modality.None): Observable<DevicesResponse> {
        return this.appRest.get(`companies/${companyContext}/monitors/devices/${modality}`);
    }
}

import { IDateComp, IDateParams } from "@ag-grid-community/core";
import * as moment from "moment";

export class CustomDateInput implements IDateComp {
    params!: IDateParams;
    eGui!: HTMLDivElement;
    eInput!: HTMLInputElement;
    date: Date | null;
    init(params: IDateParams) {
        const template = `
            <input type="datetime-local" data-input style="width: 100%;" />
            `;

        this.params = params;

        this.eGui = document.createElement('div');
        this.eGui.setAttribute('role', 'presentation');
        this.eGui.innerHTML = template;

        this.eInput = this.eGui.querySelector('input')!;

        this.eInput.onchange = () => {
            this.date = new Date(this.eInput.value);
            this.params.onDateChanged();
        }
    }

    getGui() {
        return this.eGui;
    }

    getDate() {
        return this.date;
    }

    setDate(date: any) {
        if(date) {
            this.eInput.value = moment(date).format('yyyy-MM-DD HH:mm');
            this.date = new Date(this.eInput.value);
        } else {
            this.date = null;
        }
    }

    setInputPlaceholder(placeholder: any) {
        this.eInput.setAttribute('placeholder', placeholder);
    }
}

<div class="column-header">
    <ng-container *ngIf="!params?.column?.userProvidedColDef?.iconType">
        <div class="ag-cell-label-container">
            <span
                *ngIf="!isGroupRowByColumn"
                [matMenuTriggerFor]="menu"
                ref="eMenu"
                class="ag-header-icon ag-header-cell-menu-button"
                aria-hidden="true"
            >
                <span
                    class="ag-icon ag-icon-menu"
                    unselectable="on"
                    role="presentation"
                ></span>
            </span>

            <div class="ag-header-cell-label" (click)="setSortByClick()">
                <span class="ag-header-cell-text">
                    {{ params?.displayName }}
                </span>
                <span
                    *ngIf="
                        !isGroupRowByColumn &&
                        (isAvailableCondition1 || isAvailableCondition2)
                    "
                    class="ag-header-icon ag-header-label-icon ag-filter-icon"
                >
                    <span
                        class="ag-icon ag-icon-filter"
                        unselectable="on"
                        role="presentation"
                    ></span>
                </span>
                <span
                    *ngIf="
                        !isGroupRowByColumn &&
                        (isAvailableSorASC || isAvailableSorDESC)
                    "
                    class="ag-sort-indicator-container"
                >
                    <span
                        *ngIf="isAvailableSorASC"
                        ref="eSortAsc"
                        class="ag-sort-indicator-icon ag-sort-ascending-icon"
                    >
                        <span
                            class="ag-icon ag-icon-asc"
                            unselectable="on"
                            role="presentation"
                        ></span>
                    </span>
                    <span
                        *ngIf="isAvailableSorDESC"
                        ref="eSortDesc"
                        class="ag-sort-indicator-icon ag-sort-descending-icon"
                    >
                        <span
                            class="ag-icon ag-icon-desc"
                            unselectable="on"
                            role="presentation"
                        ></span>
                    </span>
                </span>
            </div>
        </div>

        <mat-menu #menu="matMenu">
            <button
                mat-menu-item
                [attr.aria-label]="filterLabel"
                [matMenuTriggerFor]="filter"
            >
                <mat-icon svgIcon="menu-filter"></mat-icon>
                <span>{{ filterLabel }}</span>
            </button>
            <mat-menu #filter="matMenu" class="column-filter-popup">
                <div class="column-filter" (click)="$event.stopPropagation()">
                    <div class="line">
                        <mat-form-field appearance="outline">
                            <mat-select
                                [(value)]="firstFilter"
                                (selectionChange)="firstFilterChanged($event)"
                            >
                                <mat-option
                                    *ngFor="let filter of filters"
                                    [value]="filter"
                                >
                                    {{
                                        aGGridToODataOperatorsNameMapper[filter]
                                    }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="!isDateFilter && !isNumberFilter && !isBlankNotBlankFilter1">
                            <input
                                matInput                                
                                [value]="firstValue"
                                (input)="firstValueChanged($event)"
                            />
                        </mat-form-field>
                        <app-date-control *ngIf="isDateFilter && !isBlankNotBlankFilter1"
                                          [dateControl]="firstValueDateControl"
                                          [timeSelectionEnabled]="true">
                        </app-date-control>
                        <mat-form-field appearance="outline" *ngIf="isNumberFilter && !isBlankNotBlankFilter1">
                            <input
                                matInput
                                type="number"
                                [value]="firstValue"
                                (input)="firstValueChanged($event)"
                            />
                        </mat-form-field>
                    </div>
                    <div class="slide">
                        <span>and</span
                        ><mat-slide-toggle
                            [checked]="operator === 'OR'"
                            (change)="changeOperators($event)"
                            >or</mat-slide-toggle
                        >
                    </div>
                    <div class="line">
                        <mat-form-field appearance="outline">
                            <mat-select
                                [(value)]="secondFilter"
                                (selectionChange)="secondFilterChanged($event)"
                            >
                                <mat-option
                                    *ngFor="let filter of filters"
                                    [value]="filter"
                                >
                                    {{
                                        aGGridToODataOperatorsNameMapper[filter]
                                    }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline" *ngIf="!isDateFilter && !isNumberFilter && !isBlankNotBlankFilter2">
                            <input
                                matInput
                                [value]="secondValue"
                                (input)="secondValueChanged($event)"
                            />
                        </mat-form-field>
                        <app-date-control *ngIf="isDateFilter && !isBlankNotBlankFilter2"
                                          [dateControl]="secondValueDateControl"
                                          [timeSelectionEnabled]="true">
                        </app-date-control>
                        <mat-form-field appearance="outline" *ngIf="isNumberFilter && !isBlankNotBlankFilter2">
                            <input
                                matInput
                                type="number"
                                [value]="secondValue"
                                (input)="secondValueChanged($event)"
                            />
                        </mat-form-field>
                    </div>
                </div>
            </mat-menu>

            <button
                mat-menu-item
                [attr.aria-label]="hideColumnLabel"
                (click)="hideColumn()"
            >
                <mat-icon svgIcon="menu-view"></mat-icon>
                <span>{{ hideColumnLabel }}</span>
            </button>
            <button
                mat-menu-item
                [attr.aria-label]="groupLabel"
                (click)="groupColumn()"
            >
                <mat-icon svgIcon="menu-group"></mat-icon>
                <span>{{ groupLabel }}</span>
            </button>
        </mat-menu>
    </ng-container>
    <mat-icon
        *ngIf="params?.column?.userProvidedColDef?.iconType"
        [svgIcon]="params?.column?.userProvidedColDef?.iconType"
    ></mat-icon>
</div>

<div *ngIf="isGroupRowByColumn" class="group-container">
    <div *ngFor="let group of rowGroupColumns" class="group-title">
        {{ group }}
    </div>
</div>

<app-default-table-tools-panel
    [isAvailableToExport]="isAvailableToExport"
    [progressValueExport]="progressValueExport"
    [isOpenFilters]="isOpenFilters"
    [isOpenColumns]="isOpenColumns"
    (exportToExcel)="exportToExcel.emit()"
    (resetAll)="resetAll.emit()"
    (clearSorting)="clearSorting.emit()"
    (clearFiltering)="clearFiltering.emit()"
    (clearRowGrouping)="clearRowGrouping.emit()"
    (resetColumnOrder)="resetColumnOrder.emit()"
    (resetColumnVisibility)="resetColumnVisibility.emit()"
    (openFilters)="openFilters.emit()"
    (openColumns)="openColumns.emit()"
>
    <ng-container right>
        <button
            mat-stroked-button
            color="primary"
            [disabled]="!hasPermissionManageUsers"
            (click)="createNewUser.emit()"
            [attr.aria-label]="newLabel"
        >
            <span>{{ newLabel }}</span>
        </button>
        <button
            mat-stroked-button
            color="primary"
            (click)="editUser.emit()"
            [attr.aria-label]="editLabel"
            [disabled]="!hasPermissionManageUsers || !isSelectedAccount"
        >
            <span>{{ editLabel }}</span>
        </button>
    </ng-container>
</app-default-table-tools-panel>

import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppDialogConfig } from './app-dialog.config';

@Component({
    selector: 'app-dialog',
    templateUrl: './app-dialog.component.html',
    styleUrls: ['./app-dialog.component.scss'],
})
export class AppDialogComponent implements OnInit {
    constructor(
        public dialogRef: MatDialogRef<AppDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig
    ) {}

    ngOnInit(): void {
        if (this.dialogConfig.panelClass?.includes('side-slider')) {
            document
                .getElementsByClassName('cdk-overlay-container')[0]
                .classList.add('side-slider-overlay-container');
        }
        else if(this.dialogConfig.panelClass?.includes('modal-popup')) {
            document
                .getElementsByClassName('cdk-overlay-container')[0]
                .classList.remove('side-slider-overlay-container');
        }
    }
}

<div class="create-account" [formGroup]="createUserForm">
    <app-switch-toggle
        *ngIf="!isEdit"
        [uncheckedLabel]="'New User'"
        [checkedLabel]="'New Role'"
        (toggleChange)="toggleForm($event)"
    ></app-switch-toggle>
    <ng-template [ngIf]="!isNewRole">
        <div class="create-account__email-disabled" *ngIf="isEdit">
                <mat-form-field appearance="outline">
                    <mat-label>Email/ID</mat-label>
                    <input matInput type="text" [disabled]="true" value="{{userToEdit.email}}"/>
                </mat-form-field>                
        </div>
        <div class="create-account__section">
            <mat-form-field appearance="outline">
                <mat-label>First Name</mat-label>
                <input matInput type="text" formControlName="first_name" />
            </mat-form-field>
        </div>

        <div class="create-account__section">
            <mat-form-field appearance="outline">
                <mat-label>Last Name</mat-label>
                <input matInput type="text" formControlName="last_name" />
            </mat-form-field>
        </div>

        <div class="create-account__section" *ngIf="!isEdit">
            <mat-form-field appearance="outline">
                <mat-label>Email/ID</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="email"
                    [email]="true"
                />
            </mat-form-field>
            <div
                class="err"
                *ngIf="isEmailExists"
            >
                Email/ID already exists.
            </div>
        </div>

        <div class="create-account__section" *ngIf="!isEdit">
            <mat-form-field appearance="outline">
                <mat-label>Confirm Email/ID</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="confirm_email"
                    [email]="true"
                />
            </mat-form-field>
            <div
                class="err"
                *ngIf="
                    (confirmEmailControl.dirty || emailControl.dirty) &&
                    !isEmailsTheSame
                "
            >
                Confirm email should be the same as email.
            </div>
        </div>

        <div class="create-account__section">
            <mat-form-field appearance="outline">
                <mat-label>Company/Concession/Modality</mat-label>
                <mat-select formControlName="concession">
                    <mat-option
                        *ngFor="let company of companies"
                        [value]="company"
                        >{{ company.name }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </ng-template>

    <app-switch-toggle
        *ngIf="!isNewRole"
        [uncheckedLabel]="'Assign Role'"
        [checkedLabel]="'Assign Permissions'"
        [checked]="isAssignRole"
        (toggleChange)="togglePermissions($event)"
    ></app-switch-toggle>

    <app-permissions-config
        [permissionsState]="userPermissions"
        *ngIf="isAssignRole && !isNewRole && !isEdit"
        (permissionChange)="handlePermissionsChange($event)"
    ></app-permissions-config>

    <ng-template [ngIf]="isNewRole">
        <div class="create-account__section">
            <mat-form-field appearance="outline">
                <mat-label>Role</mat-label>
                <input matInput type="text" formControlName="role" />
            </mat-form-field>
        </div>

        <app-permissions-config
            [permissionsState]="newRoleState"
            (permissionChange)="handleNewRolePermissionsChange($event)"
        ></app-permissions-config>
    </ng-template>

    <ng-template [ngIf]="!isEdit">
        <div
            class="create-account__section"
            *ngIf="!isAssignRole && !isNewRole"
        >
            <mat-form-field appearance="outline">
                <mat-label>Role</mat-label>
                <mat-select formControlName="existing_roles">
                    <mat-option
                        *ngFor="let role of createdRoles"
                        [value]="role"
                        >{{ role.roleName }}</mat-option
                    >
                </mat-select>
            </mat-form-field>
        </div>
    </ng-template>
    <ng-template [ngIf]="isEdit">
        <ng-template [ngIf]="!isAssignRole">
            <div
                class="create-account__section"
                *ngIf="!isAssignRole && !isNewRole"
            >
                <mat-form-field appearance="outline">
                    <mat-label>Role</mat-label>
                    <mat-select formControlName="existing_roles">
                        <mat-option
                            *ngFor="let role of createdRoles"
                            [value]="role"
                            >{{ role.roleName }}</mat-option
                        >
                    </mat-select>
                </mat-form-field>
            </div>
        </ng-template>
        <ng-template [ngIf]="isAssignRole">
            <app-permissions-config
                [permissionsState]="editPermissionState"
                (permissionChange)="handleEditPermissionsChange($event)"
            ></app-permissions-config>
        </ng-template>
    </ng-template>
    <div class="button-bar">
        <div class="button-bar__left">
            <button mat-stroked-button color="primary" (click)="close()">
                Cancel
            </button>
            <button
                *ngIf="!isNewRole && isEdit"
                mat-stroked-button
                color="primary"
                (click)="deleteAccount()"
            >
                Delete
            </button>
        </div>
        <div class="button-bar__right">
            <button
                *ngIf="isNewRole"
                [disabled]="!createUserForm.valid"
                mat-flat-button
                color="primary"
                (click)="createNewRole(createUserForm.value)"
            >
                Save
            </button>
            <button
                *ngIf="!isNewRole"
                [disabled]="
                    !isEdit
                        ? !(createUserForm.valid && isEmailsTheSame && !isEmailExists)
                        : !createUserForm.valid
                "
                mat-flat-button
                color="primary"
                (click)="createAccount(createUserForm.value)"
            >
                Save
            </button>
        </div>
    </div>
</div>

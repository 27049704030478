import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map } from 'rxjs';
import { AppRestService } from '@app/shared/app-rest';
import { ICompanyContext } from '@app/interfaces/company-context.interface';
import { ICompany } from '../company/company.service';

@Injectable({
    providedIn: 'root',
})
export class CompanyContextService {
    private selectedCompanyContext = new BehaviorSubject<string>('');
    constructor(private appRest: AppRestService) {}

    public getCompanyHierarchyForUser(
        companyContext: string
    ): Observable<ICompanyContext[]> {
        return this.appRest.get(`companies/${companyContext}/companies`).pipe(
            map((res) => {
                const rootCompanyGroup = res.result.find(
                    (x: ICompanyContext) => x.companyContext === companyContext
                );
                return this.setCompanyChildren(res.result, rootCompanyGroup);
            })
        );
    }

    // Sets children concessions to the parent company.
    private setCompanyChildren(
        companiesList: ICompanyContext[],
        parent: ICompanyContext
    ): ICompanyContext[] {
        const groupedComapnies: ICompanyContext[] = [];
        const children = companiesList.filter(
            (c) => c.parentContext === parent.companyContext
        );
        if (children.length > 0) {
            parent.childCompanies = children;
            groupedComapnies.push(parent);
            parent.childCompanies.forEach((c) =>
                this.setCompanyChildren(companiesList, c)
            );
        } else {
            groupedComapnies.push(parent);
        }
        return groupedComapnies;
    }

    setCompanyContext(context: string): void {
        this.selectedCompanyContext.next(context);
    }

    getCompanyContext(): BehaviorSubject<string> {
        return this.selectedCompanyContext;
    }

    getAllCompanies(companyContext: string): Observable<{
        totalCount: number;
        companies: ICompany[];
    }> {
        return this.appRest.get(`companies/${companyContext}/companies`).pipe(
            map((res) => {
                return {
                    totalCount: res.totalCount,
                    companies: res.result,
                };
            })
        );
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { UsersToolsPanelModule } from './users-tools-panel';
import { RowsPerPageModule } from '@app/shared/grid/rows-per-page';
import { CreateAccountComponent } from './create-account/create-account.component';
import { AppDialogModule } from '@app/shared/app-dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ReactiveFormsModule } from '@angular/forms';
import { PermissionsConfigComponent } from './create-account/permissions-config/permissions-config.component';
import { MatIconModule } from '@angular/material/icon';
import { EditRoleComponent } from './create-account/edit-role/edit-role.component';
import { SwitchToggleModule } from '@app/shared/switch-toggle/switch-toggle.module';

@NgModule({
    declarations: [
        UsersComponent,
        CreateAccountComponent,
        PermissionsConfigComponent,
        EditRoleComponent,
    ],
    imports: [
        CommonModule,
        UsersRoutingModule,
        AgGridModule,
        UsersToolsPanelModule,
        RowsPerPageModule,
        ReactiveFormsModule,
        AppDialogModule,
        MatButtonModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatSlideToggleModule,
        MatIconModule,
        SwitchToggleModule,
    ],
})
export class UsersModule {}

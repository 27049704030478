<app-default-table-tools-panel
    [isAvailableToExport]="isAvailableToExport"
    [progressValueExport]="progressValueExport"
    [isOpenFilters]="isOpenFilters"
    [isOpenColumns]="isOpenColumns"
    (exportToExcel)="exportToExcel.emit()"
    (resetAll)="resetAll.emit()"
    (clearSorting)="clearSorting.emit()"
    (clearFiltering)="clearFiltering.emit()"
    (clearRowGrouping)="clearRowGrouping.emit()"
    (resetColumnOrder)="resetColumnOrder.emit()"
    (resetColumnVisibility)="resetColumnVisibility.emit()"
    (openFilters)="openFilters.emit()"
    (openColumns)="openColumns.emit()"
    >
    <ng-container left>
        <button
            (click)="refreshData.emit()"
            mat-icon-button
            [matTooltip]="refreshDataLabel"
            [attr.aria-label]="refreshDataLabel"
        >
            <mat-icon svgIcon="refresh"></mat-icon>
        </button>
        <span class="data-updated-text"> Updated: {{ lastUpdated | date:'HH:mm:ss' }}</span>
        <span class="modality-message" *ngIf="modality === 'None'">
            Data from validators unassigned to a concession/modality
        </span>
        <span *ngIf="hasPermissionShowWatermarkTimeStamp" class="watermark-text"> Watermark : {{watermarkDelay}} seconds</span>
        <button
            mat-stroked-button
            [hidden]="!hasPermissionManageDefaultPreference"
            color="primary"
            (click)="saveDefaultPreferences.emit()"
        >
            Save Defaults
        </button>
    </ng-container>
</app-default-table-tools-panel>

import { CustomDateCellRendererComponent } from "@app/shared/grid/cell-renderer";
import { CommandsDataEnum, CommandsSettings } from "./commands.interface";
import { CommandNameCellRendererComponent } from "@app/shared/grid/cell-renderer/command-name-cell-renderer";

export const commandsSettings: CommandsSettings = {
    [CommandsDataEnum.commandName]: { 
        dataFieldName: CommandsDataEnum.commandName,
        label: 'Command',
        cellRendererComponent: CommandNameCellRendererComponent
    },
    [CommandsDataEnum.applicationTypes]: { 
        dataFieldName: CommandsDataEnum.applicationTypes,
        label: 'Service',
    },
    [CommandsDataEnum.deviceCount]: { 
        dataFieldName: CommandsDataEnum.deviceCount,
        label: 'Devices',
    },
    [CommandsDataEnum.description]: { 
        dataFieldName: CommandsDataEnum.description,
        label: 'Description',
    },
    [CommandsDataEnum.commandStatus]: { 
        dataFieldName: CommandsDataEnum.commandStatus,
        label: 'Command Status',
    },
    [CommandsDataEnum.pendingCount]: { 
        dataFieldName: CommandsDataEnum.pendingCount,
        label: 'Pending',
    },
    [CommandsDataEnum.runningCount]: { 
        dataFieldName: CommandsDataEnum.runningCount,
        label: 'Running',
    },
    [CommandsDataEnum.succeededCount]: { 
        dataFieldName: CommandsDataEnum.succeededCount,
        label: 'Succeeded',
    },
    [CommandsDataEnum.failedCount]: { 
        dataFieldName: CommandsDataEnum.failedCount,
        label: 'Failed',
    },
    [CommandsDataEnum.receivedCount]: { 
        dataFieldName: CommandsDataEnum.receivedCount,
        label: 'Received',
    },
    [CommandsDataEnum.notReceivedCount]: { 
        dataFieldName: CommandsDataEnum.notReceivedCount,
        label: 'Not Received',
    },
    [CommandsDataEnum.commandType]: { 
        dataFieldName: CommandsDataEnum.commandType,
        label: 'Type',
    },
    [CommandsDataEnum.expiresOn]: { 
        dataFieldName: CommandsDataEnum.expiresOn,
        label: 'Expiration',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [CommandsDataEnum.jobIds]: { 
        dataFieldName: CommandsDataEnum.jobIds,
        label: 'Job ID',
    },
    [CommandsDataEnum.start]: { 
        dataFieldName: CommandsDataEnum.start,
        label: 'Started',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [CommandsDataEnum.end]: { 
        dataFieldName: CommandsDataEnum.end,
        label: 'Ended',
        cellRendererComponent: CustomDateCellRendererComponent
    },
} as CommandsSettings;

export const DataFieldNameMap: Map<string, string> = Object.keys(CommandsDataEnum).reduce(
    (acc: Map<string, string>, field: string) => (acc.set(
        commandsSettings[CommandsDataEnum[field as keyof typeof CommandsDataEnum]]?.dataFieldName, 
        field
    )),
    new Map<string, string>
);

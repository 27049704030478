export enum PositionHistoryDataEnum {
  deviceId = 'deviceId',
  firstPowerOn = 'firstPowerOn',
  companyName = 'companyName',
  vehicleId = 'vehicleId',
  validatorPosition = 'validatorPosition',
  idbtsam = 'idbtsam',
  pkisam = 'pkisam',
}

export type PositionHistoryData = {
  [key in PositionHistoryDataEnum]: any;
};

export type PositionHistorySettings = {
  [key in PositionHistoryDataEnum]: {
    dataFieldName: string;
    label: string;
    cellRendererComponent?: any;
  };
};

export type PositionHistoryColumnSettings = {
  key: PositionHistoryDataEnum;
  visible: boolean;
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WatermarkDelayService } from '@app/services/watermark-delay/watermark-delay.service';
import { interval, startWith, switchMap } from 'rxjs';

@Component({
    selector: 'app-heartbeats-tools-panel',
    templateUrl: './heartbeats-panel.component.html',
})
export class HeartbeatsPanelComponent implements OnInit {
    @Input() lastUpdated: Date;
    @Input() modality: string;
    @Input() isAvailableToExport: boolean;
    @Input() progressValueExport: number;
    @Input() isOpenFilters: boolean;
    @Input() isOpenColumns: boolean;
    @Input() hasPermissionManageDefaultPreference: boolean;
    @Input() hasPermissionShowWatermarkTimeStamp: boolean;

    @Output() refreshData = new EventEmitter();
    @Output() exportToExcel = new EventEmitter();
    @Output() resetAll = new EventEmitter();
    @Output() clearSorting = new EventEmitter();
    @Output() clearFiltering = new EventEmitter();
    @Output() clearRowGrouping = new EventEmitter();
    @Output() resetColumnOrder = new EventEmitter();
    @Output() resetColumnVisibility = new EventEmitter();
    @Output() openFilters = new EventEmitter();
    @Output() openColumns = new EventEmitter();
    @Output() saveDefaultPreferences = new EventEmitter();

    refreshDataLabel = 'Refresh Data';
    watermarkDelay = '0';
    constructor(private watermarkDelayService: WatermarkDelayService) {}
    ngOnInit(): void {
        interval(60000)
            .pipe(
                startWith(0),
                switchMap(() => this.watermarkDelayService.getWatermarkDelay())
            )
            .subscribe((x) => {
                this.watermarkDelay = Number(x.watermarkDelayInSeconds).toFixed(2);
            });
    }
}

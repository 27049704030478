<div [formGroup]="editRoleForm">
    <mat-form-field appearance="outline">
        <mat-label>Role</mat-label>
        <input matInput type="text" formControlName="role" />
    </mat-form-field>
</div>
<app-permissions-config
    [permissionsState]="permissionsState"
    (permissionChange)="handlePermissionChange($event)"
></app-permissions-config>

<div class="button-bar">
    <div class="button-bar__left">
        <button mat-stroked-button color="primary" (click)="close()">Cancel</button>
        <button mat-stroked-button color="primary" (click)="delete(editRoleForm.value)">Delete</button>
    </div>
    <div class="button-bar__right">
        <button
            [disabled]=""
            mat-flat-button
            color="primary"
            (click)="save(editRoleForm.value)"
        >
            Save
        </button>
    </div>
</div>

import { EventsV2DataEnum, EventsV2Settings } from './heartbeatsV2.interface';
import {
    ConnectionStateCellRendererComponent,
    LocationCellRendererComponent,
    CustomDateCellRendererComponent
} from '@app/shared/grid/cell-renderer';

export const eventsV2Settings: EventsV2Settings = {
    [EventsV2DataEnum.connectionState]: {
        dataFieldName: 'validatorInfo.connectionState',
        label: 'Connection State',
        cellRendererComponent: ConnectionStateCellRendererComponent,
    },
    [EventsV2DataEnum.deviceSerialNo]: {
        dataFieldName: 'validatorInfo.deviceSerialNo',
        label: 'Validator Serial No',
    },
    [EventsV2DataEnum.validatorStatus]: {
        dataFieldName: 'validatorInfo.validatorStatus',
        label: 'Validator Status',
    },
    [EventsV2DataEnum.statusCode]: {
        dataFieldName: 'validatorInfo.statusCode',
        label: 'Status Code',
        hide: true,
    },
    [EventsV2DataEnum.reasonStatus]: {
        dataFieldName: 'validatorInfo.reasonStatus',
        label: 'Reason Status',
    },
    [EventsV2DataEnum.validatorRole]: {
        dataFieldName: 'validatorInfo.validatorRole',
        label: 'Validator Role',
    },
    [EventsV2DataEnum.validatorPositionNo]: {
        dataFieldName: 'validatorInfo.validatorPositionNo',
        label: 'Validator Position No',
    },
    
    [EventsV2DataEnum.vehicle]: {
        dataFieldName: 'vehicleInfo.vehicleID',
        label: 'Vehicle',
    },
    [EventsV2DataEnum.vehicleType]: {
        dataFieldName: 'vehicleInfo.vehicleType',
        label: 'Vehicle Type',
    },
    [EventsV2DataEnum.PTO]: {
        dataFieldName: 'vehicleInfo.companyName',
        label: 'PTO',
    },
    [EventsV2DataEnum.kv6Status]: {
        dataFieldName: 'vehicleInfo.kv6Status',
        label: 'KV6 Status',
    },
    [EventsV2DataEnum.location]: {
        dataFieldName: 'vehicleInfo.gpsData',
        label: 'Location',
        cellRendererComponent: LocationCellRendererComponent,
    },
    [EventsV2DataEnum.isPilot]: {
        dataFieldName: 'vehicleInfo.isPilot',
        label: 'Is Pilot',
    },
    [EventsV2DataEnum.locationComments]: {
        dataFieldName: 'vehicleInfo.locationComments',
        label: 'Location Comments',
    },
    [EventsV2DataEnum.manuallySelectedLocation]: {
        dataFieldName: 'vehicleInfo.manuallySelectedLocation',
        label: 'Manually Selected Location',
    },
    [EventsV2DataEnum.modelId]: {
        dataFieldName: 'vehicleInfo.modelId',
        label: 'Model Id',
    },
    [EventsV2DataEnum.modelName]: {
        dataFieldName: 'vehicleInfo.modelName',
        label: 'Model Name',
    },
    [EventsV2DataEnum.modelValidatorCount]: {
        dataFieldName: 'vehicleInfo.modelValidatorCount',
        label: 'Model Validator Count',
    },
    [EventsV2DataEnum.validatorGroupNo]: {
        dataFieldName: 'vehicleInfo.validatorGroupNo',
        label: 'Validator Group No',
    },

    [EventsV2DataEnum.lastReceivedEventType]: {
        dataFieldName: 'events.messageType',
        label: 'Last Received Event Type',
    },
    [EventsV2DataEnum.lastMessageReceivedDate]: {
        dataFieldName: 'events.createdOn',
        label: 'Last Event Received Date',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsV2DataEnum.messageGeneratedDate]: {
        dataFieldName: 'events.messageGeneratedDateTime',
        label: 'Event Generated Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsV2DataEnum.messageIDCounter]: {
        dataFieldName: 'events.messageCounter',
        label: 'Event ID counter',
        hide: true,
    },

    [EventsV2DataEnum.OVCStatus]: {
        dataFieldName: 'fareMedia.ovcStatus',
        label: 'OVC Status',
    },
    [EventsV2DataEnum.OVCStatusReason]: {
        dataFieldName: 'fareMedia.ovcStatusReason',
        label: 'OVC Status Reason',
        hide: true,
    },
    [EventsV2DataEnum.numberOfSDOATransactionsSinceLastPowerOn]: {
        dataFieldName: 'fareMedia.numberOfSDOATransactions',
        label: 'SDOA Transactions since Power On',
        hide: true,
    },
    [EventsV2DataEnum.EMVStatus]: {
        dataFieldName: 'fareMedia.emvStatus',
        label: 'EMV Status',
    },
    [EventsV2DataEnum.EMVStatusReason]: {
        dataFieldName: 'fareMedia.emvStatusReason',
        label: 'EMV Status Reason',
        hide: true,
    },
    [EventsV2DataEnum.numberOfEMVTransactionsSinceLastPowerOn]: {
        dataFieldName: 'fareMedia.numberOfEMVTransactions',
        label: 'Number Of EMV Transactions Since Last PowerOn',
        hide: true,
    },
    [EventsV2DataEnum.CLTStatus]: {
        dataFieldName: 'fareMedia.cltStatus',
        label: 'CLT Status',
    },
    [EventsV2DataEnum.CLTStatusReason]: {
        dataFieldName: 'fareMedia.cltStatusReason',
        label: 'CLT Status Reason',
        hide: true,
    },
    // CLT Transactions since Power On

    [EventsV2DataEnum.BCStatus]: {
        dataFieldName: 'fareMedia.bcStatus',
        label: 'BC Status',
    },
    [EventsV2DataEnum.BCStatusReason]: {
        dataFieldName: 'fareMedia.bcStatusReason',
        label: 'BC Status Reason',
        hide: true,
    },
    [EventsV2DataEnum.numberOfBarcodeTransactionsSinceLastPowerOn]: {
        dataFieldName: 'fareMedia.numberOfBarcodeTransactions',
        label: 'Barcode Transactions since Power On',
        hide: true,
    },

    [EventsV2DataEnum.publicLineNo]: {
        dataFieldName: 'tripPosition.publicLineNo',
        label: 'Public Line No',
    },
    [EventsV2DataEnum.trip]: {
        dataFieldName: 'tripPosition.tripNo',
        label: 'Trip',
    },
    [EventsV2DataEnum.previousStation]: {
        dataFieldName: 'tripPosition.previousStationName',
        label: 'Previous Station',
    },
    [EventsV2DataEnum.previousStationRefNo]: {
        dataFieldName: 'tripPosition.previousStationRefNo',
        label: 'Previous Station Ref No',
        hide: true,
    },
    [EventsV2DataEnum.nextStation]: {
        dataFieldName: 'tripPosition.nextStationName',
        label: 'Next Station',
    },
    [EventsV2DataEnum.betweenStops]: {
        dataFieldName: 'tripPosition.betweenStops',
        label: 'Between Stops',
    },
    [EventsV2DataEnum.nextStationRefNo]: {
        dataFieldName: 'tripPosition.nextStationRefNo',
        label: 'Next Station Ref No',
        hide: true,
    },
    [EventsV2DataEnum.EBSInterfaceStatus]: {
        dataFieldName: 'tripPosition.ebsInterfaceStatus',
        label: 'EBS Interface Status',
    },

    [EventsV2DataEnum.errorMessage]: {
        dataFieldName: 'errorsWarnings.errorMessage',
        label: 'Error Message',
    },
    [EventsV2DataEnum.errorCode]: {
        dataFieldName: 'errorsWarnings.errorCode',
        label: 'Error Code',
        hide: true,
    },
    [EventsV2DataEnum.warningMessage]: {
        dataFieldName: 'errorsWarnings.warningMessage',
        label: 'Warning Message',
    },
    [EventsV2DataEnum.warningCode]: {
        dataFieldName: 'errorsWarnings.warningCode',
        label: 'Warning Code',
        hide: true,
    },

    [EventsV2DataEnum.validatorModel]: {
        dataFieldName: 'hardwareInfo.validatorModel',
        label: 'Validator Model',
    },  
    [EventsV2DataEnum.hasBarcodeReader]: {
        dataFieldName: 'hardwareInfo.hasBarcodeReader',
        label: 'Has Barcode Reader',
    },  
    [EventsV2DataEnum.servicePatternId]: {
        dataFieldName: 'hardwareInfo.servicePatternId',
        label: 'Service Pattern Id',
    },
    [EventsV2DataEnum.ramFree]: {
        dataFieldName: 'hardwareInfo.ramFree',
        label: 'Ram Free',
    },
    [EventsV2DataEnum.ramTotal]: {
        dataFieldName: 'hardwareInfo.ramTotal',
        label: 'Ram Total',
    },
    [EventsV2DataEnum.diskFree]: {
        dataFieldName: 'hardwareInfo.diskFree',
        label: 'Disk Free',
    },
    [EventsV2DataEnum.diskTotal]: {
        dataFieldName: 'hardwareInfo.diskTotal',
        label: 'Disk Total',
    },
    [EventsV2DataEnum.internalIPAddress]: {
        dataFieldName: 'hardwareInfo.internalIPaddress',
        label: 'Internal IP Address',
    },

    [EventsV2DataEnum.softwareVersion]: {
        dataFieldName: 'versions.softwareVersion',
        label: 'Software Version',
    },
    [EventsV2DataEnum.lastSoftwareUpdate]: {
        dataFieldName: 'versions.lastSoftwareUpdateDate',
        label: 'Last Software Update',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsV2DataEnum.validatorConfigVersion]: {
        dataFieldName: 'versions.validatorConfigVersion',
        label: 'Validator Configuration Version',
    },
    [EventsV2DataEnum.validatorVersion]: {
        dataFieldName: 'versions.validatorVersion',
        label: 'Validator Version',
    },
    [EventsV2DataEnum.issuerAccessListVersion]: {
        dataFieldName: 'versions.issuerAccessListVersion',
        label: 'Issuer Access List Version',
    },
    [EventsV2DataEnum.tokenAccessListVersion]: {
        dataFieldName: 'versions.tokenAccessListVersion',
        label: 'Token Access List Version',
    },

    [EventsV2DataEnum.ceilingValueCSCLeft]: {
        dataFieldName: 'samInfo.ceilingValueCSCLeft',
        label: 'Ceiling Value CSC Left',
    },
    [EventsV2DataEnum.ceilingValueCTLeft]: {
        dataFieldName: 'samInfo.ceilingValueCTLeft',
        label: 'Ceiling Value CT Left',
    },
    [EventsV2DataEnum.IDBT_SAMUserAuthenticationCounter]: {
        dataFieldName: 'samInfo.idbtsamUserAuthenticationCounter',
        label: 'IDBT SAM User Authentication Counter',
    },
    [EventsV2DataEnum.IDBT_SAM]: {
        dataFieldName: 'samInfo.idbtsam',
        label: 'IDBT SAM',
    },
    [EventsV2DataEnum.PKI_SAM]: {
        dataFieldName: 'samInfo.pkisam',
        label: 'PKI SAM',
    },
    [EventsV2DataEnum.PKI_SAMType]: {
        dataFieldName: 'samInfo.pkisamType',
        label: 'PKI SAM Type',
    },
    [EventsV2DataEnum.PKI_SAM_BEID]: {
        dataFieldName: 'samInfo.pkisambeid',
        label: 'PKI SAM BEID',
    },

    [EventsV2DataEnum.EODCurrentEffectiveDate]: {
        dataFieldName: 'eod.eodEffectiveDateCurrent',
        label: 'EOD Current Effective Date',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsV2DataEnum.EODFutureEffectiveDate]: {
        dataFieldName: 'eod.eodEffectiveDateFuture',
        label: 'EOD Future Effective Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsV2DataEnum.EODMCFVersionCurrent]: {
        dataFieldName: 'eod.eodmcfVersionCurrent',
        label: 'EOD MCF Version Current',
        
    },
    [EventsV2DataEnum.EODTopologyVersionCurrent]: {
        dataFieldName: 'eod.eodTopologyVersionCurrent',
        label: 'EOD Topology Version Current',
    },
    [EventsV2DataEnum.EODTopologyVersionFuture]: {
        dataFieldName: 'eod.eodTopologyVersionFuture',
        label: 'EOD Topology Version Future',
        hide: true,
    },
    [EventsV2DataEnum.EODFareVersionCurrent]: {
        dataFieldName: 'eod.eodFareVersionCurrent',
        label: 'EOD Fare Version Current',
    },
    [EventsV2DataEnum.EODFareVersionFuture]: {
        dataFieldName: 'eod.eodFareVersionFuture',
        label: 'EOD Fare Version Future',
        hide: true,
    },
    [EventsV2DataEnum.EODDicoVersionCurrent]: {
        dataFieldName: 'eod.eodDicoVersionCurrent',
        label: 'EOD Dico Version Current',
    },
    [EventsV2DataEnum.EODDicoVersionFuture]: {
        dataFieldName: 'eod.eodDicoVersionFuture',
        label: 'EOD Dico Version Future',
        hide: true,
    },
    [EventsV2DataEnum.EODBlackListVersionCurrent]: {
        dataFieldName: 'eod.eodBlackListCurrent',
        label: 'EOD Black List Version Current',
    },
    [EventsV2DataEnum.EODTimeVersionCurrent]: {
        dataFieldName: 'eod.eodTimeVersionCurrent',
        label: 'EOD Time Version Current',
    },
    [EventsV2DataEnum.EODTimeVersionFuture]: {
        dataFieldName: 'eod.eodTimeVersionFuture',
        label: 'EOD Time Version Future',
        hide: true,
    },

    [EventsV2DataEnum.TXARNumberOfTransaction]: {
        dataFieldName: 'txar.numberOfTransaction',
        label: 'TXAR Number Of Transaction',
    },
    [EventsV2DataEnum.TXARUploadDate]: {
        dataFieldName: 'txar.txarUploadDate',
        label: 'TXAR Upload Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsV2DataEnum.TXARFileCreateDate]: {
        dataFieldName: 'txar.txarFileCreateDate',
        label: 'TXAR File Create Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsV2DataEnum.TXARFileName]: {
        dataFieldName: 'txar.fileName',
        label: 'TXAR File Name',
        hide: true,
    },
    [EventsV2DataEnum.connectionStateUpdatedOn]: {
        dataFieldName: 'validatorInfo.connectionStateUpdatedOn',
        label: 'Connection State Update Date',
        cellRendererComponent: CustomDateCellRendererComponent
    },    
};

export const DataFieldNameV2Map: Map<string, string> = Object.keys(
    EventsV2DataEnum
).reduce(
    (acc: Map<string, string>, field: string) =>
        acc.set(
            eventsV2Settings[EventsV2DataEnum[field as keyof typeof EventsV2DataEnum]]
                .dataFieldName,
            field
        ),
    new Map<string, string>()
);

import {
  Component,
  ElementRef,
  ViewChild,
} from '@angular/core';
import {
  ColDef,
  ColumnState,
  GetLocaleTextParams,
  GridReadyEvent,
  IServerSideGetRowsRequest,
  RowGroupingDisplayType,
  RowModelType,
  SideBarDef,
  ToolPanelDef
} from '@ag-grid-community/core';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { Modality } from '@app/models/modality.enum';
import { SettingsCacheService } from '../settings-cache';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { IconColumnMenuComponent } from './icon-column-menu';
import { Subscription } from 'rxjs';
import { AGGridToODataOperatorsNameMapper } from './grid.interface'
import { CustomDateInput } from "@app/shared/grid/custom-date-input";

@Component({
  template: ''
})
export abstract class GridComponent {
  @ViewChild('rowsPerPage') rowsPerPage: ElementRef;

  GroupRowsByColumn = 'GroupRowsByColumn';

  companyContextSubscription: Subscription;

  public settingsCacheService: SettingsCacheService;

  public modules = [
    ClientSideRowModelModule,
    ServerSideRowModelModule,
    RowGroupingModule,
    SideBarModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
  ];

  public components = {
    agColumnHeader: IconColumnMenuComponent,
    agDateInput: CustomDateInput
  };

  public columnDefs: Array<any> = [];

  public defaultColDef: ColDef = {
    minWidth: 16,
    width: 125,
    resizable: true,
  };

  public autoGroupColumnDef: ColDef = { };

  public groupDisplayType: RowGroupingDisplayType = 'groupRows';
  public paginationPageSize = 25;
  public cacheBlockSize = 25;
  public rowGroupPanelShow: 'always' | 'onlyWhenGrouping' | 'never' = 'never';
  public pagination = true;
  public serverSideGetRowsRequest: IServerSideGetRowsRequest;

  public rowModelType: RowModelType = 'serverSide';

  public get isAvailableTable(): boolean {
    return this.columnDefs.length !== 0;
  }

  public get isAvailableRowGroupColumns(): boolean {
    return  this._grid.columnApi.getRowGroupColumns().length > 0;
  }

  public isAvailableToExport = true;
  public progressValueExport = 0;
  public sideBar: SideBarDef = {
    toolPanels: [
      {
        id: 'columns',
        labelDefault: 'Columns',
        labelKey: 'columns',
        iconKey: 'columns',
        toolPanel: 'agColumnsToolPanel',
        toolPanelParams: {
          suppressRowGroups: false,
          suppressValues: true,
          suppressPivots: true,
          suppressPivotMode: true,
          suppressColumnFilter: false,
          suppressColumnSelectAll: false,
          suppressColumnExpandAll: false,
        },
      } as ToolPanelDef,
      {
        id: 'filters',
        labelDefault: 'Filters',
        labelKey: 'filters',
        iconKey: 'filter',
        toolPanel: 'agFiltersToolPanel',
      } as ToolPanelDef,
    ] as Array<ToolPanelDef>,
  };

  public _grid: GridReadyEvent;

  public context = { componentParent: this };

  public isOpenFilters = false;
  public isOpenColumns = false;
  public modality = Modality.All;

  public lastUpdated: Date = new Date();

  public isAutoHeight = true;

  public enableCellTextSelection = true;
  public ensureDomOrder = true;

  public _initRowsPerPage(): void {
    // Injections to Rows Per Page Component into AG Grid Paging Panel
    const pagingPanel = document.getElementsByClassName('ag-paging-panel')[0];
    pagingPanel?.appendChild(this.rowsPerPage.nativeElement);
  }

  public onPageSizeChanged(value: number, gridName?: string): void {
    this.paginationPageSize = value;
    this.cacheBlockSize = value;
    this._grid.api.paginationSetPageSize(value);
  }

  public openFilters(): void {
    this.isOpenFilters = !this.isOpenFilters;
    this.isOpenColumns = false;

    if (this.isOpenFilters) {
      this._grid.api.openToolPanel('filters');
    } else {
      this._grid.api.closeToolPanel();
    }
  }

  public getPixelsFromRem(rem: number): number {
    const rootFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    return rem * rootFontSize;
  }

  public _initDomLayout(): void {
    this.setDomLayout();
    this._grid.api.addEventListener('viewportChanged', (event: any) => {
        this.setDomLayout();
    });
  }

  private setDomLayout(): void {
    const isAutoHeight = this._grid.api.getRenderedNodes().length < 11;
    this.isAutoHeight = isAutoHeight;
    this._grid.api.setDomLayout(isAutoHeight ? 'autoHeight' : 'normal');
  }

  public _initColumnRowGroup(setHeaderHeight = false): void {
    this._grid.api.addEventListener('columnRowGroupChanged', (event: any) => {
      this.pagination = !this.isAvailableRowGroupColumns;

      if (this.isAvailableRowGroupColumns) {
          this._grid.columnApi.setColumnVisible(this.GroupRowsByColumn, true);
          if (setHeaderHeight) {
            this._grid.api.setHeaderHeight(this.getPixelsFromRem(5.875));
          }
      } else {
          this._grid.columnApi.setColumnVisible(this.GroupRowsByColumn, false);
          if (setHeaderHeight) {
            this._grid.api.setHeaderHeight(this.getPixelsFromRem(2.9375));
          }
      }
    });
  }

  public openColumns(): void {
    this.isOpenColumns = !this.isOpenColumns;
    this.isOpenFilters = false;

    if (this.isOpenColumns) {
      this._grid.api.openToolPanel('columns');
    } else {
      this._grid.api.closeToolPanel();
    }
  }

  public refreshData(filterChanged = true): void {
    this.lastUpdated = new Date();
    if (filterChanged) {
      this._grid?.api?.onFilterChanged();
    }
  }

  /**
   * Resets the grid to default state if none is provided resets all
   *
   * @param defaultState state to which to reset if not provided clears grouping, sorting, ordering, filtering, visibility
   */

  public resetAll(defaultState: ColumnState[] | null = null): void {
    this._grid.columnApi.resetColumnState();
    this.clearFiltering();
    this._grid.columnApi.setRowGroupColumns([]);
    if(defaultState){
        this._grid.columnApi.applyColumnState({state: defaultState, applyOrder: true});
    }
  }

  public clearSorting(): void {
    const state = this._grid.columnApi?.getColumnState();
    this._grid.columnApi.applyColumnState({ state: state.map(item => ({
      ...item,
      sort: null
    })) });
    this.refreshData(false);
  }

  public clearFiltering(settings: any = null): void {
    this._grid.columnApi?.getColumnState().forEach(item => this._grid.api.destroyFilter(item.colId));
    this.refreshData();
  }

  public clearRowGrouping(): void {
    this._grid.columnApi.setRowGroupColumns([]);
    this.refreshData(false);
  }

  /**
   * Resets the order of columns in grid
   *
   * @param defaultState state to which to reset order if not provided order resets to initial column def
   */
  public resetColumnOrder(defaultState: ColumnState[] | null = null): void {
    let stateToApply: ColumnState[] = [];
    if (defaultState === null) {
      const initialColumns = this._grid.columnApi.getColumns();
      if (initialColumns) {
        stateToApply = initialColumns.map((column) => {
          return {
            colId: column.getColId()
          }
        })
      }
    } else {
      stateToApply = defaultState.map((column) => {
        return {
          colId: column.colId
        }
      })
    }

    this._grid.columnApi.applyColumnState({state: stateToApply, applyOrder: true});
  }

  /**
   * Resets the visibility of columns in grid
   *
   * @param defaultState state to which to reset visibility if not provided visibility resets to initial column def
   */

  public resetColumnVisibility(defaultState: ColumnState[] | null = null): void {
    let stateToApply: ColumnState[] = [];
    if (defaultState === null) {
      const initialColumns = this._grid.columnApi.getColumns();
      if (initialColumns) {
        stateToApply = initialColumns.map((column) => {
          let hide = false;
          if (column.getColId() === this.GroupRowsByColumn) {
            hide = !this.isAvailableRowGroupColumns
          }
          return {
            colId: column.getColId(),
            hide: hide
          }
        })
      }
    } else {
      stateToApply = defaultState.map((column) => {
        let hide = column.hide;
        if (column.colId === this.GroupRowsByColumn) {
            hide = !this.isAvailableRowGroupColumns
        }
        return {
          colId: column.colId,
          hide: hide
        }
      })
    }

    this._grid.columnApi.applyColumnState({state: stateToApply});
  }

  /**
   * Returns new option names for filter operators dropdown, can be used to expand the renaming or localisation for more info
   * https://www.ag-grid.com/angular-data-grid/localisation/
   */
  public getLocaleText : (params: GetLocaleTextParams) => string = (
    params: GetLocaleTextParams
  ) => {
    const changedText = AGGridToODataOperatorsNameMapper[params.key];
    if (changedText) {
      return changedText
    }
    return params.defaultValue;
  }

  protected _getGridRowsData<Type>() {
      const gridRowsData: Type[] = [];
      this._grid.api.forEachNodeAfterFilterAndSort((rowNode) =>
          rowNode.data && gridRowsData.push(rowNode.data)
      );
      return gridRowsData;
  }

  protected _getColumnsOrderForExport(settings: any, dataEnum: any) {
      return this._grid.columnApi.getAllDisplayedColumns()
          .filter((column: any) => column.visible
              && !!(dataEnum[column.colId])
              && (!dataEnum.radioButton || column.colId !== dataEnum.radioButton))
          .map((column: any) => {
              return settings[dataEnum[column.colId]].label;
          });
  }

  protected _prepareDataForExport(rowData: Array<any>, settings: any, dataEnum: any) {
      return rowData.map((item) =>
          (this._grid.columnApi.getColumns() ?? [])
              .filter((column: any) => column.visible && !!(dataEnum[column.colId]))
              .map((column: any) => column.colId)
              .reduce(
                  (acc: { [key: string]: any }, colId: string) => {
                      if(colId !== dataEnum.radioButton) {
                          const value = settings[dataEnum[colId]];
                          acc[value.label] = item[dataEnum[colId]];
                          if(value.cellRendererComponent && value.cellRendererComponent.format && acc[value.label]) {
                              acc[value.label] = value.cellRendererComponent.format(acc[value.label]);
                          }
                          if(Array.isArray(acc[value.label])) {
                              acc[value.label] = acc[value.label].join(',');
                          }
                      }
                      return acc;
                  },
                  {}
              )
      );
  }
}

<div [formGroup]="searchForm">
    <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search Vehicles</mat-label>
        <input matInput formControlName="vehicleId" />
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-icon matPrefix>search</mat-icon>
        <mat-label>Search Validators</mat-label>
        <input matInput formControlName="serialNumber" />
    </mat-form-field>
</div>

<h1 class="new-command__heading">Select Devices</h1>

<app-devices-grid
    [rowData]="devices"
    (selectedDevicesChanged)="updateSelectedDevices($event)"
></app-devices-grid>
<div class="button-bar">
    <button mat-stroked-button color="primary" (click)="closeSelectDevices()">
        Cancel
    </button>
    <button
        content
        class="button-to-right"
        (click)="saveSelection()"
        mat-flat-button
        color="primary"
    >
        Save Selection
    </button>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../environments/environment'

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  get isProduction(): boolean {
    return environment.production;
  }

  get isAcceptance(): boolean{
    return environment.acceptance;
  }

  get isDevelopment(): boolean{
    return environment.development;
  }

  get apiUrl(): string {
    return environment.auth.apiUrl;
  }

  get redirectUrl(): string{
    return environment.auth.authorizationParams.redirect_uri;
  }

  get oldPortalUrl(): string{
    return environment.auth.oldPortalUrl;
  }

  get liveUpdatesUrl(): string{
    return environment.auth.liveUpdatesUrl;
  }

  get agGridLicenseKey(): string{
    return environment.auth.agGridLincenseKey;
  }
}
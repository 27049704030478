import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppDialogComponent } from './app-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { AppDialogService } from './app-dialog.service';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
    declarations: [AppDialogComponent],
    exports: [AppDialogComponent, MatDialogModule],
    imports: [CommonModule, MatButtonModule, MatDialogModule],
    providers: [AppDialogService],
})
export class AppDialogModule {}

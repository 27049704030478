import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultTableToolsPanelModule } from '@app/shared/tools-panel/default-table-tools-panel';
import { DevicesToolPanelComponent } from './devices-tool-panel.component';

@NgModule({
    declarations: [DevicesToolPanelComponent],
    exports: [DevicesToolPanelComponent],
    imports: [CommonModule, DefaultTableToolsPanelModule],
})
export class DevicesToolsPanelModule {}

import {
    CustomRoleCellRendererComponent,
    RadioButtonCellRendererComponent,
} from '@app/shared/grid/cell-renderer';
import { IconTypeEnum, UsersDataEnum, UsersSettings } from './users.interface';

export const usersSettings: UsersSettings = {
    [UsersDataEnum.radioButton]: {
        dataFieldName: UsersDataEnum.radioButton,
        label: '',
        cellRendererComponent: RadioButtonCellRendererComponent,
        alwaysAvailable: true,
        suppressMenu: true,
        suppressSortable: true,
        suppressFilterable: true,
        lockVisible: true,
        suppressDragLeaveHidesColumns: true,
    },
    [UsersDataEnum.firstName]: {
        dataFieldName: UsersDataEnum.firstName,
        label: 'First Name',
    },
    [UsersDataEnum.lastName]: {
        dataFieldName: UsersDataEnum.lastName,
        label: 'Last Name',
    },
    [UsersDataEnum.email]: {
        dataFieldName: UsersDataEnum.email,
        label: 'Email/ID',
    },
    [UsersDataEnum.companyContext]: {
        dataFieldName: UsersDataEnum.companyContext,
        label: 'Company',
    },
    [UsersDataEnum.assignedRole]: {
        dataFieldName: UsersDataEnum.assignedRole,
        label: 'Assigned Role',
        cellRendererComponent: CustomRoleCellRendererComponent,
        alwaysAvailable: true,
        suppressMenu: true,
        suppressSortable: true,
        suppressFilterable: true,
        lockVisible: true,
        suppressDragLeaveHidesColumns: true,
    },
    [UsersDataEnum.dashboardPermission]: {
        dataFieldName: UsersDataEnum.dashboardPermission,
        label: 'dashboardPermission', // it will be changed
        iconType: IconTypeEnum.Dashboard,
    },
    [UsersDataEnum.heartbeatsPermission]: {
        dataFieldName: UsersDataEnum.heartbeatsPermission,
        label: 'heartbeatsPermission', // it will be changed
        iconType: IconTypeEnum.Heartbeats,
    },
    [UsersDataEnum.eventHistoryPermission]: {
        dataFieldName: UsersDataEnum.eventHistoryPermission,
        label: 'eventHistoryPermission', // it will be changed
        iconType: IconTypeEnum.EventsHistory,
    },
    [UsersDataEnum.mapPermission]: {
        dataFieldName: UsersDataEnum.mapPermission,
        label: 'mapPermissions', // it will be changed
        iconType: IconTypeEnum.Map,
    },
    [UsersDataEnum.devicePositionPermission]: {
        dataFieldName: UsersDataEnum.devicePositionPermission,
        label: 'devicePositionPermission', // it will be changed
        iconType: IconTypeEnum.Device,
    },
    [UsersDataEnum.positionHistoryPermission]: {
        dataFieldName: UsersDataEnum.positionHistoryPermission,
        label: 'positionHistoryPermission', // it will be changed
        iconType: IconTypeEnum.PositionHistory,
    },
    [UsersDataEnum.vehiclesPermission]: {
        dataFieldName: UsersDataEnum.vehiclesPermission,
        label: 'vehiclesPermission', // it will be changed
        iconType: IconTypeEnum.Vehicles,
    },
    [UsersDataEnum.commandsPermission]: {
        dataFieldName: UsersDataEnum.commandsPermission,
        label: 'commandsPermission', // it will be changed
        iconType: IconTypeEnum.Commands,
    },
    [UsersDataEnum.usersPermission]: {
        dataFieldName: UsersDataEnum.usersPermission,
        label: 'usersPermission', // it will be changed
        iconType: IconTypeEnum.Users,
    },
} as UsersSettings;

export const DataFieldNameMap: Map<string, string> = Object.keys(
    UsersDataEnum
).reduce(
    (acc: Map<string, string>, field: string) =>
        acc.set(
            usersSettings[UsersDataEnum[field as keyof typeof UsersDataEnum]]
                .dataFieldName,
            field
        ),
    new Map<string, string>()
);

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppPermissionGuard } from './shared/app-permission/app-permission.guard';
import { AppPermissions } from './shared/app-permission/app-permissions';

export const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard').then((m) => m.DashboardModule),
        data: {
            title: 'Dashboard',
            iconPath: 'assets/svg/header/dashboard.svg',
        },
    },
    {
        path: 'commands',
        loadChildren: () => import('./pages/commands').then((m) => m.CommandsModule),
        canActivate: [AppPermissionGuard],
        data: {
            requiredPermission: AppPermissions.ViewCommands,
            title: 'Commands',
            iconPath: 'assets/svg/header/commands.svg',
        },
    },
    {
        path: 'map',
        loadChildren: () => import('./pages/map').then((m) => m.MapModule),
        canActivate: [AppPermissionGuard],
        data: {
            requiredPermission: AppPermissions.ViewMap,
            title: 'Map',
            iconPath: 'assets/svg/header/map.svg',
        },
    },
    {
        path: 'heartbeats',
        loadChildren: () => import('./pages/heartbeatsV2').then((m) => m.HeartbeatsV2Module),
        canActivate: [AppPermissionGuard],
        data: {
            requiredPermission: AppPermissions.ViewHeartbeats,
            title: 'Heartbeats',
            iconPath: 'assets/svg/header/heartbeats.svg',
        },
    },
    {
        path: 'position-history',
        loadChildren: () => import('./pages/position-history').then((m) => m.PositionHistoryModule),
        canActivate: [AppPermissionGuard],
        data: {
            requiredPermission: AppPermissions.ViewPositionHistory,
            title: 'Position History',
            iconPath: 'assets/svg/header/positionHistory.svg',
        },
    },
    {
        path: 'vehicles',
        loadChildren: () => import('./pages/vehicles').then((m) => m.VehiclesModule),
        canActivate: [AppPermissionGuard],
        data: { 
            requiredPermission: AppPermissions.ViewVehicles,
            title: 'Vehicles', 
            iconPath: 'assets/svg/header/vehicles.svg' 
        },
    },
    {
        path: 'users',
        loadChildren: () => import('./pages/users').then((m) => m.UsersModule),
        canActivate: [AppPermissionGuard],
        data: { 
            requiredPermission: AppPermissions.ViewUsers,
            title: 'Users', 
            iconPath: 'assets/svg/header/person.svg' 
        },
    },
    {
        path: 'device-position',
        loadChildren: () => import('./pages/device-position').then((m) => m.DevicePositionModule),
        canActivate: [AppPermissionGuard],
        data: { 
            requiredPermission: AppPermissions.ViewDevicePosition,
            title: 'Device Position', 
            iconPath: 'assets/svg/header/devicePosition.svg' 
        },
    },
    {
        path: 'event-history',
        loadChildren: () => import('./pages/event-history').then((m) => m.EventHistoryModule),
        canActivate: [AppPermissionGuard],
        data: { 
            requiredPermission: AppPermissions.ViewEventHistory,
            title: 'Event History', 
            iconPath: 'assets/svg/header/eventHistory.svg' 
        },
    },
    {
        path: 'validator-groups',
        loadChildren: () => import('./pages/validator-groups').then((m) => m.ValidatorGroupsModule),
        canActivate: [AppPermissionGuard],
        data: { 
            requiredPermission: AppPermissions.ViewEventHistory,
            title: 'Validator Groups', 
            iconPath: 'assets/svg/header/validatorGroups.svg' 
        },
    },
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserPermission, UserRole } from '@app/services/account/user-role.interface';
import { UserRoleService } from '@app/services/account/user-role.service';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { AppDialogConfig } from '@app/shared/app-dialog/app-dialog.config';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { Subject, switchMap, takeUntil } from 'rxjs';

@Component({
    selector: 'app-edit-role',
    templateUrl: './edit-role.component.html',
    styleUrls: ['./edit-role.component.scss'],
})
export class EditRoleComponent implements OnInit, OnDestroy {
    editRoleForm: FormGroup;
    permissionsState: string[] = [];
    destroy$: Subject<void> = new Subject();
    customRoles: UserRole[];
    roleToEdit: UserRole;
    constructor(
        public dialogRef: MatDialogRef<EditRoleComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        public appDialogService: AppDialogService,
        public companyContextService: CompanyContextService,
        public userRoleService: UserRoleService
    ) {}
    ngOnInit(): void {
        this.editRoleForm = new FormGroup({
            role: new FormControl(''),
        });

        this.companyContextService
            .getCompanyContext()
            .pipe(
                takeUntil(this.destroy$),
                switchMap((ctx: string) =>
                    this.userRoleService.getCreatedRoles(ctx)
                )
            )
            .subscribe((res: UserRole[]) => {
                this.customRoles = res;
                this.roleToEdit = this.customRoles.find(
                    (customRole: UserRole) =>
                        customRole.roleName === this.dialogConfig.data.roleName
                ) as UserRole;
                this.permissionsState = this.roleToEdit.permissions.map(
                    (permission: UserPermission) => permission.permissionName
                );
                this.editRoleForm
                    .get('role')
                    ?.setValue(this.roleToEdit.roleName);
            });
    }

    handlePermissionChange(permissionState: string[]) {
        this.permissionsState = permissionState;
    }

    close() {
        this.appDialogService.closeSliderDialog(this.dialogRef);
    }

    delete(value: any) {
        if(value){
            this.appDialogService.closeSliderDialog(this.dialogRef, { 
                action: 'DELETE_ROLE',
                payload: {
                    roleName: value.role,
                    roleId: this.roleToEdit.roleId,
                    permissions: this.permissionsState,
                },
            });
        }
    }

    save(value: { role: string }) {
        this.appDialogService.closeSliderDialog(this.dialogRef, {
            action: 'SAVE_ROLE',
            payload: {
                roleName: value.role,
                roleId: this.roleToEdit.roleId,
                permissions: this.permissionsState,
            },
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-form-slider',
    templateUrl: './from-slider.component.html',
    styleUrls: ['./from-slider.component.scss'],
})
export class FromSliderComponent {
    @Input() min: number;
    @Input() max: number;
    @Input() step: number;
    @Input() suffix: string;
    @Input() control: FormControl;
    @Input() showTicks = true;
}

import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnInit } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { ICompanyContext } from '@app/interfaces/company-context.interface';
import { CompanyContextService } from '@app/services/company-context/company-context.service';

@Component({
  selector: 'app-company-hierarchy',
  templateUrl: './company-hierarchy.component.html',
  styleUrls: ['./company-hierarchy.component.scss']
})
export class CompanyHierarchyComponent implements OnInit {
  @Input() nodes: ICompanyContext[];

  selectedItem: string;
  treeControl = new NestedTreeControl<ICompanyContext>(node => node.childCompanies);
  dataSource = new MatTreeNestedDataSource<ICompanyContext>();

  constructor(private companyContextService: CompanyContextService){
    this.dataSource.data = this.nodes;
  }

  ngOnInit(): void {
    if(this.nodes)
    {
      this.selectedItem = this.nodes[0].name ? this.nodes[0].name : "";
      const context = this.nodes[0].companyContext ? this.nodes[0].companyContext : "";
      this.companyContextService.setCompanyContext(context);
    }
  }



  hasChild = (_: number, node: ICompanyContext) => !!node.childCompanies && node.childCompanies.length > 0;

  onSelectionChange(event: {nodeContext: string, nodeName: string}) {
    if(event.nodeName === this.selectedItem) return;
    this.selectedItem = event.nodeName;
    this.companyContextService.setCompanyContext(event.nodeContext);
  }
}

import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-cell-renderer',
  templateUrl: './location-cell-renderer.component.html',
  styleUrls: ['./location-cell-renderer.component.scss']
})
export class LocationCellRendererComponent implements ICellRendererAngularComp { 
  public displayValue!: string;
  public params!: any;

  constructor (
    private router: Router,
  ) {

  }

  public agInit(params: ICellRendererParams): void {
    this.params = params;    
    this.displayValue = params.value;
  }

  public refresh(): boolean {
    return false;
  }

  public openLocationOnMap(): void {
    this.router.navigate(['/map'], {
        queryParams: { vehicleId: this.params.value },
    });
  }
}

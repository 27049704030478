import {Component, Inject, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {
    ApplicationTypes,
    BrightnessOptions,
    CommandPayload,
    EDOptions,
    LogLevelOptions,
    NewCommandForm,
    ServiceCheckboxesForm,
    SoundOptions,
    generateServicesArray,
    servicesRadioConfig,
    timeRanges,
} from './new-command.interfaces';
import {
    CommandTypeValue,
    CommandTypeValues,
    CommandTypes
} from '../commands.interface';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Device } from '@app/services/devices/devices.service';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { UserInfoService } from '@app/services/user-info/user-info.service';
import { UserData } from '@app/models/user-data.model';
import { CommandsService } from '../commands.service';
import { take } from 'rxjs';
import { DeviceSelectorService } from './devices-transfer.service';
import { KeySettings, SettingsCacheService } from '@app/shared';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppDialogConfig, DialogSizes } from "@app/shared/app-dialog/app-dialog.config";
import { AppDialogService } from "@app/shared/app-dialog/app-dialog.service";
import * as moment from 'moment';

export interface NewCommandCloseData {
    isSuccess: boolean;
}

@Component({
    selector: 'app-new-command',
    templateUrl: './new-command.component.html',
    styleUrls: ['./new-command.component.scss'],
})
export class NewCommandComponent implements OnInit {
    newCommandForm: FormGroup;
    commandTypes = CommandTypes;
    soundOptions = SoundOptions;
    logLevelOptions = LogLevelOptions;
    edOptions = EDOptions;
    commandTypeValues: CommandTypeValue[] = CommandTypeValues;
    currentCommand: CommandTypeValue = this.commandTypeValues[0];
    timeRanges: string[] = timeRanges;
    brightnessOptions = BrightnessOptions;
    servicesRadio = servicesRadioConfig;
    currentCompanyContext: string;
    userData: UserData;
    checked = false;
    constructor(
        public dialogRef: MatDialogRef<NewCommandComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        private companyContextService: CompanyContextService,
        private userInfoService: UserInfoService,
        private commandsService: CommandsService,
        public appDialogService: AppDialogService,
        private deviceTransferService: DeviceSelectorService,
        private settingsCacheService: SettingsCacheService
    ) {}

    get volume(): FormControl {
        return this.newCommandForm.get('volume') as FormControl;
    }

    get transactionPeriod(): FormControl {
        return this.newCommandForm.get('transactionPeriod') as FormControl;
    }

    get maxTransactionCheckTime(): FormControl {
        return this.newCommandForm.get(
            'maxTransactionCheckTime'
        ) as FormControl;
    }
    get maxAuditCheckTime(): FormControl {
        return this.newCommandForm.get('maxAuditCheckTime') as FormControl;
    }
    get maxNumberOfRecords(): FormControl {
        return this.newCommandForm.get('maxNumberOfRecords') as FormControl;
    }
    get SWcheckTimeInMinutes(): FormControl {
        return this.newCommandForm.get('SWcheckTimeInMinutes') as FormControl;
    }
    get OVcheckTimeInMinutes(): FormControl {
        return this.newCommandForm.get('OVcheckTimeInMinutes') as FormControl;
    }
    get EODcheckTimeInMinutes(): FormControl {
        return this.newCommandForm.get('EODcheckTimeInMinutes') as FormControl;
    }
    get EMVcheckTimeInMinutes(): FormControl {
        return this.newCommandForm.get('EMVcheckTimeInMinutes') as FormControl;
    }
    get brightnessSlide(): FormControl {
        return this.newCommandForm.get('brightnessSlide') as FormControl;
    }

    ngOnInit(): void {
        this.companyContextService
            .getCompanyContext()
            .subscribe((companyCtx: string) => {
                this.currentCompanyContext = companyCtx;
            });

        this.userInfoService.isUserDataLoaded().subscribe((val) => {
            if (val) {
                this.userData =
                    this.settingsCacheService.getDataFromLocalStorage(
                        KeySettings.UserData
                    );
            } else {
                this.userInfoService
                    .getUserData()
                    .subscribe((user: UserData) => {
                        this.userData = user;
                    });
            }
        });

        this.newCommandForm = new FormGroup<NewCommandForm>({
            command: new FormControl(this.currentCommand.type, {
                nonNullable: true,
                validators: [Validators.required],
            }),
            description: new FormControl('', {
                nonNullable: true,
            }),
            commandType: new FormControl(false, {
                nonNullable: true,
                validators: [Validators.required],
            }),
            expirationDate: new FormControl(new Date().toISOString(), {
                nonNullable: true,
                validators: [Validators.required],
            }),
            expirationTime: new FormControl(timeRanges[0], {
                nonNullable: true,
                validators: [Validators.required],
            }),
            devices: new FormControl([] as Device[], {
                nonNullable: true,
                validators: [Validators.required],
            }),
        });

        this.deviceTransferService.selectedDevices.subscribe(
            (devices: Device[]) => {
                this.newCommandForm.get('devices')?.setValue(devices);
                this.isValidCommand();
            }            
        );
    }

    private isValidCommand() {
        if (this.newCommandForm.get('command')?.value === CommandTypes.SELECT) {
            this.setFormAsInvalid();
        }
    }

    get serviceCheckboxs(): FormGroup {
        return this.newCommandForm.get('serviceCheckboxs') as FormGroup;
    }

    commandTypeChange(command: CommandTypes): void {
        this.currentCommand = this.commandTypeValues.find(
            (commandTypeValue: CommandTypeValue) =>
                commandTypeValue.type === command
        ) as CommandTypeValue;

        switch (command) {
            case CommandTypes.SELECT:
                this.setFormAsInvalid();
                break;
            case CommandTypes.REBOOT:
                break;
            case CommandTypes.RESTART_SERVICE:
                this.addServiceBoxesControl();
                break;

            case CommandTypes.RETRIEVE_LOG_FILE:
                this.addServiceBoxesControl();
                this.newCommandForm.removeControl('period');

                this.newCommandForm.addControl(
                    'period',
                    new FormControl(moment(new Date().toString()), {
                        nonNullable: true,
                    })
                );
                break;

            case CommandTypes.RETRIEVE_CONFIG_FILE:
                this.addServiceBoxesControl();
                break;

            case CommandTypes.OVERWRITE_CONFIG_FILE:
                this.newCommandForm.removeControl('configurationType');
                this.newCommandForm.addControl(
                    'configurationType',
                    new FormControl(ApplicationTypes.DEVICE_SERVICE_MONITOR)
                );
                this.newCommandForm.removeControl('files');
                this.newCommandForm.addControl(
                    'files',
                    new FormControl('', { nonNullable: true })
                );
                break;

            case CommandTypes.SET_SOUND:
                this.newCommandForm.removeControl('files');
                this.newCommandForm.addControl(
                    'files',
                    new FormControl([], { nonNullable: true })
                );
                this.newCommandForm.removeControl('sound');
                this.newCommandForm.addControl(
                    'sound',
                    new FormControl('', { nonNullable: true })
                );
                break;

            case CommandTypes.SET_EVENT_LOG_LEVEL:
                this.addServiceBoxesControl();
                this.newCommandForm.removeControl('logLevel');
                this.newCommandForm.addControl(
                    'logLevel',
                    new FormControl(this.logLevelOptions[2].value, {
                        nonNullable: true,
                    })
                );
                break;

            case CommandTypes.SET_VOLUME:
                this.newCommandForm.addControl('volume', new FormControl(0));

                break;

            case CommandTypes.SET_TRANSACTION_DATA_RETENTION_PERIOD:
                this.newCommandForm.removeControl('transactionPeriod');
                this.newCommandForm.addControl(
                    'transactionPeriod',
                    new FormControl('')
                );
                break;

            case CommandTypes.SET_MAX_TRANSACTION_DATA_SEND_TIME:
                this.newCommandForm.removeControl('maxTransactionCheckTime');
                this.newCommandForm.addControl(
                    'maxTransactionCheckTime',
                    new FormControl(60)
                );
                this.newCommandForm.removeControl('maxAuditCheckTime');
                this.newCommandForm.addControl(
                    'maxAuditCheckTime',
                    new FormControl(60)
                );
                break;

            case CommandTypes.SET_MAX_NUMBER_TRANSACTION_DATA_RECORDS:
                this.newCommandForm.removeControl('maxNumberOfRecords');
                this.newCommandForm.addControl(
                    'maxNumberOfRecords',
                    new FormControl(2000)
                );
                break;

            case CommandTypes.SET_NEW_SW_CHECK_TIME:
                this.newCommandForm.removeControl('SWcheckTimeInMinutes');
                this.newCommandForm.addControl(
                    'SWcheckTimeInMinutes',
                    new FormControl(0)
                );
                break;

            case CommandTypes.SET_NEW_EOD_CHECK_TIME:
                this.newCommandForm.removeControl('EODcheckTimeInMinutes');
                this.newCommandForm.addControl(
                    'EODcheckTimeInMinutes',
                    new FormControl(0)
                );
                break;

            case CommandTypes.SET_NEW_EMV_BLACKLIST_CHECK_TIME:
                this.newCommandForm.removeControl('EMVcheckTimeInMinutes');
                this.newCommandForm.addControl(
                    'EMVcheckTimeInMinutes',
                    new FormControl(0)
                );
                break;

            case CommandTypes.SET_NEW_OV_CHIPCARD_BLACKLIST_CHECK_TIME:
                this.newCommandForm.removeControl('OVcheckTimeInMinutes');
                this.newCommandForm.addControl(
                    'OVcheckTimeInMinutes',
                    new FormControl(0)
                );
                break;

            case CommandTypes.SET_DISPLAY_BRIGHTNESS:
                this.newCommandForm.removeControl('brightness');
                this.newCommandForm.addControl(
                    'brightness',
                    new FormControl('', { nonNullable: true })
                );

                this.newCommandForm
                    .get('brightness')
                    ?.valueChanges.subscribe((value: string) => {
                        if (value === 'Static') {
                            this.newCommandForm.removeControl(
                                'brightnessSlide'
                            );
                            this.newCommandForm.addControl(
                                'brightnessSlide',
                                new FormControl(0)
                            );
                        } else {
                            this.newCommandForm.removeControl(
                                'brightnessSlide'
                            );
                        }
                    });

                break;

            case CommandTypes.SET_EEPROM_VALUE:
                this.newCommandForm.removeControl('EEPROM');
                this.newCommandForm.addControl(
                    'EEPROM',
                    new FormControl('', { nonNullable: true })
                );
                break;

            case CommandTypes.SET_FARE_MEDIA_USAGE_ALLOWED:
                this.newCommandForm.removeControl('OVAllowed');
                this.newCommandForm.addControl(
                    'OVAllowed',
                    new FormControl('enabled', { nonNullable: true })
                );
                this.newCommandForm.removeControl('BarCodeAllowed');
                this.newCommandForm.addControl(
                    'BarCodeAllowed',
                    new FormControl('enabled', { nonNullable: true })
                );
                this.newCommandForm.removeControl('EMVAllowed');
                this.newCommandForm.addControl(
                    'EMVAllowed',
                    new FormControl('enabled', { nonNullable: true })
                );

                break;

            case CommandTypes.SET_DEGRADED_MODE:
                this.newCommandForm.removeControl('DegradedMode');
                this.newCommandForm.addControl(
                    'DegradedMode',
                    new FormControl('enabled', { nonNullable: true })
                );
                break;

            case CommandTypes.TRIGGER_FILE_INTEGRITY_CHECK:
                break;
        }
    }
    setFormAsInvalid() {
        this.newCommandForm.get('command')?.setErrors({'invalid': true});
    }

    timeSelectChange(timeRange: string): void {
        const now = new Date();
        const nowDateTime = now.toISOString();
        const nowDate = nowDateTime.split('T')[0];
        const stringToTimeDate = new Date(nowDate + 'T' + timeRange);

        const selectedDate = new Date(
            this.newCommandForm.get('expirationDate')?.value
        );

        selectedDate.setHours(stringToTimeDate.getHours());
        selectedDate.setMinutes(stringToTimeDate.getMinutes());

        this.newCommandForm
            .get('expirationDate')
            ?.setValue(selectedDate.toISOString());
    }

    dateChange(e: MatDatepickerInputEvent<any, any>): void {
        this.timeSelectChange(this.newCommandForm.get('expirationTime')?.value);
    }

    initServiceGroup(): FormGroup<ServiceCheckboxesForm> {
        return new FormGroup<ServiceCheckboxesForm>({
            isDeviceServiceMonitor: new FormControl(false, {
                nonNullable: true,
            }),
            isHeartbeat360: new FormControl(false, { nonNullable: true }),
            isLocalSupport: new FormControl(false, { nonNullable: true }),
            isUpdateHandler: new FormControl(false, { nonNullable: true }),
            isUserInterface: new FormControl(false, { nonNullable: true }),
            isValidator: new FormControl(false, { nonNullable: true }),
        });
    }

    commandTypeToggleChange(checked: boolean) {
        this.newCommandForm.get('commandType')?.setValue(checked);
        if (checked) {
            this.addExpirationControls();
            this.checked = true;
            this.isValidCommand();
        } else {
            this.newCommandForm.removeControl('expirationDate');
            this.newCommandForm.removeControl('expirationTime');
            this.checked = false;
            this.isValidCommand();
        }
    }

    addExpirationControls(): void {
        this.newCommandForm.addControl(
            'expirationDate',
            new FormControl(new Date().toISOString(), {
                nonNullable: true,
                validators: [Validators.required],
            })
        );
        this.newCommandForm.addControl(
            'expirationTime',
            new FormControl(timeRanges[0], {
                nonNullable: true,
                validators: [Validators.required],
            })
        );
    }

    onFileSelected(files: FileList) {
        const fileNames: string[] = [];
        for (let i = 0; i < files.length; i++) {
            fileNames.push(files[i].name);
            const formData = new FormData();
            formData.append('files', files[i]);

            this.commandsService
                .uploadFiles(formData)
                .pipe(take(1))
                .subscribe((res) => {
                    this.newCommandForm.get('files')?.setValue(fileNames);
                });
        }
    }

    onFileRemoved(fileName: string): void {
        const formData = new FormData();
        formData.append('fileNames', fileName);

        this.commandsService
            .removeFile(formData)
            .pipe(take(1))
            .subscribe((res) => {
                const updateFiles: string[] = this.newCommandForm
                    .get('files')
                    ?.value.filter((existingfileName: string) => {
                        return existingfileName !== fileName;
                    });
                this.newCommandForm.get('files')?.setValue(updateFiles);
            });
    }

    submit(value: any) {
        const payload: CommandPayload = {
            id: '',
            jobId: '',
            jobType: value.commandType ? 'Indirect' : 'Direct',
            command: value.command,
            commandDisplay: '',
            companyName: '',
            companyContext: this.currentCompanyContext,
            createdBy: this.userData.email,
            devices: value.devices,
            description: value.description,
            expires: true,
            expiresDisplay: '',
            expiresOn: value.expirationDate ? value.expirationDate : null,
            payload: '',
            files: value.files ? value.files : [],
            configurationType: value.configurationType
                ? value.configurationType
                : '',
            volume: value.volume ? value.volume : '100',
            brightnessType: value.brightness ? value.brightness : 'Dynamic',
            brightness: value.brightnessSlide ? value.brightnessSlide : 10,
            logLevel: value.logLevel ? value.logLevel : 'Info',
            transactionDataRetentionTime: value.transactionPeriod
                ? `${value.transactionPeriod}`
                : '7',
            maxNumberTransactionDataRecords: value.maxNumberOfRecords
                ? `${value.maxNumberOfRecords}`
                : '2000',
            maxTxDataSendTimeMinutes: value.maxTransactionCheckTime
                ? `${value.maxTransactionCheckTime}`
                : '60',
            maxArDataSendTimeMinutes: value.maxAuditCheckTime
                ? `${value.maxAuditCheckTime}`
                : '60',
            minutes: value.SWcheckTimeInMinutes
                ? `${value.SWcheckTimeInMinutes}`
                : '60',
            soundType: value.sound ? value.sound : 'checkin',
            degradedMode: value.DegradedMode ? value.DegradedMode : 'Disabled',
            emvUsageAllowed: value.EMVAllowed ? value.EMVAllowed : 'Enabled',
            barcodeUsageAllowed: value.BarCodeAllowed
                ? value.BarCodeAllowed
                : 'Enabled',
            ovChipUsageAllowed: value.OVAllowed ? value.OVAllowed : 'Enabled',
            eeprom: value.EEPROM ? value.EEPROM : '',
            logFileDate: value.period ? moment(value.period).format('YYYY-MM-DDTHH:mm:sss') : moment(new Date()).format('YYYY-MM-DDTHH:mm:sss'),
            schedule: false,
            scheduleDisplay: '',
            start: null,
            end: null,
            status: '',
            failedCount: 0,
            runningCount: 0,
            deviceCount: 0,
            succeededCount: 0,
            pendingCount: 0,
            deviceFlag: 0,
            canDisableOVChipCardUsage: false,
            applicationTypes: ['DeviceServiceMonitor'],
        };

        if (this.currentCommand.type === CommandTypes.OVERWRITE_CONFIG_FILE) {
            payload['configurationType'] = value.configurationType;
        } else {
            payload['applicationTypes'] = value.serviceCheckboxs
                ? generateServicesArray(value.serviceCheckboxs)
                : ['DeviceServiceMonitor'];
        }

        if(payload.devices.length >= 20) {
            const dialogConfig: AppDialogConfig = {
                title: 'Warning',
                dialogMessage: `This command will be sent to ${payload.devices.length} validators. Are you sure you wish to continue?`,
                dialogSize: DialogSizes.Medium,
                showDialogButtons: true,
                okText: "Send",
                cancelText: "Cancel",
                panelClass: ['modal-popup'],   
            }

            this.appDialogService.openDialog(dialogConfig).subscribe((res) => {
                if(res) {
                    this.commandsService
                        .createCommand(payload)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.close({ isSuccess: true });
                        });
                }
                else{
                    this.appDialogService.closePopupDialog();
                }
            });
            
        }
        else {
            this.commandsService
                .createCommand(payload)
                .pipe(take(1))
                .subscribe(() => {
                    this.close({ isSuccess: true });
                });
        }
    }

    addServiceBoxesControl(): void {
        this.newCommandForm.removeControl('serviceCheckboxs');
        this.newCommandForm.addControl(
            'serviceCheckboxs',
            this.initServiceGroup()
        );
    }

    close(data?: NewCommandCloseData) {
        this.newCommandForm.reset();
        this.commandTypeToggleChange(false);
        this.newCommandForm.get('description')?.setValue('');
        this.commandTypeChange(CommandTypes.REBOOT);
        this.appDialogService.closeSliderDialog(this.dialogRef, data);
        this.newCommandForm.get('devices')?.setValue([]);
        this.deviceTransferService.clear();
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';
import { MatIconModule } from '@angular/material/icon';
import { ConnectionStateCellRendererComponent } from './connection-state-cell-renderer.component';



@NgModule({
  declarations: [
    ConnectionStateCellRendererComponent
  ],
  exports: [
    ConnectionStateCellRendererComponent
  ],
  imports: [
    CommonModule,
    AgGridModule,
    MatIconModule
  ]
})
export class ConnectionStateCellRendererModule { }

import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component, OnInit } from '@angular/core';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { EditRoleComponent } from '@app/pages/users/create-account/edit-role/edit-role.component';
import { DialogSizes } from '@app/shared/app-dialog/app-dialog.config';
import { UserRoleService } from '@app/services/account/user-role.service';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { AppNotificationService, NotificationType } from '@app/services/notification/notification.service';

@Component({
    selector: 'app-custom-role-cell-renderer',
    templateUrl: './custom-role-cell-renderer.component.html',
    styleUrls: ['./custom-role-cell-renderer.component.scss'],
})
export class CustomRoleCellRendererComponent
    implements ICellRendererAngularComp, OnInit
{
    hasPermissionRoleAccess = false;
    hasPermissionManageUsers = false;
    constructor(
        public appDialogService: AppDialogService,
        public userRoleService: UserRoleService,
        private permissionService: AppPermissionService,
        private notificationService: AppNotificationService
    ) {}
    ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            if(user?.permissions.includes(AppPermissions.FeatureUsersRoleAccess)){
              this.hasPermissionRoleAccess = true;
            }
            if(user?.permissions.includes(AppPermissions.ManageUsers)){
                this.hasPermissionManageUsers = true;
              }
          });
    }
    displayValue: string;
    public agInit(params: ICellRendererParams): void {
        this.displayValue = params.value ?? '-';
    }

    public refresh(): boolean {
        return false;
    }

    openRoleEdit() {
        if (this.displayValue !== '-') {
            this.appDialogService
                .openDialog({
                    title: 'Edit Role',
                    showDialogButtons: false,
                    component: EditRoleComponent,
                    dialogSize: DialogSizes.Small,
                    panelClass: ['side-slider'],
                    data: { roleName: this.displayValue },
                })
                .subscribe((data: any) => {
                    if (data?.action === "SAVE_ROLE") {
                        this.userRoleService
                            .updateRole(data.payload)
                            .subscribe(() => {
                                this.userRoleService.setUserAction("SAVE_ROLE");
                            });
                    }
                    if (data?.action === "DELETE_ROLE") {
                        this.userRoleService
                            .deleteRole(data.payload.roleId)
                            .subscribe(
                                {
                                    next: () => {
                                        this.userRoleService.setUserAction("DELETE_ROLE");
                                        this.notificationService.showSnackBar(
                                          'User Role Deleted!',
                                          NotificationType.Info,
                                          2000
                                      );
                                    },
                                    error: (err: any) => {
                                        console.error('Deleting role failed!', err);
                                        this.userRoleService.setUserAction("DELETE_ROLE_FAILED");
                                        this.notificationService.showSnackBar(
                                          'Deleting role failed!',
                                          NotificationType.Error,
                                          2000
                                      );
                                    },
                                });
                    }
                });
        }
    }
}

import {
    ColDef,
    GridReadyEvent,
    SelectionChangedEvent,
} from '@ag-grid-community/core';
import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { HeartbeatsService } from '@app/pages/heartbeats/heartbeats.service';
import { Device } from '@app/services/devices/devices.service';
import {
    ExportService,
    GridComponent,
    GridService,
    TableField,
} from '@app/shared';
import { TableFieldTypes } from '@app/shared/operators';
import { take } from 'rxjs';
import { DeviceSelectorService } from '../devices-transfer.service';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { ConnectionStateCellRendererComponent } from '@app/shared/grid/cell-renderer/connection-state-cell-renderer/connection-state-cell-renderer.component';
import { CheckBoxCellRendererComponent } from '@app/shared/grid/cell-renderer';

@Component({
    selector: 'app-devices-grid',
    templateUrl: './devices-grid.component.html',
    styleUrls: [
        './devices-grid.component.scss',
        '../../../../shared/grid/grid.component.scss',
    ],
})
export class DevicesGridComponent
    extends GridComponent
    implements OnInit, OnChanges, OnDestroy
{
    @Input() rowData: Array<any> = [];
    selectedDevices: Device[] = [];
    @ViewChild('rowsPerPage2') rowsPerPage2: ElementRef;
    @Output() selectedDevicesChanged: EventEmitter<Device[]> = new EventEmitter<
        Device[]
    >();
    
    constructor(
        public exportService: ExportService,
        private gridService: GridService,
        public heartbeatsService: HeartbeatsService,
        public deviceTransferService: DeviceSelectorService,
        public _companyContextService: CompanyContextService
    ) {
        super();

        this.deviceTransferService.deviceSelectionClear.subscribe((res) => {
            this._grid.api.deselectAll();
        });
        this.setColumnDefs();
    }

    public ngOnInit(): void {
        this.companyContextSubscription = this._companyContextService
            .getCompanyContext()
            .subscribe(() => this.refreshData());
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['rowData']) {
            this._initRowsPerPageDevicesGrid();
            this._initColumnRowGroup(true);
        }
    }

    public ngOnDestroy(): void {
        this.companyContextSubscription.unsubscribe();
    }

    setColumnDefs(): void {
        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next: (tableFieldsMap: Map<string, TableField>) => {
                    this.columnDefs = [
                        {
                            field: '',
                            width: 90,
                            sortable: true,
                            enableRowGroup: true,
                            headerCheckboxSelection: true,
                            headerCheckboxSelectionFilteredOnly: true,
                            filter: 'agTextColumnFilter',
                            filterParams: {filterOptions : this.gridService.createFilterOptions(TableFieldTypes.STRING)},
                            cellRenderer: CheckBoxCellRendererComponent,
                            comparator: function (valueA, valueB, nodeA, nodeB) {
                                const isSelectedA = nodeA.isSelected() ? 1 : 0;
                                const isSelectedB = nodeB.isSelected() ? 1 : 0;
                                return isSelectedB - isSelectedA;
                            }
                        } as ColDef,
                        {
                            field: 'vehicleId',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('vehicleId')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('vehicleId')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Vehicle',
                        } as ColDef,
                        {
                            field: 'validatorPositionNo',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('validatorPositionNo')
                                    ?.dataType ?? TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('validatorPositionNo')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Position',
                        } as ColDef,
                        {
                            field: 'deviceSerialNo',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('deviceSerialNo')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('deviceSerialNo')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Validator Serial Number',
                        } as ColDef,
                        {
                            field: 'pto',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('pto')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('pto')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'PTO',
                        } as ColDef,
                        {
                            field: 'connectionState',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('connectionState')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('connectionState')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Connection State',
                            cellRenderer: ConnectionStateCellRendererComponent
                        } as ColDef,
                    ];
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: "Group rows by column:",
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true, 
                            cellRenderer: 'agGroupCellRenderer'
                        } as ColDef,
                        ...this.columnDefs
                    ];
                }
            });
    }

    public _initRowsPerPageDevicesGrid(): void {
        // Injections to Rows Per Page Component into AG Grid Paging Panel
        const pagingPanel = document.querySelector(
            'app-devices-grid .ag-paging-panel'
        );
        pagingPanel?.appendChild(this.rowsPerPage2.nativeElement);
    }

    handleSelectionChange(event: SelectionChangedEvent<Device>) {
        this.selectedDevices = event.api.getSelectedRows();
        this.selectedDevicesChanged.emit(this.selectedDevices);
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;
        this._initDomLayout();
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        this.exportService.exportToExcel(
            this.rowData,
            1000000,
            'exported_data.xlsx',
            'Chunk_'
        );

        this.progressValueExport = 0;
        this.isAvailableToExport = true;
    }
}

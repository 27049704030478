import { NgModule } from '@angular/core';
import { MatSliderModule } from '@angular/material/slider';
import { FromSliderComponent } from './from-slider.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [FromSliderComponent],
    imports: [CommonModule, MatSliderModule, ReactiveFormsModule],
    exports: [FromSliderComponent],
})
export class FormSliderModule {}

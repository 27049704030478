<div class="command-details__section">
    <h1 class="command-details__heading">Results</h1>
    <div class="command-details__result">
        <div class="command-details__result-field active">
            <div class="command-details__result-field-value">
                {{ selectedCommand?.devices?.length }}
            </div>
            <div class="command-details__result-field-name">Sent</div>
        </div>
        <div class="command-details__result-field">
            <div class="command-details__result-field-value">
                {{ selectedCommand?.pendingCount }}
            </div>
            <div class="command-details__result-field-name">Pending</div>
        </div>
        <div class="command-details__result-field">
            <div class="command-details__result-field-value">
                {{ selectedCommand?.runningCount }}
            </div>
            <div class="command-details__result-field-name">Running</div>
        </div>
        <div class="command-details__result-field">
            <div class="command-details__result-field-value">
                {{ selectedCommand?.receivedCount }}
            </div>
            <div class="command-details__result-field-name">Received</div>
        </div>
        <div class="command-details__result-field">
            <div class="command-details__result-field-value">
                {{ selectedCommand?.notReceivedCount }}
            </div>
            <div class="command-details__result-field-name">Not Received</div>
        </div>
        <div class="command-details__result-field">
            <div class="command-details__result-field-value">
                {{ selectedCommand?.succeededCount }}
            </div>
            <div class="command-details__result-field-name">Succeeded</div>
        </div>
        <div class="command-details__result-field">
            <div class="command-details__result-field-value">
                {{ selectedCommand?.failedCount }}
            </div>
            <div class="command-details__result-field-name">Failed</div>
        </div>
    </div>
</div>
<div class="command-details__section">
    <mat-accordion>
        <mat-expansion-panel [expanded]="isInfoOpened"
            (opened)="isInfoOpened = true"
            (closed)="isInfoOpened = false"
        >
            <mat-expansion-panel-header>
                <mat-panel-title> Info </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="command-details__info-row">
                <div class="command-details__info-field">
                    <div class="command-details__info-field-name">Command</div>
                    <div class="command-details__info-field-value">
                        {{
                            getCommandTypeDisplayText(selectedCommand?.commandName)
                        }}
                    </div>
                </div>
                <div class="command-details__info-field">
                    <div class="command-details__info-field-name">Service</div>
                    <div class="command-details__info-field-value">
                        {{
                            getServiceDisplayText(
                                selectedCommand?.applicationTypes
                            )
                        }}
                    </div>
                </div>
                <div class="command-details__info-field">
                    <div class="command-details__info-field-name">Type</div>
                    <div class="command-details__info-field-value">
                        {{ getJobTypeText(selectedCommand?.commandType) }}
                    </div>
                </div>
                <div
                    class="command-details__info-field"
                    *ngIf="selectedCommand?.expires"
                >
                    <div class="command-details__info-field-name">
                        Expiration
                    </div>
                    <div class="command-details__info-field-value">
                        {{ selectedCommand?.expiresOn | date : "short" }}
                    </div>
                </div>
                <div class="command-details__info-field">
                    <div class="command-details__info-field-name">Started</div>
                    <div class="command-details__info-field-value">
                        {{ selectedCommand?.start | date : "short" }}
                    </div>
                </div>
                <div class="command-details__info-field">
                    <div class="command-details__info-field-name">Ended</div>
                    <div class="command-details__info-field-value">
                        {{ selectedCommand?.end | date : "short" }}
                    </div>
                </div>
            </div>
            <div class="command-details__info-row">
                <div class="command-details__info-field">
                    <div class="command-details__info-field-name">
                        Description
                    </div>
                    <div class="command-details__info-field-value">
                        {{ selectedCommand?.description }}
                    </div>
                </div>
            </div>
            <div class="command-details__info-row">
                <div class="command-details__info-field">
                    <div class="command-details__info-field-name">Job Id</div>
                    <div class="command-details__info-field-value">
                        {{ selectedCommand?.jobIds }}
                    </div>
                </div>
            </div>
            <mat-accordion class="payload">
                <mat-expansion-panel
                    (opened)="isPayloadOpened = true"
                    (closed)="isPayloadOpened = false"
                >
                    <mat-expansion-panel-header>
                        <mat-panel-title class="payload-title">
                            Payload
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="command-details__info-row">
                        <div class="command-details__info-field-value">
                            {{ selectedCommand?.payloads }}
                        </div>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<mat-accordion class="payload">
    <mat-expansion-panel [expanded]="isDevicesOpened"
        (opened)="isDevicesOpened = true"
        (closed)="isDevicesOpened = false"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>
                Devices ({{ selectedCommand?.deviceCount }})
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <app-command-details-tools-panel
                [isAvailableToExport]="isAvailableToExport"
                [progressValueExport]="progressValueExport"
                [isOpenFilters]="isOpenFilters"
                [isOpenColumns]="isOpenColumns"
                (exportToExcel)="exportToExcel()"
                (resetAll)="resetAll()"
                (clearSorting)="clearSorting()"
                (clearFiltering)="clearFiltering()"
                (clearRowGrouping)="clearRowGrouping()"
                (resetColumnOrder)="resetColumnOrder()"
                (resetColumnVisibility)="resetColumnVisibility()"
                (openFilters)="openFilters()"
                (openColumns)="openColumns()"
                (downloadAllLogs)="handleDownloadAllLogs()"
                [isDownloadAllEnabled]="isDownloadAllEnabled"
            >
            </app-command-details-tools-panel>

            <ag-grid-angular
                class="ag-theme-alpine"
                [ngClass]="{'auto-height': isAutoHeight}"
                [modules]="modules"
                [columnDefs]="columnDefs"
                [sideBar]="sideBar"
                [defaultColDef]="defaultColDef"
                [autoGroupColumnDef]="autoGroupColumnDef"
                [animateRows]="true"
                [suppressDragLeaveHidesColumns]="false"
                [suppressMakeColumnVisibleAfterUnGroup]="true"
                [suppressRowGroupHidesColumns]="true"
                [rowGroupPanelShow]="rowGroupPanelShow"
                [rowModelType]="'clientSide'"
                [domLayout]="'autoHeight'"
                [rowData]="rowData"
                [groupDisplayType]="groupDisplayType"
                [pagination]="pagination"
                [paginationPageSize]="paginationPageSize"
                [maintainColumnOrder]="true"
                [components]="components"
                [getLocaleText]="getLocaleText"
                [enableCellTextSelection]="enableCellTextSelection"
                [ensureDomOrder]="ensureDomOrder"
                (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>

            <div #rowsPerPage2>
                <app-rows-per-page (pageSize)="onPageSizeChanged($event)">
                </app-rows-per-page>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
<button class="float-right" mat-flat-button color="primary" (click)="close()">
    Close
</button>

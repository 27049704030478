import { Component, EventEmitter, Output } from '@angular/core';

interface FileData {
    name: string;
    type: string;
    size: number;
}

@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.scss'],
})
export class UploadFilesComponent {
    @Output() filesSelectionChange: EventEmitter<FileList> =
        new EventEmitter<FileList>();
    @Output() fileRemoved: EventEmitter<string> = new EventEmitter<string>();

    currentSelection: FileList | null;
    selectionData: FileData[] = [];

    onFileSelected(event: any): void {
        if (!event.target) return;
        this.selectionData = [];
        this.currentSelection = event.target.files;

        if (this.currentSelection) {
            for (let i = 0; i < this.currentSelection.length; i++)
                this.selectionData.push({
                    name: this.currentSelection[i].name,
                    size: this.currentSelection[i].size,
                    type: this.currentSelection[i].type.split('/')[1],
                });
        }
        this.filesSelectionChange.emit(event.target.files);
    }

    removeFile(file: FileData): void {
        this.fileRemoved.emit(file.name);

        this.selectionData = this.selectionData.filter(
            (existingFile: FileData) => {
                return existingFile.name !== file.name;
            }
        );
    }
}

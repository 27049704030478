import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { IChannelUpdate } from '@app/interfaces/channel-update.interface';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from 'environments/environment.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: Socket;

  constructor(private http: HttpClient, private envService: EnvironmentService) {
    this.socket = io(this.envService.liveUpdatesUrl);
  }

  connect() {
    if (!this.socket) {
      this.socket = io(this.envService.liveUpdatesUrl);
    }
  }

  disconnect() {
    if (this.socket) {
      this.socket?.disconnect();
    }
  }

  isConnected(): boolean {
    return this.socket && this.socket?.connected;
  }

  subscribeToChannel(channel: string) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const reqData : Object = { "channelName": channel };
    this.socket?.emit('subscribe', reqData);
  }

  unsubscribeFromChannel(channel: string) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const reqData : Object = { "channelName": channel };
    this.socket?.emit('unsubscribe', reqData);
  }

  publishToChannel(channel: string, message: string) {
    // eslint-disable-next-line @typescript-eslint/ban-types
    const reqData : Object = { "channelName": channel, "message" : message };
    return this.http.post(`${this.envService.liveUpdatesUrl}/publish`, reqData);
  }

  onMessage(): Observable<IChannelUpdate> {
    return new Observable((observer) => {
      this.socket.on('channelUpdate', (data) => {
        observer.next(data);
      });
    });
  }
}

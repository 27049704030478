import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ExportService,
    GridComponent,
    GridService,
    KeySettings,
    PERCENTAGE,
    PageSettingsCache,
    SettingsCacheService,
    TableField,
} from '@app/shared';
import {
    CommandsColumnSettings,
    CommandsData,
    CommandsDataEnum,
    ICommandData,
} from './commands.interface';
import { CommandsService } from './commands.service';
import { Observable, Subject, filter, map, take, takeUntil } from 'rxjs';
import { commandsSettings } from './commands.settings';
import {
    ColDef,
    Column,
    ColumnState,
    ColumnVO,
    GridReadyEvent,
    RowClickedEvent,
} from '@ag-grid-community/core';
import { TableFieldTypes } from '@app/shared/operators';
import { HeartbeatsService } from '../heartbeats/heartbeats.service';
import { PreferencesService } from '@app/shared/preferences/preferences.service';
import { NavigationStart, Router } from '@angular/router';
import { Device } from '@app/services/devices/devices.service';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { NewCommandCloseData, NewCommandComponent } from './new-command/new-command.component';
import { DialogSizes } from "@app/shared/app-dialog/app-dialog.config";
import { AppDialogService } from "@app/shared/app-dialog/app-dialog.service";
import { CommandDetailsComponent } from "@app/pages/commands/command-details/command-details.component";
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { Modality } from '@app/models/modality.enum';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { WebSocketService } from '@app/services/websocket/websocket.service';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { EnvironmentService } from 'environments/environment.service';

@Component({
    selector: 'app-commands',
    templateUrl: './commands.component.html',
    styleUrls: ['../../shared/grid/grid.component.scss'],
})
export class CommandsComponent
    extends GridComponent
    implements OnInit, OnDestroy {
    private isGridDataLoaded = false;
    public rowData!: Array<CommandsData>;
    selectedDevices: Device[] = [];
    commandsSettings = commandsSettings;
    destroy$: Subject<void> = new Subject();
    channelName: string;
    liveUpdatesOn: boolean;

    constructor(
        private heartbeatsService: HeartbeatsService,
        private commandsService: CommandsService,
        private exportService: ExportService,
        public override settingsCacheService: SettingsCacheService,
        private gridService: GridService,
        private prefService: PreferencesService,
        private router: Router,
        public appDialogService: AppDialogService,
        public _companyContextService: CompanyContextService,
        private permissionService: AppPermissionService,
        private wsService: WebSocketService,
        private env: EnvironmentService
    ) {
        super();
        LicenseManager.setLicenseKey(this.env.agGridLicenseKey);
    }

    public ngOnInit(): void {
        this.prefService.enableSavingGridPreferences(KeySettings.CommandsGrid);
        this.router.events
            .pipe(takeUntil(this.destroy$), filter((event) => event instanceof NavigationStart))
            .subscribe(() => {
                const colState = this._grid?.columnApi?.getColumnState();
                this.settingsCacheService.saveDataToLocalStorage(
                    KeySettings.CommandsGrid,
                    colState
                );
            });

        this.permissionService.user$.subscribe((user) => {
            const userPermissions = user?.permissions;
            if (userPermissions.includes(AppPermissions.FeatureCommandsPTOViewsAll)) {
                this.modality = Modality.All;
            } else {
                this.modality = Modality.None;
            }
            if(userPermissions.includes(AppPermissions.FeatureLiveUpdates)){
                this.liveUpdatesOn = true;
            }
            else{
                this.liveUpdatesOn = false;
            }
        });

        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next: (tableFieldsMap: Map<string, TableField>) => {
                    const settings = this.settingsCacheService.commands;

                    for (const columnSettings of settings.columnSettingsList) {
                        const rowGroupIndex =
                            settings.serverSideGetRowsRequest.rowGroupCols.findIndex(
                                (column: ColumnVO) =>
                                    column.id === columnSettings.key
                            );
                        const colSettings =
                            commandsSettings[
                            CommandsDataEnum[
                            columnSettings.key as keyof typeof CommandsDataEnum
                            ]
                            ];
                        const dataFieldName = colSettings?.dataFieldName;

                        this.columnDefs.push({
                            field: columnSettings.key,
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get(colSettings?.dataFieldName)
                                    ?.dataType ?? (colSettings?.cellRendererComponent ? TableFieldTypes.DATE_TIME : TableFieldTypes.STRING)
                            ),
                            filterParams: {
                                filterOptions: this.gridService.createFilterOptions(
                                    tableFieldsMap.get(colSettings?.dataFieldName)
                                        ?.dataType ?? (colSettings?.cellRendererComponent ? TableFieldTypes.DATE_TIME : TableFieldTypes.STRING)
                                ),
                                comparator: colSettings.cellRendererComponent ? (filter: Date, cellValue: string) => {
                                    if (cellValue == null) return 0;
                                    const cellValueAsDate = new Date(cellValue);
                                    if (cellValueAsDate > filter) {
                                        return 1
                                    } else if (cellValueAsDate < filter) {
                                        return -1
                                    }
                                    return 0;
                                } : null
                            },
                            sortable: true,
                            enableRowGroup: true,
                            rowGroup: rowGroupIndex > -1,
                            rowGroupIndex:
                                rowGroupIndex > -1 ? rowGroupIndex : null,
                            sort:
                                settings.serverSideGetRowsRequest.sortModel[0]
                                    ?.colId === columnSettings.key
                                    ? settings.serverSideGetRowsRequest.sortModel[0]
                                        ?.sort
                                    : null,
                            hide: !columnSettings.visible,
                            headerName:
                                tableFieldsMap.get(dataFieldName)?.displayName ??
                                colSettings.label,
                            cellRenderer: colSettings.cellRendererComponent,
                        } as ColDef);
                    }
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: "Group rows by column:",
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer'
                        } as ColDef,
                        ...this.columnDefs
                    ];
                }
            });
        if(!this.wsService.isConnected() && this.liveUpdatesOn){
            this.wsService.connect();
        }
    }

    public ngOnDestroy(): void {
        this.companyContextSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
        if(this.wsService.isConnected()){
            this.wsService?.unsubscribeFromChannel(this.channelName);
        }
    }

    public refreshDataByContext(): void {
        this._grid.api.showLoadingOverlay();
        this.isGridDataLoaded = false;
        const jobsData$ = this.commandsService.jobs(this.modality).pipe(
            map((data: any) => {
                data.rowData.map((rowData: CommandsData) => {
                    if (rowData.commandType === "Direct") {
                        rowData.commandType = "One Time"
                    }
                    else if (rowData.commandType === "Indirect") {
                        rowData.commandType = "Recurring"
                    }
                })
                return data.rowData.filter((x: CommandsData) => x.jobIds != null);
            })
        );
        jobsData$.subscribe(data$ => {
            this.rowData = data$;
            this.isGridDataLoaded = true;
            this._grid.api?.hideOverlay();
            this.lastUpdated = new Date();
        });
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;
        this.companyContextSubscription = this._companyContextService
            .getCompanyContext()
            .subscribe((ctx: string) => {
                if(!ctx) return;
                this.refreshDataByContext();
                const tempChannelName = `Commands_${ctx}`;
                if(this.channelName && this.channelName !== tempChannelName && this.wsService.isConnected()){
                    this.wsService.unsubscribeFromChannel(this.channelName);
                }
                if(this.wsService.isConnected() && this.liveUpdatesOn){
                    this.channelName = tempChannelName;
                    this.wsService.subscribeToChannel(this.channelName);
                    this.wsService.onMessage().subscribe((data) => {
                        if (data.channelName === this.channelName) {
                            const dataUpdate = JSON.parse(data.response) as CommandsData;
                            const index = this.rowData?.findIndex((p) => p.id === dataUpdate.id);
                            if (index > -1) {
                                this.rowData[index] = dataUpdate;
                                this._grid.api.setRowData(this.rowData);
                            }
                        }
                    });
                }
            });

        this._grid.api.addEventListener('columnVisible', (event: any) => {
            this.settingsCacheService.commands = {
                ...this.settingsCacheService.commands,
                columnSettingsList:
                    this.settingsCacheService.commands.columnSettingsList.map(
                        (
                            column: CommandsColumnSettings
                        ): CommandsColumnSettings => {
                            if (
                                event.column === null ||
                                column.key === event.column.colId
                            ) {
                                return {
                                    ...column,
                                    visible: event.visible,
                                };
                            }

                            return column;
                        }
                    ),
            } as PageSettingsCache<CommandsColumnSettings>;
        });

        const filterModel =
            this.settingsCacheService.commands.serverSideGetRowsRequest
                .filterModel;

        if (filterModel) {
            for (const [key, value] of Object.entries(filterModel)) {
                this._grid.api.getFilterInstance(key)?.setModel(value);
            }
        }

        this._initRowsPerPage();
        this._initColumnRowGroup(true);
        this._initDomLayout();

        const keySetting = KeySettings.CommandsGrid;
        const gridPreferencesLocal =
            this.settingsCacheService.getDataFromLocalStorage(
                keySetting
            );
        const gridPreferencesDB = this.settingsCacheService.getDataFromLocalStorage(
            KeySettings.Preferences
        )['preferences'][keySetting];

        const gridPreferencesDefault = this.settingsCacheService.getDataFromLocalStorage(
            KeySettings.DefaultPreferences
        )['preferences'][keySetting];

        if (gridPreferencesLocal) {
            this._grid.columnApi.applyColumnState({ state: gridPreferencesLocal, applyOrder: true, });
        }
        else if (gridPreferencesDB) {
            this._grid.columnApi.applyColumnState({ state: gridPreferencesDB, applyOrder: true, });
        }
        else if (gridPreferencesDefault) {
            this._grid.columnApi.applyColumnState({ state: gridPreferencesDefault, applyOrder: true });
        }

        const pageSize = this.settingsCacheService.getGridPageSizes?.find(x => x.gridName === KeySettings.CommandsGrid);
        if (pageSize) {
            this.paginationPageSize = pageSize.pageSize;
            this.cacheBlockSize = pageSize.pageSize;
            this._grid.api.paginationSetPageSize(pageSize.pageSize);
        }
    }

    public getData(): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this.commandsService.jobs(this.modality).pipe(take(1));
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        const chunkSize = 1000000;
        const endRow = chunkSize;
        const rowData: Array<CommandsData> = [];

        this.getData().subscribe(
            (data: { rowCount: number; rowData: Array<CommandsData> }) =>
                this.updateProgressBar(
                    endRow + chunkSize,
                    rowData,
                    chunkSize,
                    data
                )
        );
    }

    public updateProgressBar(
        endRow: number,
        rowData: Array<CommandsData>,
        chunkSize: number,
        data: { rowCount: number; rowData: Array<any> }
    ): void {
        rowData = [...rowData, ...data.rowData];

        this.progressValueExport = Math.round(
            PERCENTAGE(rowData.length, data.rowCount)
        );

        if (rowData.length < data.rowCount) {
            this.getData().subscribe(
                (data: { rowCount: number; rowData: Array<CommandsData> }) =>
                    this.updateProgressBar(
                        endRow + chunkSize,
                        rowData,
                        chunkSize,
                        data
                    )
            );
        } else {
            if (this.isGridDataLoaded) {
                const gridRowsData: any[] = this._getGridRowsData();
                rowData = gridRowsData
                    .map((item: any) => {
                        return rowData.find((row: any) => {
                            return item.id === row.id;
                        });
                    })
                    .filter(row => !!row) as CommandsData[];
            }
            const columnsOrder = this._getColumnsOrderForExport(commandsSettings, CommandsDataEnum);
            const result = this._prepareDataForExport(rowData, commandsSettings, CommandsDataEnum);
            if (result.length) {
                this.exportService.exportToExcel(
                    result,
                    chunkSize,
                    'exported_data.xlsx',
                    'Chunk_',
                    columnsOrder
                );
            }
            this.progressValueExport = 0;
            this.isAvailableToExport = true;
        }
    }

    public clearFilters(): void {
        const filterModel =
            this.settingsCacheService.commands.serverSideGetRowsRequest
                .filterModel;
        Object.keys(filterModel)?.forEach((key: string | Column) =>
            this._grid.api.destroyFilter(key)
        );
    }

    public openCommandDetails(event: RowClickedEvent<ICommandData>) {
        this.appDialogService
            .openDialog({
                title: 'Command Details',
                showDialogButtons: false,
                component: CommandDetailsComponent,
                dialogSize: DialogSizes.XLarge,
                panelClass: ['side-slider'],
                data: {
                    command: event.data
                },
            });
    }

    openSendCommand() {
        this.appDialogService
            .openDialog({
                title: 'Send Command',
                showDialogButtons: false,
                component: NewCommandComponent,
                dialogSize: DialogSizes.Medium,
                panelClass: ['side-slider'],
                data: {},
            })
            .subscribe((data: NewCommandCloseData) => {
                if (data && data.isSuccess) {
                    this.refreshDataByContext();
                }
            });
    }

    public getDefaultPreferencesForReset(): ColumnState[] | null {
        const keySetting = KeySettings.CommandsGrid;
        const gridPreferencesDefault = this.settingsCacheService.getDataFromLocalStorage(
            KeySettings.DefaultPreferences
        )['preferences'][keySetting];
        if (gridPreferencesDefault) {
            return gridPreferencesDefault as ColumnState[];
        }
        return null
    }

    public override onPageSizeChanged(value: number): void {
        this.paginationPageSize = value;
        this.cacheBlockSize = value;
        const currentPageSizes = this.settingsCacheService.getGridPageSizes;
        if (currentPageSizes) {
            //check if the gridName already exists in the array and if so update the pageSize
            const index = currentPageSizes.findIndex(x => x.gridName === KeySettings.CommandsGrid);
            if (index !== -1) {
                currentPageSizes[index].pageSize = value;
            } else {
                currentPageSizes.push({ gridName: KeySettings.CommandsGrid, pageSize: value });
            }
            this.settingsCacheService.getGridPageSizes = currentPageSizes;
        } else {
            this.settingsCacheService.getGridPageSizes = [{ gridName: KeySettings.CommandsGrid, pageSize: value }];
        }
        this._grid.api.paginationSetPageSize(value);
    }
}

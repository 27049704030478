import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, Subscription, interval, takeUntil } from 'rxjs';
import { AppAuthorizationService } from '@app/services/authorization/authorization.service';
import {
    AppNotificationService,
    NotificationType,
} from './services/notification/notification.service';
import { UserInfoService } from '@app/services/user-info/user-info.service';
import { PreferencesService } from '@app/shared/preferences/preferences.service';

import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { IconTypeEnum } from './pages/users';
import { AppRestService, KeySettings, SettingsCacheService } from './shared';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    private PREFERENCES_UPDATE_PERIOD = 15 * 60 * 1000; //every 15 min for now - change later
    dataLoaded$ = new Subject<boolean>();
    private authSubscription: Subscription;
    private userSubscription: Subscription;
    private intervalSubscription: Subscription;
    private intervalUnsubscribe = new Subject<void>();
    private appRestSubscription: Subscription;
    constructor(
        private userInfoService: UserInfoService,
        private authService: AppAuthorizationService,
        private notificationService: AppNotificationService,
        private prefService: PreferencesService,
        private matIconRegistry: MatIconRegistry,
        private domSanitizer: DomSanitizer,
        private appRestService: AppRestService,
        private settingsCacheService: SettingsCacheService
    ) {
        this.matIconRegistry
            .addSvgIcon(
                'menu-view',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/column-menu-view.svg'
                )
            )
            .addSvgIcon(
                'menu-filter',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/column-menu-filter.svg'
                )
            )
            .addSvgIcon(
                'menu-group',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/column-menu-group.svg'
                )
            )
            .addSvgIcon(
                'refresh',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/refresh.svg'
                )
            )
            .addSvgIcon(
                'export',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/export.svg'
                )
            )
            .addSvgIcon(
                'columns',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/columns.svg'
                )
            )
            .addSvgIcon(
                'filters',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/filters.svg'
                )
            )
            .addSvgIcon(
                'clear-filters',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/clear-filters.svg'
                )
            )
            .addSvgIcon(
                'clock',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/clock.svg'
                )
            )
            .addSvgIcon(
                'calendar',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/calendar.svg'
                )
            )
            .addSvgIcon(
                'file',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/file.svg'
                )
            )
            .addSvgIcon(
                'download',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/download.svg'
                )
            )
            .addSvgIcon(
                'edit',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/edit.svg'
                )
            )
            .addSvgIcon(
                'view',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/view.svg'
                )
            )
            .addSvgIcon(
                'restricted',
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    'assets/icons/restricted.svg'
                )
            );

        Object.keys(IconTypeEnum).forEach((key: string) => {
            const icon = IconTypeEnum[key as keyof typeof IconTypeEnum];

            this.matIconRegistry.addSvgIcon(
                icon,
                this.domSanitizer.bypassSecurityTrustResourceUrl(
                    `assets/icons/${icon}.svg`
                )
            );
        });
    }

    ngOnInit(): void {
        //Check user's logged in state, Initiate login if not logged in. If logged in, fetch user details.
        this.authSubscription = this.authService
            .isLoggedIn()
            .subscribe((loggedIn) => {
                if (!loggedIn) {
                    this.authService.initiateLogin();
                } else {
                    this.userInfoService.getUserData().subscribe();
                }
            });
        this.userSubscription = this.userInfoService
            .isUserDataLoaded()
            .subscribe((val) => {
                if (val) {
                    this.dataLoaded$.next(true);
                    this.notificationService.showSnackBar(
                        'User data loaded!',
                        NotificationType.Info,
                        3000
                    );
                }
            });

        this.appRestSubscription = this.appRestService
            .get('heartbeat-metadata/pulse')
            .subscribe((data) => {
                this.settingsCacheService.saveDataToLocalStorage(
                    KeySettings.TableFields,
                    data
                );
            });


        const sixHourInterval = interval(this.PREFERENCES_UPDATE_PERIOD);

        this.intervalSubscription = sixHourInterval
            .pipe(takeUntil(this.intervalUnsubscribe))
            .subscribe(() => {
                this.prefService.updateAllPreferences();
            });
    }
    ngOnDestroy(): void {
        this.authSubscription.unsubscribe();
        this.userSubscription.unsubscribe();
        this.appRestSubscription.unsubscribe();
        if (this.intervalSubscription) {
            this.intervalSubscription.unsubscribe();
        }
        this.intervalUnsubscribe.next();
        this.intervalUnsubscribe.complete();
    }
}

<div [formGroup]="newCommandForm">
    <div class="new-command__section">
        <h1 class="new-command__heading">Command</h1>

        <mat-form-field appearance="outline" class="full-width">
            <mat-select
                formControlName="command"
                (selectionChange)="commandTypeChange($event.value)"
            >
                <mat-option
                    *ngFor="let command of commandTypeValues"
                    [value]="command.type"
                >
                    {{ command.value }}</mat-option
                >
            </mat-select>
        </mat-form-field>
        <div class="new-command__note-text new-command_margin--bottom-sm">
            <div
                *ngIf="currentCommand"
                class="new-command-note"
                [innerHTML]="currentCommand.note"
            ></div>
        </div>
    </div>
    <!-- ------------------------------------------------------------------- -->
    <div [ngSwitch]="currentCommand.type">
        <div *ngSwitchCase="commandTypes.RESTART_SERVICE">
            <app-service-checkboxs
                class="new-command__section"
                [serviceCheckboxsForm]="serviceCheckboxs"
            ></app-service-checkboxs>
        </div>

        <div *ngSwitchCase="commandTypes.RETRIEVE_LOG_FILE" class="new-command__section">
            <app-service-checkboxs
                class="new-command__section"
                [serviceCheckboxsForm]="serviceCheckboxs"
            ></app-service-checkboxs>

            <h1 class="new-command__heading">Date</h1>
            <mat-form-field appearance="outline" class="full-width">
                <mat-icon
                    matPrefix
                    svgIcon="calendar"
                    (click)="period.open()"
                ></mat-icon>
                <input
                    matInput
                    formControlName="period"
                    [matDatepicker]="period"
                />
                <mat-datepicker #period></mat-datepicker>
            </mat-form-field>
        </div>

        <div *ngSwitchCase="commandTypes.RETRIEVE_CONFIG_FILE">
            <app-service-checkboxs
                class="new-command__section"
                [serviceCheckboxsForm]="serviceCheckboxs"
            ></app-service-checkboxs>
        </div>
        <div *ngSwitchCase="commandTypes.OVERWRITE_CONFIG_FILE">
            <h1 class="new-command__heading">Configuration Type</h1>
            <mat-radio-group
                class="config-radio new-command__section"
                formControlName="configurationType"
            >
                <div
                    class="config-radio__radio-wrapper"
                    *ngFor="let option of servicesRadio"
                >
                    <mat-radio-button [value]="option.value">{{
                        option.label
                    }}</mat-radio-button>
                </div>
            </mat-radio-group>
            <h1 class="new-command__heading">Upload files</h1>

            <app-upload-files
                class="new-command__section"
                (filesSelectionChange)="onFileSelected($event)"
                (fileRemoved)="onFileRemoved($event)"
            ></app-upload-files>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_SOUND"
        >
            <h1 class="new-command__heading">Sound</h1>
            <mat-radio-group
                formControlName="sound"
                class="new-command__section"
            >
                <mat-radio-button
                    *ngFor="let soundOption of soundOptions"
                    [value]="soundOption.value"
                    >{{ soundOption.label }}</mat-radio-button
                >
            </mat-radio-group>
            <h1 class="new-command__heading">Upload files</h1>

            <app-upload-files
                class="new-command__section"
                (filesSelectionChange)="onFileSelected($event)"
                (fileRemoved)="onFileRemoved($event)"
            ></app-upload-files>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_EVENT_LOG_LEVEL"
        >
            <app-service-checkboxs
                class="new-command__section"
                [isShorten]="true"
                [serviceCheckboxsForm]="serviceCheckboxs"
            ></app-service-checkboxs>

            <h1 class="new-command__heading">Log Level</h1>
            <mat-radio-group formControlName="logLevel">
                <mat-radio-button
                    *ngFor="let logLevel of logLevelOptions"
                    [value]="logLevel.value"
                    >{{ logLevel.label }}</mat-radio-button
                >
            </mat-radio-group>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_VOLUME"
        >
            <h1 class="new-command__heading">Volume</h1>
            <app-form-slider
                [max]="100"
                [min]="0"
                [step]="10"
                [control]="volume"
            ></app-form-slider>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_TRANSACTION_DATA_RETENTION_PERIOD"
        >
            <h1 class="new-command__heading">
                Transaction Data Retention Period (In Days)
            </h1>
            <app-form-slider
                [max]="7"
                [min]="0"
                [step]="1"
                [control]="transactionPeriod"
            ></app-form-slider>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_MAX_TRANSACTION_DATA_SEND_TIME"
        >   
            <div
                class="new-command__section"
            >
                <h1 class="new-command__heading">Maximum transaction check time</h1>
                <app-form-slider
                    [max]="1440"
                    [min]="0"
                    [step]="1"
                    [control]="maxTransactionCheckTime"
                    [showTicks]="false"
                ></app-form-slider>
            </div>

            <h1 class="new-command__heading">Maximum audit check time</h1>
            <app-form-slider
                [max]="1440"
                [min]="0"
                [step]="1"
                [control]="maxAuditCheckTime"
                [showTicks]="false"
            ></app-form-slider>
        </div>

        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_MAX_NUMBER_TRANSACTION_DATA_RECORDS"
        >
            <h1 class="new-command__heading">
                Max Number of Transaction Data Records
            </h1>
            <app-form-slider
                [max]="100000"
                [min]="0"
                [step]="500"
                [showTicks]="false"
                [control]="maxNumberOfRecords"
            ></app-form-slider>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_NEW_SW_CHECK_TIME"
        >
            <h1 class="new-command__heading">Check Time in Minutes</h1>
            <app-form-slider
                [max]="1440"
                [min]="0"
                [step]="1"
                [showTicks]="false"
                [control]="SWcheckTimeInMinutes"
            ></app-form-slider>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="
                commandTypes.SET_NEW_OV_CHIPCARD_BLACKLIST_CHECK_TIME
            "
        >
            <h1 class="new-command__heading">Check Time in Minutes</h1>
            <app-form-slider
                [max]="1440"
                [min]="0"
                [step]="1"
                [showTicks]="false"
                [control]="OVcheckTimeInMinutes"
            ></app-form-slider>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_NEW_EOD_CHECK_TIME"
        >
            <h1 class="new-command__heading">Check Time in Minutes</h1>
            <app-form-slider
                [max]="1440"
                [min]="0"
                [step]="1"
                [showTicks]="false"
                [control]="EODcheckTimeInMinutes"
            ></app-form-slider>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_NEW_EMV_BLACKLIST_CHECK_TIME"
        >
            <h1 class="new-command__heading">Check Time in Minutes</h1>
            <app-form-slider
                [max]="1440"
                [min]="0"
                [step]="1"
                [showTicks]="false"
                [control]="EMVcheckTimeInMinutes"
            ></app-form-slider>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_DISPLAY_BRIGHTNESS"
        >
            <div class="new-command__section">
                <h1 class="new-command__heading">Brightness</h1>
                <mat-radio-group formControlName="brightness">
                    <mat-radio-button
                        *ngFor="let brightnessOption of brightnessOptions"
                        [value]="brightnessOption.value"
                        >{{ brightnessOption.label }}</mat-radio-button
                    >
                </mat-radio-group>
            </div>
            <div *ngIf="newCommandForm.get('brightness')?.value === 'Static'">
                <h1 class="new-command__heading">Brightness (%)</h1>
                <app-form-slider
                    [max]="100"
                    [min]="0"
                    [step]="10"
                    [control]="brightnessSlide"
                ></app-form-slider>
            </div>
        </div>

        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_FARE_MEDIA_USAGE_ALLOWED"
        >
            <h1 class="new-command__heading">OV Chip Card Usage Allowed</h1>
            <mat-radio-group
                class="new-command__section"
                formControlName="OVAllowed"
            >
                <mat-radio-button
                    *ngFor="let ed of edOptions"
                    [value]="ed.value"
                    >{{ ed.label }}</mat-radio-button
                >
            </mat-radio-group>
            <h1 class="new-command__heading">Bar Code Usage Allowed</h1>
            <mat-radio-group
                class="new-command__section"
                formControlName="BarCodeAllowed"
            >
                <mat-radio-button
                    *ngFor="let ed of edOptions"
                    [value]="ed.value"
                    >{{ ed.label }}</mat-radio-button
                >
            </mat-radio-group>
            <h1 class="new-command__heading">EMV Card Usage Allowed</h1>
            <mat-radio-group
                class="new-command_margin--bottom-sm"
                formControlName="EMVAllowed"
            >
                <mat-radio-button
                    *ngFor="let ed of edOptions"
                    [value]="ed.value"
                    >{{ ed.label }}</mat-radio-button
                >
            </mat-radio-group>
        </div>

        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_DEGRADED_MODE"
        >
            <h1 class="new-command__heading">Degraded Mode</h1>
            <mat-radio-group
                class="new-command_margin--bottom-sm"
                formControlName="DegradedMode"
            >
                <mat-radio-button
                    *ngFor="let ed of edOptions"
                    [value]="ed.value"
                    >{{ ed.label }}</mat-radio-button
                >
            </mat-radio-group>
        </div>
        <div
            class="new-command__section"
            *ngSwitchCase="commandTypes.SET_EEPROM_VALUE"
        >
            <h1 class="new-command__heading">EEPROM</h1>
            <mat-form-field class="full-width" appearance="outline">
                <input matInput formControlName="EEPROM" type="text" />
            </mat-form-field>
        </div>
    </div>
    <!-- ------------------------------------------------------------------- -->
    <div class="new-command__section">
        <h1 class="new-command__heading">Command Type</h1>
        <div
            class="new-command__note-text new-command_margin--bottom-sm"
            *ngIf="commandTypeToggle.checked || checked"
        >
            NOTE: A recurring command will continue to attempt to reach the
            devices until the expiration date.
        </div>

        <app-switch-toggle
            class="new-command__switch-toggle"
            #commandTypeToggle
            [checked]="checked"
            [uncheckedLabel]="'One time'"
            [checkedLabel]="'Recurring'"
            (toggleChange)="commandTypeToggleChange($event)"
        ></app-switch-toggle>

        <div *ngIf="commandTypeToggle.checked">
            <p class="new-command__accent-label">Expires</p>
            <mat-form-field appearance="outline" class="full-width">
                <mat-icon
                    matPrefix
                    svgIcon="calendar"
                    (click)="picker.open()"
                ></mat-icon>
                <mat-icon
                    matPrefix
                    svgIcon="clock"
                    (click)="timeSelect.open()"
                ></mat-icon>
                <input
                    matInput
                    formControlName="expirationDate"
                    [matDatepicker]="picker"
                    (dateChange)="dateChange($event)"
                />
                <mat-datepicker #picker></mat-datepicker>
                <mat-select
                    id="time"
                    [hidden]="true"
                    formControlName="expirationTime"
                    #timeSelect
                    (selectionChange)="timeSelectChange($event.value)"
                >
                    <mat-option
                        *ngFor="let timeRange of timeRanges"
                        [value]="timeRange"
                    >
                        {{ timeRange }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    
    <div class="new-command__section">
        <h1 class="new-command__heading">Devices</h1>

        <app-selected-devices-grid></app-selected-devices-grid>
    </div>
    <div class="new-command__section">
        <h1 class="new-command__heading">Include Command Description</h1>

        <mat-form-field appearance="outline" class="full-width">
            <input
                matInput
                formControlName="description"
                placeholder="Description (optional)"
            />
        </mat-form-field>
    </div>
    <div class="button-bar">
        <button mat-stroked-button color="primary" (click)="close()">
            Cancel
        </button>
        <button
            mat-flat-button
            class="button-to-right"
            color="primary"
            [disabled]="!newCommandForm.valid"
            (click)="submit(newCommandForm.value)"
        >
            Send Command
        </button>
    </div>
</div>

import { ICompany } from '@app/services/company/company.service';

export enum VehiclesDataEnum {
    radioButton = 'radioButton',
    companyContext = 'companyContext',
    vehicleId = 'vehicleId',
    vehicleModel = 'vehicleModel',
    numberOfValidators = 'numberOfValidators',
    pilot = 'pilot',
}

export type VehiclesData = {
    [key in VehiclesDataEnum]: any;
};

export type VehiclesSettings = {
    [key in VehiclesDataEnum]: {
        dataFieldName: string;
        label: string;
        cellRendererComponent?: any;
        alwaysAvailable?: boolean;
        suppressMenu?: boolean;
        suppressSortable?: boolean;
        suppressFilterable?: boolean;
        lockVisible?: boolean;
        suppressDragLeaveHidesColumns?: boolean;
    };
};

export type VehiclesColumnSettings = {
    key: VehiclesDataEnum;
    visible: boolean;
};

export interface IVehicleModel {
    vehicleId: string;
    name: number;
    companyContext: string;
    numberOfValidators: number;
    companyName: string;
    refreshedOn: string;
    pilot: string;
    eepromPTO: string;
    fullVehicleId: string;
    vehicleModelData?: any;
    vehicleModelName?: string;
}

export interface EEPROMPTO {
    eepromPTO: string;
}

export interface VehiclePayload {
    company: ICompany;
    companyContext: string;
    companyName: string;
    eeprom: {
        eepromPTO: string;
    };
    eepromPTO: string;
    hasTobsu: boolean;
    hasTobsuDisplay: string;
    isPilot: boolean;
    isPilotDisplay: string;
    name: string;
    validatorCount: number;
    vehicleId: string;
    vehicleModel: IVehicleModel;
}

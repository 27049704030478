import { CustomDateCellRendererComponent, RadioButtonCellRendererComponent } from "@app/shared/grid/cell-renderer";
import { DevicePositionDataEnum, DevicePositionSettings } from "./device-position.interface";


export const devicePositionSettings: DevicePositionSettings = {
    [DevicePositionDataEnum.radioButton]: { 
        dataFieldName: DevicePositionDataEnum.radioButton,
        label: '',
        cellRendererComponent: RadioButtonCellRendererComponent,
        alwaysAvailable: true,
        suppressMenu: true,
        suppressSortable: true,
        suppressFilterable: true,
        lockVisible: true,
        suppressDragLeaveHidesColumns: true,
    },
    [DevicePositionDataEnum.manuallySelectedLocation]: { 
        dataFieldName: DevicePositionDataEnum.manuallySelectedLocation,
        label: 'Manually Selected Location',
    },
    [DevicePositionDataEnum.deviceSerialNo]: { 
        dataFieldName: DevicePositionDataEnum.deviceSerialNo,
        label: 'Validator Serial',
    },
    [DevicePositionDataEnum.validatorPositionNo]: { 
        dataFieldName: DevicePositionDataEnum.validatorPositionNo,
        label: 'Validator Position',
    },
    [DevicePositionDataEnum.vehicleId]: { 
        dataFieldName: DevicePositionDataEnum.vehicleId,
        label: 'Vehicle',
    },
    [DevicePositionDataEnum.lastMessageReceivedDateTime]: { 
        dataFieldName: DevicePositionDataEnum.lastMessageReceivedDateTime,
        label: 'Last Message Received',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [DevicePositionDataEnum.idbtsam]: { 
        dataFieldName: DevicePositionDataEnum.idbtsam,
        label: 'IDBT SAM',
    },
    [DevicePositionDataEnum.comments]: { 
        dataFieldName: DevicePositionDataEnum.comments,
        label: 'Comments',
    },
} as DevicePositionSettings;

export const DataFieldNameMap: Map<string, string> = Object.keys(DevicePositionDataEnum).reduce(
    (acc: Map<string, string>, field: string) => (acc.set(
        devicePositionSettings[DevicePositionDataEnum[field as keyof typeof DevicePositionDataEnum]].dataFieldName, 
        field
    )),
    new Map<string, string>
);

import { Component, Inject, OnInit } from '@angular/core';
import { VehiclesService } from '../vehicles.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppDialogConfig } from '@app/shared/app-dialog/app-dialog.config';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { FormControl, FormGroup } from '@angular/forms';
import { IVehicleModel } from '../vehicles.interface';

@Component({
    selector: 'app-vehicle-details',
    templateUrl: './vehicle-details.component.html',
    styleUrls: ['./vehicle-details.component.scss'],
})
export class VehicleDetailsComponent implements OnInit {
    vehicleData: IVehicleModel = {} as IVehicleModel;
    fullVehicleId: string;
    vehicleDetailsForm: FormGroup;
    errorOccurred = false;

    constructor(
        private vehicleService: VehiclesService,
        public dialogRef: MatDialogRef<VehicleDetailsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        public appDialogService: AppDialogService,
    ) {}

    ngOnInit() {
        this.errorOccurred = false;
        this.vehicleDetailsForm = new FormGroup({
            company: new FormControl({ value: null, disabled: true }, {}),
            eepromPTO: new FormControl({ value: null, disabled: true }, {}),
            vehicleModel: new FormControl({ value: null, disabled: true }, {}),
            vehicleId: new FormControl({ value: '', disabled: true }, {
                nonNullable: true,
            }),
            pilot: new FormControl({ value: false, disabled: true }),
        });

        this.fullVehicleId = this.dialogConfig.data.vehicleToView.fullVehicleId;
        this.initVehicleDetails();
    }

    initVehicleDetails() {
        if (this.fullVehicleId !== null) {
            this.vehicleService
                .getVehicleDetailsById(`${this.fullVehicleId}`)
                .subscribe({next:(res) => {
                    this.vehicleData.eepromPTO = res?.eepromPTO;
                    this.vehicleData.companyContext = res?.companyContext;
                    this.vehicleData.companyName = res?.companyName;
                    this.vehicleData.pilot = res?.isPilot;
                    this.vehicleData.name = res?.name;
                    this.vehicleData.vehicleModelData = res?.vehicleModel;
                    this.vehicleData.refreshedOn = res?.refreshedOn;
                    this.vehicleData.vehicleId = res?.vehicleId;
                    this.vehicleData.numberOfValidators = res?.validatorCount;
                    this.vehicleData.vehicleModelName = res?.vehicleModel?.name;
                    this.setFormValues();
                },
                error: (err) => { 
                    this.errorOccurred = true;
                    console.log('Vehicle details not found!',err);
                }
            });                    
        }
    }

    setFormValues(){        
        this.vehicleDetailsForm.get('company')?.setValue(this.vehicleData.companyContext);
        this.vehicleDetailsForm.get('vehicleId')?.setValue(this.vehicleData.name);
        this.vehicleDetailsForm.get('pilot')?.setValue(this.vehicleData.pilot);
        this.vehicleDetailsForm.get('eepromPTO')?.setValue(this.vehicleData.eepromPTO);
        this.vehicleDetailsForm.get('vehicleModel')?.setValue(this.vehicleData.vehicleModelName);
    }

    cancel() {
        this.appDialogService.closeSliderDialog(this.dialogRef);
    }
}

<div [formGroup]="vehicleForm">
    <mat-form-field appearance="outline" *ngIf="companies.length">
        <mat-label>Concession/Modality</mat-label>
        <mat-select
            formControlName="company"
            (selectionChange)="companyContextSelected($event.value)"
        >
            <mat-option
                *ngFor="let company of companies"
                [value]="company.companyContext"
                >{{ company.name }}</mat-option
            >
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="eeproms.length">
        <mat-label>EEPROM PTO Value</mat-label>
        <mat-select formControlName="eepromPTO">
            <mat-option
                *ngFor="let eeprom of eeproms"
                [value]="eeprom.eepromPTO"
                >{{ eeprom.eepromPTO }}</mat-option
            >
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" *ngIf="vehicleModels.length">
        <mat-label>Vehicle Model</mat-label>
        <mat-select formControlName="vehicleModel">
            <mat-option
                *ngFor="let vehicleModel of vehicleModels"
                [value]="vehicleModel.vehicleModelId"
                >{{ vehicleModel.name }}</mat-option
            >
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
        <mat-label>Vehicle ID</mat-label>
        <input matInput formControlName="name" />
    </mat-form-field>

    <mat-checkbox formControlName="pilot">Pilot</mat-checkbox>
    <div class="button-bar">
        <button mat-stroked-button color="primary" (click)="cancel()">
            Cancel
        </button>
        <button
            mat-flat-button
            color="primary"
            [disabled]="!vehicleForm.valid"
            (click)="submit(vehicleForm.value)"
        >
            Save
        </button>
    </div>
</div>

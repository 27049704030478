import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevicePositionToolsPanelComponent } from './device-position-tools-panel.component';
import { AngularMaterialModule } from '@app/angular-material/angular-material.module';
import { DefaultTableToolsPanelModule } from '@app/shared/tools-panel/default-table-tools-panel';



@NgModule({
  declarations: [
    DevicePositionToolsPanelComponent
  ],
  exports: [
    DevicePositionToolsPanelComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    DefaultTableToolsPanelModule
  ]
})
export class DevicePositionToolsPanelModule { }

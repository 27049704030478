import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rows-per-page',
  templateUrl: './rows-per-page.component.html',
  styleUrls: []
})
export class RowsPerPageComponent {
  items = ['10', '25', '50', '100'];

  @Input() selectedPageSize = 25;

  @Output() pageSize = new EventEmitter<number>();

  public onPageSizeChanged(event: any): void {
    this.selectedPageSize = event.target.value;
    this.pageSize.emit(event.target.value);
  }
}

<mat-sidenav-container *ngIf="!mobileViewEnabled">
  <mat-sidenav #sidenav="matSidenav" mode="side" opened role="navigation">
    <button
      mat-button
      class="menu-button"
      routerLink="/dashboard"
      matTooltip="Dashboard"
      matTooltipPosition="right"
      *ngIf="hasPermission('ViewDashboard')"
    >
      <img src="assets/svg/sidenav/dashboard.svg" alt="Dashboard Icon" />
    </button>
    <button
    mat-button
    class="menu-button"
    routerLink="/heartbeats"
    matTooltip="Heartbeats"
    matTooltipPosition="right"
    *ngIf="hasPermission('ViewHeartbeats')"
  >
    <img src="assets/svg/sidenav/heartbeats.svg" alt="Heartbeats Icon" />
  </button>
    <button
      mat-button
      class="menu-button"
      routerLink="/event-history"
      matTooltip="Event History"
      matTooltipPosition="right"
      *ngIf="hasPermission('ViewEventHistory')"
    >
      <img
        src="assets/svg/sidenav/eventHistory.svg"
        alt="Event History Icon"
      />
    </button>
    <button
      mat-button
      class="menu-button"
      routerLink="/map"
      matTooltip="Map"
      matTooltipPosition="right"
      *ngIf="hasPermission('ViewMap')"
    >
      <img src="assets/svg/sidenav/map.svg" alt="Map Icon" />
    </button>
    <button
      mat-button
      class="menu-button"
      routerLink="/device-position"
      matTooltip="Device Position"
      matTooltipPosition="right"
      *ngIf="hasPermission('ViewDevicePosition')"
    >
      <img
        src="assets/svg/sidenav/devicePosition.svg"
        alt="Device Position Icon"
      />
    </button>

    <button
      mat-button
      class="menu-button"
      routerLink="/position-history"
      matTooltip="Position History"
      matTooltipPosition="right"
      *ngIf="hasPermission('ViewPositionHistory')"
    >
      <img
        src="assets/svg/sidenav/positionHistory.svg"
        alt="Position History Icon"
      />
    </button>

    <button
      mat-button
      class="menu-button"
      routerLink="/vehicles"
      matTooltip="Vehicles"
      matTooltipPosition="right"
      *ngIf="hasPermission('ViewVehicles')"
    >
      <img src="assets/svg/sidenav/vehicles.svg" alt="Vehicles Icon" />
    </button>

    <button
      mat-button
      class="menu-button"
      routerLink="/commands"
      matTooltip="Commands"
      matTooltipPosition="right"
      *ngIf="hasPermission('ViewCommands')"
    >
      <img src="assets/svg/sidenav/commands.svg" alt="Commands Icon" />
    </button>

    <div class="icons-bottom-aligned">
      <button
        mat-button
        class="menu-button"
        routerLink="/users"
        matTooltip="Users"
        matTooltipPosition="right"
        *ngIf="hasPermission('ViewUsers')"
      >
        <img src="assets/svg/sidenav/users.svg" alt="Users Icon" />
      </button>

      <button
        mat-button
        class="menu-button"
        routerLink="/validator-groups"
        matTooltip="Validator Groups"
        matTooltipPosition="right"
        *ngIf="hasPermission('ViewValidatorGroups')"
      >
        <img
          src="assets/svg/sidenav/validatorGroups.svg"
          alt="Validator Groups Icon"
        />
      </button>

      <button
        mat-button
        class="menu-button"
        routerLink="/signing-keys"
        matTooltip="Signing Keys"
        matTooltipPosition="right"
        *ngIf="hasPermission('ViewConfiguration')"
      >
        <img
          src="assets/svg/sidenav/signingKeys.svg"
          alt="Signing Keys Icon"
        />
      </button>
    </div>
  </mat-sidenav>
  <mat-sidenav-content>
    <div class="sidenav-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<div class="mat-elevation-z4" *ngIf="mobileViewEnabled">
  <mat-form-field class="mobile-view-menu-form">
    <mat-select [(value)]="selectedPage" class="mobile-view-menu-form__selected-option">
      <mat-select-trigger>
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/sidenav/{{selectedPage | camelCase}}.svg"
            alt={{selectedPage}}
          />
          <span>{{selectedPage}}</span>
      </mat-select-trigger>
      <mat-option value="Dashboard" routerLink="/dashboard" *ngIf="hasPermission('ViewDashboard')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/dashboard.svg"
            alt="Dashboard Icon"
          />
          <span>Dashboard</span>
        </span>
      </mat-option>
      <mat-option value="Heartbeats" routerLink="/heartbeats" *ngIf="hasPermission('ViewHeartbeats')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/heartbeats.svg"
            alt="Heartbeats Icon"
          />
          <span>Heartbeats</span>
        </span>
      </mat-option>
      <mat-option value="Event History" routerLink="/event-history" *ngIf="hasPermission('ViewEventHistory')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/eventHistory.svg"
            alt="Event History Icon"
          />
          <span>Event History</span>
        </span>
      </mat-option>
      <mat-option value="Map" routerLink="/map" *ngIf="hasPermission('ViewMap')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/map.svg"
            alt="Map Icon"
          />
          <span>Map</span>
        </span>
      </mat-option>
      <mat-option value="Device Position" routerLink="/device-position" *ngIf="hasPermission('ViewDevicePosition')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/devicePosition.svg"
            alt="Device Position Icon"
          />
          <span>Device Position</span>
        </span>
      </mat-option>
      <mat-option value="Position History" routerLink="/position-history" *ngIf="hasPermission('ViewPositionHistory')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/positionHistory.svg"
            alt="Position History Icon"
          />
          <span>Position History</span>
        </span>
      </mat-option>
      <mat-option value="Vehicles" routerLink="/vehicles" *ngIf="hasPermission('ViewVehicles')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/vehicles.svg"
            alt="Vehicles Icon"
          />
          <span>Vehicles</span>
        </span>
      </mat-option>
      <mat-option value="Commands" routerLink="/commands" *ngIf="hasPermission('ViewCommands')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/commands.svg"
            alt="Commands Icon"
          />
          <span>Commands</span>
        </span>
      </mat-option>
      <mat-option value="Users" routerLink="/users" *ngIf="hasPermission('ViewUsers')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/users.svg"
            alt="Users Icon"
          />
          <span>Users</span>
        </span>
      </mat-option>
      <mat-option value="Validator Groups" routerLink="/validator-groups" *ngIf="hasPermission('ViewValidatorGroups')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/validatorGroups.svg"
            alt="Validator Groups Icon"
          />
          <span>Validator Groups</span>
        </span>
      </mat-option>
      <mat-option value="Signing Keys" routerLink="/signing-keys" *ngIf="hasPermission('ViewConfiguration')">
        <span class="centered-content">
          <img
            class="mobile-view-menu-form__image"
            src="assets/svg/header/signingKeys.svg"
            alt="Signing Keys Icon"
          />
          <span>Signing Keys</span>
        </span>
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div class="sidenav-content__mobile">
    <router-outlet></router-outlet>
  </div>
</div>

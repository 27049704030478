import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from '@ag-grid-community/angular';

import { HeartbeatsV2RoutingModule } from './heartbeatsV2-routing.module';
import { HeartbeatsV2Component } from './heartbeatsV2.component';
import { 
  ConnectionStateCellRendererModule, 
  LocationCellRendererModule,
  CustomDateCellRendererModule 
} from '@app/shared/grid/cell-renderer';
import { RowsPerPageModule } from '@app/shared/grid/rows-per-page';
import { HeartbeatsPanelModule } from '@app/pages/heartbeats/heartbeats-panel';
import { AppDialogModule } from '@app/shared/app-dialog';

@NgModule({
  declarations: [
    HeartbeatsV2Component
  ],
  imports: [
    CommonModule,
    HeartbeatsV2RoutingModule,
    AgGridModule,
    HeartbeatsPanelModule,
    ConnectionStateCellRendererModule,
    CustomDateCellRendererModule,
    LocationCellRendererModule,
    RowsPerPageModule,
    AppDialogModule
  ]
})
export class HeartbeatsV2Module { }

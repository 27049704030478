import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomRoleCellRendererComponent } from './custom-role-cell-renderer.component';

@NgModule({
    declarations: [CustomRoleCellRendererComponent],
    exports: [CustomRoleCellRendererComponent],
    imports: [CommonModule, AgGridModule],
})
export class CustomRoleCellRendererModule {}

<app-default-table-tools-panel
    [isAvailableToExport]="isAvailableToExport"
    [progressValueExport]="progressValueExport"
    [isOpenFilters]="isOpenFilters"
    [isOpenColumns]="isOpenColumns"
    (exportToExcel)="exportToExcel.emit()"
    (resetAll)="resetAll.emit()"
    (clearSorting)="clearSorting.emit()"
    (clearFiltering)="clearFiltering.emit()"
    (clearRowGrouping)="clearRowGrouping.emit()"
    (resetColumnOrder)="resetColumnOrder.emit()"
    (resetColumnVisibility)="resetColumnVisibility.emit()"
    (openFilters)="openFilters.emit()"
    (openColumns)="openColumns.emit()"
>
    <button *ngIf="isDownloadAllEnabled"
        right
        (click)="downloadAll()"        
        mat-icon-button
        [matTooltip]="downloadLabel"
        [attr.aria-label]="downloadLabel"
    >
        <mat-icon svgIcon="download"></mat-icon>
    </button>
</app-default-table-tools-panel>

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-device-position-tools-panel',
    templateUrl: './device-position-tools-panel.component.html',
})
export class DevicePositionToolsPanelComponent{
    @Input() isAvailableToExport: boolean;
    @Input() progressValueExport: number;
    @Input() isOpenFilters: boolean;
    @Input() isOpenColumns: boolean;
    @Input() isSelectedVehicle: boolean;

    @Output() exportToExcel = new EventEmitter();
    @Output() resetAll = new EventEmitter();
    @Output() clearSorting = new EventEmitter();
    @Output() clearFiltering = new EventEmitter();
    @Output() clearRowGrouping = new EventEmitter();
    @Output() resetColumnOrder = new EventEmitter();
    @Output() resetColumnVisibility = new EventEmitter();
    @Output() openFilters = new EventEmitter();
    @Output() openColumns = new EventEmitter();
}

import { EventsDataEnum, EventsSettings } from './heartbeats.interface';
import {
    ConnectionStateCellRendererComponent,
    LocationCellRendererComponent,
    CustomDateCellRendererComponent
} from '@app/shared/grid/cell-renderer';

export const eventsSettings: EventsSettings = {
    [EventsDataEnum.connectionState]: {
        dataFieldName: 'body.connectionState',
        label: 'Connection State',
        cellRendererComponent: ConnectionStateCellRendererComponent,
    },
    [EventsDataEnum.deviceSerialNo]: {
        dataFieldName: 'header.deviceSerialNo',
        label: 'Validator Serial No',
    },
    [EventsDataEnum.validatorStatus]: {
        dataFieldName: 'body.validatorStatus',
        label: 'Validator Status',
    },
    [EventsDataEnum.statusCode]: {
        dataFieldName: 'body.statusCode',
        label: 'Status Code',
        hide: true,
    },
    [EventsDataEnum.reasonStatus]: {
        dataFieldName: 'body.reasonStatus',
        label: 'Reason Status',
    },
    [EventsDataEnum.validatorRole]: {
        dataFieldName: 'body.validatorRole',
        label: 'Validator Role',
    },
    [EventsDataEnum.validatorPositionNo]: {
        dataFieldName: 'header.validatorPositionNo',
        label: 'Validator Position No',
    },
    
    [EventsDataEnum.vehicle]: {
        dataFieldName: 'header.vehicleID',
        label: 'Vehicle',
    },
    [EventsDataEnum.vehicleType]: {
        dataFieldName: 'body.vehicleType',
        label: 'Vehicle Type',
    },
    [EventsDataEnum.PTO]: {
        dataFieldName: 'companyName',
        label: 'PTO',
    },
    [EventsDataEnum.kv6Status]: {
        dataFieldName: 'body.kv6Status',
        label: 'KV6 Status',
    },
    [EventsDataEnum.location]: {
        dataFieldName: 'header.gpsData',
        label: 'Location',
        cellRendererComponent: LocationCellRendererComponent,
    },

    [EventsDataEnum.lastReceivedEventType]: {
        dataFieldName: 'header.messageType',
        label: 'Last Received Event Type',
    },
    [EventsDataEnum.lastMessageReceivedDate]: {
        dataFieldName: 'createdOn',
        label: 'Last Event Received Date',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsDataEnum.messageGeneratedDate]: {
        dataFieldName: 'header.messageGeneratedDateTime',
        label: 'Event Generated Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsDataEnum.messageIDCounter]: {
        dataFieldName: 'header.messageCounter',
        label: 'Event ID counter',
        hide: true,
    },

    [EventsDataEnum.OVCStatus]: {
        dataFieldName: 'body.ovcStatus',
        label: 'OVC Status',
    },
    [EventsDataEnum.OVCStatusReason]: {
        dataFieldName: 'body.ovcStatusReason',
        label: 'OVC Status Reason',
        hide: true,
    },
    [EventsDataEnum.numberOfSDOATransactionsSinceLastPowerOn]: {
        dataFieldName: 'body.numberOfSDOATransactions',
        label: 'SDOA Transactions since Power On',
        hide: true,
    },
    [EventsDataEnum.EMVStatus]: {
        dataFieldName: 'body.emvStatus',
        label: 'EMV Status',
    },
    [EventsDataEnum.EMVStatusReason]: {
        dataFieldName: 'body.emvStatusReason',
        label: 'EMV Status Reason',
        hide: true,
    },
    [EventsDataEnum.numberOfEMVTransactionsSinceLastPowerOn]: {
        dataFieldName: 'body.numberOfEMVTransactions',
        label: 'Number Of EMV Transactions Since Last PowerOn',
        hide: true,
    },
    [EventsDataEnum.CLTStatus]: {
        dataFieldName: 'body.cltStatus',
        label: 'CLT Status',
    },
    [EventsDataEnum.CLTStatusReason]: {
        dataFieldName: 'body.cltStatusReason',
        label: 'CLT Status Reason',
        hide: true,
    },
    // CLT Transactions since Power On

    [EventsDataEnum.BCStatus]: {
        dataFieldName: 'body.bcStatus',
        label: 'BC Status',
    },
    [EventsDataEnum.BCStatusReason]: {
        dataFieldName: 'body.bcStatusReason',
        label: 'BC Status Reason',
        hide: true,
    },
    [EventsDataEnum.numberOfBarcodeTransactionsSinceLastPowerOn]: {
        dataFieldName: 'body.numberOfBarcodeTransactions',
        label: 'Barcode Transactions since Power On',
        hide: true,
    },

    [EventsDataEnum.publicLineNo]: {
        dataFieldName: 'body.publicLineNo',
        label: 'Public Line No',
    },
    [EventsDataEnum.trip]: {
        dataFieldName: 'body.tripNo',
        label: 'Trip',
    },
    [EventsDataEnum.previousStation]: {
        dataFieldName: 'body.previousStationName',
        label: 'Previous Station',
    },
    [EventsDataEnum.previousStationRefNo]: {
        dataFieldName: 'body.previousStationRefNo',
        label: 'Previous Station Ref No',
        hide: true,
    },
    [EventsDataEnum.nextStation]: {
        dataFieldName: 'body.nextStationName',
        label: 'Next Station',
    },
    [EventsDataEnum.betweenStops]: {
        dataFieldName: 'body.betweenStops',
        label: 'Between Stops',
    },
    [EventsDataEnum.nextStationRefNo]: {
        dataFieldName: 'body.nextStationRefNo',
        label: 'Next Station Ref No',
        hide: true,
    },
    [EventsDataEnum.EBSInterfaceStatus]: {
        dataFieldName: 'body.ebsInterfaceStatus',
        label: 'EBS Interface Status',
    },

    [EventsDataEnum.errorMessage]: {
        dataFieldName: 'body.errorMessage',
        label: 'Error Message',
    },
    [EventsDataEnum.errorCode]: {
        dataFieldName: 'body.errorCode',
        label: 'Error Code',
        hide: true,
    },
    [EventsDataEnum.warningMessage]: {
        dataFieldName: 'body.warningMessage',
        label: 'Warning Message',
    },
    [EventsDataEnum.warningCode]: {
        dataFieldName: 'body.warningCode',
        label: 'Warning Code',
        hide: true,
    },

    [EventsDataEnum.validatorModel]: {
        dataFieldName: 'body.validatorModel',
        label: 'Validator Model',
    },  
    [EventsDataEnum.hasBarcodeReader]: {
        dataFieldName: 'body.hasBarcodeReader',
        label: 'Has Barcode Reader',
    },  
    [EventsDataEnum.servicePatternId]: {
        dataFieldName: 'body.servicePatternId',
        label: 'Service Pattern Id',
    },
    [EventsDataEnum.ramFree]: {
        dataFieldName: 'body.ramFree',
        label: 'Ram Free',
    },
    [EventsDataEnum.ramTotal]: {
        dataFieldName: 'body.ramTotal',
        label: 'Ram Total',
    },
    [EventsDataEnum.diskFree]: {
        dataFieldName: 'body.diskFree',
        label: 'Disk Free',
    },
    [EventsDataEnum.diskTotal]: {
        dataFieldName: 'body.diskTotal',
        label: 'Disk Total',
    },
    [EventsDataEnum.internalIPAddress]: {
        dataFieldName: 'body.internalIPaddress',
        label: 'Internal IP Address',
    },

    [EventsDataEnum.softwareVersion]: {
        dataFieldName: 'body.softwareVersion',
        label: 'Software Version',
    },
    [EventsDataEnum.lastSoftwareUpdate]: {
        dataFieldName: 'body.lastSoftwareUpdateDate',
        label: 'Last Software Update',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsDataEnum.validatorConfigVersion]: {
        dataFieldName: 'body.validatorConfigVersion',
        label: 'Validator Configuration Version',
    },
    [EventsDataEnum.validatorVersion]: {
        dataFieldName: 'body.validatorVersion',
        label: 'Validator Version',
    },
    [EventsDataEnum.issuerAccessListVersion]: {
        dataFieldName: 'body.issuerAccessListVersion',
        label: 'Issuer Access List Version',
    },
    [EventsDataEnum.tokenAccessListVersion]: {
        dataFieldName: 'body.tokenAccessListVersion',
        label: 'Token Access List Version',
    },

    [EventsDataEnum.ceilingValueCSCLeft]: {
        dataFieldName: 'body.ceilingValueCSCLeft',
        label: 'Ceiling Value CSC Left',
    },
    [EventsDataEnum.ceilingValueCTLeft]: {
        dataFieldName: 'body.ceilingValueCTLeft',
        label: 'Ceiling Value CT Left',
    },
    [EventsDataEnum.IDBT_SAMUserAuthenticationCounter]: {
        dataFieldName: 'body.idbtsamUserAuthenticationCounter',
        label: 'IDBT SAM User Authentication Counter',
    },
    [EventsDataEnum.IDBT_SAM]: {
        dataFieldName: 'body.idbtsam',
        label: 'IDBT SAM',
    },
    [EventsDataEnum.PKI_SAM]: {
        dataFieldName: 'body.pkisam',
        label: 'PKI SAM',
    },
    [EventsDataEnum.PKI_SAMType]: {
        dataFieldName: 'body.pkisamType',
        label: 'PKI SAM Type',
    },
    [EventsDataEnum.PKI_SAM_BEID]: {
        dataFieldName: 'body.pkisambeid',
        label: 'PKI SAM BEID',
    },

    [EventsDataEnum.EODCurrentEffectiveDate]: {
        dataFieldName: 'body.eodEffectiveDateCurrent',
        label: 'EOD Current Effective Date',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsDataEnum.EODFutureEffectiveDate]: {
        dataFieldName: 'body.eodEffectiveDateFuture',
        label: 'EOD Future Effective Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsDataEnum.EODMCFVersionCurrent]: {
        dataFieldName: 'body.eodmcfVersionCurrent',
        label: 'EOD MCF Version Current',
        
    },
    [EventsDataEnum.EODTopologyVersionCurrent]: {
        dataFieldName: 'body.eodTopologyVersionCurrent',
        label: 'EOD Topology Version Current',
    },
    [EventsDataEnum.EODTopologyVersionFuture]: {
        dataFieldName: 'body.eodTopologyVersionFuture',
        label: 'EOD Topology Version Future',
        hide: true,
    },
    [EventsDataEnum.EODFareVersionCurrent]: {
        dataFieldName: 'body.eodFareVersionCurrent',
        label: 'EOD Fare Version Current',
    },
    [EventsDataEnum.EODFareVersionFuture]: {
        dataFieldName: 'body.eodFareVersionFuture',
        label: 'EOD Fare Version Future',
        hide: true,
    },
    [EventsDataEnum.EODDicoVersionCurrent]: {
        dataFieldName: 'body.eodDicoVersionCurrent',
        label: 'EOD Dico Version Current',
    },
    [EventsDataEnum.EODDicoVersionFuture]: {
        dataFieldName: 'body.eodDicoVersionFuture',
        label: 'EOD Dico Version Future',
        hide: true,
    },
    [EventsDataEnum.EODBlackListVersionCurrent]: {
        dataFieldName: 'body.eodBlackListCurrent',
        label: 'EOD Black List Version Current',
    },
    [EventsDataEnum.EODTimeVersionCurrent]: {
        dataFieldName: 'body.eodTimeVersionCurrent',
        label: 'EOD Time Version Current',
    },
    [EventsDataEnum.EODTimeVersionFuture]: {
        dataFieldName: 'body.eodTimeVersionFuture',
        label: 'EOD Time Version Future',
        hide: true,
    },

    [EventsDataEnum.TXARNumberOfTransaction]: {
        dataFieldName: 'body.numberOfTransaction',
        label: 'TXAR Number Of Transaction',
    },
    [EventsDataEnum.TXARUploadDate]: {
        dataFieldName: 'body.txarUploadDate',
        label: 'TXAR Upload Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsDataEnum.TXARFileCreateDate]: {
        dataFieldName: 'body.txarFileCreateDate',
        label: 'TXAR File Create Date',
        hide: true,
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [EventsDataEnum.TXARFileName]: {
        dataFieldName: 'body.fileName',
        label: 'TXAR File Name',
        hide: true,
    },
    [EventsDataEnum.connectionStateUpdatedOn]: {
        dataFieldName: 'body.connectionStateUpdatedOn',
        label: 'Connection State Update Date',
        cellRendererComponent: CustomDateCellRendererComponent
    },    
};

export const DataFieldNameMap: Map<string, string> = Object.keys(
    EventsDataEnum
).reduce(
    (acc: Map<string, string>, field: string) =>
        acc.set(
            eventsSettings[EventsDataEnum[field as keyof typeof EventsDataEnum]]
                .dataFieldName,
            field
        ),
    new Map<string, string>()
);

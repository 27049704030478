import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    selector: 'app-switch-toggle',
    templateUrl: './switch-toggle.component.html',
    styleUrls: ['./switch-toggle.component.scss'],
})
export class SwitchToggleComponent {
    @Input() checked = false;
    @Output() toggleChange = new EventEmitter<boolean>();
    @Input() checkedLabel: string;
    @Input() uncheckedLabel: string;
    @Input() id: string;

    toggle(event: MatSlideToggleChange) {
        this.checked = event.checked;
        this.toggleChange.emit(event.checked);
    }
}

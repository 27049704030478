import { HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';

/**
 * Api response model.
 */
export interface ApiResponse<T> {
    isAggregated: boolean,
    lastUpdated: Date,
    lastUpdatedDateTime: string,
    result: T
    totalCount: number
  }

/**
 * Interface for rest call arguments
 */
export interface IRestCallArgs {
    svcPath: string;
    method: RequestMethod;
    data?: object;
    options?: IRestCallOptions;
}

export enum RequestMethod {
    Get = 0,
    Post = 1,
    Put = 2,
    Delete = 3,
    Options = 4,
    Head = 5,
    Patch = 6
}

export interface IHttpOptions{
    params?: HttpParams;
    headers?: HttpHeaders;
    responseType?: RestResponseType;

}

/**
 * Interface to the Rest Call Options
 */
export interface IRestCallOptions {
    /**
     * Optional user header options
     */
    headers?: HttpHeaders;

    /**
     * Optional query. Query is applicable to the get call and can be passed as
     * oData object or a string
     */
    query?: string;

    /**
     * Optional response type. In very rare situations you may request a 'text' type of response.
     * Regularly it is 'json'
     */
    responseType?: RestResponseType;
}

export enum RestResponseType {
    json = 'json',
    text = 'text',
    blob = 'blob',
    arraybuffer = 'arraybuffer'
}
/**
* This gets populated by method call
*/
export interface IRestCallOutput {
    /**
     * Result is taken from value of the response
     */
    isValue: boolean;
    /**
     * Response of the call
     */
    response: HttpResponse<any>;
    /**
     * Error response of the call
     */
    errorResponse?: HttpErrorResponse;
}


/**
 * Interface to resolveServerUrl
 */
export interface IServerUrlResolver {
    /**
     * Protocol used for communication.
     */
    protocol: string;
  
    /**
     * Server URL used for the connection.
     */
    serverUrl: string;
  
    /** Portion of the path that gets specified by each component */
    restPath: string;
  
  }

  /**
 * Represents the REST request
 */
export interface IRestRequest {
    /**
     * the service to be used
     */
    svcPath: string;
  
    /**
     * data
     */
    data: object;
  
    /**
     * method that determines the kind of REST call
     */
    method: RequestMethod;
  
    /**
     * optional options
     */
    options?: IRestCallOptions;
  }
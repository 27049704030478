import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { CustomDateCellRendererComponent } from './custom-date-cell-renderer.component';

@NgModule({
    declarations: [CustomDateCellRendererComponent],
    exports: [CustomDateCellRendererComponent],
    imports: [CommonModule, AgGridModule],
    providers: [DatePipe],
})
export class CustomDateCellRendererModule {}

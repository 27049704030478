import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AppPermissionService } from './app-permission.service';
import { Observable, of, switchMap } from 'rxjs';
import { AppNotificationService, NotificationType } from '@app/services/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AppPermissionGuard {
  constructor(
    private permissionService: AppPermissionService,
    private router: Router,
    private notificationService : AppNotificationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.permissionService.user$.pipe(switchMap((user => {
      if (user?.permissions?.includes(route.data['requiredPermission'])) {
        return of(true);
      } else {
        this.notificationService.showSnackBar('You lack necessary permission to view this page!', NotificationType.Error, 3000);
        this.router.navigate(['/dashboard']); //navigate user to dashboard
        return of(false);
      }
    })));
  }
}

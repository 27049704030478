<div class="set-location" [formGroup]="setLocationForm">
    <div class="set-location__section">
        <div class="set-location__form-label">Manually Selected Location</div>

        <mat-form-field appearance="outline">
            <mat-select formControlName="location">
                <mat-option
                    *ngFor="let location of locations"
                    [value]="location.name"
                    >{{ location.name }}</mat-option
                >
            </mat-select>
        </mat-form-field>
    </div>
    <div class="set-location__section">
        <div class="set-location__form-label">Comment</div>

        <textarea rows="5" formControlName="comment"></textarea>
    </div>
    <div class="button-bar">
        <button mat-stroked-button color="primary" (click)="close()">
            Cancel
        </button>
        <button
            *ngIf="setLocationForm"
            [disabled]="!setLocationForm.valid"
            mat-flat-button
            color="primary"
            (click)="setLocation(setLocationForm.value)"
        >
            Save
        </button>
    </div>
</div>

import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppDialogConfig } from '@app/shared/app-dialog/app-dialog.config';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { DevicePositionService } from '../device-position.service';
import { Subject, switchMap, take } from 'rxjs';
import {
    DeviceLocationOption,
    SetLocationData,
} from '../device-position.interface';

@Component({
    selector: 'app-set-location',
    templateUrl: './set-location.component.html',
    styleUrls: ['./set-location.component.scss'],
})
export class SetLocationComponent implements OnInit {
    setLocationForm: FormGroup;
    locations: Partial<DeviceLocationOption>[] = [];
    destroy$: Subject<void> = new Subject();
    currentDevice: SetLocationData;
    constructor(
        public dialogRef: MatDialogRef<SetLocationComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        public appDialogService: AppDialogService,
        public devicePositionService: DevicePositionService
    ) {
        this.setLocationForm = new FormGroup({
            location: new FormControl('', { validators: Validators.required }),
            comment: new FormControl(this.dialogConfig.data.comment),
        });
    }

    ngOnInit(): void {
        this.devicePositionService
            .getLocationDataByDeviceId(this.dialogConfig.data.deviceSerialNo)
            .pipe(
                take(1),
                switchMap((deviceLocationData: SetLocationData) => {
                    this.currentDevice = deviceLocationData;

                    return this.devicePositionService.getLocations(
                        deviceLocationData.companyContext
                    );
                })
            )
            .subscribe((res: { result: DeviceLocationOption[] }) => {
                this.locations = res.result;
                this.locations = [
                    { name: 'Location not set' },
                    ...this.locations,
                ];

                this.setLocationForm
                    .get('location')
                    ?.setValue(this.currentDevice.manuallySelectedLocation);
            });
    }

    close() {
        this.appDialogService.closeSliderDialog(this.dialogRef);
    }

    setLocation(value: any) {
        const payload: SetLocationData = {
            ...this.currentDevice,
            manuallySelectedLocation: value.location,
            comments: value.comment,
        };
        this.appDialogService.closeSliderDialog(this.dialogRef, payload);
    }
}

import { FormControl, FormGroup } from '@angular/forms';
import { Device } from '@app/services/devices/devices.service';
import { CommandTypes } from '../commands.interface';

export interface NewCommandForm {
    command: FormControl<CommandTypes>;
    description: FormControl<string>;
    commandType: FormControl<boolean>;
    expirationDate: FormControl<string>;
    expirationTime: FormControl<string>;
    devices: FormControl<Device[]>;
    // optional
    applicationTypes?: FormControl<string>;
    serviceCheckboxs?: FormGroup<ServiceCheckboxesForm>;
    configurationType?: FormControl<string>;
    serviceEventCheckboxs?: FormGroup<ServiceEventCheckboxesForm>;
    period?: FormControl<string>;
    serviceRadio?: FormControl<string>;
    files?: FormControl<string>;
    sound?: FormControl<string>;
    logLevel?: FormControl<string>;
    volume?: FormControl<string>;
    transactionPeriod?: FormControl<string>;
    maxTransactionCheckTime?: FormControl<string>;
    maxAuditCheckTime?: FormControl<string>;
    maxNumberOfRecords?: FormControl<string>;
    SWcheckTimeInMinutes?: FormControl<string>;
    EODcheckTimeInMinutes?: FormControl<string>;
    EMVcheckTimeInMinutes?: FormControl<string>;
    OVcheckTimeInMinutes?: FormControl<string>;

    brightness?: FormControl<string>;
    brightnessSlide?: FormControl<number>;
    EEPROM?: FormControl<string>;
    OVAllowed?: FormControl<string>;
    BarCodeAllowed?: FormControl<string>;
    EMVAllowed?: FormControl<string>;
    DegradedMode?: FormControl<string>;
}

export interface Options {
    value: string;
    label: string;
}

export const EDOptions: Options[] = [
    {
        value: 'Enabled',
        label: 'Enabled',
    },
    {
        value: 'Disabled',
        label: 'Disabled',
    },
];
export const SoundOptions: Options[] = [
    {
        value: 'checkin',
        label: 'Check-In',
    },
    {
        value: 'checkout',
        label: 'Check-Out',
    },
    {
        value: 'ok',
        label: 'EMV OK',
    },
    {
        value: 'error',
        label: 'Error',
    },
];

export const LogLevelOptions: Options[] = [
    {
        value: 'Trace',
        label: 'Trace',
    },
    {
        value: 'Debug',
        label: 'Debug',
    },
    {
        value: 'Info',
        label: 'Info',
    },
    {
        value: 'Warning',
        label: 'Warning',
    },
    {
        value: 'Error',
        label: 'Error',
    },
];

export const BrightnessOptions: Options[] = [
    {
        value: 'Dynamic',
        label: 'Dynamic',
    },
    {
        value: 'Static',
        label: 'Static',
    },
];

export enum ApplicationTypes {
    DEVICE_SERVICE_MONITOR = 'DeviceServiceMonitor',
    HEARTBEAT_360_CLIENT = 'Heartbeat360Client',
    USER_INTERFACE = 'UserInterface',
    UPDATE_HANDLER = 'UpdateHandler',
    VALIDATOR = 'Validator',
    SCREEN_CONFIGURATION = 'ScreenConfiguration',
    LOCAL_SUPPORT_FUNCTION = 'LocalSupportFunction',
}

export const servicesRadioConfig: Options[] = [
    {
        value: ApplicationTypes.DEVICE_SERVICE_MONITOR,
        label: 'Device Service Monitor',
    },
    {
        value: ApplicationTypes.HEARTBEAT_360_CLIENT,
        label: 'Heartbeat360 Client',
    },
    {
        value: ApplicationTypes.USER_INTERFACE,
        label: 'User Interface',
    },
    {
        value: ApplicationTypes.UPDATE_HANDLER,
        label: 'Update Handler',
    },
    {
        value: ApplicationTypes.VALIDATOR,
        label: 'Validator',
    },
    {
        value: ApplicationTypes.SCREEN_CONFIGURATION,
        label: 'Screen Configuration',
    },
    {
        value: ApplicationTypes.LOCAL_SUPPORT_FUNCTION,
        label: 'Local Support Function',
    },
];

export interface ServiceCheckboxesForm {
    isDeviceServiceMonitor: FormControl<boolean>;
    isHeartbeat360: FormControl<boolean>;
    isUserInterface: FormControl<boolean>;
    isUpdateHandler: FormControl<boolean>;
    isValidator: FormControl<boolean>;
    isLocalSupport: FormControl<boolean>;
}

export const generateServicesArray = (serviceCheckboxsFormValue: any) => {
    const result = [];

    if (serviceCheckboxsFormValue.isDeviceServiceMonitor)
        result.push(ApplicationTypes.DEVICE_SERVICE_MONITOR);
    if (serviceCheckboxsFormValue.isHeartbeat360)
        result.push(ApplicationTypes.HEARTBEAT_360_CLIENT);
    if (serviceCheckboxsFormValue.isUserInterface)
        result.push(ApplicationTypes.USER_INTERFACE);
    if (serviceCheckboxsFormValue.isUpdateHandler)
        result.push(ApplicationTypes.UPDATE_HANDLER);
    if (serviceCheckboxsFormValue.isValidator)
        result.push(ApplicationTypes.VALIDATOR);
    if (serviceCheckboxsFormValue.isLocalSupport)
        result.push(ApplicationTypes.LOCAL_SUPPORT_FUNCTION);

    return result;
};

export interface ServiceEventCheckboxesForm {
    isDeviceServiceMonitor: FormControl<boolean>;
    isHeartbeat360: FormControl<boolean>;
    isUserInterface: FormControl<boolean>;
    isUpdateHandler: FormControl<boolean>;
    isValidator: FormControl<boolean>;
}

export const timeRanges: string[] = [
    '00:00',
    '00:30',
    '01:00',
    '01:30',
    '02:00',
    '02:30',
    '03:00',
    '03:30',
    '04:00',
    '04:30',
    '05:00',
    '05:30',
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
];

export interface CommandPayload {
    id: string;
    jobId: string;
    jobType: string;
    command: CommandTypes;
    commandDisplay: string;
    companyName: string;
    companyContext: string;
    createdBy: string;
    devices: Device[];
    description: string;
    expires: boolean;
    expiresDisplay: string;
    expiresOn: string;
    payload: string;
    files: [];
    configurationType: string;
    volume: number;
    brightnessType: string;
    brightness: number;
    logLevel: string;
    transactionDataRetentionTime: string;
    maxNumberTransactionDataRecords: string;
    maxTxDataSendTimeMinutes: string;
    maxArDataSendTimeMinutes: string;
    minutes: string;
    soundType: string;
    degradedMode: string;
    emvUsageAllowed: string;
    barcodeUsageAllowed: string;
    ovChipUsageAllowed: string;
    eeprom: string;
    logFileDate: string;

    applicationTypes: string[];
    schedule: boolean;
    scheduleDisplay: string;
    start: null;
    end: null;

    status: string;
    failedCount: number;
    runningCount: number;
    deviceCount: number;
    succeededCount: number;
    pendingCount: number;
    deviceFlag: number;
    canDisableOVChipCardUsage: boolean;
}

<input type="file" multiple (change)="onFileSelected($event)" />
Maximum allowed file size is 50MB.

<div class="files" *ngIf="selectionData.length">
    <div class="file" *ngFor="let file of selectionData">
        <div class="file__icon">
            <mat-icon svgIcon="file"></mat-icon>
            <span class="type">{{ file.type }}</span>
        </div>
        <div class="file-data">
            <div class="file-data__name">{{ file.name }}</div>
            <div class="file-data__size">
                {{ (file.size / 1024).toFixed(2) }}KB
            </div>
        </div>

        <div class="close" (click)="removeFile(file)">
            <mat-icon>close</mat-icon>
        </div>
    </div>
</div>

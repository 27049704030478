import { IServerSideGetRowsRequest } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { AppRestService, GridService } from '@app/shared';
import { Observable, map, switchMap, take } from 'rxjs';
import {
    DevicePositionDataEnum,
    SetLocationData,
} from './device-position.interface';
import { devicePositionSettings } from './device-position.settings';

@Injectable({
    providedIn: 'root',
})
export class DevicePositionService {
    constructor(
        public _appRestService: AppRestService,
        public _gridService: GridService,
        public _companyContextService: CompanyContextService
    ) {}

    public devicePosition(
        request: IServerSideGetRowsRequest,
        pageSize: number
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this._companyContextService
            .getCompanyContext()
            .pipe(
                take(1),
                switchMap((currentCompany: string) => {
                    const params: { [key: string]: any } = {
                        context: currentCompany,
                        aggregate: true,
                        inlinecount: 'allpages',
                    };

                    const query = new URLSearchParams(params).toString();

                    return this._appRestService.get(
                        `devices/device-locations`,
                        { query }
                    );
                })
            )
            .pipe(map(this._queryResultMapper));
    }

    public _queryResultMapper(data: any): {
        rowCount: number;
        rowData: Array<any>;
    } {
        return {
            rowCount: data.totalCount,
            rowData: data.result,
        };
    }

    public getDataFieldNameByKey(key: string): string {
        return devicePositionSettings[
            DevicePositionDataEnum[key as keyof typeof DevicePositionDataEnum]
        ]?.dataFieldName.replace('.', '/');
    }

    public getLocations(companyContext: string): Observable<any> {
        return this._appRestService.get(`devices/${companyContext}/locations`);
    }

    public getLocationDataByDeviceId(deviceSerialNo: string): Observable<any> {
        return this._appRestService.get(
            `devices/device-locations/${deviceSerialNo}`
        );
    }

    public setLocation(body: SetLocationData): Observable<any> {
        return this._appRestService.put(
            `devices/device-locations/${body.deviceSerialNo}`,
            body
        );
    }
}

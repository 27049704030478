import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { VehiclesRoutingModule } from './vehicles-routing.module';
import { VehiclesComponent } from './vehicles.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { VehiclesToolsPanelModule } from './vehicles-tools-panel';
import { RowsPerPageModule } from '@app/shared/grid/rows-per-page';
import { CreateVehicleComponent } from './create-vehicle/create-vehicle.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppDialogModule } from "@app/shared/app-dialog";
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [VehiclesComponent, CreateVehicleComponent, VehicleDetailsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        VehiclesRoutingModule,
        AgGridModule,
        VehiclesToolsPanelModule,
        AppDialogModule,
        RowsPerPageModule,
        MatButtonModule,
        MatSelectModule,
        MatInputModule,
        MatCheckboxModule,
        HttpClientModule,
        MatIconModule
    ],
    providers: [HttpClient],
})
export class VehiclesModule {}

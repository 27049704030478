export enum DevicePositionDataEnum {
    radioButton = 'radioButton',
    manuallySelectedLocation = 'manuallySelectedLocation',
    deviceSerialNo = 'deviceSerialNo',
    validatorPositionNo = 'validatorPositionNo',
    vehicleId = 'vehicleId',
    lastMessageReceivedDateTime = 'lastMessageReceivedDateTime',
    idbtsam = 'idbtsam',
    comments = 'comments',
}

export type DevicePositionData = {
    [key in DevicePositionDataEnum]: any;
};

export type DevicePositionSettings = {
    [key in DevicePositionDataEnum]: {
        dataFieldName: string;
        label: string;
        cellRendererComponent?: any;
        alwaysAvailable?: boolean;
        suppressMenu?: boolean;
        suppressSortable?: boolean;
        suppressFilterable?: boolean;
        lockVisible?: boolean;
        suppressDragLeaveHidesColumns?: boolean;
    };
};

export type DevicePositionColumnSettings = {
    key: DevicePositionDataEnum;
    visible: boolean;
};

export interface SetLocationData {
    deviceSerialNo: string;
    lastMessageReceivedDateTime: string;
    vehicleId: string;
    idbtsam: string;
    validatorPositionNo: string;
    manuallySelectedLocation: string;
    type: string | null;
    comments: string;
    internalVehicleId: string;
    companyContext: string;
    companyName: string;
    refreshedOn: string;
}

export interface DeviceLocationOption {
    id: string;
    context: string;
    name: string;
    companyContext: string;
    companyName: string;
    refreshedOn: string;
}

import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'app-service-checkboxs',
    templateUrl: './service-checkboxs.component.html',
    styleUrls: [
        './service-checkboxs.component.scss',
        '../new-command.component.scss',
    ],
})
export class ServiceCheckboxsComponent {
    @Input() serviceCheckboxsForm: FormGroup;
    @Input() isShorten = false;
}

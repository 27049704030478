
export class UserInfo {
  userName: string;
  userFullName: string;
  userCompanyContext: string;
  userCompanyName: string;
}

export class UserData extends UserInfo {
    email: string;
    emailVerified: string;
    familyName: string;
    givenName: string;
    permissions: string[];
    name: string;
    nickName: string;
    picture: string;
    userAuth0Id: string;
    userLastUpdated: Date;
  }


import { Injectable } from '@angular/core';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import {
    AppRestService,
    GridService,
    KeySettings,
    SettingsCacheService,
    TableFieldV2,
} from '@app/shared';
import { EventsV2Data, EventsV2DataEnum } from './heartbeatsV2.interface';
import { ColumnVO, IServerSideGetRowsRequest } from '@ag-grid-community/core';
import { DataFieldNameV2Map, eventsV2Settings } from './heartbeatsV2.settings';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { Modality } from '@app/models/modality.enum';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';

@Injectable({
    providedIn: 'root',
})
export class HeartbeatsV2Service {
    public _groupeByResultCache = new Map<string, any>();

    hasPermissionVehicleInfoAdditionalColumns = false;
    hasPermissionKV6Columns = false;
    hasPermissionFareMediaColumns = false;
    hasPermissionHardwareColumns = false;
    hasPermissionCLTColumns = false;

    constructor(
        public _appRestService: AppRestService,
        public _gridService: GridService,
        public _companyContextService: CompanyContextService,
        private _settingsCacheService: SettingsCacheService,
        private _permissionService: AppPermissionService
    ) {
        this._permissionService.user$.subscribe((user) => {
            this.setPermissions(user?.permissions);
        });
    }

    setPermissions(userPermissions: string[]): void {
        if (userPermissions.includes(AppPermissions.FeatureVehicleInfoAdditionalColumns)) {
            this.hasPermissionVehicleInfoAdditionalColumns = true;
        }
        else {
            this.hasPermissionVehicleInfoAdditionalColumns = false;
        }
        if (userPermissions.includes(AppPermissions.FeatureTablesKV6Columns)) {
            this.hasPermissionKV6Columns = true;
        }
        else {
            this.hasPermissionKV6Columns = false;
        }
        if (userPermissions.includes(AppPermissions.FeatureTablesFareMediaColumns)) {
            this.hasPermissionFareMediaColumns = true;
        }
        else {
            this.hasPermissionFareMediaColumns = false;
        }
        if (userPermissions.includes(AppPermissions.FeatureTablesHardwareColumns)) {
            this.hasPermissionHardwareColumns = true;
        }
        else {
            this.hasPermissionHardwareColumns = false;
        }
        if (userPermissions.includes(AppPermissions.FeatureTablesCLTColumns)) {
            this.hasPermissionCLTColumns = true;
        }
        else {
            this.hasPermissionCLTColumns = false;
        }
    }

    isColumnPermitted(tableFieldData: TableFieldV2): boolean {
        if (tableFieldData.dataFieldName === 'vehicleInfo.modelValidatorCount' ||
            tableFieldData.dataFieldName === 'vehicleInfo.validatorGroupNo' ||
            tableFieldData.dataFieldName === 'vehicleInfo.isPilot' ||
            tableFieldData.dataFieldName === 'vehicleInfo.modelName'
        ) {
            return this.hasPermissionVehicleInfoAdditionalColumns;
        }
        else if (tableFieldData.dataFieldName === 'vehicleInfo.kV6Status') {
            return this.hasPermissionKV6Columns;
        }
        else if (tableFieldData.dataFieldName === 'fareMedia.ovcStatus' ||
            tableFieldData.dataFieldName === 'fareMedia.ovcStatusReason' ||
            tableFieldData.dataFieldName === 'fareMedia.emvStatus' ||
            tableFieldData.dataFieldName === 'fareMedia.emvStatusReason' ||
            tableFieldData.dataFieldName === 'fareMedia.bcStatus' ||
            tableFieldData.dataFieldName === 'fareMedia.bcStatusReason' ||
            tableFieldData.dataFieldName === 'fareMedia.numberOfSDOATransactions' ||
            tableFieldData.dataFieldName === 'fareMedia.numberOfEMVTransactions' ||
            tableFieldData.dataFieldName === 'fareMedia.numberOfBarcodeTransactions'
        ) {
            return this.hasPermissionFareMediaColumns;
        }
        else if (tableFieldData.dataFieldName === 'hardwareInfo.servicePatternId' ||
            tableFieldData.dataFieldName === 'hardwareInfo.validatorModel' ||
            tableFieldData.dataFieldName === 'hardwareInfo.ramFree' ||
            tableFieldData.dataFieldName === 'hardwareInfo.ramTotal' ||
            tableFieldData.dataFieldName === 'hardwareInfo.diskTotal' ||
            tableFieldData.dataFieldName === 'hardwareInfo.diskFree' ||
            tableFieldData.dataFieldName === 'hardwareInfo.internalIPaddress' ||
            tableFieldData.dataFieldName === 'hardwareInfo.hasBarcodeReader'

        ) {
            return this.hasPermissionHardwareColumns;
        }
        else if (tableFieldData.dataFieldName === 'fareMedia.cltStatus' ||
            tableFieldData.dataFieldName === 'fareMedia.cltStatusReason'
        ) {
            return this.hasPermissionCLTColumns;
        }
        return true;
    }

    public companies(
        request: IServerSideGetRowsRequest,
        pageSize: number,
        modality: Modality = Modality.All
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this._companyContextService.getCompanyContext().pipe(
            filter(value => value !== ''),
            switchMap((currentCompany: string) => {
                if (this._isGroupByRequest(request)) {
                    return this.groupBy(request, currentCompany);
                } else {
                    return this.query(
                        request,
                        pageSize,
                        currentCompany,
                        modality
                    );
                }
            })
        );
    }

    public getTableFields(): Observable<Array<TableFieldV2>> {
        const cachedData = this._settingsCacheService.getDataFromLocalStorage(KeySettings.TableFieldsV2);
        if (cachedData && cachedData.length > 0) { return of(cachedData as TableFieldV2[]); }
        else {
            return this._appRestService.get('heartbeat-metadata/pulse?api-version=2').pipe(
                switchMap((data: Array<TableFieldV2>) => {
                    //check if the items in array are permitted using the flags set my setPermissions method
                    const allowedFields = data?.filter((tableField: TableFieldV2) => this.isColumnPermitted(tableField));
                    this._settingsCacheService.saveDataToLocalStorage(KeySettings.TableFieldsV2, allowedFields);
                    return of(allowedFields as TableFieldV2[]);
                }));
        }
    }

    public getTableFieldsMap(): Observable<Map<string, TableFieldV2>> {
        return this.getTableFields().pipe(
            map((list: Array<TableFieldV2>) =>
                list?.reduce(
                    (acc: Map<string, TableFieldV2>, item: TableFieldV2) =>
                        acc.set(item.dataFieldName, item),
                    new Map<string, TableFieldV2>()
                )
            )
        );
    }

    public _isGroupByRequest(request: IServerSideGetRowsRequest): boolean {
        return !!(
            request.rowGroupCols.length &&
            request.groupKeys.length < request.rowGroupCols.length
        );
    }

    public groupBy(
        request: IServerSideGetRowsRequest,
        currentCompany: string
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        const body = {
            groupByFields: request.rowGroupCols.map(
                (item: ColumnVO) =>
                    eventsV2Settings[
                        EventsV2DataEnum[item.id as keyof typeof EventsV2DataEnum]
                    ].dataFieldName
            ),
        };

        const bodyString = JSON.stringify(body);

        if (this._groupeByResultCache.has(bodyString)) {
            return of(this._groupeByResultCache.get(bodyString)).pipe(
                map((data) =>
                    this._gridService.groupByResultMap(
                        data,
                        request.groupKeys,
                        DataFieldNameV2Map
                    )
                )
            );
        } else {
            return this._appRestService
                .post(`companies/${currentCompany}/monitors/group-by?api-version=2`, body)
                .pipe(
                    tap((data: any) =>
                        this._groupeByResultCache.set(bodyString, data)
                    ),
                    map((data) =>
                        this._gridService.groupByResultMap(
                            data,
                            request.groupKeys,
                            DataFieldNameV2Map
                        )
                    )
                );
        }
    }

    public query(
        request: IServerSideGetRowsRequest,
        pageSize: number,
        currentCompany: string,
        modality: Modality = Modality.All
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        const skipVal = (request.endRow ?? 0) - pageSize;
        const params = new URLSearchParams();
        params.append('inlinecount', 'allpages');
        params.append('count', 'true');
        params.append('skip', `${skipVal}`);
        params.append('top', `${pageSize}`);
        params.append('api-version', '2');


        const query =
            new URLSearchParams(params).toString() +
            this._gridService.createOrderByParams(
                request.sortModel,
                this.getDataFieldNameByKey.bind(this)
            ) +
            this._gridService.createFilterParams(
                request,
                this.getDataFieldNameByKey.bind(this),
                false
            );


        return this._appRestService
            .get(`companies/${currentCompany}/monitors/query/${modality}`, {
                query,
            })
            .pipe(
                map(this._queryResultMapper)
            );
    }

    public _queryResultMapper(data: any): {
        rowCount: number;
        rowData: Array<any>;
    } {
        return {
            rowCount: data.totalCount,
            rowData: data.result.map(
                (item: any) =>
                    <EventsV2Data>{
                        connectionState: item.validatorInfo.connectionState,
                        deviceSerialNo: item.validatorInfo.deviceSerialNo,
                        lastReceivedEventType: item.events.messageType,
                        lastMessageReceivedDate: item.events.createdOn,
                        messageGeneratedDate:
                            item.events?.messageGeneratedDateTime,
                        messageIDCounter: item.events.messageCounter,
                        PTO: item.vehicleInfo?.companyName,
                        vehicle: item.vehicleInfo?.vehicleID,
                        validatorPositionNo: item.validatorInfo.validatorPositionNo,
                        vehicleType: item.vehicleInfo?.vehicleType,
                        isPilot: item.vehicleInfo?.isPilot,
                        modelName: item.vehicleInfo?.modelName,
                        modelValidatorCount: item.vehicleInfo?.modelValidatorCount,
                        validatorGroupNo: item.vehicleInfo?.validatorGroupNo,
                        OVCStatus: item.fareMedia.ovcStatus,
                        OVCStatusReason: item.fareMedia.ovcStatusReason,
                        EMVStatus: item.fareMedia.emvStatus,
                        EMVStatusReason: item.fareMedia.emvStatusReason,
                        BCStatus: item.fareMedia.bcStatus,
                        BCStatusReason: item.fareMedia.bcStatusReason,
                        CLTStatus: item.fareMedia.cltStatus,
                        CLTStatusReason: item.fareMedia.cltStatusReason,
                        validatorRole: item.validatorInfo.validatorRole,
                        validatorModel: item.hardwareInfo.validatorModel,
                        publicLineNo: item.tripPosition.publicLineNo,
                        previousStation: item.tripPosition.previousStationName,
                        nextStation: item.tripPosition.nextStationName,
                        previousStationRefNo: item.tripPosition.previousStationRefNo,
                        nextStationRefNo: item.tripPosition.nextStationRefNo,
                        trip: item.tripPosition.tripNo,
                        servicePatternId: item.hardwareInfo.servicePatternId,
                        validatorStatus: item.validatorInfo.validatorStatus,
                        statusCode: item.validatorInfo.statusCode,
                        reasonStatus: item.validatorInfo.reasonStatus,
                        errorCode: item.errorsWarnings.errorCode,
                        errorMessage: item.errorsWarnings.errorMessage,
                        warningCode: item.errorsWarnings.warningCode,
                        warningMessage: item.errorsWarnings.warningMessage,
                        EBSInterfaceStatus: item.tripPosition.ebsInterfaceStatus,
                        lastSoftwareUpdate: item.versions.lastSoftwareUpdateDate,
                        softwareVersion: item.versions.softwareVersion,
                        validatorConfigVersion:
                            item.versions.validatorConfigVersion,
                        validatorVersion: item.versions.validatorVersion,
                        issuerAccessListVersion:
                            item.versions.issuerAccessListVersion,
                        tokenAccessListVersion:
                            item.versions.tokenAccessListVersion,
                        ceilingValueCSCLeft: item.samInfo.ceilingValueCSCLeft,
                        ceilingValueCTLeft: item.samInfo.ceilingValueCTLeft,
                        IDBT_SAMUserAuthenticationCounter:
                            item.samInfo.idbtsamUserAuthenticationCounter,
                        IDBT_SAM: item.samInfo.idbtsam,
                        PKI_SAM: item.samInfo.pkisam,
                        PKI_SAMType: item.samInfo.pkisamType,
                        PKI_SAM_BEID: item.samInfo.pkisambeid,
                        EODCurrentEffectiveDate:
                            item.eod.eodEffectiveDateCurrent,
                        EODMCFVersionCurrent: item.eod.eodmcfVersionCurrent,
                        EODTopologyVersionCurrent:
                            item.eod.eodTopologyVersionCurrent,
                        EODFareVersionCurrent: item.eod.eodFareVersionCurrent,
                        EODDicoVersionCurrent: item.eod.eodDicoVersionCurrent,
                        EODBlackListVersionCurrent:
                            item.eod.eodBlackListCurrent,
                        EODTimeVersionCurrent: item.eod.eodTimeVersionCurrent,
                        EODFutureEffectiveDate:
                            item.eod.eodEffectiveDateFuture,
                        EODTopologyVersionFuture:
                            item.eod.eodTopologyVersionFuture,
                        EODFareVersionFuture: item.eod.eodFareVersionFuture,
                        EODDicoVersionFuture: item.eod.eodDicoVersionFuture,
                        EODTimeVersionFuture: item.eod.eodTimeVersionFuture,
                        TXARUploadDate: item.txar.txarUploadDate,
                        TXARFileCreateDate: item.txar.txarFileCreateDate,
                        TXARNumberOfTransaction: item.txar.numberOfTransaction,
                        TXARFileName: item.txar.fileName,
                        numberOfSDOATransactionsSinceLastPowerOn:
                            item.fareMedia.numberOfSDOATransactions,
                        numberOfEMVTransactionsSinceLastPowerOn:
                            item.fareMedia.numberOfEMVTransactions,
                        numberOfBarcodeTransactionsSinceLastPowerOn:
                            item.fareMedia.numberOfBarcodeTransactions,
                        ramTotal: item.hardwareInfo.ramTotal,
                        ramFree: item.hardwareInfo.ramFree,
                        diskTotal: item.hardwareInfo.diskTotal,
                        diskFree: item.hardwareInfo.diskFree,
                        internalIPAddress: item.hardwareInfo.internalIPaddress,
                        location: item.vehicleInfo?.gpsData,
                        kv6Status: item.vehicleInfo?.kV6Status,
                        connectionStateUpdatedOn: item.validatorInfo.connectionStateUpdatedOn,
                        betweenStops: item.tripPosition.betweenStops,
                        hasBarcodeReader: item.hardwareInfo.hasBarcodeReader,
                    }
            ),
        };
    }

    public getDataFieldNameByKey(key: string): string {
        return eventsV2Settings[
            EventsV2DataEnum[key as keyof typeof EventsV2DataEnum]
        ]?.dataFieldName.replace('.', '/');
    }
}

import {
    ColumnState,
    IServerSideGetRowsRequest,
} from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import {
    EventsColumnSettings,
    EventsDataEnum,
} from '@app/pages/heartbeats';
import {
    PositionHistoryColumnSettings,
    PositionHistoryDataEnum,
} from '@app/pages/position-history/position-history.interface';
import { CommandsColumnSettings, CommandsDataEnum } from '@app/pages/commands';
import { VehiclesColumnSettings, VehiclesDataEnum } from '@app/pages/vehicles';
import { UsersColumnSettings, UsersDataEnum } from '@app/pages/users';

import {
    DevicePositionColumnSettings,
    DevicePositionDataEnum,
} from '@app/pages/device-position';
import { Preferences } from '../preferences/preferences.service';
import { eventsSettings } from '@app/pages/heartbeats/heartbeats.settings';
import { positionHistorySettings } from '@app/pages/position-history/position-history.settings';
import { commandsSettings } from '@app/pages/commands/commands.settings';
import { vehiclesSettings } from '@app/pages/vehicles/vehicles.settings';
import { usersSettings } from '@app/pages/users/users.settings';
import { devicePositionSettings } from '@app/pages/device-position/device-position.settings';
import { SearchEventParams } from '@app/pages/event-history/event-history.service';
import { ValidatorGroupDataEnum, ValidatorGroupsColumnSettings } from '@app/pages/validator-groups/validator-groups.interface';
import { validatorGroupsSettings } from '@app/pages/validator-groups/validator-groups.settings';
import { EventsV2ColumnSettings, EventsV2DataEnum } from '@app/pages/heartbeatsV2';
import { eventsV2Settings } from '@app/pages/heartbeatsV2/heartbeatsV2.settings';

export enum KeySettings {
    Heartbeats = 'Heartbeats',
    HeartbeatsV2 = 'HeartbeatsV2',
    PositionHistory = 'PositionHistory',
    UserData = 'UserData',
    UserPermissions = 'UserPermissions',
    CompanyTree = 'CompanyTree',
    Preferences = 'Preferences',
    DefaultPreferences = 'defaultPreferences',
    Commands = 'Commands',
    Vehicles = 'Vehicles',
    PulseData = 'PulseData',
    Users = 'Users',
    DevicePosition = 'DevicePosition',
    EventHistory = 'EventHistory',
    EventHistorySearchParams = 'EventHistorySearchParams',
    TableFields = 'TableFields',
    TableFieldsV2 = 'TableFieldsV2',
    ValidatorGroups = 'ValidatorGroups',
    RolePermissionMapping = 'RolePermissionMapping',
    AllRoles = 'AllRoles',
    PageSizes = 'PageSizes',
    GridPageSize = 'GridPageSizes',
    SublistOptions = 'SublistOptions',

    // Grid User Preferences related settings are below
    HeartbeatsGrid = 'heartbeatsGrid',
    HeartbeatsV2Grid = 'heartbeatsV2Grid',
    CommandsGrid = 'commandsGrid',
    EventHistoryGrid = 'eventHistoryGrid',
    VehiclesGrid = 'vehiclesGrid',
    DevicePositionGrid = 'devicePositionGrid',
    PositionHistoryGrid = 'positionHistoryGrid',
    UsersGrid = 'usersGrid',
    ValidatorGroupsGrid = 'validatorGroupsGrid',
}

export interface PageSize{
    gridName: string,
    pageSize: number
}

@Injectable({
    providedIn: 'root',
})
export class SettingsCacheService {
    public resetAll(): void {
        localStorage.clear();
    }

    get eventHistorySearchParams(): SearchEventParams {
        const result = localStorage.getItem(
            KeySettings.EventHistorySearchParams
        );

        return result ? JSON.parse(result) : undefined;
    }

    set eventHistorySearchParams(eventsData: SearchEventParams) {
        localStorage.setItem(
            KeySettings.EventHistorySearchParams,
            JSON.stringify(eventsData)
        );
    }

    public get heartbeats(): PageSettingsCache<EventsColumnSettings> {
        const settings = localStorage.getItem(KeySettings.Heartbeats);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(EventsDataEnum),
                  eventsSettings
              );
    }

    public set heartbeats(settings: PageSettingsCache<EventsColumnSettings>) {
        localStorage.setItem(KeySettings.Heartbeats, JSON.stringify(settings));
    }

    public get heartbeatsV2(): PageSettingsCache<EventsV2ColumnSettings> {
        const settings = localStorage.getItem(KeySettings.HeartbeatsV2);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(EventsV2DataEnum),
                  eventsV2Settings
              );
    }

    public set heartbeatsV2(settings: PageSettingsCache<EventsV2ColumnSettings>) {
        localStorage.setItem(KeySettings.HeartbeatsV2, JSON.stringify(settings));
    }

    public getDataFromLocalStorage(dataKey: KeySettings) {
        const settings = localStorage.getItem(dataKey);
        return settings ? JSON.parse(settings) : undefined;
    }

    public saveDataToLocalStorage(dataKey: KeySettings, data: any) {
        localStorage.setItem(dataKey, JSON.stringify(data));
    }

    public get positionHistory(): PageSettingsCache<PositionHistoryColumnSettings> {
        const settings = localStorage.getItem(KeySettings.PositionHistory);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(PositionHistoryDataEnum),
                  positionHistorySettings
              );
    }

    public set positionHistory(
        settings: PageSettingsCache<PositionHistoryColumnSettings>
    ) {
        localStorage.setItem(
            KeySettings.PositionHistory,
            JSON.stringify(settings)
        );
    }
    set preferences(preferences: Preferences) {
        localStorage.setItem(
            KeySettings.Preferences,
            JSON.stringify(preferences)
        );
    }

    get preferences(): Preferences {
        return JSON.parse(
            localStorage.getItem(KeySettings.Preferences) as string
        );
    }

    removePreferences(): void {
        localStorage.removeItem(KeySettings.Preferences);
    }
    public get commands(): PageSettingsCache<CommandsColumnSettings> {
        const settings = localStorage.getItem(KeySettings.Commands);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(CommandsDataEnum),
                  commandsSettings
              );
    }

    public set commands(settings: PageSettingsCache<CommandsColumnSettings>) {
        localStorage.setItem(KeySettings.Commands, JSON.stringify(settings));
    }

    public get vehicles(): PageSettingsCache<VehiclesColumnSettings> {
        const settings = localStorage.getItem(KeySettings.Vehicles);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(VehiclesDataEnum),
                  vehiclesSettings
              );
    }

    public set vehicles(settings: PageSettingsCache<VehiclesColumnSettings>) {
        localStorage.setItem(KeySettings.Vehicles, JSON.stringify(settings));
    }

    public get validatorGroups(): PageSettingsCache<ValidatorGroupsColumnSettings> {
        const settings = localStorage.getItem(KeySettings.ValidatorGroups);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(ValidatorGroupDataEnum),
                  validatorGroupsSettings
              );
    }

    public set validatorGroups(settings: PageSettingsCache<ValidatorGroupsColumnSettings>) {
        localStorage.setItem(KeySettings.ValidatorGroups, JSON.stringify(settings));
    }

    public get pulseData(): PageSettingsCache<EventsV2ColumnSettings> {
        const settings = localStorage.getItem(KeySettings.PulseData);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(EventsV2DataEnum),
                  eventsSettings
              );
    }

    public set pulseData(settings: PageSettingsCache<EventsV2ColumnSettings>) {
        localStorage.setItem(KeySettings.PulseData, JSON.stringify(settings));
    }

    public get users(): PageSettingsCache<UsersColumnSettings> {
        const settings = localStorage.getItem(KeySettings.Users);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(Object.keys(UsersDataEnum), usersSettings);
    }

    public set users(settings: PageSettingsCache<UsersColumnSettings>) {
        localStorage.setItem(KeySettings.Users, JSON.stringify(settings));
    }

    public get devicePosition(): PageSettingsCache<DevicePositionColumnSettings> {
        const settings = localStorage.getItem(KeySettings.DevicePosition);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(DevicePositionDataEnum),
                  devicePositionSettings
              );
    }

    public set devicePosition(
        settings: PageSettingsCache<DevicePositionColumnSettings>
    ) {
        localStorage.setItem(
            KeySettings.DevicePosition,
            JSON.stringify(settings)
        );
    }

    public get eventHistory(): PageSettingsCache<EventsColumnSettings> {
        const settings = localStorage.getItem(KeySettings.EventHistory);
        return settings
            ? JSON.parse(settings)
            : new PageSettingsCache(
                  Object.keys(EventsDataEnum),
                  eventsSettings
              );
    }

    public set eventHistory(settings: PageSettingsCache<EventsColumnSettings>) {
        localStorage.setItem(
            KeySettings.EventHistory,
            JSON.stringify(settings)
        );
    }

    get getGridPageSizes(): PageSize[]{
        const data = localStorage.getItem(KeySettings.GridPageSize);
        if(data){
            return JSON.parse( data as string);
        }
        else{
            return []
        }
        
    }

    set getGridPageSizes(gridData : PageSize[]){
        localStorage.setItem(KeySettings.GridPageSize, JSON.stringify(gridData));
    }
}

export class PageSettingsCache<T> {
    constructor(private keys: Array<string>, private settings: any) {}

    public columnState: Array<ColumnState>;

    public columnSettingsList: Array<T> = this.keys.map(
        (key: string) =>
            ({
                key,
                visible: !this.settings[key]?.hide,
            } as T)
    );
    public serverSideGetRowsRequest: IServerSideGetRowsRequest = {
        startRow: 0,
        endRow: 0,
        rowGroupCols: [],
        valueCols: [],
        pivotCols: [],
        pivotMode: false,
        groupKeys: [],
        filterModel: {},
        sortModel: [],
    };
    public pageSize: PageSize = {
        gridName: '',
        pageSize: 0
    }
}

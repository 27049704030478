<ng-container *ngIf="isAvailableTable">
    <app-device-position-tools-panel
        [isAvailableToExport]="isAvailableToExport"
        [progressValueExport]="progressValueExport"
        [isOpenFilters]="isOpenFilters"
        [isOpenColumns]="isOpenColumns"
        (setLocation)="setLocation()"
        (exportToExcel)="exportToExcel()"
        (resetAll)="resetAll(getDefaultPreferencesForReset())"
        (clearSorting)="clearSorting()"
        (clearFiltering)="clearFiltering(settingsCacheService.devicePosition)"
        (clearRowGrouping)="clearRowGrouping()"
        (resetColumnOrder)="resetColumnOrder(getDefaultPreferencesForReset())"
        (resetColumnVisibility)="resetColumnVisibility(getDefaultPreferencesForReset())"
        (openFilters)="openFilters()"
        (openColumns)="openColumns()"
    >
        <button
            mat-stroked-button
            color="primary"
            [disabled]="!this.selectedDeviceId || !hasPermissionManageDevicePosition"
            (click)="setLocation()"
            [attr.aria-label]="locationLabel"
        >
            <span>{{ locationLabel }}</span>
        </button>
    </app-device-position-tools-panel>

    <ag-grid-angular
        class="ag-theme-alpine"
        [modules]="modules"
        [columnDefs]="columnDefs"
        [sideBar]="sideBar"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [animateRows]="true"
        [suppressDragLeaveHidesColumns]="false"
        [suppressMakeColumnVisibleAfterUnGroup]="true"
        [suppressRowGroupHidesColumns]="true"
        [rowGroupPanelShow]="rowGroupPanelShow"
        [rowModelType]="'clientSide'"
        [domLayout]="'autoHeight'"
        [rowData]="rowData"
        [groupDisplayType]="groupDisplayType"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize"
        [context]="context"
        [components]="components"
        [getLocaleText]="getLocaleText"
        [enableCellTextSelection]="enableCellTextSelection"
        [ensureDomOrder]="ensureDomOrder"
        (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>

    <div #rowsPerPage>
        <app-rows-per-page [selectedPageSize]="paginationPageSize" (pageSize)="onPageSizeChanged($event)">
        </app-rows-per-page>
    </div>
</ng-container>

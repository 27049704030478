import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';

@Component({
    selector: 'app-commands-tools-panel',
    templateUrl: './commands-tools-panel.component.html',
})
export class CommandsToolsPanelComponent implements OnInit {
    @Input() lastUpdated: Date;
    @Input() isAvailableToExport: boolean;
    @Input() progressValueExport: number;
    @Input() isOpenFilters: boolean;
    @Input() isOpenColumns: boolean;
    @Input() modality: string;

    @Output() refreshData = new EventEmitter();
    @Output() sendCommand = new EventEmitter();
    @Output() exportToExcel = new EventEmitter();
    @Output() resetAll = new EventEmitter();
    @Output() clearSorting = new EventEmitter();
    @Output() clearFiltering = new EventEmitter();
    @Output() clearRowGrouping = new EventEmitter();
    @Output() resetColumnOrder = new EventEmitter();
    @Output() resetColumnVisibility = new EventEmitter();
    @Output() openFilters = new EventEmitter();
    @Output() openColumns = new EventEmitter();
    hasPermissionManageCommands = false;

    refreshDataLabel = 'Refresh Data';
    constructor(private permissionService: AppPermissionService) {}
    ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            if (user?.permissions.includes(AppPermissions.ManageCommands)) {
                this.hasPermissionManageCommands = true;
            }
        });
    }
}

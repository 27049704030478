import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import {
    HttpClient,
    HttpHeaders,
    HttpResponse,
    HttpParams,
    HttpErrorResponse,
} from '@angular/common/http';
import {
    ApiResponse,
    IHttpOptions,
    IRestCallArgs,
    IRestCallOptions,
    IServerUrlResolver,
    RequestMethod,
    RestResponseType,
} from './app-rest.interface';
import { AppUtilService } from '@app/shared/app-utility';
import { AppConfigService } from 'config/app.config.service';
import { EnvironmentService } from 'environments/environment.service';

@Injectable({
    providedIn: 'root',
})
export class AppRestService {
    constructor(
        private http: HttpClient, private envService: EnvironmentService ) {}

    /**
     * Make a GET REST call
     *
     * @param svcPath - the service to be used eg. "companies/TLX/jobs"
     * @param options - optional options
     * @returns observable with data response
     */
    public get(svcPath: string, options?: IRestCallOptions): Observable<any> {
        return this.call(
            this.getCallArgs(svcPath, RequestMethod.Get, {}, options)
        );
    }

    /**
     * Make a DELETE REST call
     *
     * @param svcPath - the service to be used eg. "companies/TLX/job/{id}"
     * @param options - optional options
     * @returns observable with data response
     */
    public delete(
        svcPath: string,
        options?: IRestCallOptions
    ): Observable<any> {
        return this.call(
            this.getCallArgs(svcPath, RequestMethod.Delete, {}, options)
        );
    }

    /**
     * Make a POST REST call
     *
     * @param svcPath - the service to be used eg. "companies/TLX/job"
     * @param data - data to be posted
     * @param options - optional options
     * @returns observable with data response
     */
    public post(
        svcPath: string,
        data: object,
        options?: IRestCallOptions
    ): Observable<any> {
        return this.call(
            this.getCallArgs(svcPath, RequestMethod.Post, data, options)
        );
    }

    /**
     * Make a PUT REST call
     *
     * @param svcPath - the service to be used eg. "companies/TLX/job/{id}"
     * @param data - data to be posted
     * @param options - optional options
     * @returns observable with data response
     */
    public put(
        svcPath: string,
        data: object,
        options?: IRestCallOptions
    ): Observable<any> {
        return this.call(
            this.getCallArgs(svcPath, RequestMethod.Put, data, options)
        );
    }

    /**
     * Make a PATCH REST call
     *
     * @param svcPath - the service to be used eg. "companies/TLX/job/{id}"
     * @param data - data to be patched
     * @param options - optional options
     * @returns observable with data response
     */
    public patch(
        svcPath: string,
        data: object,
        options?: IRestCallOptions
    ): Observable<any> {
        return this.call(
            this.getCallArgs(svcPath, RequestMethod.Patch, data, options)
        );
    }

    /**
     * Get Rest Call arguments
     *
     * @param svcPath - the service to be used eg. "companies/TLX/jobs"
     * @param data - data
     * @param method - method that determines the kind of REST call
     * @param options - optional options
     * @returns rest call arguments object
     */
    private getCallArgs(
        svcPath: string,
        method: RequestMethod,
        data?: object,
        options?: IRestCallOptions
    ): IRestCallArgs {
        const restCallArgs: IRestCallArgs = {
            svcPath,
            method,
            data,
            options,
        };
        return restCallArgs;
    }

    /**
     * Make a Common REST call
     *
     * @param svc - the service to be used eg. "companies/TLX/jobs"
     * @param data - data
     * @param method - method that determines the kind of REST call
     * @param options - optional options
     * @returns observable with data response
     */
    private call(restArgs: IRestCallArgs): Observable<any> {
        const httpOptions: IHttpOptions = {};
        let hParams: HttpParams;
        httpOptions.headers = this.setHeaders(restArgs.options);
        if (restArgs.options) {
            //start construction of Http Params
            if (restArgs.options?.query) {
                hParams = new HttpParams({
                    fromString: restArgs.options.query.toString(),
                });
                httpOptions.params = hParams;
            }
            if (restArgs.options.responseType) {
                httpOptions.responseType = restArgs.options.responseType;
            }
        }
        const url = this.buildRestServiceUrl(
            this.envService.apiUrl,
            restArgs.svcPath
        );
        return this.requestHttpMethod(
            restArgs.method,
            url,
            restArgs.data,
            httpOptions
        ).pipe(
            map((result) => {
                return result;
            }),
            catchError((error) => {
                if (error instanceof HttpErrorResponse) {
                    if(error.status === 200 || error.statusText === "OK") return of(null);
                }
                return of(null);

            })
        );
    }

    /**
     * Handle a valid response
     *
     * @param response - the response from REST call
     * @returns response
     */
    private doHandleResponse(response: any): Observable<ApiResponse<any>> {
        //need to be expanded later

        return of({
            isAggregated: response?.isAggregated,
            lastUpdated: response?.lastUpdated,
            lastUpdatedDateTime: response?.lastUpdatedDateTime,
            result: response?.result,
            totalCount: response?.totalCount,
        });
    }

    private handleError(error: any): Observable<any> {
        let errorMessage: string;
        if (error instanceof HttpErrorResponse) {
            if(error.status === 200 || error.statusText === "OK") return of(error.error);
            errorMessage = `${error.status} - ${error.statusText || ''} ${
                error.message
            }`;
        } else {
            errorMessage = error.message ? error.message : error.toString();
        }

        return throwError(() => new Error(errorMessage));
    }

    /**
     * Builds the correct request method based on the method parameter.
     * @param method RequestMethod.
     * @param url Backend url
     * @param data data to be sent.
     * @param httpOptions HttpOptions
     */
    requestHttpMethod(
        method: RequestMethod,
        url: string,
        data?: object,
        httpOptions?: any
    ): Observable<any> {
        switch (method) {
            case RequestMethod.Patch:
                return this.http.patch(url, data, httpOptions);
            case RequestMethod.Post:
                return this.http.post<HttpResponse<any>>(
                    url,
                    data,
                    httpOptions
                );
            case RequestMethod.Delete:
                return this.http.delete<HttpResponse<any>>(url, httpOptions);
            case RequestMethod.Get:
                return this.http.get(url, httpOptions);
            case RequestMethod.Put:
                return this.http.put<HttpResponse<any>>(url, data, httpOptions);
            default:
                throw new Error('Invalid HTTP method');
        }
    }

    /**
     * Set the headers for the call. Sets the default headers and merges in the headers from
     * the options (if provided)
     *
     * @param _tkn - current token
     * @param options - optional options
     * @returns merged headers
     */
    private setHeaders(options?: IRestCallOptions): HttpHeaders {
        let httpHeaders: HttpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        const optionHeaders: HttpHeaders = options?.headers
            ? options.headers
            : new HttpHeaders();

        optionHeaders.keys().forEach((name) => {
            const val = optionHeaders.getAll(name);
            if (val) httpHeaders = httpHeaders.set(name, val);
        });

        return httpHeaders;
    }

    /**
     * Current url settings resolved
     */
    private urlResolver: IServerUrlResolver = {
        protocol: 'https://',
        serverUrl: '',
        restPath: '',
    };

    /**
     * Build rest service url with environment specific apiEndpoint
     * @param apiEndpoint
     * @param svcPath
     * @returns
     */
    public buildRestServiceUrl(apiEndpoint: string, svcPath: string): string {
        const url = apiEndpoint + '/' + svcPath;
        return url;
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommandsRoutingModule } from './commands-routing.module';
import { CommandsComponent } from './commands.component';
import { CommandsToolsPanelModule } from './commands-tools-panel';
import { AgGridModule } from '@ag-grid-community/angular';
import { RowsPerPageModule } from '@app/shared/grid/rows-per-page';
import { CommandDetailsComponent } from './command-details/command-details.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { NewCommandComponent } from './new-command/new-command.component';
import { ReactiveFormsModule } from '@angular/forms';

import {
    DateAdapter,
    MAT_DATE_FORMATS,
    MAT_DATE_LOCALE,
    MatNativeDateModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatIconModule } from '@angular/material/icon';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ServiceCheckboxsComponent } from './new-command/service-checkboxs/service-checkboxs.component';
import { MatRadioModule } from '@angular/material/radio';
import { CommandDetailsToolsPanelModule } from './command-details/command-details-tools-panel';
import { DevicesGridComponent } from './new-command/devices-grid/devices-grid.component';
import { SelectedDevicesGridComponent } from './new-command/selected-devices-grid/selected-devices-grid.component';
import { SelectDevicesComponent } from './new-command/select-devices/select-devices.component';
import { DevicesToolsPanelModule } from './new-command/devices-tools-panel/devices-tool-panel.module';
import { FormSliderModule } from '@app/shared/from-slider/form-slider.module';
import { UploadFilesModule } from '@app/shared/upload-files/upload-files.module';
import { DeviceSelectorService } from './new-command/devices-transfer.service';
import { SwitchToggleModule } from '@app/shared/switch-toggle/switch-toggle.module';
import { ConnectionStateCellRendererModule, CustomDateCellRendererModule } from '@app/shared/grid/cell-renderer';
import { AppDialogModule } from "@app/shared/app-dialog";

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY MMM DD HH:mm',
    },
    display: {
        dateInput: 'YYYY MMM DD HH:mm',
        monthYearLabel: 'YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'YYYY',
    },
};
@NgModule({
    declarations: [
        CommandsComponent,
        CommandDetailsComponent,
        NewCommandComponent,
        ServiceCheckboxsComponent,
        DevicesGridComponent,
        SelectedDevicesGridComponent,
        SelectDevicesComponent,
    ],
    imports: [
        CommonModule,
        CommandsRoutingModule,
        AgGridModule,
        AppDialogModule,
        CommandsToolsPanelModule,
        ReactiveFormsModule,
        CommandDetailsToolsPanelModule,
        RowsPerPageModule,
        MatButtonModule,
        MatExpansionModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatIconModule,
        MatCheckboxModule,
        MatRadioModule,
        DevicesToolsPanelModule,
        FormSliderModule,
        UploadFilesModule,
        SwitchToggleModule,
        CustomDateCellRendererModule,
        ConnectionStateCellRendererModule
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        DeviceSelectorService,
    ],
})
export class CommandsModule {}

import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommandNameCellRendererComponent } from './command-name-cell-renderer.component';

@NgModule({
    declarations: [CommandNameCellRendererComponent],
    exports: [CommandNameCellRendererComponent],
    imports: [CommonModule, AgGridModule],
})
export class CommandNameCellRendererModule {}

import {
    Component,
    EventEmitter, Inject,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import {
    Device,
    DevicesResponse,
    DevicesService,
} from '@app/services/devices/devices.service';
import { take } from 'rxjs';
import { DeviceSelectorService } from '../devices-transfer.service';
import { DevicesGridComponent } from '../devices-grid/devices-grid.component';
import { IRowNode } from '@ag-grid-community/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppDialogConfig } from "@app/shared/app-dialog/app-dialog.config";
import { AppDialogService } from "@app/shared/app-dialog/app-dialog.service";
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { Modality } from '@app/models/modality.enum';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';

@Component({
    selector: 'app-select-devices',
    templateUrl: './select-devices.component.html',
    styleUrls: [
        './select-devices.component.scss',
        '../new-command.component.scss',
        '../../command-details/command-details.component.scss',
    ],
})
export class SelectDevicesComponent implements OnInit {
    @Output() selectedDevicesChanged: EventEmitter<Device[]> = new EventEmitter<
        Device[]
    >();
    @ViewChild(DevicesGridComponent) devicesGrid: DevicesGridComponent;
    devices: Device[] = [];
    allDevices: Device[] = [];
    searchForm: FormGroup;
    currentSelection: Device[] = [];
    modality: Modality = Modality.None
    constructor(
        public dialogRef: MatDialogRef<SelectDevicesComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        public appDialogService: AppDialogService,
        public devicesService: DevicesService,
        public companyContextService: CompanyContextService,
        public deviceTransferService: DeviceSelectorService,
        private permissionService: AppPermissionService        
    ) {}

    ngOnInit(): void {
        this.searchForm = new FormGroup({
            vehicleId: new FormControl(''),
            serialNumber: new FormControl(''),
        });

        this.permissionService.user$.subscribe((user) => {
            const userPermissions = user?.permissions;
            if (userPermissions.includes(AppPermissions.FeatureCommandsPTOViewsAll)) {
                this.modality = Modality.All;
            } else {
                this.modality = Modality.None;
            }            
        });

        this.searchForm
            .get('vehicleId')
            ?.valueChanges.subscribe((v: string) => {
                this.devices = this.allDevices.filter((device: Device) => {
                    return this.currentSelection.find(
                        (selectedDevice: Device) =>
                            selectedDevice.deviceId === device.deviceId
                    )
                        ? true
                        : device.vehicleId
                              .toLocaleLowerCase()
                              .includes(v.toLowerCase());
                });
                this.syncSelected();
            });

        this.searchForm
            .get('serialNumber')
            ?.valueChanges.subscribe((v: string) => {
                this.devices = this.allDevices.filter((device: Device) => {
                    return this.currentSelection.find(
                        (selectedDevice: Device) =>
                            selectedDevice.deviceId === device.deviceId
                    )
                        ? true
                        : device.deviceSerialNo
                              .toLocaleLowerCase()
                              .includes(v.toLowerCase());
                });
                this.syncSelected();
            });

        this.companyContextService
            .getCompanyContext()
            .subscribe((res: string) => {
                this.devicesService
                    .getDevices(res, this.modality)
                    .pipe(take(1))
                    .subscribe((devicesRes: DevicesResponse) => {
                        this.allDevices = devicesRes.result;
                        this.devices = devicesRes.result;
                    });
            });

        this.deviceTransferService.deviceSelectionClear.subscribe(
            (res: any) => {
                this.updateSelectedDevices([]);
                this.deviceTransferService.updateDevices([]);
            }
        );
    }

    syncSelected(): void {
        setTimeout(() => {
            this.devicesGrid._grid.api.forEachNode((node: IRowNode<Device>) => {
                this.currentSelection.forEach((selectedNode: Device) => {
                    if (node.data?.deviceId === selectedNode.deviceId) {
                        node.setSelected(true);
                    }
                });
            });
        });
    }

    updateSelectedDevices(devices: Device[]) {
        this.currentSelection = [...devices];
    }

    closeSelectDevices() {
        this.appDialogService.closeSliderDialog(this.dialogRef);
    }

    saveSelection() {
        this.deviceTransferService.updateDevices(this.currentSelection);
        this.closeSelectDevices();
    }
}

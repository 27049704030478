import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    ExportService,
    GridComponent,
    GridService,
    KeySettings,
    PERCENTAGE,
    PageSettingsCache,
    SettingsCacheService,
    TableField,
} from '@app/shared';
import {
    IVehicleModel,
    VehiclesColumnSettings,
    VehiclesData,
    VehiclesDataEnum,
} from './vehicles.interface';
import { VehiclesService } from './vehicles.service';
import { HeartbeatsService } from '../heartbeats/heartbeats.service';
import {
    ColDef,
    ColumnState,
    ColumnVO,
    GridReadyEvent,
} from '@ag-grid-community/core';
import { Observable, Subject, filter, take, takeUntil } from 'rxjs';
import { vehiclesSettings } from './vehicles.settings';
import { TableFieldTypes } from '@app/shared/operators';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { NavigationStart, Router } from '@angular/router';
import { PreferencesService } from '@app/shared/preferences/preferences.service';
import { AppDialogService } from "@app/shared/app-dialog/app-dialog.service";
import { DialogSizes } from "@app/shared/app-dialog/app-dialog.config";
import { CreateVehicleComponent } from "@app/pages/vehicles/create-vehicle/create-vehicle.component";
import { LicenseManager } from '@ag-grid-enterprise/core';
import { EnvironmentService } from 'environments/environment.service';

@Component({
    selector: 'app-vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['../../shared/grid/grid.component.scss'],
})
export class VehiclesComponent
    extends GridComponent
    implements OnInit, OnDestroy
{
    private isGridDataLoaded = false;
    public rowData!: Array<IVehicleModel>;
    destroy$: Subject<void> = new Subject();

    public vehicleToEdit: IVehicleModel | null = null;
    vehiclesSettings = vehiclesSettings;
    hasPermissionManageVehicles = false;
    public get isSelectedVehicle(): boolean {
        return this.vehicleToEdit !== null;
    }

    constructor(
        private heartbeatsService: HeartbeatsService,
        private vehiclesService: VehiclesService,
        private exportService: ExportService,
        public override settingsCacheService: SettingsCacheService,
        private gridService: GridService,
        public appDialogService: AppDialogService,
        public _companyContextService: CompanyContextService,
        private permissionService: AppPermissionService,
        private router: Router,
        private prefService: PreferencesService,
        private env: EnvironmentService
    ) {
        super();
        LicenseManager.setLicenseKey(this.env.agGridLicenseKey);
    }

    public ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            if (user?.permissions.includes(AppPermissions.ManageVehicles)) {
                this.hasPermissionManageVehicles = true;
            }
        });
        this.prefService.enableSavingGridPreferences(KeySettings.VehiclesGrid);
        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter((event) => event instanceof NavigationStart)
            )
            .subscribe(() => {
                const colState = this._grid.columnApi?.getColumnState();
                this.settingsCacheService.saveDataToLocalStorage(
                    KeySettings.VehiclesGrid,
                    colState
                );
            });

        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next: (tableFieldsMap: Map<string, TableField>) => {
                    const settings = this.settingsCacheService.vehicles;

                    for (const columnSettings of settings.columnSettingsList) {
                        const rowGroupIndex =
                            settings.serverSideGetRowsRequest.rowGroupCols.findIndex(
                                (column: ColumnVO) =>
                                    column.id === columnSettings.key
                            );
                        const colSettings =
                            vehiclesSettings[
                                VehiclesDataEnum[
                                    columnSettings.key as keyof typeof VehiclesDataEnum
                                ]
                            ];
                        const dataFieldName = colSettings.dataFieldName;

                        this.columnDefs.push({
                            field: columnSettings.key,
                            filter: colSettings.suppressFilterable
                                ? null
                                : this.gridService.createFilter(
                                      tableFieldsMap.get(
                                          colSettings.dataFieldName
                                      )?.dataType ?? TableFieldTypes.STRING
                                  ),
                            filterParams: {
                                filterOptions:
                                    this.gridService.createFilterOptions(
                                        tableFieldsMap.get(
                                            colSettings.dataFieldName
                                        )?.dataType ?? TableFieldTypes.STRING
                                    ),
                            },
                            sortable: !colSettings.suppressMenu,
                            enableRowGroup: true,
                            rowGroup: rowGroupIndex > -1,
                            rowGroupIndex:
                                rowGroupIndex > -1 ? rowGroupIndex : null,
                            sort:
                                settings.serverSideGetRowsRequest.sortModel[0]
                                    ?.colId === columnSettings.key
                                    ? settings.serverSideGetRowsRequest
                                          .sortModel[0]?.sort
                                    : null,
                            hide:
                                !colSettings.alwaysAvailable &&
                                !columnSettings.visible,
                            suppressMenu: colSettings.suppressMenu,
                            lockVisible: colSettings.lockVisible,
                            suppressDragLeaveHidesColumns:
                                colSettings.suppressDragLeaveHidesColumns,
                            headerName:
                                tableFieldsMap.get(dataFieldName)
                                    ?.displayName ?? colSettings.label,
                            cellRenderer: colSettings.cellRendererComponent,
                        } as ColDef);
                    }
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: 'Group rows by column:',
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer',
                        } as ColDef,
                        ...this.columnDefs,
                    ];
                },
            });
    }

    public ngOnDestroy(): void {
        this.companyContextSubscription.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }

    public refreshDataByContext(): void {
        this._grid.api.showLoadingOverlay();
        this.isGridDataLoaded = false;
        this.vehicleToEdit = null;
        this.vehiclesService
            .vehicles()
            .pipe(take(1))
            .subscribe((data: any) => {
                this.isGridDataLoaded = true;
                this._grid.api.setRowData(data.rowData);
                this._grid.api.hideOverlay();
                this.refreshData(false);
            });
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;

        this.companyContextSubscription = this._companyContextService
            .getCompanyContext()
            .subscribe(() => this.refreshDataByContext());

        this._grid.api.addEventListener('columnVisible', (event: any) => {
            this.settingsCacheService.vehicles = {
                ...this.settingsCacheService.vehicles,
                columnSettingsList:
                    this.settingsCacheService.vehicles.columnSettingsList.map(
                        (
                            column: VehiclesColumnSettings
                        ): VehiclesColumnSettings => {
                            if (
                                event.column === null ||
                                column.key === event.column.colId
                            ) {
                                return {
                                    ...column,
                                    visible: event.visible,
                                };
                            }

                            return column;
                        }
                    ),
            } as PageSettingsCache<VehiclesColumnSettings>;
        });

        const filterModel =
            this.settingsCacheService.vehicles.serverSideGetRowsRequest
                .filterModel;

        if (filterModel) {
            for (const [key, value] of Object.entries(filterModel)) {
                this._grid.api.getFilterInstance(key)?.setModel(value);
            }

            this.refreshData();
        }

        this._initRowsPerPage();
        this._initColumnRowGroup(true);
        this._initDomLayout();

        const keySetting = KeySettings.VehiclesGrid;
        const gridPreferencesLocal =
            this.settingsCacheService.getDataFromLocalStorage(keySetting);
        const gridPreferencesDB =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.Preferences
            )['preferences'][keySetting];

        const gridPreferencesDefault =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.DefaultPreferences
            )['preferences'][keySetting];

        if (gridPreferencesLocal) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesLocal,
                applyOrder: true,
            });
        } else if (gridPreferencesDB) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesDB,
                applyOrder: true,
            });
        } else if (gridPreferencesDefault) {
            this._grid.columnApi.applyColumnState({
                state: gridPreferencesDefault,
                applyOrder: true,
            });
        }
                
        const pageSize = this.settingsCacheService.getGridPageSizes?.find((pageSize) => pageSize.gridName === KeySettings.VehiclesGrid);
        if(pageSize){
            this.paginationPageSize = pageSize.pageSize;
            this.cacheBlockSize = pageSize.pageSize;
            this._grid.api.paginationSetPageSize(pageSize.pageSize);
        }
    }

    public getData(
        endRow: number,
        chunkSize: number
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this.vehiclesService.vehicles().pipe(take(1));
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        const chunkSize = 1000000;
        const endRow = chunkSize;
        const rowData: Array<VehiclesData> = [];

        this.getData(endRow, chunkSize).subscribe(
            (data: { rowCount: number; rowData: Array<VehiclesData> }) =>
                this.updateProgressBar(
                    endRow + chunkSize,
                    rowData,
                    chunkSize,
                    data
                )
        );
    }

    public updateProgressBar(
        endRow: number,
        rowData: Array<VehiclesData>,
        chunkSize: number,
        data: { rowCount: number; rowData: Array<any> }
    ): void {
        rowData = [...rowData, ...data.rowData];

        this.progressValueExport = Math.round(
            PERCENTAGE(rowData.length, data.rowCount)
        );

        if (rowData.length < data.rowCount) {
            this.getData(endRow, chunkSize).subscribe(
                (data: { rowCount: number; rowData: Array<VehiclesData> }) =>
                    this.updateProgressBar(
                        endRow + chunkSize,
                        rowData,
                        chunkSize,
                        data
                    )
            );
        } else {
            if (this.isGridDataLoaded) {
                const gridRowsData: VehiclesData[] = this._getGridRowsData();
                rowData = gridRowsData
                    .map((item) => {
                        return rowData.find((row) => {
                            return Object.keys(VehiclesDataEnum)
                                .filter(
                                    (key) =>
                                        key !== VehiclesDataEnum.radioButton
                                )
                                .every(
                                    (key) =>
                                        item[key as keyof VehiclesData] ===
                                        row[key as keyof VehiclesData]
                                );
                        });
                    })
                    .filter((row) => !!row) as VehiclesData[];
            }
            const columnsOrder = this._getColumnsOrderForExport(
                vehiclesSettings,
                VehiclesDataEnum
            );
            const result = this._prepareDataForExport(
                rowData,
                vehiclesSettings,
                VehiclesDataEnum
            );
            if (result) {
                this.exportService.exportToExcel(
                    result,
                    chunkSize,
                    'exported_data.xlsx',
                    'Chunk_',
                    columnsOrder
                );
            }
            this.progressValueExport = 0;
            this.isAvailableToExport = true;
        }
    }

    public newVehicle(): void {
        if (this.vehicleToEdit) {
            this.vehicleToEdit = null;
            const radioButtons = document.getElementsByTagName(
                'mat-radio-button'
            ) as any;

            for (let i = 0; i < radioButtons.length; i++) {
                radioButtons[i].getElementsByTagName('input')[0].checked =
                    false;
                radioButtons[i].classList.remove('mat-mdc-radio-checked');
            }
        }
        this.appDialogService
            .openDialog({
                title: 'New Vehicle',
                showDialogButtons: false,
                component: CreateVehicleComponent,
                dialogSize: DialogSizes.Small,
                panelClass: ['side-slider'],
                data: {
                    vehicleToEdit: null,
                },
            })
            .subscribe((data) => {
                if (data) {
                    this.vehiclesService
                        .createVehicle(data)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.refreshDataByContext();
                        });
                }
            });
    }

    public editVehicle(): void {
        this.appDialogService
            .openDialog({
                title: 'Edit Vehicle',
                showDialogButtons: false,
                component: CreateVehicleComponent,
                dialogSize: DialogSizes.Small,
                panelClass: ['side-slider'],
                data: {
                    vehicleToEdit: this.vehicleToEdit,
                },
            })
            .subscribe((data) => {
                if (data) {
                    this.vehiclesService
                        .updateVehicle(data)
                        .pipe(take(1))
                        .subscribe(() => {
                            this.refreshDataByContext();
                        });
                }
            });
    }

    public deleteVehicle(): void {
        if (this.vehicleToEdit) {
            this.vehiclesService
                .delete(this.vehicleToEdit.fullVehicleId)
                .subscribe(() => {
                    this.refreshDataByContext();
                });
        }
    }

    public selected(data: any) {
        this.vehicleToEdit = { ...data };
    }

    public getDefaultPreferencesForReset(): ColumnState[] | null {
        const keySetting = KeySettings.VehiclesGrid;
        const gridPreferencesDefault =
            this.settingsCacheService.getDataFromLocalStorage(
                KeySettings.DefaultPreferences
            )['preferences'][keySetting];
        if (gridPreferencesDefault) {
            return gridPreferencesDefault as ColumnState[];
        }
        return null;
    }

    public override onPageSizeChanged(value: number): void {
        this.paginationPageSize = value;
        this.cacheBlockSize = value;
        const currentPageSizes = this.settingsCacheService.getGridPageSizes;
        if(currentPageSizes){
           //check if the gridName already exists in the array and if so update the pageSize
           const index = currentPageSizes.findIndex(x => x.gridName === KeySettings.VehiclesGrid);
           if(index !== -1){
               currentPageSizes[index].pageSize = value;
           } else{
               currentPageSizes.push({gridName: KeySettings.VehiclesGrid, pageSize: value});
           }
           this.settingsCacheService.getGridPageSizes = currentPageSizes;
        } else{
            this.settingsCacheService.getGridPageSizes = [{gridName: KeySettings.VehiclesGrid, pageSize: value}];
        }  
        this._grid.api.paginationSetPageSize(value);
    }
}

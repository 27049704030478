import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DefaultTableToolsPanelComponent } from './default-table-tools-panel.component';
import { AngularMaterialModule } from '@app/angular-material/angular-material.module';
import { ToolsPanelModule } from '../tools-panel.module';



@NgModule({
  declarations: [
    DefaultTableToolsPanelComponent
  ],
  exports: [
    DefaultTableToolsPanelComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    ToolsPanelModule,
  ]
})
export class DefaultTableToolsPanelModule { }

import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';

@Component({
    selector: 'app-check-box-cell-renderer',
    template: `<mat-checkbox [(ngModel)]="isSelected" (change)="selected()"></mat-checkbox>`,
})
export class CheckBoxCellRendererComponent
    implements ICellRendererAngularComp
{
    public params!: ICellRendererParams;
    public isSelected: boolean;
    public agInit(params: ICellRendererParams): void {
        this.params = params;
        this.isSelected = !!this.params.node.isSelected();
        this.params.api.addEventListener('selectionChanged', () => {
            this.isSelected = !!this.params.node.isSelected();
        });
    }

    public refresh(): boolean {
        this.isSelected = !!this.params.node.isSelected();
        return true;   
    }

    public selected(): void {
        this.params.node.setSelected(this.isSelected);        
    }
}



import { Injectable } from '@angular/core';
import { SortModelItem } from '@ag-grid-community/core';
import { AGGridToODataOperatorsMapper, DateTimeOperatorsMap, NumberOperatorsMap, StringOperatorsMap, StringOperatorsMapV2 } from './grid.interface';
import { FilterOptionsDate, FilterOptionsNumber, FilterOptionsString, TableFieldTypes } from '../operators';

const nullGroup = 'None';

@Injectable({
    providedIn: 'root',
})
export class GridService {
    public createOrderByParams(
        sortModel: Array<SortModelItem>,
        getDataFieldNameByKey: any
    ): string {
        let orderByParams = '';

        if (sortModel.length) {
            const sortItem = sortModel[0];
            const orderBy = getDataFieldNameByKey(sortItem.colId);
            const sort: 'asc' | 'desc' = sortItem.sort;
            orderByParams = `&orderby=${orderBy} ${sort}`;
        }

        return orderByParams;
    }

    public _createOperator(
        operatorsMap: Map<string, any>,
        type: string,
        field: string,
        value: string
    ): string {
        return (
            operatorsMap
                .get(AGGridToODataOperatorsMapper[type])
                ?.operatorTemplate.replace('field', field)
                .replace('value', value) ?? ''
        );
    }

    public _createFilter(
        filterModel: any,
        dataFieldName: string,
        operatorsMap: Map<string, any>
    ): string {
        const {
            filterType,
            filter,
            type,
            operator,
            condition1,
            condition2,
            dateFrom,
        } = filterModel;

        if (filterType === 'date') {
            if(type === 'blank' || type === 'notBlank'){
                return this._createOperator(
                    operatorsMap,
                    type,
                    dataFieldName,
                    '1970-01-01 00:00'
                );
            }
            if (dateFrom) {
                return this._createOperator(
                    operatorsMap,
                    type,
                    dataFieldName,
                    new Date(dateFrom).toISOString()
                );
            } else if (condition1.type || condition2.type) {
                return (
                    this._createOperator(
                        operatorsMap,
                        condition1.type,
                        dataFieldName,
                        new Date(condition1.dateFrom).toISOString()
                    ) +
                    ` ${operator} ` +
                    this._createOperator(
                        operatorsMap,
                        condition2.type,
                        dataFieldName,
                        new Date(condition2.dateFrom).toISOString()
                    )
                );
            }
        }

        if (filterType === 'number') {
            if(type === 'blank' || type === 'notBlank'){
                return this._createOperator(
                    operatorsMap,
                    type,
                    dataFieldName,
                    '0'
                );
            }
            if (filter) {
                return this._createOperator(
                    operatorsMap,
                    type,
                    dataFieldName,
                    filter
                );
            } else {
                return (
                    this._createOperator(
                        operatorsMap,
                        condition1.type,
                        dataFieldName,
                        condition1.filter
                    ) +
                    ` ${operator} ` +
                    this._createOperator(
                        operatorsMap,
                        condition2.type,
                        dataFieldName,
                        condition2.filter
                    )
                );
            }
        }
        if(type === 'blank' || type === 'notBlank'){
            return this._createOperator(
                operatorsMap,
                type,
                dataFieldName,
                'blankValue'
            );
        }

        if (filter) {
            return this._createOperator(
                operatorsMap,
                type,
                dataFieldName,
                `'${filter}'`
            );
        } else {
            return (
                this._createOperator(
                    operatorsMap,
                    condition1.type,
                    dataFieldName,
                    `'${condition1.filter}'`
                ) +
                ` ${operator} ` +
                this._createOperator(
                    operatorsMap,
                    condition2.type,
                    dataFieldName,
                    `'${condition2.filter}'`
                )
            );
        }

    }

    public createFilterParams(
        request: any,
        getDataFieldNameByKey: any,
        redisEndpoint = false
    ): string {
        const rowGroupCols = request.rowGroupCols;
        const groupKeys = request.groupKeys;
        const filterModel = request.filterModel;
        const keys: Array<string> = Object.keys(filterModel);
        let result = keys.reduce(
            (acc: string, key: string, index: number) => {
                const dataFieldName = getDataFieldNameByKey(key);

                if (index) {
                    acc += ' AND ';
                }

                switch (filterModel[key].filterType) {
                    case 'text':
                        if(redisEndpoint) {
                            acc += this._createFilter(
                                filterModel[key],
                                dataFieldName,
                                StringOperatorsMapV2
                            );                            
                        }
                        else {
                            acc += this._createFilter(
                                filterModel[key],
                                dataFieldName,
                                StringOperatorsMap
                            );                            
                        }
                        break;

                    case 'number':
                        acc += this._createFilter(
                            filterModel[key],
                            dataFieldName,
                            NumberOperatorsMap
                        );
                        break;

                    case 'date':
                        acc += this._createFilter(
                            filterModel[key],
                            dataFieldName,
                            DateTimeOperatorsMap
                        );
                        break;
                }

                return acc;
            },
            keys.length ? '&$filter=' : ''
        );

        if (groupKeys.length) {
            result = groupKeys.reduce(
                (acc: string, key: string, index: number) => {
                    const field = rowGroupCols[index].field;
                    const dataFieldName = getDataFieldNameByKey(field);

                    if (index) {
                        acc += ' AND ';
                    }

                    return (
                        acc +
                        this._createOperator(
                            StringOperatorsMap,
                            'equals',
                            dataFieldName,
                            key !== nullGroup ? `'${key}'` : 'null'
                        )
                    );
                },
                result === '' ? '&$filter=' : result + ' AND '
            );
        }

        return result;
    }

    public _valuesMap(
        groupsMap: Map<any, any>,
        keys: Array<string>,
        parentKey: string,
        parentValue: string,
        data: Array<any>
    ): Map<any, any> {
        if (keys.length) {
            const key = keys[0];

            data.forEach((item: any) => {
                if (parentValue === '' || parentValue === item[parentKey]) {
                    if (item[key] !== undefined) {
                        groupsMap.set(
                            item[key],
                            this._valuesMap(
                                groupsMap.get(item[key]) ?? new Map<any, any>(),
                                keys.slice(1),
                                key,
                                item[key],
                                data
                            )
                        );
                    } else {
                        groupsMap.set(
                            nullGroup,
                            this._valuesMap(
                                groupsMap.get(nullGroup) ?? new Map<any, any>(),
                                keys.slice(1),
                                key,
                                item[key],
                                data
                            )
                        );
                    }
                }
            });
        }

        return groupsMap;
    }

    public _createGroupsMap(data: Array<any>): Map<any, any> {
        let keys = Object.keys(data[0]).filter(
            (key: string) => key !== 'count'
        );
        if (!keys.length) {
            keys = Object.keys(data[1]).filter(
                (key: string) => key !== 'count'
            );
        }

        return this._valuesMap(new Map<any, any>(), keys, '', '', data);
    }

    public _getGroups(
        groupsMap: Map<any, any>,
        groupKeys: Array<string>
    ): Array<string> {
        return groupKeys.length
            ? this._getGroups(groupsMap.get(groupKeys[0]), groupKeys.slice(1))
            : Array.from(groupsMap.keys());
    }

    public groupByResultMap(
        data: any,
        groupKeys: Array<string>,
        dataFieldNameMap: Map<string, string>
    ): any {
        const groupsMap = this._createGroupsMap(data.result);
        const groups = this._getGroups(groupsMap, groupKeys);

        let keys = Object.keys(data.result[0]).filter(
            (key: string) => key !== 'count'
        );
        if (!keys.length) {
            keys = Object.keys(data.result[1]).filter(
                (key: string) => key !== 'count'
            );
        }

        return {
            rowCount: groups.length,
            rowData: groups.map((group: any) => {
                const key = keys[groupKeys.length];
                const result: { [key: string]: any } = {};
                const dataFieldName = dataFieldNameMap.get(key) ?? '';

                if (dataFieldName) {
                    result[dataFieldName] = group == null ? 'None' : group;
                }

                return result;
            }),
        };
    }

    public createFilter(
        type: TableFieldTypes
    ): 'agTextColumnFilter' | 'agNumberColumnFilter' | 'agDateColumnFilter' {
        switch (type) {
            case TableFieldTypes.NUMBER:
                return 'agNumberColumnFilter';
            case TableFieldTypes.DATE_TIME:
                return 'agDateColumnFilter';
        }

        return 'agTextColumnFilter';
    }

    public createFilterOptions(type: TableFieldTypes) {
        switch (type) {
            case TableFieldTypes.NUMBER:
                return FilterOptionsNumber;
            case TableFieldTypes.DATE_TIME:
                return FilterOptionsDate;
        }
        return FilterOptionsString;
    }
}

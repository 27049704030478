import { RadioButtonCellRendererComponent } from "@app/shared/grid/cell-renderer";
import { VehiclesDataEnum, VehiclesSettings } from "./vehicles.interface";


export const vehiclesSettings: VehiclesSettings = {
    [VehiclesDataEnum.radioButton]: { 
        dataFieldName: VehiclesDataEnum.radioButton,
        label: '',
        cellRendererComponent: RadioButtonCellRendererComponent,
        alwaysAvailable: true,
        suppressMenu: true,
        suppressSortable: true,
        suppressFilterable: true,
        lockVisible: true,
        suppressDragLeaveHidesColumns: true,
    },
    [VehiclesDataEnum.companyContext]: { 
        dataFieldName: VehiclesDataEnum.companyContext,
        label: 'Concession/Modality',
    },
    [VehiclesDataEnum.vehicleId]: { 
        dataFieldName: VehiclesDataEnum.vehicleId,
        label: 'Vehicle ID',
    },
    [VehiclesDataEnum.vehicleModel]: { 
        dataFieldName: VehiclesDataEnum.vehicleModel,
        label: 'Vehicle Model',
    },
    [VehiclesDataEnum.numberOfValidators]: { 
        dataFieldName: VehiclesDataEnum.numberOfValidators,
        label: 'Number Of Validators',
    },
    [VehiclesDataEnum.pilot]: { 
        dataFieldName: VehiclesDataEnum.pilot,
        label: 'Pilot',
    },
} as VehiclesSettings;

export const DataFieldNameMap: Map<string, string> = Object.keys(VehiclesDataEnum).reduce(
    (acc: Map<string, string>, field: string) => (acc.set(
        vehiclesSettings[VehiclesDataEnum[field as keyof typeof VehiclesDataEnum]].dataFieldName, 
        field
    )),
    new Map<string, string>
);

import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@app/shared/snackbar/snackbar.component';

export enum NotificationType {
    Info = 'Info',
    Warn = 'Warn',
    Error = 'Error',
}

@Injectable({
    providedIn: 'root',
})
export class AppNotificationService {
    constructor(private _snackBar: MatSnackBar) {}

    showSnackBar(
        message: string,
        notificationType: NotificationType,
        duration: number
    ) {
        this._snackBar.openFromComponent(SnackBarComponent, {
            data: { message, notificationType },
            horizontalPosition: 'right',
            verticalPosition: 'bottom',
            duration: duration,
        });
    }
}

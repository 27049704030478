import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '@app/angular-material/angular-material.module';
import { CheckBoxCellRendererComponent } from './check-box-cell-renderer.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    CheckBoxCellRendererComponent
  ],
  exports: [
    CheckBoxCellRendererComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule,
    MatCheckboxModule,
    FormsModule
  ]
})
export class CheckBoxCellRendererModule { }

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-command-details-tools-panel',
    templateUrl: './command-details-tools-panel.component.html',
})
export class CommandDetailsToolsPanelComponent {
    @Input() isAvailableToExport: boolean;
    @Input() progressValueExport: number;
    @Input() isOpenFilters: boolean;
    @Input() isOpenColumns: boolean;
    @Input() isDownloadAllEnabled: boolean;

    @Output() exportToExcel = new EventEmitter();
    @Output() resetAll = new EventEmitter();
    @Output() clearSorting = new EventEmitter();
    @Output() clearFiltering = new EventEmitter();
    @Output() clearRowGrouping = new EventEmitter();
    @Output() resetColumnOrder = new EventEmitter();
    @Output() resetColumnVisibility = new EventEmitter();
    @Output() openFilters = new EventEmitter();
    @Output() openColumns = new EventEmitter();
    @Output() downloadAllLogs = new EventEmitter();

    downloadLabel = 'Download All';

    downloadAll() {
        this.downloadAllLogs.emit();
    }
}

export enum AppPermissions {
    ViewDashboard = 'ViewDashboard',
    ManageDashboard = 'ManageDashboard',
    ViewHeartbeats = 'ViewHeartbeats',
    ViewEventHistory = 'ViewEventHistory',
    ViewMap = 'ViewMap',
    ViewDevicePosition = 'ViewDevicePosition',
    ManageDevicePosition = 'ManageDevicePosition',
    ViewPositionHistory = 'ViewPositionHistory',
    ViewVehicles = 'ViewVehicles',
    ManageVehicles = 'ManageVehicles',
    ViewCommands = 'ViewCommands',
    ManageCommands = 'ManageCommands',
    ViewUsers = 'ViewUsers',
    ManageUsers = 'ManageUsers',
    ViewValidatorGroups = 'ViewValidatorGroups',
    ManageValidatorGroups = 'ManageValidatorGroups',
    ViewConfiguration = 'ViewConfiguration',
    ManageConfiguration = 'ManageConfiguration',
    FeatureDashboardPTOViewsAll = 'Feature>Dashboard>PTOViewsAll',
    FeatureHeartbeatsPTOViewsAll = 'Feature>Heartbeats>PTOViewsAll',
    FeatureEditPulseMetricType = 'Feature>EditPulse>MetricType',
    FeatureHeartbeatsSearchBar = 'Feature>Heartbeats>SearchBar',
    FeatureShowWatermarkTimeStamp = 'Feature>ShowWatermarkTimeStamp',
    FeatureTablesFareMediaColumns = 'Feature>Tables>FareMediaColumns',
    FeatureTablesHardwareColumns = 'Feature>Tables>HardwareColumns',
    FeatureTablesKV6Columns = 'Feature>Tables>KV6Columns',
    FeatureTablesCLTColumns = 'Feature>Tables>CLTColumns',
    FeatureDevicePositionMultiSelect = 'Feature>DevicePosition>MultiSelect',
    FeatureCommandsShowStatus = 'Feature>Commands>ShowStatus',
    FeatureCommandsDownloadFiles = 'Feature>Commands>DownloadFiles',
    FeatureCommandsSelectMultipleServices = 'Feature>Commands>SelectMultipleServices',
    FeatureUsersRoleAccess = 'Feature>Users>RoleAccess',
    FeatureUsersPulseEditAccess = 'Feature>Users>PulseEditAccess',
    FeatureMapsColorCodedPins = 'Feature>Maps>ColorCodedPins',
    FeaturMapsExpandedToolTip = 'Feature>Maps>ExpandedToolTip',
    FeatureCommandsPTOViewsAll = 'Feature>Commands>PTOViewsAll',
    FeatureLiveUpdates = 'Feature>LiveUpdates',
    FeatureVehicleInfoAdditionalColumns = 'Feature>VehicleInfo>AdditionalColumns',
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-default-table-tools-panel',
    templateUrl: './default-table-tools-panel.component.html',
    styleUrls: ['./default-table-tools-panel.component.scss'],
})
export class DefaultTableToolsPanelComponent {
    @Input() isAvailableToExport: boolean;
    @Input() progressValueExport: number;
    @Input() isOpenFilters: boolean;
    @Input() isOpenColumns: boolean;

    @Output() exportToExcel = new EventEmitter();
    @Output() resetAll = new EventEmitter();
    @Output() clearSorting = new EventEmitter();
    @Output() clearFiltering = new EventEmitter();
    @Output() clearRowGrouping = new EventEmitter();
    @Output() resetColumnOrder = new EventEmitter();
    @Output() resetColumnVisibility = new EventEmitter();
    @Output() openFilters = new EventEmitter();
    @Output() openColumns = new EventEmitter();

    exportToExcelLabel = 'Export to Excel';
    clearLabel = 'Reset Menu';

    get filtersLabel(): string {
        return this.isOpenFilters ? 'Close Filters' : 'Open Filters';
    }

    get columnsLabel(): string {
        return this.isOpenColumns ? 'Close Columns' : 'Open Columns';
    }

    menuItemList: Array<{ label: string; eventEmitter: EventEmitter<any> }> = [
        {
            label: 'Reset All',
            eventEmitter: this.resetAll,
        },
        {
            label: 'Clear Sorting',
            eventEmitter: this.clearSorting,
        },
        {
            label: 'Clear Filtering',
            eventEmitter: this.clearFiltering,
        },
        {
            label: 'Clear Row Grouping',
            eventEmitter: this.clearRowGrouping,
        },
        {
            label: 'Reset Column Order',
            eventEmitter: this.resetColumnOrder,
        },
        {
            label: 'Reset Column Visibility',
            eventEmitter: this.resetColumnVisibility,
        },
    ];
}

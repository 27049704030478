import { authConfig } from 'config/auth0_config';

export const environment = {
    production: false,
    acceptance: false,
    development: true,
    auth: {
        oldPortalUrl: 'https://wa-dev-we-heartbeat-portal.azurewebsites.net/',
        domain: 'telexis-dev.eu.auth0.com',
        clientId: 'o3GStb68xzlJy4Zpbet2iWClfVxPEwxE',
        apiUrl: 'https://wa-dev-we-heartbeat-api-core.azurewebsites.net/api',
        liveUpdatesUrl: 'https://wa-dev-we-heartbeat-live-updates-api.azurewebsites.net',
        callback_url: 'https://dev.heartbeat360.nl/callback',
        azureMapsKey: 'gIBlqEEUFZ1lj8onPbrCo8M60p4CCdRuLKVWTuPNSiU',
        agGridLincenseKey: "Using_this_{AG_Grid}_Enterprise_key_{AG-067378}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Telexis_BV}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Heartbeat360}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Heartbeat360}_need_to_be_licensed___{Heartbeat360}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_September_2025}____[v3]_[01]_MTc1ODMyMjgwMDAwMA==ca2dab621486bb03f9f4db262d777907",
        authorizationParams: {
            audience: 'https://www.api360.com',
            redirect_uri: 'https://dev.heartbeat360.nl',
        },
    },
};

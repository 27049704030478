import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component, ViewChild } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'app-radio-button-cell-renderer',
    templateUrl: './radio-button-cell-renderer.component.html',
})
export class RadioButtonCellRendererComponent
    implements ICellRendererAngularComp
{
    @ViewChild('radio') radioButton: MatRadioButton;
    public params!: ICellRendererParams;

    public agInit(params: ICellRendererParams): void {
        this.params = params;
    }

    public refresh(): boolean {
        return false;
    }

    public selected(): void {
        this.params.context.componentParent.selected(
            this.params.node.data,
            this.radioButton
        );
    }
}

<mat-form-field appearance="outline" class="full-width">
    <mat-icon matPrefix svgIcon="calendar" (click)="picker.open()"></mat-icon>
    <mat-icon
        *ngIf="timeSelectionEnabled"
        matPrefix
        svgIcon="clock"
        (click)="timeSelectv.open()"
    ></mat-icon>
    <input
        matInput
        [formControl]="dateControl"
        [matDatepicker]="picker"
        (dateChange)="dateChange($event)"
    />
    <mat-datepicker #picker></mat-datepicker>
    <div [formGroup]="dateForm" [hidden]="!timeSelectionEnabled">
        <mat-select
            id="time_anchor"
            [hidden]="true"
            [formControlName]="'time'"
            #timeSelect
            (selectionChange)="timeSelectChange($event.value)"
        >
            <mat-option
                *ngFor="let timeRange of timeRanges"
                [value]="timeRange"
            >
                {{ timeRange }}
            </mat-option>
        </mat-select>
    </div>
</mat-form-field>

import { VehicleModel } from "@app/services/map/map.service";

export enum ValidatorGroupDataEnum {
    radioButton = 'radioButton',
    groupId = 'groupId',
    name = 'name',
    position = 'position',
    vehicleModel = 'vehicleModel',
}

export type ValidatorGroupData = {
    [key in ValidatorGroupDataEnum]: any;
};

export type ValidatorGroupsSettings = {
    [key in ValidatorGroupDataEnum]: {
        dataFieldName: string;
        label: string;
        cellRendererComponent?: any;
        alwaysAvailable?: boolean;
        suppressMenu?: boolean;
        suppressSortable?: boolean;
        suppressFilterable?: boolean;
        lockVisible?: boolean;
        suppressDragLeaveHidesColumns?: boolean;
    };
};

export type ValidatorGroupsColumnSettings = {
    key: ValidatorGroupDataEnum;
    visible: boolean;
};

export interface IValidatorGroupModel {
    companyContext?: string;
    companyName?: string;
    groupId?: number;
    name?: string;
    position?: string;
    refreshedOn?: string;
    validatorGroupId?: string;
    vehicleModel?: VehicleModel;
}

export interface ValidatorGroupPayload {
    companyContext?: string;
    companyName?: string;
    groupId?: number;
    name?: string;
    position?: string;
    validatorGroupId?: string;
    vehicleModel?: VehicleModel;

}
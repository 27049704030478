<app-default-table-tools-panel
    [isAvailableToExport]="isAvailableToExport"
    [progressValueExport]="progressValueExport"
    [isOpenFilters]="isOpenFilters"
    [isOpenColumns]="isOpenColumns"
    (exportToExcel)="exportToExcel.emit()"
    (resetAll)="resetAll.emit()"
    (clearSorting)="clearSorting.emit()"
    (clearFiltering)="clearFiltering.emit()"
    (clearRowGrouping)="clearRowGrouping.emit()"
    (resetColumnOrder)="resetColumnOrder.emit()"
    (resetColumnVisibility)="resetColumnVisibility.emit()"
    (openFilters)="openFilters.emit()"
    (openColumns)="openColumns.emit()"
    >
    <ng-container left>
        <button
            (click)="refreshData.emit()"
            mat-icon-button 
            [matTooltip]="refreshDataLabel"
            [attr.aria-label]="refreshDataLabel">
            <mat-icon svgIcon="refresh"></mat-icon>
        </button>
        Updated: {{ lastUpdated | date:'HH:mm:ss' }}
    </ng-container>
    
    <ng-container right>
        <button
            mat-stroked-button
            color="primary"
            [disabled]="!hasPermissionManageVehicles"
            (click)="newVehicle.emit()"
            [attr.aria-label]="newLabel">
            <span>{{ newLabel }}</span>
        </button>
        <button
            mat-stroked-button
            color="primary"
            [disabled]="!hasPermissionManageVehicles || !isSelectedVehicle"
            (click)="editVehicle.emit()"
            [attr.aria-label]="editLabel">
            <span>{{ editLabel }}</span>
        </button>
        <button
            mat-stroked-button
            color="primary"
            [disabled]="!hasPermissionManageVehicles || !isSelectedVehicle"
            (click)="deleteVehicle.emit()"
            [attr.aria-label]="deleteLabel">
            <span>{{ deleteLabel }}</span>
        </button>
    </ng-container>
</app-default-table-tools-panel>

import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { EnvironmentService } from 'environments/environment.service';


@Injectable({
  providedIn: 'root'
})
export class AppAuthorizationService {

    constructor(private auth: AuthService, private envService: EnvironmentService){}

    initiateLogin() {
        this.auth.loginWithRedirect();
    }

    isLoggedIn(): Observable<boolean> {
        return this.auth.isAuthenticated$;
    }

    getAuth0UserSubscription(): Observable<User | null | undefined> {
        return this.auth.user$;
    }

    initiateLogout(){
        this.auth.logout({
            logoutParams: {returnTo: this.envService.redirectUrl}
        });
    }

}
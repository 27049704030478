import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';

@Component({
  selector: 'app-connection-state-cell-renderer',
  templateUrl: './connection-state-cell-renderer.component.html',
  styleUrls: ['./connection-state-cell-renderer.component.scss']
})
export class ConnectionStateCellRendererComponent implements ICellRendererAngularComp {
  public displayValue!: string;

  public agInit(params: ICellRendererParams): void {
    this.displayValue = params.value;
  }

  public refresh(): boolean {
    return false;
  }
}

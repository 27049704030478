import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HeartbeatsV2Component } from './heartbeatsV2.component';

const routes: Routes = [
  {
    path: '',
    component: HeartbeatsV2Component
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class HeartbeatsV2RoutingModule { }

<h1 class="new-command__heading">Validator Services</h1>

<div class="new-command__services" [formGroup]="serviceCheckboxsForm">
    <div class="new-command__services-section">
        <mat-checkbox formControlName="isDeviceServiceMonitor"
            >Device Service Monitor</mat-checkbox
        >
        <mat-checkbox formControlName="isUpdateHandler"
            >Update Handler</mat-checkbox
        >
    </div>
    <div class="new-command__services-section">
        <mat-checkbox formControlName="isHeartbeat360"
            >Heartbeat360 Client</mat-checkbox
        >
        <mat-checkbox formControlName="isValidator">Validator</mat-checkbox>
    </div>
    <div class="new-command__services-section">
        <mat-checkbox formControlName="isUserInterface"
            >User Interface</mat-checkbox
        >
        <mat-checkbox formControlName="isLocalSupport" *ngIf="!isShorten"
            >Local Support Function
        </mat-checkbox>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Device } from '@app/services/devices/devices.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class DeviceSelectorService {
    private devices$: BehaviorSubject<Device[]> = new BehaviorSubject(
        [] as Device[]
    );

    private deviceSelectionClear$: Subject<any> = new Subject();

    public selectedDevices: Observable<Device[]> = this.devices$.asObservable();
    public deviceSelectionClear: Observable<any> =
        this.deviceSelectionClear$.asObservable();

    updateDevices(devices: Device[]): void {
        this.devices$.next(devices);
    }
    clear(): void {
        this.deviceSelectionClear$.next({});
    }
}

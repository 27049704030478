import {
    Component,
    Input,
    OnChanges,
    ViewChild,
    ElementRef,
    OnDestroy,
    OnInit, Inject,
} from '@angular/core';
import {
    ExportService,
    GridComponent,
    GridService,
    TableField,
} from '@app/shared';
import { HeartbeatsService } from '@app/pages/heartbeats/heartbeats.service';
import {
    ColDef,
    GridReadyEvent,
} from '@ag-grid-community/core';
import { take } from 'rxjs';
import { TableFieldTypes } from '@app/shared/operators';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import {
    Options,
    servicesRadioConfig,
} from '../new-command/new-command.interfaces';
import {
    CommandTypeValue,
    CommandTypeValues,
    CommandTypes
} from '../commands.interface';
import { CustomDownloadUrlCellRendererComponent } from '@app/shared/grid/cell-renderer/custom-download-url-cell-renderer/custom-download-url-cell-renderer.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { AppNotificationService, NotificationType } from '@app/services/notification/notification.service';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { AppDialogConfig } from "@app/shared/app-dialog/app-dialog.config";
import { AppDialogService } from "@app/shared/app-dialog/app-dialog.service";
import { LicenseManager } from '@ag-grid-enterprise/core';
import { EnvironmentService } from 'environments/environment.service';

@Component({
    selector: 'app-command-details',
    templateUrl: './command-details.component.html',
    styleUrls: [
        './command-details.component.scss',
        '../../../shared/grid/grid.component.scss',
    ],
})
export class CommandDetailsComponent
    extends GridComponent
    implements OnInit, OnDestroy
{
    public rowData!: Array<any>;
    commandTypeValues = CommandTypeValues;
    servicesValues = servicesRadioConfig;
    @ViewChild('rowsPerPage2') rowsPerPage2: ElementRef;

    selectedCommand: any;
    isInfoOpened = true;
    isDevicesOpened = true;
    isPayloadOpened = false;
    public isDownloadAllEnabled = false;

    constructor(
        public dialogRef: MatDialogRef<CommandDetailsComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        public appDialogService: AppDialogService,
        private heartbeatsService: HeartbeatsService,
        private exportService: ExportService,
        private gridService: GridService,
        public _companyContextService: CompanyContextService,
        private http: HttpClient,
        private notificationService: AppNotificationService,
        private env: EnvironmentService
    ) {
        super();
        LicenseManager.setLicenseKey(this.env.agGridLicenseKey);

        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next: (tableFieldsMap: Map<string, TableField>) => {
                    this.columnDefs = [
                        {
                            field: 'downloadUrl',
                            filter: this.gridService.createFilter(TableFieldTypes.STRING),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(TableFieldTypes.STRING)},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Download',
                            cellRenderer: CustomDownloadUrlCellRendererComponent,
                            filterValueGetter : (params) => {
                                if(params?.data?.downloadUrl){
                                    return 'Yes';
                                }
                                else{
                                    return 'None';
                                }
                            },
                            comparator: function (valueA, valueB, nodeA, nodeB, isInverted){
                                const order = ["Yes", "None"];
                                let indexA = -1;
                                let indexB = -1;
                                if(nodeA?.data?.downloadUrl){
                                    indexA = 0;
                                    valueA = 'Yes'
                                }
                                else if(nodeA?.data?.deviceJobStatus){
                                    indexA = order.indexOf(nodeA?.data?.deviceJobStatus);
                                    valueA = nodeA?.data?.deviceJobStatus;
                                }
                                else{
                                    indexA = 1;
                                    valueA = 'None';
                                }

                                if(nodeB?.data?.downloadUrl){
                                    indexB = 0;
                                    valueB = 'Yes'
                                }
                                else if(nodeB?.data?.deviceJobStatus){
                                    indexB = order.indexOf(nodeB?.data?.deviceJobStatus);
                                    valueB = nodeB?.data?.deviceJobStatus;
                                }
                                else{
                                    indexB = 1;
                                    valueB = 'None';
                                }

                                if(indexA !== -1 && indexB !== -1){
                                    return indexA - indexB;
                                }
                                else{
                                    return valueA.localeComapre(valueB) * (isInverted ? -1 : 1);
                                }
                            }
                        } as ColDef,
                        {
                            field: 'vehicle',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('vehicle')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('vehicle')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Vehicle',
                        } as ColDef,
                        {
                            field: 'position',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('position')
                                    ?.dataType ?? TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('position')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Position',
                        } as ColDef,
                        {
                            field: 'validatorSerial',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('validatorSerial')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('validatorSerial')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Validator Serial',
                        } as ColDef,
                        {
                            field: 'status',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('status')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('status')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Status',
                        } as ColDef,
                        {
                            field: 'statusReason',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('statusReason')
                                    ?.dataType ?? TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('statusReason')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Status Reason',
                        } as ColDef,
                        {
                            field: 'batchNumber',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('batchNumber')?.dataType ??
                                    TableFieldTypes.NUMBER
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('batchNumber')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Batch Number',
                        } as ColDef,
                        {
                            field: 'batchStatus',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('batchStatus')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('batchStatus')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Batch Status',
                        } as ColDef,
                        {
                            field: 'applicationType',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('applicationType')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: {filterOptions : this.gridService.createFilterOptions(
                                tableFieldsMap.get('applicationType')
                                ?.dataType ?? TableFieldTypes.STRING
                            )},
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Service',
                        } as ColDef
                    ];
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: "Group rows by column:",
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer'
                        } as ColDef,
                        ...this.columnDefs
                    ];
                }
            });
    }

    public ngOnInit(): void {
        this.companyContextSubscription = this._companyContextService
            .getCompanyContext()
            .subscribe(() => this.refreshData());
        this._initRowsPerPageCommandDetailsGrid();
        this.selectedCommand = this.dialogConfig.data.command;
        this.rowData = this.selectedCommand?.devices;
        this.isDownloadAllEnabled = this.filterDevicesWithDownloadUrl(this.selectedCommand?.devices)?.length > 0;
	}

    public ngOnDestroy(): void {
        this.companyContextSubscription.unsubscribe();
    }

    public _initRowsPerPageCommandDetailsGrid(): void {
        // Injections to Rows Per Page Component into AG Grid Paging Panel
        const pagingPanel = document.querySelector(
            'app-command-details .ag-paging-panel'
        );
        pagingPanel?.appendChild(this.rowsPerPage2.nativeElement);
    }

    close() {
        this.isInfoOpened = false;
        this.isDevicesOpened = false;
        this.appDialogService.closeSliderDialog(this.dialogRef, {});
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;
        this._initColumnRowGroup(true);
        this._initDomLayout();
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        this.exportService.exportToExcel(
            this.rowData,
            1000000,
            'exported_data.xlsx',
            'Chunk_'
        );

        this.progressValueExport = 0;
        this.isAvailableToExport = true;
    }

    getCommandTypeDisplayText(type: CommandTypes): string {
        return this.commandTypeValues.find(
            (commandTypeValue: CommandTypeValue) =>
                commandTypeValue.type === type
        )?.value as string;
    }

    getServiceDisplayText(types: string[]): string {
        let displayText = '';
        if(types){
            types.forEach( (type, index) => {
                if(index == 0){
                    displayText = this.servicesValues.find(
                        (option: Options) => option.value === type
                    )?.label as string;
                }
                else{
                    displayText = displayText + ', ' + this.servicesValues.find(
                        (option: Options) => option.value === type
                    )?.label;
                }

            });
        }

        return displayText;
    }

    getJobTypeText(jobType: string) {
        if(jobType === "Direct"){
            return "One Time"
        }
        else if(jobType === "Indirect"){
            return "Recurring"
        }
        else{
            return jobType;
        }
    }

    public handleDownloadAllLogs(): void {
        const devicesWithUrl = this.filterDevicesWithDownloadUrl(this.selectedCommand?.devices);
        const zip = new JSZip();
        devicesWithUrl.forEach((element: any, index: number) => {
            const deviceSerial = element?.downloadUrl.split('/')[4];
            let fileName = element?.downloadUrl.split('/')[5];
            fileName = fileName.substr(0, fileName.indexOf('?'));
            fileName = deviceSerial + '/' + fileName;
            this.http.get(element.downloadUrl, { responseType: 'text' }).subscribe({
                next:(textData) =>
                {
                    const blob = new Blob([textData], { type: 'text/plain' });
                    zip.file(`${fileName}`, blob);
                },
                complete:() => {
                    if(index == devicesWithUrl.length - 1){
                        setTimeout(() => {
                            zip.generateAsync({ type: 'blob' }).then((blob) => {
                                saveAs(blob, 'allLogs.zip');
                              });
                        }, 500);

                    }
                },
                error:(err:HttpErrorResponse)=>{
                    console.log(err);
                    this.notificationService.showSnackBar(
                        'Fetching log file failed!',
                        NotificationType.Error,
                        2000
                    )
                }
            });
        });
    }

    private filterDevicesWithDownloadUrl(devices: any){
        return devices?.filter( (x:any) => x.downloadUrl != null);
    }
}

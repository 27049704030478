interface PermissionConfig {
    permissionDisplayValue: string;
    permissionName: PermissionsName;
    permissionOptions: PermissionOptions[];
}

export enum PermissionOptions {
    VIEW = 'View',
    MANAGE = 'Manage',
}

const allPermission: PermissionOptions[] = [
    PermissionOptions.VIEW,
    PermissionOptions.MANAGE,
];

export enum PermissionsName {
    Commands = 'Commands',
    Configuration = 'Configuration',
    DevicePosition = 'DevicePosition',
    Users = 'Users',
    Vehicles = 'Vehicles',
    ValidatorGroups = 'ValidatorGroups',
    Dashboard = 'Dashboard',
    Map = 'Map',
    Heartbeats = 'Heartbeats',
    PositionHistory = 'PositionHistory',
    EventHistory = 'EventHistory',
}

export const permissionConfig: PermissionConfig[] = [
    {
        permissionDisplayValue: 'Dashboard',
        permissionName: PermissionsName.Dashboard,
        permissionOptions: allPermission,
    },
    {
        permissionDisplayValue: 'Heartbeats',
        permissionName: PermissionsName.Heartbeats,
        permissionOptions: [PermissionOptions.VIEW],
    },
    {
        permissionDisplayValue: 'Events History',
        permissionName: PermissionsName.EventHistory,
        permissionOptions: [PermissionOptions.VIEW],
    },
    {
        permissionDisplayValue: 'Map',
        permissionName: PermissionsName.Map,
        permissionOptions: [PermissionOptions.VIEW],
    },
    {
        permissionDisplayValue: 'Device Position',
        permissionName: PermissionsName.DevicePosition,
        permissionOptions: allPermission,
    },
    {
        permissionDisplayValue: 'Position History',
        permissionName: PermissionsName.PositionHistory,
        permissionOptions: [PermissionOptions.VIEW],
    },
    {
        permissionDisplayValue: 'Vehicles',
        permissionName: PermissionsName.Vehicles,
        permissionOptions: allPermission,
    },
    {
        permissionDisplayValue: 'Commands',
        permissionName: PermissionsName.Commands,
        permissionOptions: allPermission,
    },
    {
        permissionDisplayValue: 'Users',
        permissionName: PermissionsName.Users,
        permissionOptions: allPermission,
    },
    {
        permissionDisplayValue: 'Validator Groups',
        permissionName: PermissionsName.ValidatorGroups,
        permissionOptions: allPermission,
    },
    {
        permissionDisplayValue: 'Config Signing Key',
        permissionName: PermissionsName.Configuration,
        permissionOptions: [PermissionOptions.MANAGE],
    },
];

<div class="value">{{ !!control.value ? control.value : 0 }}</div>
<div class="slider">
    <mat-slider
        class="mat-slider"
        [max]="max"
        [min]="min"
        [step]="step"
        [showTickMarks]="showTicks"
        [disableRipple]="true"
    >
        <input matSliderThumb showTickMarks [formControl]="control" />
    </mat-slider>
    <div class="range">
        <span>{{ min }}</span>
        <span>{{ max }} {{ suffix }}</span>
    </div>
</div>

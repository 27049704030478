import { Injectable } from '@angular/core';
import { AppDialogComponent } from './app-dialog.component';
import {
    MatDialog,
    MatDialogConfig,
    MatDialogRef,
} from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AppDialogConfig } from './app-dialog.config';

@Injectable({
    providedIn: 'root',
})
export class AppDialogService {
    constructor(private dialog: MatDialog) {}
    openDialog(panelConfig: AppDialogConfig): Observable<any> {
        const isDialogSideSLide = panelConfig.panelClass.includes('side-slider');
        if (isDialogSideSLide) {
            panelConfig.panelClass = [
                ...panelConfig.panelClass,
                'animate__animated',
                'animate__slideInRight',
            ];
            panelConfig.disableClose = true;
        }
        const dialogConfig: MatDialogConfig = {
            data: { ...panelConfig },
            panelClass: panelConfig.panelClass,
            width: panelConfig.dialogSize,
            disableClose: panelConfig.disableClose,
        };

        if(isDialogSideSLide) {
            dialogConfig.position = { right: '0' };
        }

        const dialogRef = this.dialog.open(AppDialogComponent, dialogConfig);
        return dialogRef.afterClosed();
    }

    closeSliderDialog(dialogRef: MatDialogRef<any>, data?: any) {
        document
            .querySelector(`.animate__animated:has(#${dialogRef.id})`)?.classList.add('animate__slideOutRight');

        setTimeout(() => {
            if(document.querySelectorAll('.side-slider').length <= 1) {
                document
                    .getElementsByClassName('cdk-overlay-container')?.[0]?.classList
                    .remove('side-slider-overlay-container');
            }
            dialogRef.close(data);
        }, 1200);
    }

    closePopupDialog() {
        setTimeout(() => {
            if(document.querySelectorAll('.modal-popup').length == 0 && document.querySelectorAll('.side-slider').length >= 1) {
                document
                    .getElementsByClassName('cdk-overlay-container')?.[0]?.classList
                    .add('side-slider-overlay-container');
            }
        }, 200);
    }
}

<ng-container *ngIf="isAvailableTable">
    <app-users-tools-panel
        (editUser)="editUser()"
        (createNewUser)="createNewUser()"
        [isSelectedAccount]="isSelectedAccount"
        [isAvailableToExport]="isAvailableToExport"
        [progressValueExport]="progressValueExport"
        [isOpenFilters]="isOpenFilters"
        [isOpenColumns]="isOpenColumns"
        (refreshData)="refreshData()"
        (exportToExcel)="exportToExcel()"
        (resetAll)="resetAll(getDefaultPreferencesForReset())"
        (clearSorting)="clearSorting()"
        (clearFiltering)="clearFiltering(settingsCacheService.users)"
        (clearRowGrouping)="clearRowGrouping()"
        (resetColumnOrder)="resetColumnOrder(getDefaultPreferencesForReset())"
        (resetColumnVisibility)="resetColumnVisibility(getDefaultPreferencesForReset())"
        (openFilters)="openFilters()"
        (openColumns)="openColumns()"
    >
    </app-users-tools-panel>

    <ag-grid-angular
        class="ag-theme-alpine"
        [modules]="modules"
        [columnDefs]="columnDefs"
        [sideBar]="sideBar"
        [defaultColDef]="defaultColDef"
        [autoGroupColumnDef]="autoGroupColumnDef"
        [animateRows]="true"
        [suppressDragLeaveHidesColumns]="false"
        [suppressMakeColumnVisibleAfterUnGroup]="true"
        [suppressRowGroupHidesColumns]="true"
        [rowGroupPanelShow]="rowGroupPanelShow"
        [rowModelType]="'clientSide'"
        [domLayout]="'autoHeight'"
        [rowData]="rowData"
        [groupDisplayType]="groupDisplayType"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [cacheBlockSize]="cacheBlockSize"
        [components]="components"
        [context]="context"
        [getLocaleText]="getLocaleText"
        [enableCellTextSelection]="enableCellTextSelection"
        [ensureDomOrder]="ensureDomOrder"
        (gridReady)="onGridReady($event)"
    >
    </ag-grid-angular>

    <div #rowsPerPage>
        <app-rows-per-page [selectedPageSize]="paginationPageSize" (pageSize)="onPageSizeChanged($event)">
        </app-rows-per-page>
    </div>
</ng-container>

import { ColDef, GridReadyEvent } from '@ag-grid-community/core';
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeartbeatsService } from '@app/pages/heartbeats/heartbeats.service';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { Device } from '@app/services/devices/devices.service';
import {
    ExportService,
    GridComponent,
    GridService,
    TableField,
    filterOptions,
} from '@app/shared';
import { TableFieldTypes } from '@app/shared/operators';
import { take } from 'rxjs';
import { DeviceSelectorService } from '../devices-transfer.service';
import { DialogSizes } from "@app/shared/app-dialog/app-dialog.config";
import { SelectDevicesComponent } from "@app/pages/commands/new-command/select-devices/select-devices.component";
import { AppDialogService } from "@app/shared/app-dialog/app-dialog.service";
import { ConnectionStateCellRendererComponent } from '@app/shared/grid/cell-renderer/connection-state-cell-renderer/connection-state-cell-renderer.component';

@Component({
    selector: 'app-selected-devices-grid',
    templateUrl: './selected-devices-grid.component.html',
    styleUrls: [
        '../devices-grid/devices-grid.component.scss',
        '../../../../shared/grid/grid.component.scss',
    ],
})
export class SelectedDevicesGridComponent
    extends GridComponent
    implements OnInit, AfterViewInit, OnDestroy
{
    public rowData: Array<any> = [];
    @ViewChild('rowsPerPage2') rowsPerPage2: ElementRef;
    constructor(
        public companyContextService: CompanyContextService,
        public exportService: ExportService,
        private gridService: GridService,
        public heartbeatsService: HeartbeatsService,
        public deviceTransferService: DeviceSelectorService,
        public appDialogService: AppDialogService,
    ) {
        super();
        this.deviceTransferService.selectedDevices.subscribe(
            (devices: Device[]) => {
                this.rowData = [...devices];
                this._initColumnRowGroup(true);
            }
        );

        this.setColumnDefs();
    }
    setColumnDefs(): void {
        this.heartbeatsService
            .getTableFieldsMap()
            .pipe(take(1))
            .subscribe({
                next: (tableFieldsMap: Map<string, TableField>) => {
                    this.columnDefs = [
                        {
                            field: 'vehicleId',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('vehicleId')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: { filterOptions },
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Vehicle ID',
                        } as ColDef,
                        {
                            field: 'validatorPositionNo',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('validatorPositionNo')
                                    ?.dataType ?? TableFieldTypes.STRING
                            ),
                            filterParams: { filterOptions },
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Validator Position No',
                        } as ColDef,
                        {
                            field: 'deviceSerialNo',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('deviceSerialNo')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: { filterOptions },
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Device Serial No',
                        } as ColDef,
                        {
                            field: 'pto',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('pto')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: { filterOptions },
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'PTO',
                        } as ColDef,
                        {
                            field: 'connectionState',
                            filter: this.gridService.createFilter(
                                tableFieldsMap.get('connectionState')?.dataType ??
                                    TableFieldTypes.STRING
                            ),
                            filterParams: { filterOptions },
                            sortable: true,
                            enableRowGroup: true,
                            headerName: 'Connection State',
                            cellRenderer: ConnectionStateCellRendererComponent
                        } as ColDef,
                    ];
                },
                complete: () => {
                    this.columnDefs = [
                        {
                            field: this.GroupRowsByColumn,
                            headerName: "Group rows by column:",
                            hide: true,
                            lockPosition: 'left',
                            showRowGroup: true,
                            cellRenderer: 'agGroupCellRenderer'
                        } as ColDef,
                        ...this.columnDefs
                    ];
                }
            });
    }
    public ngOnInit(): void {
        this.companyContextSubscription = this.companyContextService.getCompanyContext().subscribe(() => this.refreshData());
    }

    ngAfterViewInit(): void {
        this._initRowsPerPageSelectedDevicesGrid();
    }
    public ngOnDestroy(): void {
        this.companyContextSubscription.unsubscribe();
    }

    public _initRowsPerPageSelectedDevicesGrid(): void {
        // Injections to Rows Per Page Component into AG Grid Paging Panel
        const pagingPanel = document.querySelector('app-selected-devices-grid .ag-paging-panel');
        pagingPanel?.appendChild(this.rowsPerPage2.nativeElement);
    }

    public onGridReady(gridReadyEvent: GridReadyEvent): void {
        this._grid = gridReadyEvent;
        this._initColumnRowGroup(true);
        this._initDomLayout();
    }

    public exportToExcel(): void {
        this.isAvailableToExport = false;

        this.exportService.exportToExcel(
            this.rowData,
            1000000,
            'exported_data.xlsx',
            'Chunk_'
        );

        this.progressValueExport = 0;
        this.isAvailableToExport = true;
    }
    openSelectDevices() {
        this.appDialogService
            .openDialog({
                title: 'Select Devices',
                showDialogButtons: false,
                component: SelectDevicesComponent,
                dialogSize: DialogSizes.Medium,
                panelClass: ['side-slider'],
                data: {},
            })
    }
}

<mat-form-field class="dropdown-tree-container">
  <span class="selected-item">{{selectedItem}}</span>
  <mat-select disableRipple>
    <mat-option>
      <cdk-tree [dataSource]="nodes" [treeControl]="treeControl" class="example-tree">
        <cdk-nested-tree-node *cdkTreeNodeDef="let node; when: hasChild" cdkTreeNodePadding>
          <div class="cdk-tree-node">
            <button mat-icon-button cdkTreeNodeToggle
                    [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            <a class="custom-link" (click)="onSelectionChange({nodeContext: node.companyContext, nodeName: node.name})">{{node.name}}</a>            
          </div>        
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)"
              role="group">
            <ng-container matTreeNodeOutlet></ng-container>
        </div>
        </cdk-nested-tree-node>
      </cdk-tree>
    </mat-option>
  </mat-select>
</mat-form-field>

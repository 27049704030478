import { Component } from '@angular/core';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommandTypeValue, CommandTypeValues, CommandTypes } from '@app/pages/commands/commands.interface';

@Component({
    selector: 'app-command-name-cell-renderer',
    template: `<span>{{ displayValue }}</span>`,
})
export class CommandNameCellRendererComponent
    implements ICellRendererAngularComp
{
    public displayValue!: string;
    private commandTypeValues = CommandTypeValues;

    public agInit(params: ICellRendererParams): void {
        this.displayValue = this.getCommandTypeDisplayText(params.value)
    }

    public refresh(): boolean {
        return false;
    }
    getCommandTypeDisplayText(type: CommandTypes): string {
        return this.commandTypeValues.find(
            (commandTypeValue: CommandTypeValue) =>
                commandTypeValue.type === type
        )?.value as string;
    }
}
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RadioButtonCellRendererComponent } from './radio-button-cell-renderer.component';
import { AngularMaterialModule } from '@app/angular-material/angular-material.module';



@NgModule({
  declarations: [
    RadioButtonCellRendererComponent
  ],
  exports: [
    RadioButtonCellRendererComponent
  ],
  imports: [
    CommonModule,
    AngularMaterialModule
  ]
})
export class RadioButtonCellRendererModule { }

<mat-toolbar class="mat-elevation-z8">
    <div *ngIf="dataLoaded$ | async">
        <img
            alt="Company Logo"
            src="../assets/svg/header/{{companyLogoName}}.svg"
            class="logo-style"
        />
    </div>

    <div>
            <ng-container *ngIf="dataLoaded$ | async">
                <div class="app-title-version">
                    <h1 class="app-title">{{title}}</h1>
                    <p class="app-version">{{version}}</p>
                </div>                
                <app-company-hierarchy [nodes]="contextGroup"></app-company-hierarchy>
            </ng-container>

    </div>

    <div class="activated-route" fxHide.lt-sm>
        <span class="centered-content">
            <img
                [src]="dataIconPath"
                [alt]="dataTitle"
                class="activated-route activated-route__icon"
            />
            <span class="activated-route activated-route__text">
                {{ dataTitle }}
            </span>
        </span>
    </div>

    <span class="example-spacer"></span>
    <div *ngIf="dataLoaded$ | async">
        <div *ngIf="userInfo">
            <button mat-button [matMenuTriggerFor]="userMenu">
                <span class="user-info" fxHide.lt-md>
                    {{ userInfo.userFullName }},
                    {{ userInfo.userCompanyName }}
                </span>
                
                <span fxHide.gt-sm>
                    <img
                        src="assets/svg/header/person.svg"
                        alt="Person"
                        class="icon-person"
                        matTooltip="{{ userInfo.userFullName }}, {{
                            userInfo.userCompanyName
                        }}"
                    />
                </span>
                <span>
                    <img
                        src="assets/svg/header/expand-more.svg"
                        alt="Expand"
                        class="icon-expand"/>
                </span>
            </button>
            <mat-menu #userMenu="matMenu">
                <button
                    mat-menu-item
                    (click)="
                        authService.initiateLogout()
                    "
                >
                    <span>Log Out</span>
                </button>
            </mat-menu>
        </div>
    </div>

</mat-toolbar>

import { ICellRendererAngularComp } from "@ag-grid-community/angular";
import { ICellRendererParams } from "@ag-grid-community/core";
import { DatePipe, formatDate } from "@angular/common";
import { Component } from "@angular/core";


@Component({
    selector: 'app-custom-date-cell-renderer',
    templateUrl: './custom-date-cell-renderer.component.html',
})
export class CustomDateCellRendererComponent implements ICellRendererAngularComp {
    private static DATE_FORMAT = 'yyyy-MM-dd HH:mm:ss';
    private static DATE_LOCALE = 'en-US';
    displayValue: string | null;
    constructor(private datePipe: DatePipe){
        this.datePipe = new DatePipe(CustomDateCellRendererComponent.DATE_LOCALE);
    }

    agInit(params: ICellRendererParams): void {
        this.displayValue = this.datePipe.transform(params?.value, CustomDateCellRendererComponent.DATE_FORMAT);
    }
    refresh(): boolean {
        return false;
    }

    public static format(value: string) {
        return formatDate(value, CustomDateCellRendererComponent.DATE_FORMAT, CustomDateCellRendererComponent.DATE_LOCALE);
    }
}

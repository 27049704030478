import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { delay, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserInfoService } from '@app/services/user-info/user-info.service';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { ActivationStart, Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {
  dataLoaded$ = new Subject<boolean>;
  mobileViewEnabled = false;
  selectedPage = 'Dashboard';
  userPermissions: string[];
  constructor(private observer: BreakpointObserver,
    private userInfoService: UserInfoService,
    private permissionService: AppPermissionService,
    private router: Router) {
  }
  ngOnInit(): void {
    this.observer
    .observe([Breakpoints.XSmall])
    .pipe(delay(1))
    .subscribe((res) => {
      this.mobileViewEnabled = res.matches;
    });
    this.router.events.pipe(
      filter((event:any) => event instanceof ActivationStart)
    ).subscribe((event: any) => {
      if(event.snapshot.data['title']){
        this.selectedPage = event.snapshot.data['title'];
      }
    });

    this.userInfoService.isUserDataLoaded().subscribe(val => {
      if(val){
        this.dataLoaded$.next(true);
      }      
    });
    this.permissionService.user$.subscribe((user) => {
      this.userPermissions = user?.permissions;
  });
  }

  hasPermission(permission:string): boolean{
    return this.userPermissions?.includes(permission);
  }
}

<div class="switch-toggle">
    <span
        class="switch-toggle__label switch-toggle__label--unchecked"
        [ngClass]="{ active: !switcher.checked }"
        >{{ uncheckedLabel }}</span
    >
    <mat-slide-toggle
        #switcher
        [checked]="checked"
        (change)="toggle($event)"
        id="{{ id }}"
    >
        <span
            class="switch-toggle__label switch-toggle__label--checked"
            [ngClass]="{ active: switcher.checked }"
            >{{ checkedLabel }}</span
        >
    </mat-slide-toggle>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconColumnMenuComponent } from './icon-column-menu.component';
import { AngularMaterialModule } from '@app/angular-material/angular-material.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateControlModule } from "@app/shared/date-control/date-control.module";



@NgModule({
  declarations: [
    IconColumnMenuComponent
  ],
  exports: [
    IconColumnMenuComponent
  ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        MatDatepickerModule,
        DateControlModule,
    ]
})
export class IconColumnMenuModule { }

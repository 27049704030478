import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';

@Component({
    selector: 'app-users-tools-panel',
    templateUrl: './users-tools-panel.component.html',
})
export class UsersToolsPanelComponent implements OnInit {
    @Input() isAvailableToExport: boolean;
    @Input() progressValueExport: number;
    @Input() isOpenFilters: boolean;
    @Input() isOpenColumns: boolean;
    @Input() isSelectedAccount: boolean;

    @Output() createNewUser = new EventEmitter();
    @Output() refreshData = new EventEmitter();
    @Output() exportToExcel = new EventEmitter();
    @Output() resetAll = new EventEmitter();
    @Output() clearSorting = new EventEmitter();
    @Output() clearFiltering = new EventEmitter();
    @Output() clearRowGrouping = new EventEmitter();
    @Output() resetColumnOrder = new EventEmitter();
    @Output() resetColumnVisibility = new EventEmitter();
    @Output() openFilters = new EventEmitter();
    @Output() openColumns = new EventEmitter();
    @Output() editUser = new EventEmitter();

    hasPermissionManageUsers = false;

    newLabel = 'New';
    editLabel = 'Edit';

    constructor(private permissionService: AppPermissionService) {}

    ngOnInit(): void {
        this.permissionService.user$.subscribe((user) => {
            if (user?.permissions.includes(AppPermissions.ManageUsers)) {
                this.hasPermissionManageUsers = true;
            }
        });
    }
}

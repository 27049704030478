import { Injectable } from "@angular/core";
import { AppRestService } from "@app/shared";
import { Observable } from "rxjs";



@Injectable({
    providedIn: 'root',
})
export class WatermarkDelayService {
    constructor( private appRest: AppRestService){}

    getWatermarkDelay(): Observable<any>{
        return this.appRest.get(`Metrics/watermark-delay`);
    }
}
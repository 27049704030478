import { CustomDateCellRendererComponent } from "@app/shared/grid/cell-renderer";
import { PositionHistoryDataEnum, PositionHistorySettings } from "./position-history.interface";


export const positionHistorySettings: PositionHistorySettings = {
    [PositionHistoryDataEnum.deviceId]: { 
        dataFieldName: PositionHistoryDataEnum.deviceId,
        label: 'Device ID',
    },
    [PositionHistoryDataEnum.firstPowerOn]: { 
        dataFieldName: PositionHistoryDataEnum.firstPowerOn,
        label: 'First Power On',
        cellRendererComponent: CustomDateCellRendererComponent
    },
    [PositionHistoryDataEnum.companyName]: { 
        dataFieldName: PositionHistoryDataEnum.companyName,
        label: 'PTO',
    },
    [PositionHistoryDataEnum.vehicleId]: { 
        dataFieldName: PositionHistoryDataEnum.vehicleId,
        label: 'Vehicle ID',
    },
    [PositionHistoryDataEnum.validatorPosition]: { 
        dataFieldName: PositionHistoryDataEnum.validatorPosition,
        label: 'Validator Position',
    },
    [PositionHistoryDataEnum.idbtsam]: { 
        dataFieldName: PositionHistoryDataEnum.idbtsam,
        label: 'IDBT-Sam',
    },
    [PositionHistoryDataEnum.pkisam]: { 
        dataFieldName: PositionHistoryDataEnum.pkisam,
        label: 'PKI-Sam',
    },
} as PositionHistorySettings;

export const DataFieldNameMap: Map<string, string> = Object.keys(PositionHistoryDataEnum).reduce(
    (acc: Map<string, string>, field: string) => (acc.set(
        positionHistorySettings[PositionHistoryDataEnum[field as keyof typeof PositionHistoryDataEnum]].dataFieldName, 
        field
    )),
    new Map<string, string>
);

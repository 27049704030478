import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AuthModule } from '@auth0/auth0-angular';
import { Auth0Interceptor } from './auth0.intercepter';

import { AppComponent } from './app.component';

import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { AngularMaterialModule } from './angular-material/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CamelCasePipe } from './shared/pipes/camelCase.pipe';
import { CompanyHierarchyComponent } from './header/company-hierarchy/company-hierarchy.component';
import { AppLoadingComponent } from './shared/loading/loading.component';
import { SnackBarComponent } from './shared/snackbar/snackbar.component';
import { AppUnauthorizedComponent } from './shared/unauthorized/unauthorized.component';
// import { AppConfigService } from 'config/app.config.service';
import { environment as env } from 'environments/environment';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material/core';
import { MY_FORMATS } from '@app/pages/commands';

// export function initializeApp(appConfigService: AppConfigService) {
//     return (): Promise<any> => {
//       return appConfigService.load();
//     }
//   }

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        SidenavComponent,
        CamelCasePipe,
        CompanyHierarchyComponent,
        AppLoadingComponent,
        SnackBarComponent,
        AppUnauthorizedComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        FlexLayoutModule,
        AuthModule.forRoot({ ...env.auth }),
        HttpClientModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AngularMaterialModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: Auth0Interceptor,
            multi: true,
        },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        // AppConfigService,
        // {
        //     provide: APP_INITIALIZER,
        //     useFactory: initializeApp,
        //     deps: [AppConfigService],
        //     multi: true,
        // },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

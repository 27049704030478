<app-tools-panel>
    <div class="left-panel" left>
        <ng-content select="[left]"></ng-content>
    </div>

    <div class="right-panel" right>
        <ng-content select="[right]"></ng-content>

        <button
            *ngIf="isAvailableToExport"
            (click)="exportToExcel.emit()"
            mat-icon-button
            [matTooltip]="exportToExcelLabel"
            [attr.aria-label]="exportToExcelLabel"
        >
            <mat-icon svgIcon="export"></mat-icon>
        </button>
        <div *ngIf="!isAvailableToExport" class="progress-value-export">
            {{ progressValueExport }} %
        </div>

        <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            [matTooltip]="clearLabel"
            [attr.aria-label]="clearLabel"
        >
            <mat-icon svgIcon="clear-filters"></mat-icon>
        </button>
        <mat-menu #menu="matMenu">
            <button
                mat-menu-item
                *ngFor="let item of menuItemList"
                (click)="item.eventEmitter.emit()"
            >
                <span>{{ item.label }}</span>
            </button>
        </mat-menu>

        <button
            (click)="openFilters.emit()"
            mat-icon-button
            [matTooltip]="filtersLabel"
            [attr.aria-label]="filtersLabel"
        >
            <mat-icon svgIcon="filters"></mat-icon>
        </button>

        <button
            (click)="openColumns.emit()"
            mat-icon-button
            [matTooltip]="columnsLabel"
            [attr.aria-label]="columnsLabel"
        >
            <mat-icon svgIcon="columns"></mat-icon>
        </button>
    </div>
</app-tools-panel>

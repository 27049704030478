import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { UserData } from '@app/models/user-data.model';
import { KeySettings, SettingsCacheService } from '@app/shared';
import { UserInfoService } from '@app/services/user-info/user-info.service';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { ICompanyContext } from '@app/interfaces/company-context.interface';
import { AppAuthorizationService } from '@app/services/authorization/authorization.service';
import { AppPermissionService } from '@app/shared/app-permission/app-permission.service';
import { AppPermissions } from '@app/shared/app-permission/app-permissions';
import { routes } from '@app/app-routing.module';
import { EnvironmentService } from 'environments/environment.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnDestroy, OnInit {
  dataLoaded$ = new Subject<boolean>;
  title = 'Heartbeat 360';
  version = '4.12.0'
  dataTitle: string;
  dataIconPath: string;
  companyLogoName = 'TLX';
  userInfo = new UserData();
  private routerSubscription: Subscription;
  private userSubscription: Subscription;
  contextGroup: ICompanyContext[];

  constructor(
    private router: Router,
    private cacheService: SettingsCacheService, 
    private userInfoService: UserInfoService, 
    private companyContextService: CompanyContextService,
    public authService: AppAuthorizationService,
    private permissionService: AppPermissionService,
    private envService: EnvironmentService
  ) { 
    
  }

  ngOnInit(): void {
    routes.forEach((item: any) => {
      if (document.location.pathname.includes(item.path) && item.data?.title) {
        this.dataTitle = item.data?.title;
        this.dataIconPath = item.data?.iconPath;
      }
    });

    this.routerSubscription = this.router.events.pipe(
      filter((event) => event instanceof ActivationStart)
    ).subscribe((event: any) => {
      this.dataTitle = event.snapshot.data['title'];
      this.dataIconPath = event.snapshot.data['iconPath'];
    });

    this.userSubscription = this.userInfoService.isUserDataLoaded().subscribe(val => {
      if (val) {
        this.userInfo = this.cacheService.getDataFromLocalStorage(KeySettings.UserData);
        this.companyContextService.getCompanyHierarchyForUser(this.userInfo.userCompanyContext).subscribe(result => {
          this.dataLoaded$.next(true);
          this.contextGroup = result;
          this.setCompanyLogoName(this.contextGroup[0]);          
          //this.setAllNoneContext(this.contextGroup);
        });
      }      
    });
  }

  private setCompanyLogoName(companyGroup: ICompanyContext){
    const context = companyGroup.companyContext ? companyGroup.companyContext.substring(0,3) : "TLX";
    if(context === "HTM" || context === "QBZ" || context === "CXX"){
      this.companyLogoName = context;
    }
    else{
      this.companyLogoName = "TLX"
    }
  }

  private setAllNoneContext(companyGroup: ICompanyContext[]){
    let userPermissions: string[] = [];
    this.permissionService.user$.subscribe(user => {
        userPermissions = user?.permissions;        
    });
    if(userPermissions?.includes(AppPermissions.FeatureDashboardPTOViewsAll)){
        companyGroup[0].name = "ALL";
    }
    else{
      companyGroup[0].name = "NONE"
    }
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
    this.userSubscription.unsubscribe();
  }
  
  openOldPortal(): void{
    window.open(this.envService.oldPortalUrl);
  }
}

import { Injectable } from '@angular/core';
import { AppRestService, GridService } from '@app/shared';
import { Observable, map, switchMap } from 'rxjs';
import { vehiclesSettings } from './vehicles.settings';
import {
    EEPROMPTO,
    IVehicleModel,
    VehiclesDataEnum,
} from './vehicles.interface';
import { CompanyContextService } from '@app/services/company-context/company-context.service';

@Injectable({
    providedIn: 'root',
})
export class VehiclesService {
    constructor(
        public _appRestService: AppRestService,
        public _gridService: GridService,
        public _companyContextService: CompanyContextService
    ) {}

    public vehicles(): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        const params: { [key: string]: any } = {
            aggregate: true,
            inlinecount: 'allpages',
        };

        const query = new URLSearchParams(params).toString();

        return this._companyContextService
            .getCompanyContext()
            .pipe(
                switchMap((currentCompany: string) =>
                    this._appRestService
                        .get(`companies/${currentCompany}/vehicles`, { query })
                        .pipe(map(this._queryResultMapper))
                )
            );
    }

    public _queryResultMapper(data: any): {
        rowCount: number;
        rowData: Array<any>;
    } {
        return {
            rowCount: data.totalCount,
            rowData: data.result.map(
                (item: any) =>
                    <IVehicleModel>{
                        companyContext: item.companyContext,
                        vehicleModel: item.vehicleModel.name,
                        numberOfValidators: item.vehicleModel.validatorCount,
                        pilot: item.isPilot ? 'yes' : 'no',
                        eepromPTO: item.eepromPTO,
                        name: item.name,
                        refreshedOn: item.refreshedOn,
                        companyName: item.companyName,
                        vehicleModelData: item.vehicleModel,
                        vehicleId: item.name,
                        fullVehicleId: item.vehicleId,
                    }
            ),
        };
    }

    public getDataFieldNameByKey(key: string): string {
        return vehiclesSettings[
            VehiclesDataEnum[key as keyof typeof VehiclesDataEnum]
        ]?.dataFieldName.replace('.', '/');
    }

    public delete(id: string): Observable<any> {
        return this._appRestService.delete(`vehicles/${id}`);
    }

    public getVehicleModels(
        companyContext: string
    ): Observable<{ result: IVehicleModel[] }> {
        return this._appRestService.get(
            `companies/${companyContext}/vehicleModels`
        );
    }

    public getEEPROMPTOValues(companyContext: string): Observable<EEPROMPTO[]> {
        return this._appRestService.get(`companies/${companyContext}/eeprom`);
    }

    public createVehicle(body: IVehicleModel): Observable<any> {
        return this._appRestService.post('vehicles', body);
    }

    public updateVehicle(body: IVehicleModel): Observable<any> {
        return this._appRestService.put('vehicles', body);
    }

    public getVehicleDetailsById(vehicleId: string) {
        return this._appRestService.get(`vehicles/${vehicleId}`);
    }
}

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { ICompany } from '@app/services/company/company.service';
import { AppDialogConfig } from '@app/shared/app-dialog/app-dialog.config';
import { AppDialogService } from '@app/shared/app-dialog/app-dialog.service';
import { Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { UsersService } from '../users.service';
import { UserRoleService } from '@app/services/account/user-role.service';
import { UserRole, RolePermissionMapping } from '@app/services/account/user-role.interface';

export enum SaveActions {
    CREATE_ACCOUNT = 'CREATE_ACCOUNT',
    CREATE_ROLE = 'CREATE_ROLE',
    EDIT_ACCOUNT = 'EDIT_ACCOUNT',
}

@Component({
    selector: 'app-create-account',
    templateUrl: './create-account.component.html',
    styleUrls: ['./create-account.component.scss'],
})
export class CreateAccountComponent implements OnInit, OnDestroy {
    createUserForm: FormGroup;
    isNewRole = false;
    isAssignRole = false;
    createdRoles: UserRole[] = [];
    companies: ICompany[] = [];
    destroy$: Subject<void> = new Subject();
    userPermissions: string[] = [];
    newRoleState: string[] = [];
    editPermissionState: string[] = [];
    isEdit = false;
    confirmEmail: Subscription;
    isEmailsTheSame = false;
    userToEdit: any;
    isEmailExists = false;
    constructor(
        public dialogRef: MatDialogRef<CreateAccountComponent>,
        @Inject(MAT_DIALOG_DATA)
        public dialogConfig: AppDialogConfig,
        public appDialogService: AppDialogService,
        public companyContextService: CompanyContextService,
        public userSerivce: UsersService,
        public userRoleService: UserRoleService
    ) {}

    ngOnInit(): void {
        this.isEdit = !!this.dialogConfig.data?.userToEdit;
        if (this.isEdit) {
            this.userToEdit = this.dialogConfig.data?.userToEdit;
        }
        const applicationRoles = this.isEdit ?
            this.dialogConfig.data?.userToEdit.applicationRoles :
            this.dialogConfig.data?.lastCreateUserData?.applicationRoles?.map((role: any) => ({
              ...role,
              id: role.roleId
            }));
        const assignedRole = this.isEdit ?
            this.dialogConfig.data?.userToEdit.assignedRole :
            this.dialogConfig.data?.lastCreateUserData?.customRole?.roleName;
        if (applicationRoles && applicationRoles.length && !assignedRole) {
            const permissionState = applicationRoles.map(
                    (role: any) => {
                        return this.dialogConfig.data?.allRoles.find(
                            (roleMapping: RolePermissionMapping) =>
                                role.id === roleMapping.roleId
                        )?.permissionName;
                    }
                );
            if(this.isEdit) {
                this.editPermissionState = permissionState;
            } else {
                this.userPermissions = permissionState;
            }
        }

        this.createUserForm = new FormGroup({});
        this.initNewUserForm();
        this.loadCreatedRoles();

        this.companyContextService
            .getCompanyContext()
            .pipe(
                takeUntil(this.destroy$),
                switchMap((ctx: string) =>
                    this.companyContextService.getAllCompanies(ctx)
                )
            )
            .subscribe(
                (companiesRes: {
                    totalCount: number;
                    companies: ICompany[];
                }) => {
                    this.companies = companiesRes.companies;
                    const userCompanyContext = this.isEdit ?
                        this.dialogConfig.data?.userToEdit.companyContext :
                        this.dialogConfig.data?.lastCreateUserData?.companyContext;
                    if (userCompanyContext) {
                        this.createUserForm
                            .get('concession')
                            ?.setValue(
                                this.companies.find(
                                    (ctx: ICompany) =>
                                        ctx.companyContext ===
                                        userCompanyContext
                                )
                            );
                    }
                }
            );
    }

    get confirmEmailControl(): FormControl {
        return this.createUserForm.get('confirm_email') as FormControl;
    }

    get emailControl(): FormControl {
        return this.createUserForm.get('email') as FormControl;
    }
    initNewUserForm() {
        const userData = this.isEdit ? this.dialogConfig.data?.userToEdit : this.dialogConfig.data?.lastCreateUserData;
        this.createUserForm.removeControl('role');
        this.createUserForm.addControl(
            'first_name',
            new FormControl(userData?.firstName, {
                validators: Validators.required,
            })
        );
        this.createUserForm.addControl(
            'last_name',
            new FormControl(userData?.lastName, {
                validators: Validators.required,
            })
        );
        this.createUserForm.addControl(
            'email',
            new FormControl(userData?.email, {
                validators: Validators.required,
            })
        );
        if (!this.isEdit) {
            this.createUserForm.addControl(
                'confirm_email',
                new FormControl( userData?.email, { validators: Validators.required })
            );
        }
        this.createUserForm.addControl(
            'concession',
            new FormControl('', {
                validators: Validators.required,
            })
        );

        if (this.isEdit) {
            if (this.dialogConfig.data.userToEdit.assignedRole) {
                this.createUserForm.addControl(
                    'existing_roles',
                    new FormControl('', { validators: Validators.required })
                );
                this.isAssignRole = false;
            } else {
                this.isAssignRole = true;
            }
        } else {
            if(!this.dialogConfig.data?.lastCreateUserData || this.dialogConfig.data?.lastCreateUserData?.customRole) {
                this.createUserForm.addControl(
                    'existing_roles',
                    new FormControl('', { validators: Validators.required })
                );
                this.isAssignRole = false;
            } else {
                this.isAssignRole = true;
            }
        }
        if (!this.isEdit) {
            this.confirmEmailControl.valueChanges.subscribe((v: string) => {
                this.isEmailsTheSame = v === this.emailControl?.value;
            });

            this.emailControl.valueChanges.subscribe((v: string) => {
                this.isEmailsTheSame = v === this.confirmEmailControl?.value;
                this.isEmailExists = this.dialogConfig.data?.allEmails().includes(v);
            });
        }
        if(!this.isEdit && userData?.email) {
            this.isEmailExists = true;
        }
    }

    initNewRoleForm() {
        this.createUserForm.removeControl('first_name');
        this.createUserForm.removeControl('last_name');
        this.createUserForm.removeControl('email');
        this.createUserForm.removeControl('confirm_email');
        this.createUserForm.removeControl('concession');
        this.createUserForm.removeControl('existing_roles');

        this.createUserForm.addControl(
            'role',
            new FormControl('', { validators: Validators.required })
        );
    }

    toggleForm(checked: boolean) {
        this.isNewRole = checked;
        this.isAssignRole = false;
        if (checked) {
            this.initNewRoleForm();
            this.newRoleState = [];
        } else {
            this.initNewUserForm();
        }
    }

    togglePermissions(checked: boolean) {
        if (checked) {
            this.createUserForm.removeControl('existing_roles');
        } else {
            this.createUserForm.addControl(
                'existing_roles',
                new FormControl('', { validators: Validators.required })
            );
            this.loadCreatedRoles();
        }
        this.isAssignRole = checked;
    }

    loadCreatedRoles() {
        this.companyContextService
            .getCompanyContext()
            .pipe(
                switchMap((ctx: string) => {
                    return this.userRoleService.getCreatedRoles(ctx);
                })
            )
            .subscribe((res) => {
                this.createdRoles = res;
                const assignedRole = this.isEdit ? this.dialogConfig.data?.userToEdit.assignedRole :
                    this.dialogConfig.data?.lastCreateUserData?.customRole?.roleName;

                if (assignedRole && !this.isAssignRole) {
                    this.createUserForm
                        .get('existing_roles')
                        ?.setValue(
                            this.createdRoles.find(
                                (role: UserRole) =>
                                    role.roleName === assignedRole
                            )
                        );
                }
            });
    }

    close() {
        this.appDialogService.closeSliderDialog(this.dialogRef);
    }

    createNewRole(value: { role: string }) {
        this.appDialogService.closeSliderDialog(this.dialogRef, {
            action: SaveActions.CREATE_ROLE,
            payload: {
                roleName: value.role,
                newRoleState: this.newRoleState,
            },
        });
    }

    createAccount(value: any) {
        const payload: any = {
            firstName: value.first_name,
            lastName: value.last_name,
            email: value.email,
            ctx: value.concession,
        };
        if (this.isAssignRole) {
            if (!this.isEdit) {
                payload['applicationRoles'] = this.userPermissions;
            } else {
                payload['applicationRoles'] = this.editPermissionState;
                payload['id'] = this.dialogConfig.data?.userToEdit.id;
            }
        } else {
            payload['customRole'] = value.existing_roles;

            if (this.isEdit) {
                payload['id'] = this.dialogConfig.data?.userToEdit.id;
            }
        }

        this.appDialogService.closeSliderDialog(this.dialogRef, {
            action: this.isEdit
                ? SaveActions.EDIT_ACCOUNT
                : SaveActions.CREATE_ACCOUNT,
            payload,
        });
    }

    deleteAccount(){
        this.appDialogService.closeSliderDialog(this.dialogRef, {
            action: 'DELETE_ACCOUNT',
            payload: {
                id: this.dialogConfig.data?.userToEdit,
            },
        });
    }

    handlePermissionsChange(permissionsState: string[]) {
        this.userPermissions = permissionsState;
    }
    handleNewRolePermissionsChange(permissionsState: string[]) {
        this.newRoleState = permissionsState;
    }
    handleEditPermissionsChange(permissionsState: string[]) {
        this.editPermissionState = permissionsState;
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}

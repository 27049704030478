import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import { AGGridToODataOperatorsNameMapper, filterOptions } from '../grid.interface';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import * as moment from 'moment';
import { FormControl } from "@angular/forms";

@Component({
  selector: 'app-icon-column-menu',
  templateUrl: './icon-column-menu.component.html',
  styleUrls: ['./icon-column-menu.component.scss'],
})
export class IconColumnMenuComponent implements IHeaderAngularComp {
  public params!: any;

  public menuLabel = 'Column Menu';
  public filterLabel = 'Filter';
  public hideColumnLabel = 'Hide Column';
  public groupLabel: string;
  public sort: 'asc' | 'desc' | null = null;

  public filters = filterOptions;
  public aGGridToODataOperatorsNameMapper = AGGridToODataOperatorsNameMapper;

  public filterType: 'text' | 'date' | 'number' = 'text';
  public firstFilter = 'contains';
  public firstValue: any = null;
  public firstValueDateControl = new FormControl();
  public secondFilter = 'contains';
  public secondValue: any = null;
  public secondValueDateControl = new FormControl();
  public operator = 'AND';

  public isDateFilter = false;
  public isNumberFilter = false;

  public rowGroupColumns: Array<string> = [];
  isBlankNotBlankFilter1 = false;
  isBlankNotBlankFilter2 = false;

  get isAvailableCondition1(): boolean {
    if(this.isBlankNotBlankFilter1){
      return !!this.firstFilter;
    }
    return !!(this.firstValue && this.firstFilter);
  }

  get isAvailableCondition2(): boolean {
    if(this.isBlankNotBlankFilter2){
      return !!this.secondFilter;
    }
    return !!(this.secondValue && this.secondFilter);
  }

  get isAvailableSorASC(): boolean {
    return this.sort === 'asc';
  }

  get isAvailableSorDESC(): boolean {
    return this.sort === 'desc';
  }

  get isGroupRowByColumn(): boolean {
    return this.params?.displayName === 'Group rows by column:';
  }

  public agInit(params: IHeaderParams): void {
    setTimeout(() => {
      const filterModel = this.params.api.getFilterModel()[this.params.column.colId];
      if (!filterModel) { return; }
      if (this.isDateFilter) {
        this.handleDateFilter(filterModel);
      } else {
        this.handleNonDateFilter(filterModel);
      }
    }, 100);
    this.setFilterOptions(params.column.getColDef());
    this.params = params;
    this.groupLabel = `Group rows by ${this.params?.displayName}`;

    if (this.isGroupRowByColumn) {
      this.updateRowGroupColumns();
      this.params.api.addEventListener('columnRowGroupChanged', () => {
        this.updateRowGroupColumns();
      });
    }

    this.params.api.eventService.addEventListener('filterChanged', (e: any) => this.filterChanged(e));
    this.params.api.eventService.addEventListener('sortChanged', () => this.sortChanged());

    this.sortChanged();

    this.firstValueDateControl.registerOnChange((v: any) => this.firstValueChanged(v));
    this.secondValueDateControl.registerOnChange((v: any) => this.secondValueChanged(v));
  }

  private handleNonDateFilter(filterModel: any): void {
    if (filterModel.filter) {
      this.firstValue = filterModel.filter;
      this.firstFilter = filterModel.type;
    }
     else {
      this.operator = filterModel.operator;

      if (filterModel.condition1) {
        this.firstValue = filterModel.condition1.filter;
        this.firstFilter = filterModel.condition1.type;
      }

      if (filterModel.condition2) {
        this.secondValue = filterModel.condition2.filter;
        this.secondFilter = filterModel.condition2.type;
      }
    }
  }

  private handleDateFilter(filterModel: any): void {
    if (filterModel.dateFrom) {
      this.firstValue = moment(filterModel.dateFrom);
      this.firstValueDateControl.setValue(this.firstValue.format());
      this.firstFilter = filterModel.type;
    }
     else {
      this.operator = filterModel.operator;

      if (filterModel.condition1) {
        this.firstValue = moment(filterModel.condition1.dateFrom);
        this.firstValueDateControl.setValue(this.firstValue.format());
        this.firstFilter = filterModel.condition1.type;
      }

      if (filterModel.condition2) {
        this.secondValue = moment(filterModel.condition2.dateFrom);
        this.secondValueDateControl.setValue(this.secondValue.format());
        this.secondFilter = filterModel.condition2.type;
      }
    }
  }

  private setFilterOptions(colDef: any): void {
    this.filters = colDef?.filterParams?.filterOptions;
    if (colDef.filter === "agNumberColumnFilter") {
      this.isNumberFilter = true;
      this.firstFilter = "equals";
      this.secondFilter = "equals";
      this.filterType = 'number';
    }

    if (colDef.filter === "agDateColumnFilter") {
      this.isDateFilter = true;
      this.firstFilter = "lessThan";
      this.secondFilter = "lessThan";
      this.filterType = 'date';
    }
  }

  public sortChanged(): void {
    this.params.columnApi?.getColumnState().forEach((item: any) => {
      if (item.colId === this.params.column.colId) {
        this.sort = item.sort;
      }
    });
  }

  public filterChanged(e: any): void {
    this.firstValue = null;
    this.secondValue = null;
    this.operator = 'AND';

    if (e.columns.length !== 0) {
      e.api.filterManager.allColumnFilters.get(this.params.column.colId)?.filterPromise.then((data: any) => {
        if (data.appliedModel?.filter || data.appliedModel?.dateFrom) {
          this.firstFilter = data.appliedModel.type;
          if (this.isDateFilter) {
            this.firstValue = moment(data.appliedModel.dateFrom);
            this.firstValueDateControl.setValue(this.firstValue.format());
          } else {
            this.firstValue = data.appliedModel.filter;
          }
        } else if (data.appliedModel?.condition1) {
          this.firstFilter = data.appliedModel.condition1.type;
          this.secondFilter = data.appliedModel.condition2.type;
          this.operator = data.appliedModel.operator;
          if (this.isDateFilter) {
            this.firstValue = moment(data.appliedModel.condition1.dateFrom);
            this.secondValue = moment(data.appliedModel.condition2.dateFrom);
            this.firstValueDateControl.setValue(this.firstValue.format());
            this.secondValueDateControl.setValue(this.secondValue.format());
          } else {
            this.firstValue = data.appliedModel.condition1.filter;
            this.secondValue = data.appliedModel.condition2.filter;
          }
        }
      });
    }
    else{
      const filterModel = this.params.api.getFilterModel()[this.params.column.colId];
      if(!filterModel) {
        this.resetFilter();
        return; 
      }
      if(filterModel.filterType === 'date'){
        this.handleDateFilter(filterModel);
      }
      else{
        this.handleNonDateFilter(filterModel);
      }
    }
  }
  resetFilter() {
    this.isBlankNotBlankFilter1 = false;
    this.isBlankNotBlankFilter2 = false;
    if(this.filterType === 'date'){
      this.firstFilter = 'lessThan';
      this.secondFilter = 'lessThan';
      this.firstValue = null;
      this.secondValue = null;
      this.firstFilterChanged({value: 'lessThan'});
      this.secondFilterChanged({value: 'lessThan'});
    }
    else if(this.filterType === 'number'){
      this.firstFilter = 'equals';
      this.secondFilter = 'equals';
      this.firstValue = null;
      this.secondValue = null;
      this.firstFilterChanged({value: 'equals'});
      this.secondFilterChanged({value: 'equals'});
    }
    else{
      this.firstFilter = 'contains';
      this.secondFilter = 'contains';
      this.firstValue = null;
      this.secondValue = null;
      this.firstFilterChanged({value: 'contains'});
      this.secondFilterChanged({value: 'contains'});
    }
  }

  public updateRowGroupColumns(): void {
    this.rowGroupColumns = this.params.columnApi.columnModel.rowGroupColumns.map((item: any) => {
      return item.userProvidedColDef.headerName;
    });
  }

  public refresh(params: IHeaderParams): boolean {
    return false;
  }

  public firstFilterChanged(value: any): void {
    this.firstFilter = value.value;
    if(this.firstFilter === 'blank' || this.firstFilter === 'notBlank'){
      this.isBlankNotBlankFilter1 = true;
      this.firstValue = 'blankValue';
    }
    else{
      this.isBlankNotBlankFilter1 = false;
    }

    this.filtering();
  }

  public firstValueChanged(value: any): void {    
    if (this.isDateFilter) {
      this.firstValue = moment(value);
    } else {
      this.firstValue = value.target.value;
    }
    this.filtering();
  }

  public secondFilterChanged(value: any): void {
    this.secondFilter = value.value;
    if(this.secondFilter === 'blank' || this.secondFilter === 'notBlank'){
      this.isBlankNotBlankFilter2 = true;
      this.secondValue = 'notBlankValue';
    }
    else{
      this.isBlankNotBlankFilter2 = false;
    }

    this.filtering();
  }

  public secondValueChanged(value: any): void {
    if (this.isDateFilter) {
      this.secondValue = moment(value);
    } else {
      this.secondValue = value.target.value;
    }
    this.filtering();
  }

  public changeOperators(value: MatSlideToggleChange): void {
    this.operator = value.checked ? 'OR' : 'AND';
    this.filtering();
  }

  public filtering(): void {
    let filterModel = this.params.api.getFilterModel();
    const filterType = this.filterType;

    let condition1;
    let condition2;

    if (this.isDateFilter) {
      if(this.firstFilter === 'blank' || this.firstFilter === 'notBlank'){
        this.firstValue = moment(new Date());
      }
      if(this.secondFilter === 'blank' || this.secondFilter === 'notBlank'){
        this.secondValue = moment(new Date());
      }
      condition1 = {
        filterType,
        type: this.firstFilter,
        dateFrom: this.firstValue?.format('YYYY-MM-DD HH:mm')
      };
      condition2 = {
        filterType,
        type: this.secondFilter,
        dateFrom: this.secondValue?.format('YYYY-MM-DD HH:mm')
      };
    } else {
      condition1 = {
        filterType,
        type: this.firstFilter,
        filter: this.firstValue
      };
      condition2 = {
        filterType,
        type: this.secondFilter,
        filter: this.secondValue
      };
    }


    if (this.isAvailableCondition1 && this.isAvailableCondition2) {
      filterModel = {
        ...filterModel,
        [this.params.column.colId]: {
          filterType,
          operator: this.operator,
          condition1,
          condition2,
          conditions: [ { ...condition1 }, { ...condition2 } ]
        }
      };
    } else if (this.isAvailableCondition1) {
      filterModel = {
        ...filterModel,
        [this.params.column.colId]: condition1
      };
    } else if (this.isAvailableCondition2) {
      filterModel = {
        ...filterModel,
        [this.params.column.colId]: condition2
      };
    } else {
      filterModel = {
        ...filterModel,
        [this.params.column.colId]: {}
      };
    }

    this.params.api.setFilterModel(filterModel);
  }

  public hideColumn(): void {
    this.params.columnApi.setColumnVisible(this.params.column.colId, false);
  }

  public groupColumn(): void {
    this.params.columnApi.addRowGroupColumn(this.params.column.colId);
  }

  public setSortByClick(): void {
    this.params.columnApi.applyColumnState({ state: this.params.columnApi?.getColumnState().map((item: any) => {
      let value;
      if (item.colId === this.params.column.colId) {
        switch (this.sort) {
          case 'asc':
            this.sort = 'desc';
            break;
          case 'desc':
            this.sort = null;
            break;
          default:
            this.sort = 'asc';
        }
        value = this.sort;
      } else {
        value = null;
      }

      return {
        ...item,
        sort: value
      };
    }) });
  }
}

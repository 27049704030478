import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, take, tap } from 'rxjs';
import { AppRestService } from '../app-rest';
import { KeySettings, SettingsCacheService } from '../settings-cache';
import { IPulse } from '@app/services/pulse/pulse.interface';
import { AppNotificationService, NotificationType } from '@app/services/notification/notification.service';

export interface PulseOrderValue {
    pulseId: string;
    sequenceNumber: number;
}
export interface PulseVisibilityPreference {
    pulseId: string;
    isVisible: boolean;
}

export interface Preferences {
    preferenceId?: string;
    companyContext: string;
    preferences: {
        pulsesOrder: PulseOrderValue[];
        pulsesVisibility: PulseVisibilityPreference[];
    };
}
interface PreferenceObject {
    [key: string]: any;
}

@Injectable({
    providedIn: 'root',
})
export class PreferencesService {
    preferences: Preferences;
    constructor(
        private http: AppRestService,
        public cacheService: SettingsCacheService,
        private notificationService: AppNotificationService
    ) {}

    private gridPreferenceList: KeySettings[] = [];

    enableSavingGridPreferences(gridName: KeySettings): void {
        if (this.gridPreferenceList.indexOf(gridName) > -1) {
            return;
        }
        this.gridPreferenceList.push(gridName);
    }

    getUserPreferences(companyId: string): Observable<Preferences> {
        return this.http.get(`companies/${companyId}/preferences`).pipe(
            map((res) => {
                this.preferences = {
                    companyContext: companyId,
                    ...res,
                };
                return res;
            }),
            catchError((err: any) => {
                return this.createNewPreferences(companyId, "userPreference");
            })
        );
    }

    getDefaultPreferences(companyId: string): Observable<Preferences> {
        return this.http.get(`companies/${companyId}/preferences/default`).pipe(
            map((res) => {
                this.preferences = {
                    companyContext: companyId,
                    ...res,
                };
                return res;
            }),
            catchError((err: any) => {
                return this.createNewPreferences(companyId, "defaultPreference");
            })
        );
    }

    createNewPreferences(companyContext: string, preferenceType: string): Observable<Preferences> {
        const defaultPreferences: Preferences = {
            companyContext,
            preferences: { pulsesOrder: [], pulsesVisibility: [] },
        };

        this.preferences = defaultPreferences;
        if(preferenceType === "userPreference"){
            return this.http.post('preferences', defaultPreferences).pipe(
                tap((res) => {
                    return res;
                })
            );
        }
        else{
            return this.http.post('companies/preferences/default', defaultPreferences).pipe(
                tap((res) => {
                    return res;
                })
            );
        }
    }


    updateAllPreferences(preferenceType = "userPreference") {
        if (this.gridPreferenceList.length == 0) {
            return;
        }
        const prefObjet: PreferenceObject = {}; 
        this.gridPreferenceList.forEach((grid) => {
            const gridData = this.cacheService.getDataFromLocalStorage(grid);
            prefObjet[grid] = gridData;
        });
        if (preferenceType === "defaultPreference"){
            const defaultPreferenceList: KeySettings[] = [KeySettings.HeartbeatsGrid, KeySettings.CommandsGrid, KeySettings.DevicePositionGrid, KeySettings.PositionHistoryGrid, KeySettings.VehiclesGrid, KeySettings.UsersGrid, KeySettings.EventHistoryGrid]
            defaultPreferenceList.forEach((grid) => {
                if(!prefObjet[grid]){
                    const gridData = this.cacheService.getDataFromLocalStorage(KeySettings.DefaultPreferences)['preferences'][grid];
                    if(gridData){
                        prefObjet[grid] = gridData;
                    } 
                }                   
            });
        }

        this.updateCachedPreference(prefObjet, preferenceType)
            .pipe(take(1))
            .subscribe((x) => {
                this.notificationService.showSnackBar(
                    'Preferences Saved!',
                    NotificationType.Info,
                    3000
                );
            });
    }

    updateCachedPreference(preferenceConfig: any, preferenceType: string): Observable<Preferences> {
        const currentPreferences: Preferences = this.cacheService.getDataFromLocalStorage(KeySettings.Preferences);

        const updatedPreferences: Preferences = {
            ...currentPreferences,
            preferences: {
                ...currentPreferences.preferences,
                ...preferenceConfig,
            },
        };
        if(preferenceType === "userPreference"){
            this.cacheService.saveDataToLocalStorage(KeySettings.Preferences, updatedPreferences);
            return this.http.put('preferences', updatedPreferences);
        }
        else{
            updatedPreferences.preferenceId = "TLX:Default-User";
            this.cacheService.saveDataToLocalStorage(KeySettings.DefaultPreferences, updatedPreferences);
            return this.http.put('companies/preferences/default', updatedPreferences);
        }
    }

    updateUserPreference(preferenceConfig: any): Observable<Preferences> {
        const updatedPreferences: Preferences = {
            ...this.preferences,
            preferences: {
                ...this.preferences.preferences,
                ...preferenceConfig,
            },
        };

        this.preferences = updatedPreferences;
        return this.http.put('preferences', updatedPreferences);
    }

    deleteUserPreferences(): Observable<any> {
        return this.http.delete(`preferences`).pipe(
            tap((res) => {
                this.preferences = {} as Preferences;
            })
        );
    }

    public updatePulseOrderPreference(pulsesOrder: PulseOrderValue[]): void {
        this.updateUserPreference({ pulsesOrder }).pipe(take(1)).subscribe();
    }

    deletePulseOrderPreference(pulseId: string) {
        let updatedPulsesOrder: PulseOrderValue[] =
            this.preferences.preferences.pulsesOrder.filter(
                (pulseOrderValue: PulseOrderValue) =>
                    pulseOrderValue.pulseId !== pulseId
            );

        updatedPulsesOrder = updatedPulsesOrder.map(
            (pulseOrderValue: PulseOrderValue, i: number) => {
                return {
                    ...pulseOrderValue,
                    sequenceNumber: i,
                };
            }
        );

        this.updateUserPreference({
            pulsesOrder: updatedPulsesOrder,
        })
            .pipe(take(1))
            .subscribe();
    }

    initPulseVisibilityPreference(pulses: IPulse[]): void {
        const pulsesVisibility: PulseVisibilityPreference[] = pulses.map(
            (pulse: IPulse) => {
                return {
                    pulseId: pulse.pulseId,
                    isVisible: true,
                };
            }
        );

        this.updateUserPreference({ pulsesVisibility })
            .pipe(take(1))
            .subscribe();
    }

    addPulseVisibilityPreference(pulse: IPulse): void {
        const pulseVisibility: PulseVisibilityPreference = {
            pulseId: pulse.pulseId,
            isVisible: true,
        };

        const updatedPulsesVisibility: PulseVisibilityPreference[] = [
            ...this.preferences.preferences.pulsesVisibility,
            pulseVisibility,
        ];
        this.updateUserPreference({
            pulsesVisibility: updatedPulsesVisibility,
        })
            .pipe(take(1))
            .subscribe();
    }

    updatePulseVisibilityPreference(
        pulseVisibility: PulseVisibilityPreference
    ): void {
        const updatedPulsesVisibility: PulseVisibilityPreference[] =
            this.preferences.preferences.pulsesVisibility.map(
                (storedPulseVisibility: PulseVisibilityPreference) => {
                    if (
                        pulseVisibility.pulseId ===
                        storedPulseVisibility.pulseId
                    ) {
                        return pulseVisibility;
                    } else {
                        return storedPulseVisibility;
                    }
                }
            );

        this.updateUserPreference({
            pulsesVisibility: updatedPulsesVisibility,
        })
            .pipe(take(1))
            .subscribe();
    }

    deletePulseVisibilityPreference(pulseId: string): void {
        const updatedPulsesVisibility: PulseVisibilityPreference[] =
            this.preferences.preferences.pulsesVisibility.filter(
                (pulseVisibilityPreference: PulseVisibilityPreference) =>
                    pulseVisibilityPreference.pulseId !== pulseId
            );

        this.updateUserPreference({
            pulsesVisibility: updatedPulsesVisibility,
        })
            .pipe(take(1))
            .subscribe();
    }

    deletePulseFromPreferences(pulseId: string): void {
        this.deletePulseOrderPreference(pulseId);
        this.deletePulseVisibilityPreference(pulseId);
    }

    private syncPulseOrderWithPreferences(pulses: IPulse[]): IPulse[] {
        const syncedWithPreferencesPulses: {
            pulses: IPulse[];
        } = {
            pulses: pulses.map((pulse: IPulse) => {
                return {
                    ...pulse,
                    sequenceNumber:
                        this.preferences.preferences.pulsesOrder.find(
                            (pulseOrderValue: PulseOrderValue) =>
                                pulse.pulseId === pulseOrderValue.pulseId
                        )?.sequenceNumber as number,
                };
            }),
        };

        return syncedWithPreferencesPulses.pulses.sort((a: IPulse, b: IPulse) =>
            a.sequenceNumber > b.sequenceNumber ? 1 : -1
        );
    }

    private syncPulsesVisibilityPreference(pulses: IPulse[]): IPulse[] {
        return pulses.map((pulse: IPulse) => {
            return {
                ...pulse,
                isVisible: this.preferences.preferences.pulsesVisibility.find(
                    (pulseVisibility: PulseVisibilityPreference) => {
                        return pulse.pulseId === pulseVisibility.pulseId;
                    }
                )?.isVisible,
            };
        });
    }

    public handlePulseOrderPreferences(res: { pulses: IPulse[] }) {
        res.pulses.forEach((pulse: IPulse) => {
            const pulseInPreference: PulseOrderValue | undefined =
                this.preferences.preferences.pulsesOrder.find(
                    (pulseOrder: PulseOrderValue) =>
                        pulseOrder.pulseId === pulse.pulseId
                );

            if (!pulseInPreference) {
                const updatedPulsesOrderPreference: PulseOrderValue[] = [
                    ...this.preferences.preferences.pulsesOrder,
                    {
                        pulseId: pulse.pulseId,
                        sequenceNumber:
                            this.preferences.preferences.pulsesOrder.length,
                    },
                ];
                this.updatePulseOrderPreference(updatedPulsesOrderPreference);
            }
        });

        res = {
            pulses: this.syncPulseOrderWithPreferences(res.pulses),
        };

        return res;
    }

    public handlePulseVisibilityPreference(res: { pulses: IPulse[] }) {
        res.pulses.forEach((pulse: IPulse) => {
            const pulseInPreference: PulseVisibilityPreference | undefined =
                this.preferences.preferences.pulsesVisibility.find(
                    (pulseVisibility: PulseVisibilityPreference) =>
                        pulseVisibility.pulseId === pulse.pulseId
                );

            if (!pulseInPreference) {
                this.addPulseVisibilityPreference(pulse);
            }
        });

        res = {
            pulses: this.syncPulsesVisibilityPreference(res.pulses),
        };
        return res;
    }
}

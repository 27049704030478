import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DevicePositionRoutingModule } from './device-position-routing.module';
import { DevicePositionComponent } from './device-position.component';
import { AgGridModule } from '@ag-grid-community/angular';
import { DevicePositionToolsPanelModule } from './device-position-tools-panel';
import { RowsPerPageModule } from '@app/shared/grid/rows-per-page';
import { SetLocationComponent } from './set-location/set-location.component';
import { AppDialogModule } from '@app/shared/app-dialog';
import { MatButtonModule } from '@angular/material/button';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { CustomDateCellRendererModule } from '@app/shared/grid/cell-renderer';

@NgModule({
    declarations: [DevicePositionComponent, SetLocationComponent],
    imports: [
        CommonModule,
        DevicePositionRoutingModule,
        AgGridModule,
        DevicePositionToolsPanelModule,
        ReactiveFormsModule,
        RowsPerPageModule,
        AppDialogModule,
        MatButtonModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        CustomDateCellRendererModule
    ],
})
export class DevicePositionModule {}

import { Injectable } from '@angular/core';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { AppRestService, GridService } from '@app/shared';
import { Observable, map, switchMap } from 'rxjs';
import { usersSettings } from './users.settings';
import { UsersData, UsersDataEnum } from './users.interface';
import { PermissionsName } from './create-account/permissions-config/permissions-config';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from 'environments/environment.service';

@Injectable({
    providedIn: 'root',
})
export class UsersService {
    constructor(
        public _appRestService: AppRestService,
        public _gridService: GridService,
        public _companyContextService: CompanyContextService,
        private http : HttpClient,
        private envService: EnvironmentService
    ) {}

    public users(): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        const params: { [key: string]: any } = {
            inlinecount: 'allpages',
        };

        const query = new URLSearchParams(params).toString();

        return this._companyContextService
            .getCompanyContext()
            .pipe(
                switchMap((currentCompany: string) =>
                    this._appRestService
                        .get(`account/${currentCompany}/users`, { query })
                        .pipe(map(this._queryResultMapper.bind(this)))
                )
            );
    }

    defineRoleDisplayValue(roleName: PermissionsName, roles: any[]): string {
        const currentRoleObjects = roles.filter((role) =>
            role.name.includes(roleName)
        );

        if (currentRoleObjects.length) {
            const manageRole = currentRoleObjects.find(
                (role: any) => role.displayValue === 'Manage'
            );

            if (manageRole) {
                return 'Edit';
            } else {
                return 'View';
            }
        } else {
            return '-';
        }
    }

    generateRowDataItem(item: any) {
        return {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            email: item.email,
            companyContext: item.companyContext,
            assignedRole: item.customRole?.name,
            applicationRoles: item.applicationRoles,
            dashboardPermission: this.defineRoleDisplayValue(
                PermissionsName.Dashboard,
                item.applicationRoles
            ),
            heartbeatsPermission: this.defineRoleDisplayValue(
                PermissionsName.Heartbeats,
                item.applicationRoles
            ),
            eventHistoryPermission: this.defineRoleDisplayValue(
                PermissionsName.EventHistory,
                item.applicationRoles
            ),
            mapPermission: this.defineRoleDisplayValue(
                PermissionsName.Map,
                item.applicationRoles
            ),
            devicePositionPermission: this.defineRoleDisplayValue(
                PermissionsName.DevicePosition,
                item.applicationRoles
            ),
            positionHistoryPermission: this.defineRoleDisplayValue(
                PermissionsName.PositionHistory,
                item.applicationRoles
            ),
            vehiclesPermission: this.defineRoleDisplayValue(
                PermissionsName.Vehicles,
                item.applicationRoles
            ),
            commandsPermission: this.defineRoleDisplayValue(
                PermissionsName.Commands,
                item.applicationRoles
            ),
            usersPermission: this.defineRoleDisplayValue(
                PermissionsName.Users,
                item.applicationRoles
            ),
        };
    }

    public _queryResultMapper(data: any): {
        rowCount: number;
        rowData: Array<any>;
    } {
        const rowData: UsersData[] = data?.map((item: any) => {
            return this.generateRowDataItem(item);
        });
        return {
            rowCount: data?.length,
            rowData,
        };
    }

    public getDataFieldNameByKey(key: string): string {
        return usersSettings[
            UsersDataEnum[key as keyof typeof UsersDataEnum]
        ]?.dataFieldName.replace('.', '/');
    }

    public deleteUser(userData: any): Observable<any>{
        const url = this.envService.apiUrl + `/account/${userData.companyContext}/users`;
        return this.http.request('delete', url, {body: {companyContext: userData.companyContext, id: userData.id, email: userData.email}});
    }
}
<app-devices-tool-panel
    [isAvailableToExport]="isAvailableToExport"
    [progressValueExport]="progressValueExport"
    [isOpenFilters]="isOpenFilters"
    [isOpenColumns]="isOpenColumns"
    (exportToExcel)="exportToExcel()"
    (resetAll)="resetAll()"
    (clearSorting)="clearSorting()"
    (clearFiltering)="clearFiltering()"
    (clearRowGrouping)="clearRowGrouping()"
    (resetColumnOrder)="resetColumnOrder()"
    (resetColumnVisibility)="
        resetColumnVisibility()
    "
    (openFilters)="openFilters()"
    (openColumns)="openColumns()"
>
</app-devices-tool-panel>

<ag-grid-angular
    class="ag-theme-alpine"
    [ngClass]="{'auto-height': isAutoHeight}"
    [modules]="modules"
    [columnDefs]="columnDefs"
    [sideBar]="sideBar"
    [rowSelection]="'multiple'"
    [defaultColDef]="defaultColDef"
    [suppressRowClickSelection]="true"
    [autoGroupColumnDef]="autoGroupColumnDef"
    [animateRows]="true"
    [suppressDragLeaveHidesColumns]="false"
    [suppressMakeColumnVisibleAfterUnGroup]="true"
    [suppressRowGroupHidesColumns]="true"
    [rowGroupPanelShow]="rowGroupPanelShow"
    [rowModelType]="'clientSide'"
    [domLayout]="'autoHeight'"
    [rowData]="rowData"
    [groupDisplayType]="groupDisplayType"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [maintainColumnOrder]="true"
    [components]="components"
    [getLocaleText]="getLocaleText"
    [enableCellTextSelection]="enableCellTextSelection"
    [ensureDomOrder]="ensureDomOrder"
    (gridReady)="onGridReady($event)"
    (selectionChanged)="handleSelectionChange($event)"
>
</ag-grid-angular>

<div #rowsPerPage2>
    <app-rows-per-page (pageSize)="onPageSizeChanged($event)">
    </app-rows-per-page>
</div>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommandDetailsToolsPanelComponent } from './command-details-tools-panel.component';
import { DefaultTableToolsPanelModule } from '@app/shared/tools-panel/default-table-tools-panel';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
    declarations: [CommandDetailsToolsPanelComponent],
    exports: [CommandDetailsToolsPanelComponent],
    imports: [
        CommonModule,
        DefaultTableToolsPanelModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
    ],
})
export class CommandDetailsToolsPanelModule {}

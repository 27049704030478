import { Injectable } from '@angular/core';
import { AppRestService, GridService } from '@app/shared';
import { Observable, map, switchMap } from 'rxjs';
import { commandsSettings } from './commands.settings';
import { CommandsDataEnum } from './commands.interface';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@auth0/auth0-angular';
import { EnvironmentService } from 'environments/environment.service';
import { Modality } from '@app/models/modality.enum';

@Injectable({
    providedIn: 'root',
})
export class CommandsService {
    constructor(
        public _appRestService: AppRestService,
        public _gridService: GridService,
        public _companyContextService: CompanyContextService,
        public http: HttpClient,
        public auth: AuthService,
        private envService: EnvironmentService    ) {}

    public jobs(modality = Modality.None): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        const params: { [key: string]: any } = {
            isAggregate: 'true',
        };

        const query = new URLSearchParams(params).toString();

        return this._companyContextService
            .getCompanyContext()
            .pipe(
                switchMap((currentCompany: string) =>
                    this._appRestService
                        .get(`companies/${currentCompany}/commands/${modality}`)
                        .pipe(map(this._queryResultMapper))
                )
            );
    }

    public _queryResultMapper(data: any): {
        rowCount: number;
        rowData: Array<any>;
    } {
        return {
            rowCount: data.totalCount,
            rowData: data.result,
        };
    }

    public getDataFieldNameByKey(key: string): string {
        return commandsSettings[
            CommandsDataEnum[key as keyof typeof CommandsDataEnum]
        ]?.dataFieldName.replace('.', '/');
    }

    public createCommand(body: any): Observable<any> {
        return this._appRestService.post('jobs', body);
    }

    public uploadFiles(files: FormData): Observable<any> {
        return this.auth.getAccessTokenSilently().pipe(
            switchMap((token: string) => {
                const headers = new HttpHeaders();
                headers.set('Authorization', 'Bearer ' + token);

                return this.http.post(
                    this.envService.apiUrl + '/jobs/uploadSave',
                    files,
                    {
                        headers: headers,
                    }
                );
            })
        );
    }

    public removeFile(fileNames: FormData): Observable<any> {
        const headers = new HttpHeaders();
        headers.set(
            'Content-Type',
            'application/x-www-form-urlencoded; charset=UTF-8'
        );

        return this._appRestService.post('jobs/uploadRemove', fileNames, {
            headers,
        });
    }
}

import { NgModule } from '@angular/core';
import { UploadFilesComponent } from './upload-files.component';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [UploadFilesComponent],
    imports: [CommonModule, MatIconModule],
    exports: [UploadFilesComponent],
})
export class UploadFilesModule {}

export enum EventsDataEnum {
    connectionState = 'connectionState',
    deviceSerialNo = 'deviceSerialNo',
    validatorStatus = 'validatorStatus',
    statusCode = 'statusCode',
    reasonStatus = 'reasonStatus',
    validatorRole = 'validatorRole',
    validatorPositionNo = 'validatorPositionNo',

    vehicle = 'vehicle',
    vehicleType = 'vehicleType',
    PTO = 'PTO',
    kv6Status = 'kv6Status',
    location = 'location',

    lastReceivedEventType = 'lastReceivedEventType',
    lastMessageReceivedDate = 'lastMessageReceivedDate',
    messageGeneratedDate = 'messageGeneratedDate',
    messageIDCounter = 'messageIDCounter',

    OVCStatus = 'OVCStatus',
    OVCStatusReason = 'OVCStatusReason',
    numberOfSDOATransactionsSinceLastPowerOn = 'numberOfSDOATransactionsSinceLastPowerOn',
    EMVStatus = 'EMVStatus',
    EMVStatusReason = 'EMVStatusReason',
    numberOfEMVTransactionsSinceLastPowerOn = 'numberOfEMVTransactionsSinceLastPowerOn',
    CLTStatus = 'CLTStatus',
    CLTStatusReason = 'CLTStatusReason',
    // CLT Transactions since Power On
    BCStatus = 'BCStatus',
    BCStatusReason = 'BCStatusReason',
    numberOfBarcodeTransactionsSinceLastPowerOn = 'numberOfBarcodeTransactionsSinceLastPowerOn',

    publicLineNo = 'publicLineNo',
    trip = 'trip',
    previousStation = 'previousStation',
    previousStationRefNo = 'previousStationRefNo',
    nextStation = 'nextStation',
    betweenStops = 'betweenStops',
    nextStationRefNo = 'nextStationRefNo',
    EBSInterfaceStatus = 'EBSInterfaceStatus',

    errorMessage = 'errorMessage',
    errorCode = 'errorCode',
    warningMessage = 'warningMessage',
    warningCode = 'warningCode',

    validatorModel = 'validatorModel',
    hasBarcodeReader = 'hasBarcodeReader',
    servicePatternId = 'servicePatternId',
    ramFree = 'ramFree',
    ramTotal = 'ramTotal',
    diskFree = 'diskFree',
    diskTotal = 'diskTotal',
    internalIPAddress = 'internalIPAddress',

    softwareVersion = 'softwareVersion',
    lastSoftwareUpdate = 'lastSoftwareUpdate',
    validatorConfigVersion = 'validatorConfigVersion',
    validatorVersion = 'validatorVersion',
    issuerAccessListVersion = 'issuerAccessListVersion',
    tokenAccessListVersion = 'tokenAccessListVersion',

    ceilingValueCSCLeft = 'ceilingValueCSCLeft',
    ceilingValueCTLeft = 'ceilingValueCTLeft',
    IDBT_SAMUserAuthenticationCounter = 'IDBT_SAMUserAuthenticationCounter',
    IDBT_SAM = 'IDBT_SAM',
    PKI_SAM = 'PKI_SAM',
    PKI_SAMType = 'PKI_SAMType',
    PKI_SAM_BEID = 'PKI_SAM_BEID',

    EODCurrentEffectiveDate = 'EODCurrentEffectiveDate',
    EODFutureEffectiveDate = 'EODFutureEffectiveDate',
    EODMCFVersionCurrent = 'EODMCFVersionCurrent',
    EODTopologyVersionCurrent = 'EODTopologyVersionCurrent',
    EODTopologyVersionFuture = 'EODTopologyVersionFuture',
    EODFareVersionCurrent = 'EODFareVersionCurrent',
    EODFareVersionFuture = 'EODFareVersionFuture',
    EODDicoVersionCurrent = 'EODDicoVersionCurrent',
    EODDicoVersionFuture = 'EODDicoVersionFuture',
    EODTimeVersionCurrent = 'EODTimeVersionCurrent',
    EODTimeVersionFuture = 'EODTimeVersionFuture',
    EODBlackListVersionCurrent = 'EODBlackListVersionCurrent',

    TXARNumberOfTransaction = 'TXARNumberOfTransaction',
    TXARUploadDate = 'TXARUploadDate',
    TXARFileCreateDate = 'TXARFileCreateDate',
    TXARFileName = 'TXARFileName',
    connectionStateUpdatedOn = 'connectionStateUpdatedOn',
}

export type EventsData = {
    [key in EventsDataEnum]: any;
} & { eventMonitorId?: string };

export type EventsSettings = {
    [key in EventsDataEnum]: {
        dataFieldName: string;
        label: string;
        cellRendererComponent?: any;
        hide?: boolean;
        onCellClicked?: any;
    };
};

export type EventsColumnSettings = {
    key: EventsDataEnum;
    visible: boolean;
    toIndex: number;
};

import { RadioButtonCellRendererComponent } from "@app/shared/grid/cell-renderer";
import { ValidatorGroupDataEnum, ValidatorGroupsSettings } from "./validator-groups.interface";


export const validatorGroupsSettings: ValidatorGroupsSettings = {
    [ValidatorGroupDataEnum.radioButton]: { 
        dataFieldName: ValidatorGroupDataEnum.radioButton,
        label: '',
        cellRendererComponent: RadioButtonCellRendererComponent,
        alwaysAvailable: true,
        suppressMenu: true,
        suppressSortable: true,
        suppressFilterable: true,
        lockVisible: true,
        suppressDragLeaveHidesColumns: true,
    },
    [ValidatorGroupDataEnum.groupId]: { 
        dataFieldName: ValidatorGroupDataEnum.groupId,
        label: 'Group Id',
    },
    [ValidatorGroupDataEnum.name]: { 
        dataFieldName: ValidatorGroupDataEnum.name,
        label: 'Reference',
    },
    [ValidatorGroupDataEnum.vehicleModel]: { 
        dataFieldName: ValidatorGroupDataEnum.vehicleModel,
        label: 'Vehicle Model',
    },
    [ValidatorGroupDataEnum.position]: { 
        dataFieldName: ValidatorGroupDataEnum.position,
        label: 'Position',
    },

} as ValidatorGroupsSettings;

export const DataFieldNameMap: Map<string, string> = Object.keys(ValidatorGroupDataEnum).reduce(
    (acc: Map<string, string>, field: string) => (acc.set(
        validatorGroupsSettings[ValidatorGroupDataEnum[field as keyof typeof ValidatorGroupDataEnum]].dataFieldName, 
        field
    )),
    new Map<string, string>
);

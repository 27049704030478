<div [formGroup]="vehicleDetailsForm">
    <mat-form-field appearance="outline">
        <mat-label>Concession/Modality</mat-label>
        <input
            matInput
            formControlName="company"
        />
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>EEPROM PTO Value</mat-label>
        <input
            matInput
            formControlName="eepromPTO"
        />
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Vehicle Model</mat-label>
        <input
            matInput
            formControlName="vehicleModel"
        />
    </mat-form-field>
    <mat-form-field appearance="outline">
        <mat-label>Vehicle ID</mat-label>
        <input
            matInput
            formControlName="vehicleId"
        />
    </mat-form-field>
    <div>
        <mat-checkbox formControlName="pilot">Pilot</mat-checkbox>

    </div>
    <div class="button-bar">
        <button mat-stroked-button color="primary" (click)="cancel()">
            Close
        </button>
    </div>
</div>

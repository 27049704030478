import { Type } from '@angular/core';

export interface AppDialogConfig {
    component?: Type<any> | null;
    title: string;
    subTitle?: string;
    dialogMessage?: string;
    dialogSize: DialogSizes;
    showDialogButtons: boolean;
    okText?: string;
    cancelText?: string;
    panelClass: string[];
    disableClose?: boolean;
    data?: any;
}

export enum DialogSizes {
    Small = '25rem',
    Medium = '38.75rem',
    Large = '52.5rem',
    XLarge = '62.5rem',
    Full = '100%',
}

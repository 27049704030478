import { Injectable } from '@angular/core';
import { Observable, filter, map, of, switchMap, tap } from 'rxjs';
import {
    AppRestService,
    GridService,
    KeySettings,
    SettingsCacheService,
    TableField,
} from '@app/shared';
import { EventsData, EventsDataEnum } from './heartbeats.interface';
import { ColumnVO, IServerSideGetRowsRequest } from '@ag-grid-community/core';
import { DataFieldNameMap, eventsSettings } from './heartbeats.settings';
import { CompanyContextService } from '@app/services/company-context/company-context.service';
import { Modality } from '@app/models/modality.enum';

@Injectable({
    providedIn: 'root',
})
export class HeartbeatsService {
    public _groupeByResultCache = new Map<string, any>();

    constructor(
        public _appRestService: AppRestService,
        public _gridService: GridService,
        public _companyContextService: CompanyContextService,
        private _settingsCacheService: SettingsCacheService
    ) {}

    public companies(
        request: IServerSideGetRowsRequest,
        pageSize: number,
        modality: Modality = Modality.All
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        return this._companyContextService.getCompanyContext().pipe(
            filter(value => value !== ''),
            switchMap((currentCompany: string) => {
                if (this._isGroupByRequest(request)) {
                    return this.groupBy(request, currentCompany);
                } else {
                    return this.query(
                        request,
                        pageSize,
                        currentCompany,
                        modality
                    );
                }
            })
        );
    }

    public getTableFields(): Observable<Array<TableField>> {
        let tableFields: Array<TableField> = [];
        tableFields =
            this._settingsCacheService.getDataFromLocalStorage(
                KeySettings.TableFields
            );
        if (tableFields.length > 0) {
            return of(tableFields);
        } else {
            this._appRestService
                .get('heartbeat-metadata/pulse')
                .subscribe((data) => {
                    this._settingsCacheService.saveDataToLocalStorage(
                        KeySettings.TableFields,
                        data
                    );
                    tableFields = data;
                    return of(tableFields);
                });            
        }
        return of(tableFields);
    }

    public getTableFieldsMap(): Observable<Map<string, TableField>> {
        return this.getTableFields().pipe(
            map((list: Array<TableField>) =>
                list.reduce(
                    (acc: Map<string, TableField>, item: TableField) =>
                        acc.set(item.dataFieldName, item),
                    new Map<string, TableField>()
                )
            )
        );
    }

    public _isGroupByRequest(request: IServerSideGetRowsRequest): boolean {
        return !!(
            request.rowGroupCols.length &&
            request.groupKeys.length < request.rowGroupCols.length
        );
    }

    public groupBy(
        request: IServerSideGetRowsRequest,
        currentCompany: string
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        const body = {
            groupByFields: request.rowGroupCols.map(
                (item: ColumnVO) =>
                    eventsSettings[
                        EventsDataEnum[item.id as keyof typeof EventsDataEnum]
                    ].dataFieldName
            ),
        };

        const bodyString = JSON.stringify(body);

        if (this._groupeByResultCache.has(bodyString)) {
            return of(this._groupeByResultCache.get(bodyString)).pipe(
                map((data) =>
                    this._gridService.groupByResultMap(
                        data,
                        request.groupKeys,
                        DataFieldNameMap
                    )
                )
            );
        } else {
            return this._appRestService
                .post(`companies/${currentCompany}/monitors/group-by`, body)
                .pipe(
                    tap((data: any) =>
                        this._groupeByResultCache.set(bodyString, data)
                    ),
                    map((data) =>
                        this._gridService.groupByResultMap(
                            data,
                            request.groupKeys,
                            DataFieldNameMap
                        )
                    )
                );
        }
    }

    public query(
        request: IServerSideGetRowsRequest,
        pageSize: number,
        currentCompany: string,
        modality: Modality = Modality.All
    ): Observable<{
        rowCount: number;
        rowData: Array<any>;
    }> {
        const params: { [key: string]: any } = {
            inlinecount: 'allpages',
            count: true,
            skip: (request.endRow ?? 0) - pageSize,
            top: pageSize,
        };
        
        const query =
            new URLSearchParams(params).toString() +
            this._gridService.createOrderByParams(
                request.sortModel,
                this.getDataFieldNameByKey.bind(this)
            ) +
            this._gridService.createFilterParams(
                request,
                this.getDataFieldNameByKey.bind(this)
            );

        return this._appRestService
            .get(`companies/${currentCompany}/monitors/query/${modality}`, {
                query,
            })
            .pipe(map(this._queryResultMapper));
    }

    public _queryResultMapper(data: any): {
        rowCount: number;
        rowData: Array<any>;
    } {
        return {
            rowCount: data.totalCount,
            rowData: data.result.map(
                (item: any) =>
                    <EventsData>{
                        connectionState: item.body.connectionState,
                        deviceSerialNo: item.header.deviceSerialNo,

                        lastReceivedEventType: item.header.messageType,
                        lastMessageReceivedDate: item.createdOn,
                        messageGeneratedDate:
                            item.header.messageGeneratedDateTime,
                        messageIDCounter: item.header.messageCounter,
                        PTO: item.companyName,
                        vehicle: item.header.vehicleID,
                        validatorPositionNo: item.header.validatorPositionNo,
                        vehicleType: item.body.vehicleType,
                        OVCStatus: item.body.ovcStatus,
                        OVCStatusReason: item.body.ovcStatusReason,
                        EMVStatus: item.body.emvStatus,
                        EMVStatusReason: item.body.emvStatusReason,
                        BCStatus: item.body.bcStatus,
                        BCStatusReason: item.body.bcStatusReason,
                        CLTStatus: item.body.cltStatus,
                        CLTStatusReason: item.body.cltStatusReason,
                        validatorRole: item.body.validatorRole,
                        validatorModel: item.body.validatorModel,
                        publicLineNo: item.body.publicLineNo,
                        previousStation: item.body.previousStationName,
                        nextStation: item.body.nextStationName,
                        previousStationRefNo: item.body.previousStationRefNo,
                        nextStationRefNo: item.body.nextStationRefNo,
                        trip: item.body.tripNo,
                        servicePatternId: item.body.servicePatternId,
                        validatorStatus: item.body.validatorStatus,
                        statusCode: item.body.statusCode,
                        reasonStatus: item.body.reasonStatus,
                        errorCode: item.body.errorCode,
                        errorMessage: item.body.errorMessage,
                        warningCode: item.body.warningCode,
                        warningMessage: item.body.warningMessage,
                        EBSInterfaceStatus: item.body.ebsInterfaceStatus,
                        lastSoftwareUpdate: item.body.lastSoftwareUpdateDate,
                        softwareVersion: item.body.softwareVersion,
                        validatorConfigVersion:
                            item.body.validatorConfigVersion,
                        validatorVersion: item.body.validatorVersion,
                        issuerAccessListVersion:
                            item.body.issuerAccessListVersion,
                        tokenAccessListVersion:
                            item.body.tokenAccessListVersion,
                        ceilingValueCSCLeft: item.body.ceilingValueCSCLeft,
                        ceilingValueCTLeft: item.body.ceilingValueCTLeft,
                        IDBT_SAMUserAuthenticationCounter:
                            item.body.idbtsamUserAuthenticationCounter,
                        IDBT_SAM: item.header.idbtsam,
                        PKI_SAM: item.header.pkisam,
                        PKI_SAMType: item.body.pkisamType,
                        PKI_SAM_BEID: item.body.pkisambeid,
                        EODCurrentEffectiveDate:
                            item.body.eodEffectiveDateCurrent,
                        EODMCFVersionCurrent: item.body.eodmcfVersionCurrent,
                        EODTopologyVersionCurrent:
                            item.body.eodTopologyVersionCurrent,
                        EODFareVersionCurrent: item.body.eodFareVersionCurrent,
                        EODDicoVersionCurrent: item.body.eodDicoVersionCurrent,
                        EODBlackListVersionCurrent:
                            item.body.eodBlackListCurrent,
                        EODTimeVersionCurrent: item.body.eodTimeVersionCurrent,
                        EODFutureEffectiveDate:
                            item.body.eodEffectiveDateFuture,
                        EODTopologyVersionFuture:
                            item.body.eodTopologyVersionFuture,
                        EODFareVersionFuture: item.body.eodFareVersionFuture,
                        EODDicoVersionFuture: item.body.eodDicoVersionFuture,
                        EODTimeVersionFuture: item.body.eodTimeVersionFuture,
                        TXARUploadDate: item.body.txarUploadDate,
                        TXARFileCreateDate: item.body.txarFileCreateDate,
                        TXARNumberOfTransaction: item.body.numberOfTransaction,
                        TXARFileName: item.body.fileName,
                        numberOfSDOATransactionsSinceLastPowerOn:
                            item.body.numberOfSDOATransactions,
                        numberOfEMVTransactionsSinceLastPowerOn:
                            item.body.numberOfEMVTransactions,
                        numberOfBarcodeTransactionsSinceLastPowerOn:
                            item.body.numberOfBarcodeTransactions,
                        ramTotal: item.body.ramTotal,
                        ramFree: item.body.ramFree,
                        diskTotal: item.body.diskTotal,
                        diskFree: item.body.diskFree,
                        internalIPAddress: item.body.internalIPaddress,
                        location: item.header.vehicleID, //item.header.gpsData,
                        kv6Status: item.body.kv6Status,
                        connectionStateUpdatedOn: item.body.connectionStateUpdatedOn,
                        betweenStops: item.body.betweenStops,
                        hasBarcodeReader: item.body.hasBarcodeReader,
                    }
            ),
        };
    }

    public getDataFieldNameByKey(key: string): string {
        return eventsSettings[
            EventsDataEnum[key as keyof typeof EventsDataEnum]
        ]?.dataFieldName.replace('.', '/');
    }
}
